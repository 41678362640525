/* tslint:disable */
/* eslint-disable */
/**
 * Method import API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  EndpointExceptionBody,
  Methodic,
  MethodicCriteria,
  PageMethodic,
} from '../models/index';
import {
    EndpointExceptionBodyFromJSON,
    EndpointExceptionBodyToJSON,
    MethodicFromJSON,
    MethodicToJSON,
    MethodicCriteriaFromJSON,
    MethodicCriteriaToJSON,
    PageMethodicFromJSON,
    PageMethodicToJSON,
} from '../models/index';

export interface MethodicControllerApiDeleteMethodicRequest {
    id: number;
}

export interface MethodicControllerApiFindMethodicBySearchCriteriaRequest {
    methodicCriteria: MethodicCriteria;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface MethodicControllerApiUpdateMethodicRequest {
    methodic: Methodic;
}

/**
 * 
 */
export class MethodicControllerApi extends runtime.BaseAPI {

    /**
     */
    async deleteMethodicRaw(requestParameters: MethodicControllerApiDeleteMethodicRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteMethodic.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/methodic/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteMethodic(id: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteMethodicRaw({ id: id }, initOverrides);
    }

    /**
     */
    async findMethodicBySearchCriteriaRaw(requestParameters: MethodicControllerApiFindMethodicBySearchCriteriaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageMethodic>> {
        if (requestParameters.methodicCriteria === null || requestParameters.methodicCriteria === undefined) {
            throw new runtime.RequiredError('methodicCriteria','Required parameter requestParameters.methodicCriteria was null or undefined when calling findMethodicBySearchCriteria.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/methodic/by-criteria`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MethodicCriteriaToJSON(requestParameters.methodicCriteria),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageMethodicFromJSON(jsonValue));
    }

    /**
     */
    async findMethodicBySearchCriteria(methodicCriteria: MethodicCriteria, page?: number, size?: number, sort?: Array<string>, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageMethodic> {
        const response = await this.findMethodicBySearchCriteriaRaw({ methodicCriteria: methodicCriteria, page: page, size: size, sort: sort }, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateMethodicRaw(requestParameters: MethodicControllerApiUpdateMethodicRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Methodic>> {
        if (requestParameters.methodic === null || requestParameters.methodic === undefined) {
            throw new runtime.RequiredError('methodic','Required parameter requestParameters.methodic was null or undefined when calling updateMethodic.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/methodic/`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MethodicToJSON(requestParameters.methodic),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MethodicFromJSON(jsonValue));
    }

    /**
     */
    async updateMethodic(methodic: Methodic, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Methodic> {
        const response = await this.updateMethodicRaw({ methodic: methodic }, initOverrides);
        return await response.value();
    }

}
