/* tslint:disable */
/* eslint-disable */
/**
 * Overview API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  EndpointExceptionBody,
  FilterDataCreateSo,
  FilterDataSearchSo,
  FilterDataSo,
  FilterDataUpdateSo,
  PageFilterDataSo,
} from '../models/index';
import {
    EndpointExceptionBodyFromJSON,
    EndpointExceptionBodyToJSON,
    FilterDataCreateSoFromJSON,
    FilterDataCreateSoToJSON,
    FilterDataSearchSoFromJSON,
    FilterDataSearchSoToJSON,
    FilterDataSoFromJSON,
    FilterDataSoToJSON,
    FilterDataUpdateSoFromJSON,
    FilterDataUpdateSoToJSON,
    PageFilterDataSoFromJSON,
    PageFilterDataSoToJSON,
} from '../models/index';

export interface FilterApiCreateFilterRequest {
    filterDataCreateSo: FilterDataCreateSo;
}

export interface FilterApiDeleteFilterByIdRequest {
    id: number;
}

export interface FilterApiDeleteFilterByUserRequest {
    requestBody: Array<number>;
}

export interface FilterApiGetFilterByIdRequest {
    id: number;
}

export interface FilterApiGetFiltersRequest {
    filterDataSearchSo: FilterDataSearchSo;
    pageNumber?: number;
    pageSize?: number;
}

export interface FilterApiUpdateFilterRequest {
    filterDataUpdateSo: FilterDataUpdateSo;
}

/**
 * 
 */
export class FilterApi extends runtime.BaseAPI {

    /**
     * Create filter for given user
     */
    async createFilterRaw(requestParameters: FilterApiCreateFilterRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FilterDataSo>> {
        if (requestParameters.filterDataCreateSo === null || requestParameters.filterDataCreateSo === undefined) {
            throw new runtime.RequiredError('filterDataCreateSo','Required parameter requestParameters.filterDataCreateSo was null or undefined when calling createFilter.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/filter`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FilterDataCreateSoToJSON(requestParameters.filterDataCreateSo),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FilterDataSoFromJSON(jsonValue));
    }

    /**
     * Create filter for given user
     */
    async createFilter(filterDataCreateSo: FilterDataCreateSo, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FilterDataSo> {
        const response = await this.createFilterRaw({ filterDataCreateSo: filterDataCreateSo }, initOverrides);
        return await response.value();
    }

    /**
     * Delete filter by id
     */
    async deleteFilterByIdRaw(requestParameters: FilterApiDeleteFilterByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteFilterById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/filter/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete filter by id
     */
    async deleteFilterById(id: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteFilterByIdRaw({ id: id }, initOverrides);
    }

    /**
     * Delete filters by user
     */
    async deleteFilterByUserRaw(requestParameters: FilterApiDeleteFilterByUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
            throw new runtime.RequiredError('requestBody','Required parameter requestParameters.requestBody was null or undefined when calling deleteFilterByUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/filter/delete`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete filters by user
     */
    async deleteFilterByUser(requestBody: Array<number>, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteFilterByUserRaw({ requestBody: requestBody }, initOverrides);
    }

    /**
     * Update filter for given user
     */
    async getFilterByIdRaw(requestParameters: FilterApiGetFilterByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FilterDataSo>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getFilterById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/filter/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FilterDataSoFromJSON(jsonValue));
    }

    /**
     * Update filter for given user
     */
    async getFilterById(id: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FilterDataSo> {
        const response = await this.getFilterByIdRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     * Get filters for given user
     */
    async getFiltersRaw(requestParameters: FilterApiGetFiltersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageFilterDataSo>> {
        if (requestParameters.filterDataSearchSo === null || requestParameters.filterDataSearchSo === undefined) {
            throw new runtime.RequiredError('filterDataSearchSo','Required parameter requestParameters.filterDataSearchSo was null or undefined when calling getFilters.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/filter/list`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FilterDataSearchSoToJSON(requestParameters.filterDataSearchSo),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageFilterDataSoFromJSON(jsonValue));
    }

    /**
     * Get filters for given user
     */
    async getFilters(filterDataSearchSo: FilterDataSearchSo, pageNumber?: number, pageSize?: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageFilterDataSo> {
        const response = await this.getFiltersRaw({ filterDataSearchSo: filterDataSearchSo, pageNumber: pageNumber, pageSize: pageSize }, initOverrides);
        return await response.value();
    }

    /**
     * Update filter for given user
     */
    async updateFilterRaw(requestParameters: FilterApiUpdateFilterRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FilterDataSo>> {
        if (requestParameters.filterDataUpdateSo === null || requestParameters.filterDataUpdateSo === undefined) {
            throw new runtime.RequiredError('filterDataUpdateSo','Required parameter requestParameters.filterDataUpdateSo was null or undefined when calling updateFilter.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/filter`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: FilterDataUpdateSoToJSON(requestParameters.filterDataUpdateSo),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FilterDataSoFromJSON(jsonValue));
    }

    /**
     * Update filter for given user
     */
    async updateFilter(filterDataUpdateSo: FilterDataUpdateSo, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FilterDataSo> {
        const response = await this.updateFilterRaw({ filterDataUpdateSo: filterDataUpdateSo }, initOverrides);
        return await response.value();
    }

}
