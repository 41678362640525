/* tslint:disable */
/* eslint-disable */
/**
 * Data import metadata
 * Administration of metadata for import
 *
 * The version of the OpenAPI document: 1.0
 * Contact: schvarcz@bb.microcomp.sk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RowAttributeDefinitionResponse
 */
export interface RowAttributeDefinitionResponse {
    /**
     * 
     * @type {string}
     * @memberof RowAttributeDefinitionResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof RowAttributeDefinitionResponse
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof RowAttributeDefinitionResponse
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof RowAttributeDefinitionResponse
     */
    dataType?: RowAttributeDefinitionResponseDataTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof RowAttributeDefinitionResponse
     */
    required?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RowAttributeDefinitionResponse
     */
    rowAttributeType?: RowAttributeDefinitionResponseRowAttributeTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof RowAttributeDefinitionResponse
     */
    skipIfNull?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof RowAttributeDefinitionResponse
     */
    rowAttributeTransformationIds?: Array<string>;
}


/**
 * @export
 */
export const RowAttributeDefinitionResponseDataTypeEnum = {
    Tinyint: 'TINYINT',
    Smallint: 'SMALLINT',
    Integer: 'INTEGER',
    Bigint: 'BIGINT',
    Real: 'REAL',
    Double: 'DOUBLE',
    Numeric: 'NUMERIC',
    Decimal: 'DECIMAL',
    Char: 'CHAR',
    Varchar: 'VARCHAR',
    Longvarchar: 'LONGVARCHAR',
    Longnvarchar: 'LONGNVARCHAR',
    Date: 'DATE',
    Time: 'TIME',
    Null: 'NULL',
    Timestamp: 'TIMESTAMP',
    Boolean: 'BOOLEAN',
    Rowid: 'ROWID',
    Nchar: 'NCHAR',
    Nvarchar: 'NVARCHAR',
    TimeWithTimezone: 'TIME_WITH_TIMEZONE',
    TimestampWithTimezone: 'TIMESTAMP_WITH_TIMEZONE',
    Bfile: 'BFILE',
    IsoDateTime: 'ISO_DATE_TIME',
    Bit: 'BIT',
    Binary: 'BINARY',
    DmsFileUuid: 'DMS_FILE_UUID'
} as const;
export type RowAttributeDefinitionResponseDataTypeEnum = typeof RowAttributeDefinitionResponseDataTypeEnum[keyof typeof RowAttributeDefinitionResponseDataTypeEnum];

/**
 * @export
 */
export const RowAttributeDefinitionResponseRowAttributeTypeEnum = {
    Primary: 'PRIMARY',
    Reference: 'REFERENCE',
    Auxiliary: 'AUXILIARY'
} as const;
export type RowAttributeDefinitionResponseRowAttributeTypeEnum = typeof RowAttributeDefinitionResponseRowAttributeTypeEnum[keyof typeof RowAttributeDefinitionResponseRowAttributeTypeEnum];


/**
 * Check if a given object implements the RowAttributeDefinitionResponse interface.
 */
export function instanceOfRowAttributeDefinitionResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RowAttributeDefinitionResponseFromJSON(json: any): RowAttributeDefinitionResponse {
    return RowAttributeDefinitionResponseFromJSONTyped(json, false);
}

export function RowAttributeDefinitionResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): RowAttributeDefinitionResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'dataType': !exists(json, 'dataType') ? undefined : json['dataType'],
        'required': !exists(json, 'required') ? undefined : json['required'],
        'rowAttributeType': !exists(json, 'rowAttributeType') ? undefined : json['rowAttributeType'],
        'skipIfNull': !exists(json, 'skipIfNull') ? undefined : json['skipIfNull'],
        'rowAttributeTransformationIds': !exists(json, 'rowAttributeTransformationIds') ? undefined : json['rowAttributeTransformationIds'],
    };
}

export function RowAttributeDefinitionResponseToJSON(value?: RowAttributeDefinitionResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'code': value.code,
        'dataType': value.dataType,
        'required': value.required,
        'rowAttributeType': value.rowAttributeType,
        'skipIfNull': value.skipIfNull,
        'rowAttributeTransformationIds': value.rowAttributeTransformationIds,
    };
}

