/* tslint:disable */
/* eslint-disable */
/**
 * Data import
 * Modul for importing data to Data Registry.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: schvarcz@bb.microcomp.sk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UploadFileResponse
 */
export interface UploadFileResponse {
    /**
     * 
     * @type {string}
     * @memberof UploadFileResponse
     */
    documentId?: string;
    /**
     * 
     * @type {string}
     * @memberof UploadFileResponse
     */
    filename?: string;
    /**
     * 
     * @type {string}
     * @memberof UploadFileResponse
     */
    contentType?: string;
    /**
     * 
     * @type {number}
     * @memberof UploadFileResponse
     */
    size?: number;
}

/**
 * Check if a given object implements the UploadFileResponse interface.
 */
export function instanceOfUploadFileResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UploadFileResponseFromJSON(json: any): UploadFileResponse {
    return UploadFileResponseFromJSONTyped(json, false);
}

export function UploadFileResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UploadFileResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'documentId': !exists(json, 'documentId') ? undefined : json['documentId'],
        'filename': !exists(json, 'filename') ? undefined : json['filename'],
        'contentType': !exists(json, 'contentType') ? undefined : json['contentType'],
        'size': !exists(json, 'size') ? undefined : json['size'],
    };
}

export function UploadFileResponseToJSON(value?: UploadFileResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'documentId': value.documentId,
        'filename': value.filename,
        'contentType': value.contentType,
        'size': value.size,
    };
}

