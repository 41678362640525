/* tslint:disable */
/* eslint-disable */
/**
 * Overview API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TimeSeriesStatistics
 */
export interface TimeSeriesStatistics {
    /**
     * 
     * @type {number}
     * @memberof TimeSeriesStatistics
     */
    count?: number;
    /**
     * 
     * @type {string}
     * @memberof TimeSeriesStatistics
     */
    min?: string;
    /**
     * 
     * @type {string}
     * @memberof TimeSeriesStatistics
     */
    max?: string;
}

/**
 * Check if a given object implements the TimeSeriesStatistics interface.
 */
export function instanceOfTimeSeriesStatistics(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function TimeSeriesStatisticsFromJSON(json: any): TimeSeriesStatistics {
    return TimeSeriesStatisticsFromJSONTyped(json, false);
}

export function TimeSeriesStatisticsFromJSONTyped(json: any, ignoreDiscriminator: boolean): TimeSeriesStatistics {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'count': !exists(json, 'count') ? undefined : json['count'],
        'min': !exists(json, 'min') ? undefined : json['min'],
        'max': !exists(json, 'max') ? undefined : json['max'],
    };
}

export function TimeSeriesStatisticsToJSON(value?: TimeSeriesStatistics | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'count': value.count,
        'min': value.min,
        'max': value.max,
    };
}

