/* tslint:disable */
/* eslint-disable */
/**
 * Overview API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PreviewDataUpdateSo } from './PreviewDataUpdateSo';
import {
    PreviewDataUpdateSoFromJSON,
    PreviewDataUpdateSoFromJSONTyped,
    PreviewDataUpdateSoToJSON,
} from './PreviewDataUpdateSo';

/**
 * 
 * @export
 * @interface BatchUpdateValuesSo
 */
export interface BatchUpdateValuesSo {
    /**
     * 
     * @type {string}
     * @memberof BatchUpdateValuesSo
     */
    viewReference: string;
    /**
     * 
     * @type {number}
     * @memberof BatchUpdateValuesSo
     */
    filterId: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof BatchUpdateValuesSo
     */
    fetchFilter?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof BatchUpdateValuesSo
     */
    searchField?: string;
    /**
     * 
     * @type {boolean}
     * @memberof BatchUpdateValuesSo
     */
    onlyChangedInDr?: boolean;
    /**
     * 
     * @type {PreviewDataUpdateSo}
     * @memberof BatchUpdateValuesSo
     */
    previewDataUpdateSo: PreviewDataUpdateSo;
}

/**
 * Check if a given object implements the BatchUpdateValuesSo interface.
 */
export function instanceOfBatchUpdateValuesSo(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "viewReference" in value;
    isInstance = isInstance && "filterId" in value;
    isInstance = isInstance && "previewDataUpdateSo" in value;

    return isInstance;
}

export function BatchUpdateValuesSoFromJSON(json: any): BatchUpdateValuesSo {
    return BatchUpdateValuesSoFromJSONTyped(json, false);
}

export function BatchUpdateValuesSoFromJSONTyped(json: any, ignoreDiscriminator: boolean): BatchUpdateValuesSo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'viewReference': json['viewReference'],
        'filterId': json['filterId'],
        'fetchFilter': !exists(json, 'fetchFilter') ? undefined : json['fetchFilter'],
        'searchField': !exists(json, 'searchField') ? undefined : json['searchField'],
        'onlyChangedInDr': !exists(json, 'onlyChangedInDr') ? undefined : json['onlyChangedInDr'],
        'previewDataUpdateSo': PreviewDataUpdateSoFromJSON(json['previewDataUpdateSo']),
    };
}

export function BatchUpdateValuesSoToJSON(value?: BatchUpdateValuesSo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'viewReference': value.viewReference,
        'filterId': value.filterId,
        'fetchFilter': value.fetchFilter,
        'searchField': value.searchField,
        'onlyChangedInDr': value.onlyChangedInDr,
        'previewDataUpdateSo': PreviewDataUpdateSoToJSON(value.previewDataUpdateSo),
    };
}

