/* tslint:disable */
/* eslint-disable */
/**
 * Catalog import API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.4.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CodelistItemInputRequired } from './CodelistItemInputRequired';
import {
    CodelistItemInputRequiredFromJSON,
    CodelistItemInputRequiredFromJSONTyped,
    CodelistItemInputRequiredToJSON,
} from './CodelistItemInputRequired';

/**
 * 
 * @export
 * @interface PointCategoryCodelistItemInputRequired
 */
export interface PointCategoryCodelistItemInputRequired {
    /**
     * 
     * @type {number}
     * @memberof PointCategoryCodelistItemInputRequired
     */
    id?: number;
    /**
     * 
     * @type {CodelistItemInputRequired}
     * @memberof PointCategoryCodelistItemInputRequired
     */
    monitoringPurposeType: CodelistItemInputRequired;
    /**
     * 
     * @type {string}
     * @memberof PointCategoryCodelistItemInputRequired
     */
    dateFrom: string;
    /**
     * 
     * @type {string}
     * @memberof PointCategoryCodelistItemInputRequired
     */
    dateTo?: string;
    /**
     * 
     * @type {string}
     * @memberof PointCategoryCodelistItemInputRequired
     */
    note?: string | null;
}

/**
 * Check if a given object implements the PointCategoryCodelistItemInputRequired interface.
 */
export function instanceOfPointCategoryCodelistItemInputRequired(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "monitoringPurposeType" in value;
    isInstance = isInstance && "dateFrom" in value;

    return isInstance;
}

export function PointCategoryCodelistItemInputRequiredFromJSON(json: any): PointCategoryCodelistItemInputRequired {
    return PointCategoryCodelistItemInputRequiredFromJSONTyped(json, false);
}

export function PointCategoryCodelistItemInputRequiredFromJSONTyped(json: any, ignoreDiscriminator: boolean): PointCategoryCodelistItemInputRequired {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'monitoringPurposeType': CodelistItemInputRequiredFromJSON(json['monitoringPurposeType']),
        'dateFrom': json['dateFrom'],
        'dateTo': !exists(json, 'dateTo') ? undefined : json['dateTo'],
        'note': !exists(json, 'note') ? undefined : json['note'],
    };
}

export function PointCategoryCodelistItemInputRequiredToJSON(value?: PointCategoryCodelistItemInputRequired | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'monitoringPurposeType': CodelistItemInputRequiredToJSON(value.monitoringPurposeType),
        'dateFrom': value.dateFrom,
        'dateTo': value.dateTo,
        'note': value.note,
    };
}

