/* tslint:disable */
/* eslint-disable */
/**
 * Data import
 * Modul for importing data to Data Registry.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: schvarcz@bb.microcomp.sk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PreviewDatasetMetadataResponse } from './PreviewDatasetMetadataResponse';
import {
    PreviewDatasetMetadataResponseFromJSON,
    PreviewDatasetMetadataResponseFromJSONTyped,
    PreviewDatasetMetadataResponseToJSON,
} from './PreviewDatasetMetadataResponse';

/**
 * 
 * @export
 * @interface PreviewImportFilePartMetadataResponse
 */
export interface PreviewImportFilePartMetadataResponse {
    /**
     * 
     * @type {string}
     * @memberof PreviewImportFilePartMetadataResponse
     */
    importFilePartDefinitionId?: string;
    /**
     * 
     * @type {Array<PreviewDatasetMetadataResponse>}
     * @memberof PreviewImportFilePartMetadataResponse
     */
    previewDatasetMetadataResponses?: Array<PreviewDatasetMetadataResponse>;
}

/**
 * Check if a given object implements the PreviewImportFilePartMetadataResponse interface.
 */
export function instanceOfPreviewImportFilePartMetadataResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PreviewImportFilePartMetadataResponseFromJSON(json: any): PreviewImportFilePartMetadataResponse {
    return PreviewImportFilePartMetadataResponseFromJSONTyped(json, false);
}

export function PreviewImportFilePartMetadataResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PreviewImportFilePartMetadataResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'importFilePartDefinitionId': !exists(json, 'importFilePartDefinitionId') ? undefined : json['importFilePartDefinitionId'],
        'previewDatasetMetadataResponses': !exists(json, 'previewDatasetMetadataResponses') ? undefined : ((json['previewDatasetMetadataResponses'] as Array<any>).map(PreviewDatasetMetadataResponseFromJSON)),
    };
}

export function PreviewImportFilePartMetadataResponseToJSON(value?: PreviewImportFilePartMetadataResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'importFilePartDefinitionId': value.importFilePartDefinitionId,
        'previewDatasetMetadataResponses': value.previewDatasetMetadataResponses === undefined ? undefined : ((value.previewDatasetMetadataResponses as Array<any>).map(PreviewDatasetMetadataResponseToJSON)),
    };
}

