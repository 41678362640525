/* tslint:disable */
/* eslint-disable */
/**
 * Data import metadata
 * Administration of metadata for import
 *
 * The version of the OpenAPI document: 1.0
 * Contact: schvarcz@bb.microcomp.sk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AttributeDefinitionResponse } from './AttributeDefinitionResponse';
import {
    AttributeDefinitionResponseFromJSON,
    AttributeDefinitionResponseFromJSONTyped,
    AttributeDefinitionResponseToJSON,
} from './AttributeDefinitionResponse';
import type { ConditionResponse } from './ConditionResponse';
import {
    ConditionResponseFromJSON,
    ConditionResponseFromJSONTyped,
    ConditionResponseToJSON,
} from './ConditionResponse';

/**
 * 
 * @export
 * @interface DataRegistryProductMetadataResponse
 */
export interface DataRegistryProductMetadataResponse {
    /**
     * 
     * @type {string}
     * @memberof DataRegistryProductMetadataResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof DataRegistryProductMetadataResponse
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof DataRegistryProductMetadataResponse
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof DataRegistryProductMetadataResponse
     */
    subdomainCode?: string;
    /**
     * 
     * @type {number}
     * @memberof DataRegistryProductMetadataResponse
     */
    repeatableFromPosition?: number;
    /**
     * 
     * @type {number}
     * @memberof DataRegistryProductMetadataResponse
     */
    repeatAfterEach?: number;
    /**
     * 
     * @type {string}
     * @memberof DataRegistryProductMetadataResponse
     */
    multiplyByRowAttributeCode?: string;
    /**
     * 
     * @type {string}
     * @memberof DataRegistryProductMetadataResponse
     */
    dataRegistryProductAttributeCodeForId?: string;
    /**
     * 
     * @type {string}
     * @memberof DataRegistryProductMetadataResponse
     */
    skipIfDoesNotExist?: string;
    /**
     * 
     * @type {Array<AttributeDefinitionResponse>}
     * @memberof DataRegistryProductMetadataResponse
     */
    attributes?: Array<AttributeDefinitionResponse>;
    /**
     * 
     * @type {Array<ConditionResponse>}
     * @memberof DataRegistryProductMetadataResponse
     */
    conditions?: Array<ConditionResponse>;
    /**
     * 
     * @type {Set<string>}
     * @memberof DataRegistryProductMetadataResponse
     */
    keyAttributeCodes?: Set<string>;
}

/**
 * Check if a given object implements the DataRegistryProductMetadataResponse interface.
 */
export function instanceOfDataRegistryProductMetadataResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DataRegistryProductMetadataResponseFromJSON(json: any): DataRegistryProductMetadataResponse {
    return DataRegistryProductMetadataResponseFromJSONTyped(json, false);
}

export function DataRegistryProductMetadataResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): DataRegistryProductMetadataResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'subdomainCode': !exists(json, 'subdomainCode') ? undefined : json['subdomainCode'],
        'repeatableFromPosition': !exists(json, 'repeatableFromPosition') ? undefined : json['repeatableFromPosition'],
        'repeatAfterEach': !exists(json, 'repeatAfterEach') ? undefined : json['repeatAfterEach'],
        'multiplyByRowAttributeCode': !exists(json, 'multiplyByRowAttributeCode') ? undefined : json['multiplyByRowAttributeCode'],
        'dataRegistryProductAttributeCodeForId': !exists(json, 'dataRegistryProductAttributeCodeForId') ? undefined : json['dataRegistryProductAttributeCodeForId'],
        'skipIfDoesNotExist': !exists(json, 'skipIfDoesNotExist') ? undefined : json['skipIfDoesNotExist'],
        'attributes': !exists(json, 'attributes') ? undefined : ((json['attributes'] as Array<any>).map(AttributeDefinitionResponseFromJSON)),
        'conditions': !exists(json, 'conditions') ? undefined : ((json['conditions'] as Array<any>).map(ConditionResponseFromJSON)),
        'keyAttributeCodes': !exists(json, 'keyAttributeCodes') ? undefined : json['keyAttributeCodes'],
    };
}

export function DataRegistryProductMetadataResponseToJSON(value?: DataRegistryProductMetadataResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'code': value.code,
        'subdomainCode': value.subdomainCode,
        'repeatableFromPosition': value.repeatableFromPosition,
        'repeatAfterEach': value.repeatAfterEach,
        'multiplyByRowAttributeCode': value.multiplyByRowAttributeCode,
        'dataRegistryProductAttributeCodeForId': value.dataRegistryProductAttributeCodeForId,
        'skipIfDoesNotExist': value.skipIfDoesNotExist,
        'attributes': value.attributes === undefined ? undefined : ((value.attributes as Array<any>).map(AttributeDefinitionResponseToJSON)),
        'conditions': value.conditions === undefined ? undefined : ((value.conditions as Array<any>).map(ConditionResponseToJSON)),
        'keyAttributeCodes': value.keyAttributeCodes === undefined ? undefined : Array.from(value.keyAttributeCodes as Set<any>),
    };
}

