/* tslint:disable */
/* eslint-disable */
/**
 * Data import metadata
 * Administration of metadata for import
 *
 * The version of the OpenAPI document: 1.0
 * Contact: schvarcz@bb.microcomp.sk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ImportFileMetadataResponse,
} from '../models/index';
import {
    ImportFileMetadataResponseFromJSON,
    ImportFileMetadataResponseToJSON,
} from '../models/index';

export interface ImportFileMetadataDefinitionApiCopyImportFileDefinitionRequest {
    importFileDefinitionId: string;
    name: string;
}

export interface ImportFileMetadataDefinitionApiGetImportFileMetadataByIdRequest {
    id: string;
}

/**
 * 
 */
export class ImportFileMetadataDefinitionApi extends runtime.BaseAPI {

    /**
     * Copy import file definition
     */
    async copyImportFileDefinitionRaw(requestParameters: ImportFileMetadataDefinitionApiCopyImportFileDefinitionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ImportFileMetadataResponse>> {
        if (requestParameters.importFileDefinitionId === null || requestParameters.importFileDefinitionId === undefined) {
            throw new runtime.RequiredError('importFileDefinitionId','Required parameter requestParameters.importFileDefinitionId was null or undefined when calling copyImportFileDefinition.');
        }

        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling copyImportFileDefinition.');
        }

        const queryParameters: any = {};

        if (requestParameters.importFileDefinitionId !== undefined) {
            queryParameters['importFileDefinitionId'] = requestParameters.importFileDefinitionId;
        }

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/import-file-metadata-definition/copy`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ImportFileMetadataResponseFromJSON(jsonValue));
    }

    /**
     * Copy import file definition
     */
    async copyImportFileDefinition(importFileDefinitionId: string, name: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ImportFileMetadataResponse> {
        const response = await this.copyImportFileDefinitionRaw({ importFileDefinitionId: importFileDefinitionId, name: name }, initOverrides);
        return await response.value();
    }

    /**
     * Get import file definition with all parts and all metadata by id
     */
    async getImportFileMetadataByIdRaw(requestParameters: ImportFileMetadataDefinitionApiGetImportFileMetadataByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ImportFileMetadataResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getImportFileMetadataById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/import-file-metadata-definition/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ImportFileMetadataResponseFromJSON(jsonValue));
    }

    /**
     * Get import file definition with all parts and all metadata by id
     */
    async getImportFileMetadataById(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ImportFileMetadataResponse> {
        const response = await this.getImportFileMetadataByIdRaw({ id: id }, initOverrides);
        return await response.value();
    }

}
