/* tslint:disable */
/* eslint-disable */
/**
 * User Profile API
 * Nástroj pre ukladanie preferencií používateľa
 *
 * The version of the OpenAPI document: 0.3.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AttributeCreate
 */
export interface AttributeCreate {
    /**
     * 
     * @type {string}
     * @memberof AttributeCreate
     */
    domain: string;
    /**
     * 
     * @type {string}
     * @memberof AttributeCreate
     */
    key: string;
    /**
     * 
     * @type {string}
     * @memberof AttributeCreate
     */
    value: string;
}

/**
 * Check if a given object implements the AttributeCreate interface.
 */
export function instanceOfAttributeCreate(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "domain" in value;
    isInstance = isInstance && "key" in value;
    isInstance = isInstance && "value" in value;

    return isInstance;
}

export function AttributeCreateFromJSON(json: any): AttributeCreate {
    return AttributeCreateFromJSONTyped(json, false);
}

export function AttributeCreateFromJSONTyped(json: any, ignoreDiscriminator: boolean): AttributeCreate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'domain': json['domain'],
        'key': json['key'],
        'value': json['value'],
    };
}

export function AttributeCreateToJSON(value?: AttributeCreate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'domain': value.domain,
        'key': value.key,
        'value': value.value,
    };
}

