/* tslint:disable */
/* eslint-disable */
/**
 * Codelist API
 * Nástroj pre správu metadát a katalógov - koncové služby
 *
 * The version of the OpenAPI document: 0.21.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CodelistAttribute } from './CodelistAttribute';
import {
    CodelistAttributeFromJSON,
    CodelistAttributeFromJSONTyped,
    CodelistAttributeToJSON,
} from './CodelistAttribute';
import type { CodelistLocalization } from './CodelistLocalization';
import {
    CodelistLocalizationFromJSON,
    CodelistLocalizationFromJSONTyped,
    CodelistLocalizationToJSON,
} from './CodelistLocalization';

/**
 * 
 * @export
 * @interface Codelist
 */
export interface Codelist {
    /**
     * 
     * @type {string}
     * @memberof Codelist
     */
    creator: string;
    /**
     * 
     * @type {string}
     * @memberof Codelist
     */
    created: string;
    /**
     * 
     * @type {string}
     * @memberof Codelist
     */
    modifier: string | null;
    /**
     * 
     * @type {string}
     * @memberof Codelist
     */
    modified: string | null;
    /**
     * 
     * @type {number}
     * @memberof Codelist
     */
    code: number;
    /**
     * 
     * @type {string}
     * @memberof Codelist
     */
    acronym: string;
    /**
     * 
     * @type {string}
     * @memberof Codelist
     */
    source: string | null;
    /**
     * 
     * @type {string}
     * @memberof Codelist
     */
    validFrom: string;
    /**
     * 
     * @type {string}
     * @memberof Codelist
     */
    effectiveFrom: string;
    /**
     * 
     * @type {string}
     * @memberof Codelist
     */
    effectiveTo: string | null;
    /**
     * 
     * @type {Array<CodelistLocalization>}
     * @memberof Codelist
     */
    localizations: Array<CodelistLocalization>;
    /**
     * 
     * @type {Array<CodelistAttribute>}
     * @memberof Codelist
     */
    attributes: Array<CodelistAttribute>;
    /**
     * 
     * @type {number}
     * @memberof Codelist
     */
    itemCount: number;
    /**
     * 
     * @type {string}
     * @memberof Codelist
     */
    status: CodelistStatusEnum;
    /**
     * 
     * @type {boolean}
     * @memberof Codelist
     */
    deleted: boolean;
}


/**
 * @export
 */
export const CodelistStatusEnum = {
    Active: 'ACTIVE',
    Inactive: 'INACTIVE',
    Waiting: 'WAITING',
    Deleted: 'DELETED'
} as const;
export type CodelistStatusEnum = typeof CodelistStatusEnum[keyof typeof CodelistStatusEnum];


/**
 * Check if a given object implements the Codelist interface.
 */
export function instanceOfCodelist(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "creator" in value;
    isInstance = isInstance && "created" in value;
    isInstance = isInstance && "modifier" in value;
    isInstance = isInstance && "modified" in value;
    isInstance = isInstance && "code" in value;
    isInstance = isInstance && "acronym" in value;
    isInstance = isInstance && "source" in value;
    isInstance = isInstance && "validFrom" in value;
    isInstance = isInstance && "effectiveFrom" in value;
    isInstance = isInstance && "effectiveTo" in value;
    isInstance = isInstance && "localizations" in value;
    isInstance = isInstance && "attributes" in value;
    isInstance = isInstance && "itemCount" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "deleted" in value;

    return isInstance;
}

export function CodelistFromJSON(json: any): Codelist {
    return CodelistFromJSONTyped(json, false);
}

export function CodelistFromJSONTyped(json: any, ignoreDiscriminator: boolean): Codelist {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'creator': json['creator'],
        'created': json['created'],
        'modifier': json['modifier'],
        'modified': json['modified'],
        'code': json['code'],
        'acronym': json['acronym'],
        'source': json['source'],
        'validFrom': json['validFrom'],
        'effectiveFrom': json['effectiveFrom'],
        'effectiveTo': json['effectiveTo'],
        'localizations': ((json['localizations'] as Array<any>).map(CodelistLocalizationFromJSON)),
        'attributes': ((json['attributes'] as Array<any>).map(CodelistAttributeFromJSON)),
        'itemCount': json['itemCount'],
        'status': json['status'],
        'deleted': json['deleted'],
    };
}

export function CodelistToJSON(value?: Codelist | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'creator': value.creator,
        'created': value.created,
        'modifier': value.modifier,
        'modified': value.modified,
        'code': value.code,
        'acronym': value.acronym,
        'source': value.source,
        'validFrom': value.validFrom,
        'effectiveFrom': value.effectiveFrom,
        'effectiveTo': value.effectiveTo,
        'localizations': ((value.localizations as Array<any>).map(CodelistLocalizationToJSON)),
        'attributes': ((value.attributes as Array<any>).map(CodelistAttributeToJSON)),
        'itemCount': value.itemCount,
        'status': value.status,
        'deleted': value.deleted,
    };
}

