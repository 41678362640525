import { FetchParams, Middleware, RequestContext } from '../openapi/codelist';
import { useMemo, useRef } from 'react';
import { useAuth } from 'react-oidc-context';

const useBearerMiddleware = (): Middleware => {
    const { user } = useAuth();

    // TODO mozno najst lepsie riesenie ako zabranit aby pri zmene tokenu doslo k zmene v useMemo dependency a tym padom k zmene api a nasledne zopakovaniu vsetkych api volani
    const token = useRef<string>();
    token.current = user?.access_token;

    return useMemo(() => {
        return {
            pre(context: RequestContext): Promise<FetchParams | void> {
                context.init.headers = {
                    ...context.init.headers,
                    Authorization: token.current ? `Bearer ${token.current}` : ''
                };
                return Promise.resolve();
            }
        };
    }, []);
};

export default useBearerMiddleware;
