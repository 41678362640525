/* tslint:disable */
/* eslint-disable */
/**
 * Data import metadata
 * Administration of metadata for import
 *
 * The version of the OpenAPI document: 1.0
 * Contact: schvarcz@bb.microcomp.sk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RowTransformationDefinitionRequest } from './RowTransformationDefinitionRequest';
import {
    RowTransformationDefinitionRequestFromJSON,
    RowTransformationDefinitionRequestFromJSONTyped,
    RowTransformationDefinitionRequestToJSON,
} from './RowTransformationDefinitionRequest';

/**
 * 
 * @export
 * @interface RowTypeDefinitionRequest
 */
export interface RowTypeDefinitionRequest {
    /**
     * 
     * @type {string}
     * @memberof RowTypeDefinitionRequest
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof RowTypeDefinitionRequest
     */
    rowPosition?: number;
    /**
     * 
     * @type {string}
     * @memberof RowTypeDefinitionRequest
     */
    rowDataType?: RowTypeDefinitionRequestRowDataTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof RowTypeDefinitionRequest
     */
    rowTypeDataOrder?: number;
    /**
     * 
     * @type {number}
     * @memberof RowTypeDefinitionRequest
     */
    dataCount?: number;
    /**
     * 
     * @type {Array<RowTransformationDefinitionRequest>}
     * @memberof RowTypeDefinitionRequest
     */
    rowTransformations?: Array<RowTransformationDefinitionRequest>;
}


/**
 * @export
 */
export const RowTypeDefinitionRequestRowDataTypeEnum = {
    Comment: 'COMMENT',
    Names: 'NAMES',
    Units: 'UNITS',
    Data: 'DATA'
} as const;
export type RowTypeDefinitionRequestRowDataTypeEnum = typeof RowTypeDefinitionRequestRowDataTypeEnum[keyof typeof RowTypeDefinitionRequestRowDataTypeEnum];


/**
 * Check if a given object implements the RowTypeDefinitionRequest interface.
 */
export function instanceOfRowTypeDefinitionRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RowTypeDefinitionRequestFromJSON(json: any): RowTypeDefinitionRequest {
    return RowTypeDefinitionRequestFromJSONTyped(json, false);
}

export function RowTypeDefinitionRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): RowTypeDefinitionRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'rowPosition': !exists(json, 'rowPosition') ? undefined : json['rowPosition'],
        'rowDataType': !exists(json, 'rowDataType') ? undefined : json['rowDataType'],
        'rowTypeDataOrder': !exists(json, 'rowTypeDataOrder') ? undefined : json['rowTypeDataOrder'],
        'dataCount': !exists(json, 'dataCount') ? undefined : json['dataCount'],
        'rowTransformations': !exists(json, 'rowTransformations') ? undefined : ((json['rowTransformations'] as Array<any>).map(RowTransformationDefinitionRequestFromJSON)),
    };
}

export function RowTypeDefinitionRequestToJSON(value?: RowTypeDefinitionRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'rowPosition': value.rowPosition,
        'rowDataType': value.rowDataType,
        'rowTypeDataOrder': value.rowTypeDataOrder,
        'dataCount': value.dataCount,
        'rowTransformations': value.rowTransformations === undefined ? undefined : ((value.rowTransformations as Array<any>).map(RowTransformationDefinitionRequestToJSON)),
    };
}

