/* tslint:disable */
/* eslint-disable */
/**
 * Overview API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ColumnDefinitionSo } from './ColumnDefinitionSo';
import {
    ColumnDefinitionSoFromJSON,
    ColumnDefinitionSoFromJSONTyped,
    ColumnDefinitionSoToJSON,
} from './ColumnDefinitionSo';
import type { TechnicalAttribute } from './TechnicalAttribute';
import {
    TechnicalAttributeFromJSON,
    TechnicalAttributeFromJSONTyped,
    TechnicalAttributeToJSON,
} from './TechnicalAttribute';

/**
 * 
 * @export
 * @interface PreviewSo
 */
export interface PreviewSo {
    /**
     * 
     * @type {number}
     * @memberof PreviewSo
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof PreviewSo
     */
    reference?: string;
    /**
     * 
     * @type {string}
     * @memberof PreviewSo
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof PreviewSo
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof PreviewSo
     */
    namespace?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PreviewSo
     */
    description?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PreviewSo
     */
    allowAdd?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PreviewSo
     */
    allowEdit?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PreviewSo
     */
    allowDelete?: boolean;
    /**
     * 
     * @type {Array<ColumnDefinitionSo>}
     * @memberof PreviewSo
     */
    columnDefinitions?: Array<ColumnDefinitionSo>;
    /**
     * 
     * @type {string}
     * @memberof PreviewSo
     */
    errorMessage?: string | null;
    /**
     * 
     * @type {TechnicalAttribute}
     * @memberof PreviewSo
     */
    technicalAttribute?: TechnicalAttribute;
    /**
     * 
     * @type {string}
     * @memberof PreviewSo
     */
    lastUpdateTs?: string;
    /**
     * 
     * @type {number}
     * @memberof PreviewSo
     */
    sortNumber?: number;
}

/**
 * Check if a given object implements the PreviewSo interface.
 */
export function instanceOfPreviewSo(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PreviewSoFromJSON(json: any): PreviewSo {
    return PreviewSoFromJSONTyped(json, false);
}

export function PreviewSoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PreviewSo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'reference': !exists(json, 'reference') ? undefined : json['reference'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'namespace': !exists(json, 'namespace') ? undefined : json['namespace'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'allowAdd': !exists(json, 'allowAdd') ? undefined : json['allowAdd'],
        'allowEdit': !exists(json, 'allowEdit') ? undefined : json['allowEdit'],
        'allowDelete': !exists(json, 'allowDelete') ? undefined : json['allowDelete'],
        'columnDefinitions': !exists(json, 'columnDefinitions') ? undefined : ((json['columnDefinitions'] as Array<any>).map(ColumnDefinitionSoFromJSON)),
        'errorMessage': !exists(json, 'errorMessage') ? undefined : json['errorMessage'],
        'technicalAttribute': !exists(json, 'technicalAttribute') ? undefined : TechnicalAttributeFromJSON(json['technicalAttribute']),
        'lastUpdateTs': !exists(json, 'lastUpdateTs') ? undefined : json['lastUpdateTs'],
        'sortNumber': !exists(json, 'sortNumber') ? undefined : json['sortNumber'],
    };
}

export function PreviewSoToJSON(value?: PreviewSo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'reference': value.reference,
        'type': value.type,
        'name': value.name,
        'namespace': value.namespace,
        'description': value.description,
        'allowAdd': value.allowAdd,
        'allowEdit': value.allowEdit,
        'allowDelete': value.allowDelete,
        'columnDefinitions': value.columnDefinitions === undefined ? undefined : ((value.columnDefinitions as Array<any>).map(ColumnDefinitionSoToJSON)),
        'errorMessage': value.errorMessage,
        'technicalAttribute': TechnicalAttributeToJSON(value.technicalAttribute),
        'lastUpdateTs': value.lastUpdateTs,
        'sortNumber': value.sortNumber,
    };
}

