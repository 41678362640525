/* tslint:disable */
/* eslint-disable */
/**
 * Codelist API
 * Nástroj pre správu metadát a katalógov - koncové služby
 *
 * The version of the OpenAPI document: 0.21.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CodelistAttribute
 */
export interface CodelistAttribute {
    /**
     * 
     * @type {string}
     * @memberof CodelistAttribute
     */
    key: string;
    /**
     * 
     * @type {string}
     * @memberof CodelistAttribute
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof CodelistAttribute
     */
    required: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CodelistAttribute
     */
    unique: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CodelistAttribute
     */
    primaryKey: boolean;
    /**
     * 
     * @type {string}
     * @memberof CodelistAttribute
     */
    dataType: CodelistAttributeDataTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CodelistAttribute
     */
    description: string | null;
    /**
     * 
     * @type {number}
     * @memberof CodelistAttribute
     */
    codelistDepend: number | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CodelistAttribute
     */
    dependAttribute: Array<string> | null;
}


/**
 * @export
 */
export const CodelistAttributeDataTypeEnum = {
    Integer: 'INTEGER',
    Bigint: 'BIGINT',
    Numeric: 'NUMERIC',
    Decimal: 'DECIMAL',
    Varchar: 'VARCHAR',
    Boolean: 'BOOLEAN',
    IsoDateTime: 'ISO_DATE_TIME'
} as const;
export type CodelistAttributeDataTypeEnum = typeof CodelistAttributeDataTypeEnum[keyof typeof CodelistAttributeDataTypeEnum];


/**
 * Check if a given object implements the CodelistAttribute interface.
 */
export function instanceOfCodelistAttribute(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "key" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "required" in value;
    isInstance = isInstance && "unique" in value;
    isInstance = isInstance && "primaryKey" in value;
    isInstance = isInstance && "dataType" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "codelistDepend" in value;
    isInstance = isInstance && "dependAttribute" in value;

    return isInstance;
}

export function CodelistAttributeFromJSON(json: any): CodelistAttribute {
    return CodelistAttributeFromJSONTyped(json, false);
}

export function CodelistAttributeFromJSONTyped(json: any, ignoreDiscriminator: boolean): CodelistAttribute {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': json['key'],
        'name': json['name'],
        'required': json['required'],
        'unique': json['unique'],
        'primaryKey': json['primaryKey'],
        'dataType': json['dataType'],
        'description': json['description'],
        'codelistDepend': json['codelistDepend'],
        'dependAttribute': json['dependAttribute'],
    };
}

export function CodelistAttributeToJSON(value?: CodelistAttribute | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': value.key,
        'name': value.name,
        'required': value.required,
        'unique': value.unique,
        'primaryKey': value.primaryKey,
        'dataType': value.dataType,
        'description': value.description,
        'codelistDepend': value.codelistDepend,
        'dependAttribute': value.dependAttribute,
    };
}

