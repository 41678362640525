/* tslint:disable */
/* eslint-disable */
/**
 * Data import
 * Modul for importing data to Data Registry.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: schvarcz@bb.microcomp.sk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DataAttributeRequest } from './DataAttributeRequest';
import {
    DataAttributeRequestFromJSON,
    DataAttributeRequestFromJSONTyped,
    DataAttributeRequestToJSON,
} from './DataAttributeRequest';

/**
 * 
 * @export
 * @interface AddRowRequest
 */
export interface AddRowRequest {
    /**
     * 
     * @type {string}
     * @memberof AddRowRequest
     */
    documentId?: string;
    /**
     * 
     * @type {{ [key: string]: DataAttributeRequest; }}
     * @memberof AddRowRequest
     */
    dataAttributeRequests?: { [key: string]: DataAttributeRequest; };
}

/**
 * Check if a given object implements the AddRowRequest interface.
 */
export function instanceOfAddRowRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AddRowRequestFromJSON(json: any): AddRowRequest {
    return AddRowRequestFromJSONTyped(json, false);
}

export function AddRowRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddRowRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'documentId': !exists(json, 'documentId') ? undefined : json['documentId'],
        'dataAttributeRequests': !exists(json, 'dataAttributeRequests') ? undefined : (mapValues(json['dataAttributeRequests'], DataAttributeRequestFromJSON)),
    };
}

export function AddRowRequestToJSON(value?: AddRowRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'documentId': value.documentId,
        'dataAttributeRequests': value.dataAttributeRequests === undefined ? undefined : (mapValues(value.dataAttributeRequests, DataAttributeRequestToJSON)),
    };
}

