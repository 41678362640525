/* tslint:disable */
/* eslint-disable */
/**
 * Data import metadata
 * Administration of metadata for import
 *
 * The version of the OpenAPI document: 1.0
 * Contact: schvarcz@bb.microcomp.sk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DataRegistryProductDefinitionRequest } from './DataRegistryProductDefinitionRequest';
import {
    DataRegistryProductDefinitionRequestFromJSON,
    DataRegistryProductDefinitionRequestFromJSONTyped,
    DataRegistryProductDefinitionRequestToJSON,
} from './DataRegistryProductDefinitionRequest';

/**
 * 
 * @export
 * @interface AssignDataRegistryProductDefinitionsRequest
 */
export interface AssignDataRegistryProductDefinitionsRequest {
    /**
     * 
     * @type {string}
     * @memberof AssignDataRegistryProductDefinitionsRequest
     */
    importFileDefinitionId?: string;
    /**
     * 
     * @type {Array<DataRegistryProductDefinitionRequest>}
     * @memberof AssignDataRegistryProductDefinitionsRequest
     */
    dataRegistryProducts?: Array<DataRegistryProductDefinitionRequest>;
}

/**
 * Check if a given object implements the AssignDataRegistryProductDefinitionsRequest interface.
 */
export function instanceOfAssignDataRegistryProductDefinitionsRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AssignDataRegistryProductDefinitionsRequestFromJSON(json: any): AssignDataRegistryProductDefinitionsRequest {
    return AssignDataRegistryProductDefinitionsRequestFromJSONTyped(json, false);
}

export function AssignDataRegistryProductDefinitionsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AssignDataRegistryProductDefinitionsRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'importFileDefinitionId': !exists(json, 'importFileDefinitionId') ? undefined : json['importFileDefinitionId'],
        'dataRegistryProducts': !exists(json, 'dataRegistryProducts') ? undefined : ((json['dataRegistryProducts'] as Array<any>).map(DataRegistryProductDefinitionRequestFromJSON)),
    };
}

export function AssignDataRegistryProductDefinitionsRequestToJSON(value?: AssignDataRegistryProductDefinitionsRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'importFileDefinitionId': value.importFileDefinitionId,
        'dataRegistryProducts': value.dataRegistryProducts === undefined ? undefined : ((value.dataRegistryProducts as Array<any>).map(DataRegistryProductDefinitionRequestToJSON)),
    };
}

