// Enumy
export enum SeparatorEnum {
    Semicolon = ';',
    Comma = ',',
    Space = ' ',
    Pipe = '|'
}
export enum FileTypeEnum {
    Csv = 'CSV',
    Xls = 'XLS',
    Xlsx = 'XLSX'
}
export enum EncodingEnum {
    Latin1 = 'ISO-8859-1',
    Utf8 = 'UTF-8',
    Windows1250 = 'windows-1250'
}
export enum FixedCodeitemAttributesEnum {
    Code = 'code',
    Acronym = 'acronym',
    ShortenedName = 'shortenedName',
    AbbreviatedName = 'abbreviatedName'
}
