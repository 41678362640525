/* tslint:disable */
/* eslint-disable */
/**
 * Notification API
 * Nástroj pre posielanie notifikácií - koncové služby
 *
 * The version of the OpenAPI document: 0.8.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AccountSo
 */
export interface AccountSo {
    /**
     * 
     * @type {Array<string>}
     * @memberof AccountSo
     */
    messageType?: Array<AccountSoMessageTypeEnum>;
}


/**
 * @export
 */
export const AccountSoMessageTypeEnum = {
    Catalog: 'CATALOG',
    Codelist: 'CODELIST',
    MonitoringPoint: 'MONITORING_POINT',
    Installation: 'INSTALLATION',
    Operator: 'OPERATOR',
    WaterShape: 'WATER_SHAPE',
    Environment: 'ENVIRONMENT',
    Dataregistry: 'DATAREGISTRY',
    OverviewDataDownloaded: 'OVERVIEW_DATA_DOWNLOADED',
    OverviewDataUploaded: 'OVERVIEW_DATA_UPLOADED',
    OverviewExported: 'OVERVIEW_EXPORTED'
} as const;
export type AccountSoMessageTypeEnum = typeof AccountSoMessageTypeEnum[keyof typeof AccountSoMessageTypeEnum];


/**
 * Check if a given object implements the AccountSo interface.
 */
export function instanceOfAccountSo(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AccountSoFromJSON(json: any): AccountSo {
    return AccountSoFromJSONTyped(json, false);
}

export function AccountSoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccountSo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'messageType': !exists(json, 'messageType') ? undefined : json['messageType'],
    };
}

export function AccountSoToJSON(value?: AccountSo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'messageType': value.messageType,
    };
}

