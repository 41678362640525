/* tslint:disable */
/* eslint-disable */
/**
 * Overview API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FilterDataSearchSo
 */
export interface FilterDataSearchSo {
    /**
     * 
     * @type {string}
     * @memberof FilterDataSearchSo
     */
    username?: string;
    /**
     * 
     * @type {string}
     * @memberof FilterDataSearchSo
     */
    viewReference: string;
    /**
     * 
     * @type {string}
     * @memberof FilterDataSearchSo
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof FilterDataSearchSo
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof FilterDataSearchSo
     */
    searchField?: string;
}

/**
 * Check if a given object implements the FilterDataSearchSo interface.
 */
export function instanceOfFilterDataSearchSo(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "viewReference" in value;

    return isInstance;
}

export function FilterDataSearchSoFromJSON(json: any): FilterDataSearchSo {
    return FilterDataSearchSoFromJSONTyped(json, false);
}

export function FilterDataSearchSoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FilterDataSearchSo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'username': !exists(json, 'username') ? undefined : json['username'],
        'viewReference': json['viewReference'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'searchField': !exists(json, 'searchField') ? undefined : json['searchField'],
    };
}

export function FilterDataSearchSoToJSON(value?: FilterDataSearchSo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'username': value.username,
        'viewReference': value.viewReference,
        'name': value.name,
        'description': value.description,
        'searchField': value.searchField,
    };
}

