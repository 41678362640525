/* tslint:disable */
/* eslint-disable */
/**
 * Overview API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SortsSo } from './SortsSo';
import {
    SortsSoFromJSON,
    SortsSoFromJSONTyped,
    SortsSoToJSON,
} from './SortsSo';

/**
 * 
 * @export
 * @interface DataPageRequestSo
 */
export interface DataPageRequestSo {
    /**
     * 
     * @type {string}
     * @memberof DataPageRequestSo
     */
    viewReference: string;
    /**
     * 
     * @type {number}
     * @memberof DataPageRequestSo
     */
    filterId: number;
    /**
     * 
     * @type {number}
     * @memberof DataPageRequestSo
     */
    pageNumber: number;
    /**
     * 
     * @type {number}
     * @memberof DataPageRequestSo
     */
    pageSize: number;
    /**
     * 
     * @type {Array<SortsSo>}
     * @memberof DataPageRequestSo
     */
    sorts?: Array<SortsSo>;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof DataPageRequestSo
     */
    fetchFilter?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof DataPageRequestSo
     */
    searchField?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DataPageRequestSo
     */
    onlyChangedInDr?: boolean;
}

/**
 * Check if a given object implements the DataPageRequestSo interface.
 */
export function instanceOfDataPageRequestSo(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "viewReference" in value;
    isInstance = isInstance && "filterId" in value;
    isInstance = isInstance && "pageNumber" in value;
    isInstance = isInstance && "pageSize" in value;

    return isInstance;
}

export function DataPageRequestSoFromJSON(json: any): DataPageRequestSo {
    return DataPageRequestSoFromJSONTyped(json, false);
}

export function DataPageRequestSoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DataPageRequestSo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'viewReference': json['viewReference'],
        'filterId': json['filterId'],
        'pageNumber': json['pageNumber'],
        'pageSize': json['pageSize'],
        'sorts': !exists(json, 'sorts') ? undefined : ((json['sorts'] as Array<any>).map(SortsSoFromJSON)),
        'fetchFilter': !exists(json, 'fetchFilter') ? undefined : json['fetchFilter'],
        'searchField': !exists(json, 'searchField') ? undefined : json['searchField'],
        'onlyChangedInDr': !exists(json, 'onlyChangedInDr') ? undefined : json['onlyChangedInDr'],
    };
}

export function DataPageRequestSoToJSON(value?: DataPageRequestSo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'viewReference': value.viewReference,
        'filterId': value.filterId,
        'pageNumber': value.pageNumber,
        'pageSize': value.pageSize,
        'sorts': value.sorts === undefined ? undefined : ((value.sorts as Array<any>).map(SortsSoToJSON)),
        'fetchFilter': value.fetchFilter,
        'searchField': value.searchField,
        'onlyChangedInDr': value.onlyChangedInDr,
    };
}

