/* tslint:disable */
/* eslint-disable */
/**
 * Data import
 * Modul for importing data to Data Registry.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: schvarcz@bb.microcomp.sk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PreviewMetadataResponse } from './PreviewMetadataResponse';
import {
    PreviewMetadataResponseFromJSON,
    PreviewMetadataResponseFromJSONTyped,
    PreviewMetadataResponseToJSON,
} from './PreviewMetadataResponse';

/**
 * 
 * @export
 * @interface PreviewDatasetMetadataResponse
 */
export interface PreviewDatasetMetadataResponse {
    /**
     * 
     * @type {number}
     * @memberof PreviewDatasetMetadataResponse
     */
    dataSet?: number;
    /**
     * 
     * @type {Array<PreviewMetadataResponse>}
     * @memberof PreviewDatasetMetadataResponse
     */
    previewMetadataResponses?: Array<PreviewMetadataResponse>;
}

/**
 * Check if a given object implements the PreviewDatasetMetadataResponse interface.
 */
export function instanceOfPreviewDatasetMetadataResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PreviewDatasetMetadataResponseFromJSON(json: any): PreviewDatasetMetadataResponse {
    return PreviewDatasetMetadataResponseFromJSONTyped(json, false);
}

export function PreviewDatasetMetadataResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PreviewDatasetMetadataResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dataSet': !exists(json, 'dataSet') ? undefined : json['dataSet'],
        'previewMetadataResponses': !exists(json, 'previewMetadataResponses') ? undefined : ((json['previewMetadataResponses'] as Array<any>).map(PreviewMetadataResponseFromJSON)),
    };
}

export function PreviewDatasetMetadataResponseToJSON(value?: PreviewDatasetMetadataResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dataSet': value.dataSet,
        'previewMetadataResponses': value.previewMetadataResponses === undefined ? undefined : ((value.previewMetadataResponses as Array<any>).map(PreviewMetadataResponseToJSON)),
    };
}

