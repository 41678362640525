/* tslint:disable */
/* eslint-disable */
/**
 * Overview API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MonitoringPlacesCondition
 */
export interface MonitoringPlacesCondition {
    /**
     * 
     * @type {boolean}
     * @memberof MonitoringPlacesCondition
     */
    deleted?: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof MonitoringPlacesCondition
     */
    regionType?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof MonitoringPlacesCondition
     */
    districtType?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof MonitoringPlacesCondition
     */
    instBranchType?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof MonitoringPlacesCondition
     */
    montPointType?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof MonitoringPlacesCondition
     */
    monitoringType?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof MonitoringPlacesCondition
     */
    partialBasinType?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof MonitoringPlacesCondition
     */
    subBasinType?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof MonitoringPlacesCondition
     */
    areaType?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof MonitoringPlacesCondition
     */
    environmentType?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof MonitoringPlacesCondition
     */
    affectedType?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof MonitoringPlacesCondition
     */
    partialHgrType?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof MonitoringPlacesCondition
     */
    waterShapeId?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof MonitoringPlacesCondition
     */
    userGroupIds?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof MonitoringPlacesCondition
     */
    basinType?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof MonitoringPlacesCondition
     */
    environmentIds?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof MonitoringPlacesCondition
     */
    id?: Array<number>;
    /**
     * 
     * @type {Array<string>}
     * @memberof MonitoringPlacesCondition
     */
    balanceCode?: Array<string>;
}

/**
 * Check if a given object implements the MonitoringPlacesCondition interface.
 */
export function instanceOfMonitoringPlacesCondition(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MonitoringPlacesConditionFromJSON(json: any): MonitoringPlacesCondition {
    return MonitoringPlacesConditionFromJSONTyped(json, false);
}

export function MonitoringPlacesConditionFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitoringPlacesCondition {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'deleted': !exists(json, 'deleted') ? undefined : json['deleted'],
        'regionType': !exists(json, 'regionType') ? undefined : json['regionType'],
        'districtType': !exists(json, 'districtType') ? undefined : json['districtType'],
        'instBranchType': !exists(json, 'instBranchType') ? undefined : json['instBranchType'],
        'montPointType': !exists(json, 'montPointType') ? undefined : json['montPointType'],
        'monitoringType': !exists(json, 'monitoringType') ? undefined : json['monitoringType'],
        'partialBasinType': !exists(json, 'partialBasinType') ? undefined : json['partialBasinType'],
        'subBasinType': !exists(json, 'subBasinType') ? undefined : json['subBasinType'],
        'areaType': !exists(json, 'areaType') ? undefined : json['areaType'],
        'environmentType': !exists(json, 'environmentType') ? undefined : json['environmentType'],
        'affectedType': !exists(json, 'affectedType') ? undefined : json['affectedType'],
        'partialHgrType': !exists(json, 'partialHgrType') ? undefined : json['partialHgrType'],
        'waterShapeId': !exists(json, 'waterShapeId') ? undefined : json['waterShapeId'],
        'userGroupIds': !exists(json, 'userGroupIds') ? undefined : json['userGroupIds'],
        'basinType': !exists(json, 'basinType') ? undefined : json['basinType'],
        'environmentIds': !exists(json, 'environmentIds') ? undefined : json['environmentIds'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'balanceCode': !exists(json, 'balanceCode') ? undefined : json['balanceCode'],
    };
}

export function MonitoringPlacesConditionToJSON(value?: MonitoringPlacesCondition | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'deleted': value.deleted,
        'regionType': value.regionType,
        'districtType': value.districtType,
        'instBranchType': value.instBranchType,
        'montPointType': value.montPointType,
        'monitoringType': value.monitoringType,
        'partialBasinType': value.partialBasinType,
        'subBasinType': value.subBasinType,
        'areaType': value.areaType,
        'environmentType': value.environmentType,
        'affectedType': value.affectedType,
        'partialHgrType': value.partialHgrType,
        'waterShapeId': value.waterShapeId,
        'userGroupIds': value.userGroupIds,
        'basinType': value.basinType,
        'environmentIds': value.environmentIds,
        'id': value.id,
        'balanceCode': value.balanceCode,
    };
}

