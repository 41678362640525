/* tslint:disable */
/* eslint-disable */
/**
 * Data import metadata
 * Administration of metadata for import
 *
 * The version of the OpenAPI document: 1.0
 * Contact: schvarcz@bb.microcomp.sk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AssignColumnDefinitionRequest,
  AssignColumnDefinitionsRequest,
  ColumnDefinitionRequest,
  ColumnDefinitionResponse,
  RemoveColumnDefinitionsRequest,
} from '../models/index';
import {
    AssignColumnDefinitionRequestFromJSON,
    AssignColumnDefinitionRequestToJSON,
    AssignColumnDefinitionsRequestFromJSON,
    AssignColumnDefinitionsRequestToJSON,
    ColumnDefinitionRequestFromJSON,
    ColumnDefinitionRequestToJSON,
    ColumnDefinitionResponseFromJSON,
    ColumnDefinitionResponseToJSON,
    RemoveColumnDefinitionsRequestFromJSON,
    RemoveColumnDefinitionsRequestToJSON,
} from '../models/index';

export interface ColumnDefinitionApiAddColumnDefinitionRequest {
    assignColumnDefinitionRequest: AssignColumnDefinitionRequest;
}

export interface ColumnDefinitionApiAddColumnDefinitionsRequest {
    assignColumnDefinitionsRequest: AssignColumnDefinitionsRequest;
}

export interface ColumnDefinitionApiGetColumnDefinitionByIdRequest {
    id: string;
}

export interface ColumnDefinitionApiGetColumnDefinitionByRowTypeDefinitionRequest {
    rowTypeDefinitionId: string;
}

export interface ColumnDefinitionApiRemoveColumnDefinitionsOperationRequest {
    removeColumnDefinitionsRequest: RemoveColumnDefinitionsRequest;
}

export interface ColumnDefinitionApiUpdateColumnDefinitionRequest {
    id: string;
    columnDefinitionRequest: ColumnDefinitionRequest;
}

/**
 * 
 */
export class ColumnDefinitionApi extends runtime.BaseAPI {

    /**
     * Add one column definition to import part file
     */
    async addColumnDefinitionRaw(requestParameters: ColumnDefinitionApiAddColumnDefinitionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ColumnDefinitionResponse>> {
        if (requestParameters.assignColumnDefinitionRequest === null || requestParameters.assignColumnDefinitionRequest === undefined) {
            throw new runtime.RequiredError('assignColumnDefinitionRequest','Required parameter requestParameters.assignColumnDefinitionRequest was null or undefined when calling addColumnDefinition.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/column-definition/add`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AssignColumnDefinitionRequestToJSON(requestParameters.assignColumnDefinitionRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ColumnDefinitionResponseFromJSON(jsonValue));
    }

    /**
     * Add one column definition to import part file
     */
    async addColumnDefinition(assignColumnDefinitionRequest: AssignColumnDefinitionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ColumnDefinitionResponse> {
        const response = await this.addColumnDefinitionRaw({ assignColumnDefinitionRequest: assignColumnDefinitionRequest }, initOverrides);
        return await response.value();
    }

    /**
     * Add column definitions to import part file
     */
    async addColumnDefinitionsRaw(requestParameters: ColumnDefinitionApiAddColumnDefinitionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.assignColumnDefinitionsRequest === null || requestParameters.assignColumnDefinitionsRequest === undefined) {
            throw new runtime.RequiredError('assignColumnDefinitionsRequest','Required parameter requestParameters.assignColumnDefinitionsRequest was null or undefined when calling addColumnDefinitions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/column-definition/add-column-definitions`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AssignColumnDefinitionsRequestToJSON(requestParameters.assignColumnDefinitionsRequest),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Add column definitions to import part file
     */
    async addColumnDefinitions(assignColumnDefinitionsRequest: AssignColumnDefinitionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.addColumnDefinitionsRaw({ assignColumnDefinitionsRequest: assignColumnDefinitionsRequest }, initOverrides);
        return await response.value();
    }

    /**
     * Get column definition by id
     */
    async getColumnDefinitionByIdRaw(requestParameters: ColumnDefinitionApiGetColumnDefinitionByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ColumnDefinitionResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getColumnDefinitionById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/column-definition/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ColumnDefinitionResponseFromJSON(jsonValue));
    }

    /**
     * Get column definition by id
     */
    async getColumnDefinitionById(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ColumnDefinitionResponse> {
        const response = await this.getColumnDefinitionByIdRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     * Get column definitions by row type definition
     */
    async getColumnDefinitionByRowTypeDefinitionRaw(requestParameters: ColumnDefinitionApiGetColumnDefinitionByRowTypeDefinitionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ColumnDefinitionResponse>>> {
        if (requestParameters.rowTypeDefinitionId === null || requestParameters.rowTypeDefinitionId === undefined) {
            throw new runtime.RequiredError('rowTypeDefinitionId','Required parameter requestParameters.rowTypeDefinitionId was null or undefined when calling getColumnDefinitionByRowTypeDefinition.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/column-definition/byRowTypeDefinition/{rowTypeDefinitionId}`.replace(`{${"rowTypeDefinitionId"}}`, encodeURIComponent(String(requestParameters.rowTypeDefinitionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ColumnDefinitionResponseFromJSON));
    }

    /**
     * Get column definitions by row type definition
     */
    async getColumnDefinitionByRowTypeDefinition(rowTypeDefinitionId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ColumnDefinitionResponse>> {
        const response = await this.getColumnDefinitionByRowTypeDefinitionRaw({ rowTypeDefinitionId: rowTypeDefinitionId }, initOverrides);
        return await response.value();
    }

    /**
     * Remove column definitions from import part file
     */
    async removeColumnDefinitionsRaw(requestParameters: ColumnDefinitionApiRemoveColumnDefinitionsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.removeColumnDefinitionsRequest === null || requestParameters.removeColumnDefinitionsRequest === undefined) {
            throw new runtime.RequiredError('removeColumnDefinitionsRequest','Required parameter requestParameters.removeColumnDefinitionsRequest was null or undefined when calling removeColumnDefinitions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/column-definition/remove`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: RemoveColumnDefinitionsRequestToJSON(requestParameters.removeColumnDefinitionsRequest),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Remove column definitions from import part file
     */
    async removeColumnDefinitions(removeColumnDefinitionsRequest: RemoveColumnDefinitionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.removeColumnDefinitionsRaw({ removeColumnDefinitionsRequest: removeColumnDefinitionsRequest }, initOverrides);
        return await response.value();
    }

    /**
     * Update column definition
     */
    async updateColumnDefinitionRaw(requestParameters: ColumnDefinitionApiUpdateColumnDefinitionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ColumnDefinitionResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateColumnDefinition.');
        }

        if (requestParameters.columnDefinitionRequest === null || requestParameters.columnDefinitionRequest === undefined) {
            throw new runtime.RequiredError('columnDefinitionRequest','Required parameter requestParameters.columnDefinitionRequest was null or undefined when calling updateColumnDefinition.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/column-definition/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ColumnDefinitionRequestToJSON(requestParameters.columnDefinitionRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ColumnDefinitionResponseFromJSON(jsonValue));
    }

    /**
     * Update column definition
     */
    async updateColumnDefinition(id: string, columnDefinitionRequest: ColumnDefinitionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ColumnDefinitionResponse> {
        const response = await this.updateColumnDefinitionRaw({ id: id, columnDefinitionRequest: columnDefinitionRequest }, initOverrides);
        return await response.value();
    }

}
