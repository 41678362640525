/* tslint:disable */
/* eslint-disable */
/**
 * Catalog import API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.4.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PointCategoryCodelistItemInputRequired } from './PointCategoryCodelistItemInputRequired';
import {
    PointCategoryCodelistItemInputRequiredFromJSON,
    PointCategoryCodelistItemInputRequiredFromJSONTyped,
    PointCategoryCodelistItemInputRequiredToJSON,
} from './PointCategoryCodelistItemInputRequired';

/**
 * 
 * @export
 * @interface PointCategories
 */
export interface PointCategories {
    /**
     * 
     * @type {Array<PointCategoryCodelistItemInputRequired>}
     * @memberof PointCategories
     */
    pointCategories?: Array<PointCategoryCodelistItemInputRequired>;
}

/**
 * Check if a given object implements the PointCategories interface.
 */
export function instanceOfPointCategories(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PointCategoriesFromJSON(json: any): PointCategories {
    return PointCategoriesFromJSONTyped(json, false);
}

export function PointCategoriesFromJSONTyped(json: any, ignoreDiscriminator: boolean): PointCategories {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pointCategories': !exists(json, 'pointCategories') ? undefined : ((json['pointCategories'] as Array<any>).map(PointCategoryCodelistItemInputRequiredFromJSON)),
    };
}

export function PointCategoriesToJSON(value?: PointCategories | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pointCategories': value.pointCategories === undefined ? undefined : ((value.pointCategories as Array<any>).map(PointCategoryCodelistItemInputRequiredToJSON)),
    };
}

