import React, { useMemo } from 'react';
import { toast } from 'react-toastify';

import { EndpointExceptionBody, ErrorContext, Middleware, ResponseContext } from '../openapi/codelist';

import ToastMessage from '../components/common/ToastMessage';

const useErrorMiddleware = (): Middleware => {
    return useMemo(() => {
        return {
            post(context: ResponseContext): Promise<Response | void> {
                if (!context.response.ok) {
                    context.response
                        .json()
                        .then((value: EndpointExceptionBody) => {
                            if (value.fieldErrors && value.fieldErrors.length > 0) {
                                const message = (
                                    <div className='py-2'>
                                        {value.message}
                                        <ul>
                                            {value.fieldErrors.map((v, idx) => (
                                                <li key={idx}>
                                                    <b>{v.field} - </b>
                                                    <i>{v.error}</i>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                );
                                toast.error(
                                    <ToastMessage
                                        title='Nesprávne údaje'
                                        message={message}
                                        clipboard={
                                            value.stackTrace
                                                ? {
                                                      title: 'Kopírovať výpis',
                                                      content: value.stackTrace
                                                  }
                                                : undefined
                                        }
                                    />,
                                    {
                                        autoClose: false
                                    }
                                );
                            } else {
                                const clipboard = value.stackTrace
                                    ? {
                                          title: 'Kopírovať výpis',
                                          content: value.stackTrace
                                      }
                                    : undefined;
                                let message = value.message;
                                if (!value.message || value.message.trim() === '') {
                                    switch (context.response.status) {
                                        case 401:
                                            message = 'Pre správnu funkcionalitu je potrebné sa prihlásiť.';
                                            break;
                                        case 403:
                                            message = 'Pre túto akciu nemáte potrebnú používateľskú rolu.';
                                            break;
                                        case 404:
                                            message = 'Volaná služba je momentálne nedostupná.';
                                            break;
                                        default:
                                            message = 'Nastala neočakávaná chyba. Skúste akciu zopakovať neskôr.';
                                    }
                                }
                                toast.error(
                                    <ToastMessage
                                        title='Nastala chyba'
                                        message={message}
                                        clipboard={clipboard}
                                    />,
                                    {
                                        autoClose: false
                                    }
                                );
                            }
                        })
                        .catch((e) => {
                            if (e.name !== 'AbortError') {
                                let message = '';
                                let url = context.response.url ? context.response.url : null;
                                switch (context.response.status) {
                                    case 401:
                                        message = 'Pre správnu funkcionalitu je potrebné sa prihlásiť.';
                                        break;
                                    case 403:
                                        message = 'Pre túto akciu nemáte potrebnú používateľskú rolu.';
                                        break;
                                    case 404:
                                        message = 'Volaná služba je momentálne nedostupná.';
                                        break;
                                    default:
                                        message = 'Nastala neočakávaná chyba. Skúste akciu zopakovať neskôr.';
                                }
                                toast.error(
                                    <ToastMessage
                                        title='Nastala chyba'
                                        message={
                                            <>
                                                <div>{message}</div>
                                                <div>{url}</div>
                                            </>
                                        }
                                    />,
                                    {
                                        autoClose: false
                                    }
                                );
                                throw e;
                            }
                        });
                }
                return Promise.resolve();
            },
            onError(context: ErrorContext): Promise<Response | void> {
                if (context.init.signal?.aborted !== true) {
                    toast.error(
                        <ToastMessage
                            title='Chyba pripojenia'
                            message='Počas komunikácie so serverom nastala chyba.'
                        />,
                        {
                            autoClose: false
                        }
                    );
                }
                return Promise.reject(context.error);
            }
        };
    }, []);
};

export default useErrorMiddleware;
