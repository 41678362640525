/* tslint:disable */
/* eslint-disable */
/**
 * Data import metadata
 * Administration of metadata for import
 *
 * The version of the OpenAPI document: 1.0
 * Contact: schvarcz@bb.microcomp.sk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ColumnDefinitionRequest } from './ColumnDefinitionRequest';
import {
    ColumnDefinitionRequestFromJSON,
    ColumnDefinitionRequestFromJSONTyped,
    ColumnDefinitionRequestToJSON,
} from './ColumnDefinitionRequest';

/**
 * 
 * @export
 * @interface AssignColumnDefinitionRequest
 */
export interface AssignColumnDefinitionRequest {
    /**
     * 
     * @type {string}
     * @memberof AssignColumnDefinitionRequest
     */
    rowTypeDefinitionId?: string;
    /**
     * 
     * @type {ColumnDefinitionRequest}
     * @memberof AssignColumnDefinitionRequest
     */
    columnDefinition?: ColumnDefinitionRequest;
}

/**
 * Check if a given object implements the AssignColumnDefinitionRequest interface.
 */
export function instanceOfAssignColumnDefinitionRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AssignColumnDefinitionRequestFromJSON(json: any): AssignColumnDefinitionRequest {
    return AssignColumnDefinitionRequestFromJSONTyped(json, false);
}

export function AssignColumnDefinitionRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AssignColumnDefinitionRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'rowTypeDefinitionId': !exists(json, 'rowTypeDefinitionId') ? undefined : json['rowTypeDefinitionId'],
        'columnDefinition': !exists(json, 'columnDefinition') ? undefined : ColumnDefinitionRequestFromJSON(json['columnDefinition']),
    };
}

export function AssignColumnDefinitionRequestToJSON(value?: AssignColumnDefinitionRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'rowTypeDefinitionId': value.rowTypeDefinitionId,
        'columnDefinition': ColumnDefinitionRequestToJSON(value.columnDefinition),
    };
}

