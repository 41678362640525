/* tslint:disable */
/* eslint-disable */
/**
 * Data import metadata
 * Administration of metadata for import
 *
 * The version of the OpenAPI document: 1.0
 * Contact: schvarcz@bb.microcomp.sk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AssignDataRegistryProductDefinitionRequest,
  AssignDataRegistryProductDefinitionsRequest,
  DataRegistryProductDefinitionRequest,
  DataRegistryProductDefinitionResponse,
  RemoveDataRegistryProductDefinitionsRequest,
} from '../models/index';
import {
    AssignDataRegistryProductDefinitionRequestFromJSON,
    AssignDataRegistryProductDefinitionRequestToJSON,
    AssignDataRegistryProductDefinitionsRequestFromJSON,
    AssignDataRegistryProductDefinitionsRequestToJSON,
    DataRegistryProductDefinitionRequestFromJSON,
    DataRegistryProductDefinitionRequestToJSON,
    DataRegistryProductDefinitionResponseFromJSON,
    DataRegistryProductDefinitionResponseToJSON,
    RemoveDataRegistryProductDefinitionsRequestFromJSON,
    RemoveDataRegistryProductDefinitionsRequestToJSON,
} from '../models/index';

export interface DataRegistryProductDefinitionApiAddDataRegistryProductDefinitionRequest {
    assignDataRegistryProductDefinitionRequest: AssignDataRegistryProductDefinitionRequest;
}

export interface DataRegistryProductDefinitionApiAddDataRegistryProductDefinitionsRequest {
    assignDataRegistryProductDefinitionsRequest: AssignDataRegistryProductDefinitionsRequest;
}

export interface DataRegistryProductDefinitionApiGetDataRegistryProductDefinitionByIdRequest {
    id: string;
}

export interface DataRegistryProductDefinitionApiGetDataRegistryProductDefinitionByImportFileDefinitionRequest {
    importFileDefinitionId: string;
}

export interface DataRegistryProductDefinitionApiRemoveDataRegistryProductDefinitionsOperationRequest {
    removeDataRegistryProductDefinitionsRequest: RemoveDataRegistryProductDefinitionsRequest;
}

export interface DataRegistryProductDefinitionApiUpdateDataRegistryProductDefinitionRequest {
    id: string;
    dataRegistryProductDefinitionRequest: DataRegistryProductDefinitionRequest;
}

/**
 * 
 */
export class DataRegistryProductDefinitionApi extends runtime.BaseAPI {

    /**
     * Add one data registry product definition to import part file
     */
    async addDataRegistryProductDefinitionRaw(requestParameters: DataRegistryProductDefinitionApiAddDataRegistryProductDefinitionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DataRegistryProductDefinitionResponse>> {
        if (requestParameters.assignDataRegistryProductDefinitionRequest === null || requestParameters.assignDataRegistryProductDefinitionRequest === undefined) {
            throw new runtime.RequiredError('assignDataRegistryProductDefinitionRequest','Required parameter requestParameters.assignDataRegistryProductDefinitionRequest was null or undefined when calling addDataRegistryProductDefinition.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data-registry-product-definition/add`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AssignDataRegistryProductDefinitionRequestToJSON(requestParameters.assignDataRegistryProductDefinitionRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DataRegistryProductDefinitionResponseFromJSON(jsonValue));
    }

    /**
     * Add one data registry product definition to import part file
     */
    async addDataRegistryProductDefinition(assignDataRegistryProductDefinitionRequest: AssignDataRegistryProductDefinitionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DataRegistryProductDefinitionResponse> {
        const response = await this.addDataRegistryProductDefinitionRaw({ assignDataRegistryProductDefinitionRequest: assignDataRegistryProductDefinitionRequest }, initOverrides);
        return await response.value();
    }

    /**
     * Add data registry product definitions to import part file
     */
    async addDataRegistryProductDefinitionsRaw(requestParameters: DataRegistryProductDefinitionApiAddDataRegistryProductDefinitionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.assignDataRegistryProductDefinitionsRequest === null || requestParameters.assignDataRegistryProductDefinitionsRequest === undefined) {
            throw new runtime.RequiredError('assignDataRegistryProductDefinitionsRequest','Required parameter requestParameters.assignDataRegistryProductDefinitionsRequest was null or undefined when calling addDataRegistryProductDefinitions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data-registry-product-definition/add-data-registry-product-definitions`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AssignDataRegistryProductDefinitionsRequestToJSON(requestParameters.assignDataRegistryProductDefinitionsRequest),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Add data registry product definitions to import part file
     */
    async addDataRegistryProductDefinitions(assignDataRegistryProductDefinitionsRequest: AssignDataRegistryProductDefinitionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.addDataRegistryProductDefinitionsRaw({ assignDataRegistryProductDefinitionsRequest: assignDataRegistryProductDefinitionsRequest }, initOverrides);
        return await response.value();
    }

    /**
     * Get data registry product definition by id
     */
    async getDataRegistryProductDefinitionByIdRaw(requestParameters: DataRegistryProductDefinitionApiGetDataRegistryProductDefinitionByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DataRegistryProductDefinitionResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getDataRegistryProductDefinitionById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data-registry-product-definition/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DataRegistryProductDefinitionResponseFromJSON(jsonValue));
    }

    /**
     * Get data registry product definition by id
     */
    async getDataRegistryProductDefinitionById(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DataRegistryProductDefinitionResponse> {
        const response = await this.getDataRegistryProductDefinitionByIdRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     * Get data registry product definitions by import file definition
     */
    async getDataRegistryProductDefinitionByImportFileDefinitionRaw(requestParameters: DataRegistryProductDefinitionApiGetDataRegistryProductDefinitionByImportFileDefinitionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DataRegistryProductDefinitionResponse>>> {
        if (requestParameters.importFileDefinitionId === null || requestParameters.importFileDefinitionId === undefined) {
            throw new runtime.RequiredError('importFileDefinitionId','Required parameter requestParameters.importFileDefinitionId was null or undefined when calling getDataRegistryProductDefinitionByImportFileDefinition.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data-registry-product-definition/byImportFileDefinition/{importFileDefinitionId}`.replace(`{${"importFileDefinitionId"}}`, encodeURIComponent(String(requestParameters.importFileDefinitionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DataRegistryProductDefinitionResponseFromJSON));
    }

    /**
     * Get data registry product definitions by import file definition
     */
    async getDataRegistryProductDefinitionByImportFileDefinition(importFileDefinitionId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DataRegistryProductDefinitionResponse>> {
        const response = await this.getDataRegistryProductDefinitionByImportFileDefinitionRaw({ importFileDefinitionId: importFileDefinitionId }, initOverrides);
        return await response.value();
    }

    /**
     * Remove data registry product definitions from import part file
     */
    async removeDataRegistryProductDefinitionsRaw(requestParameters: DataRegistryProductDefinitionApiRemoveDataRegistryProductDefinitionsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.removeDataRegistryProductDefinitionsRequest === null || requestParameters.removeDataRegistryProductDefinitionsRequest === undefined) {
            throw new runtime.RequiredError('removeDataRegistryProductDefinitionsRequest','Required parameter requestParameters.removeDataRegistryProductDefinitionsRequest was null or undefined when calling removeDataRegistryProductDefinitions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data-registry-product-definition/remove`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: RemoveDataRegistryProductDefinitionsRequestToJSON(requestParameters.removeDataRegistryProductDefinitionsRequest),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Remove data registry product definitions from import part file
     */
    async removeDataRegistryProductDefinitions(removeDataRegistryProductDefinitionsRequest: RemoveDataRegistryProductDefinitionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.removeDataRegistryProductDefinitionsRaw({ removeDataRegistryProductDefinitionsRequest: removeDataRegistryProductDefinitionsRequest }, initOverrides);
        return await response.value();
    }

    /**
     * Update data registry product definition
     */
    async updateDataRegistryProductDefinitionRaw(requestParameters: DataRegistryProductDefinitionApiUpdateDataRegistryProductDefinitionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DataRegistryProductDefinitionResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateDataRegistryProductDefinition.');
        }

        if (requestParameters.dataRegistryProductDefinitionRequest === null || requestParameters.dataRegistryProductDefinitionRequest === undefined) {
            throw new runtime.RequiredError('dataRegistryProductDefinitionRequest','Required parameter requestParameters.dataRegistryProductDefinitionRequest was null or undefined when calling updateDataRegistryProductDefinition.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data-registry-product-definition/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: DataRegistryProductDefinitionRequestToJSON(requestParameters.dataRegistryProductDefinitionRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DataRegistryProductDefinitionResponseFromJSON(jsonValue));
    }

    /**
     * Update data registry product definition
     */
    async updateDataRegistryProductDefinition(id: string, dataRegistryProductDefinitionRequest: DataRegistryProductDefinitionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DataRegistryProductDefinitionResponse> {
        const response = await this.updateDataRegistryProductDefinitionRaw({ id: id, dataRegistryProductDefinitionRequest: dataRegistryProductDefinitionRequest }, initOverrides);
        return await response.value();
    }

}
