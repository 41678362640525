/* tslint:disable */
/* eslint-disable */
/**
 * Data import metadata
 * Administration of metadata for import
 *
 * The version of the OpenAPI document: 1.0
 * Contact: schvarcz@bb.microcomp.sk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RowAttributeTransformationDefinitionRequest } from './RowAttributeTransformationDefinitionRequest';
import {
    RowAttributeTransformationDefinitionRequestFromJSON,
    RowAttributeTransformationDefinitionRequestFromJSONTyped,
    RowAttributeTransformationDefinitionRequestToJSON,
} from './RowAttributeTransformationDefinitionRequest';

/**
 * 
 * @export
 * @interface AssignRowAttributeTransformationDefinitionRequest
 */
export interface AssignRowAttributeTransformationDefinitionRequest {
    /**
     * 
     * @type {string}
     * @memberof AssignRowAttributeTransformationDefinitionRequest
     */
    rowAttributeDefinitionId?: string;
    /**
     * 
     * @type {RowAttributeTransformationDefinitionRequest}
     * @memberof AssignRowAttributeTransformationDefinitionRequest
     */
    rowAttributeTransformationDefinition?: RowAttributeTransformationDefinitionRequest;
}

/**
 * Check if a given object implements the AssignRowAttributeTransformationDefinitionRequest interface.
 */
export function instanceOfAssignRowAttributeTransformationDefinitionRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AssignRowAttributeTransformationDefinitionRequestFromJSON(json: any): AssignRowAttributeTransformationDefinitionRequest {
    return AssignRowAttributeTransformationDefinitionRequestFromJSONTyped(json, false);
}

export function AssignRowAttributeTransformationDefinitionRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AssignRowAttributeTransformationDefinitionRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'rowAttributeDefinitionId': !exists(json, 'rowAttributeDefinitionId') ? undefined : json['rowAttributeDefinitionId'],
        'rowAttributeTransformationDefinition': !exists(json, 'rowAttributeTransformationDefinition') ? undefined : RowAttributeTransformationDefinitionRequestFromJSON(json['rowAttributeTransformationDefinition']),
    };
}

export function AssignRowAttributeTransformationDefinitionRequestToJSON(value?: AssignRowAttributeTransformationDefinitionRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'rowAttributeDefinitionId': value.rowAttributeDefinitionId,
        'rowAttributeTransformationDefinition': RowAttributeTransformationDefinitionRequestToJSON(value.rowAttributeTransformationDefinition),
    };
}

