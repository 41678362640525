/* tslint:disable */
/* eslint-disable */
/**
 * Data import
 * Modul for importing data to Data Registry.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: schvarcz@bb.microcomp.sk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ValidationReportResponse } from './ValidationReportResponse';
import {
    ValidationReportResponseFromJSON,
    ValidationReportResponseFromJSONTyped,
    ValidationReportResponseToJSON,
} from './ValidationReportResponse';

/**
 * 
 * @export
 * @interface PageValidationReportResponse
 */
export interface PageValidationReportResponse {
    /**
     * 
     * @type {number}
     * @memberof PageValidationReportResponse
     */
    totalPages?: number;
    /**
     * 
     * @type {number}
     * @memberof PageValidationReportResponse
     */
    totalElements?: number;
    /**
     * 
     * @type {Array<ValidationReportResponse>}
     * @memberof PageValidationReportResponse
     */
    content?: Array<ValidationReportResponse>;
}

/**
 * Check if a given object implements the PageValidationReportResponse interface.
 */
export function instanceOfPageValidationReportResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PageValidationReportResponseFromJSON(json: any): PageValidationReportResponse {
    return PageValidationReportResponseFromJSONTyped(json, false);
}

export function PageValidationReportResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PageValidationReportResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalPages': !exists(json, 'totalPages') ? undefined : json['totalPages'],
        'totalElements': !exists(json, 'totalElements') ? undefined : json['totalElements'],
        'content': !exists(json, 'content') ? undefined : ((json['content'] as Array<any>).map(ValidationReportResponseFromJSON)),
    };
}

export function PageValidationReportResponseToJSON(value?: PageValidationReportResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'totalPages': value.totalPages,
        'totalElements': value.totalElements,
        'content': value.content === undefined ? undefined : ((value.content as Array<any>).map(ValidationReportResponseToJSON)),
    };
}

