/* tslint:disable */
/* eslint-disable */
/**
 * Method import API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ItemResult } from './ItemResult';
import {
    ItemResultFromJSON,
    ItemResultFromJSONTyped,
    ItemResultToJSON,
} from './ItemResult';

/**
 * 
 * @export
 * @interface Methodic
 */
export interface Methodic {
    /**
     * 
     * @type {number}
     * @memberof Methodic
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Methodic
     */
    determinantCode?: string;
    /**
     * 
     * @type {string}
     * @memberof Methodic
     */
    analyticalMethodCode?: string;
    /**
     * 
     * @type {string}
     * @memberof Methodic
     */
    lOQ?: string;
    /**
     * 
     * @type {string}
     * @memberof Methodic
     */
    referenceToLiterature?: string;
    /**
     * 
     * @type {string}
     * @memberof Methodic
     */
    unit?: string;
    /**
     * 
     * @type {string}
     * @memberof Methodic
     */
    analyticalMethodName?: string;
    /**
     * 
     * @type {string}
     * @memberof Methodic
     */
    validation?: string;
    /**
     * 
     * @type {string}
     * @memberof Methodic
     */
    uncertainty?: string;
    /**
     * 
     * @type {ItemResult}
     * @memberof Methodic
     */
    anMethodType?: ItemResult;
    /**
     * 
     * @type {ItemResult}
     * @memberof Methodic
     */
    anMethodicType?: ItemResult;
    /**
     * 
     * @type {ItemResult}
     * @memberof Methodic
     */
    unitType?: ItemResult;
    /**
     * 
     * @type {ItemResult}
     * @memberof Methodic
     */
    determinantType?: ItemResult;
    /**
     * 
     * @type {string}
     * @memberof Methodic
     */
    status?: MethodicStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof Methodic
     */
    lineNumber?: number;
}


/**
 * @export
 */
export const MethodicStatusEnum = {
    Created: 'created',
    Waiting: 'waiting',
    Importing: 'importing',
    Validating: 'validating',
    Validated: 'validated',
    Imported: 'imported',
    Failed: 'failed',
    Unvalidated: 'unvalidated'
} as const;
export type MethodicStatusEnum = typeof MethodicStatusEnum[keyof typeof MethodicStatusEnum];


/**
 * Check if a given object implements the Methodic interface.
 */
export function instanceOfMethodic(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MethodicFromJSON(json: any): Methodic {
    return MethodicFromJSONTyped(json, false);
}

export function MethodicFromJSONTyped(json: any, ignoreDiscriminator: boolean): Methodic {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'determinantCode': !exists(json, 'determinantCode') ? undefined : json['determinantCode'],
        'analyticalMethodCode': !exists(json, 'analyticalMethodCode') ? undefined : json['analyticalMethodCode'],
        'lOQ': !exists(json, 'LOQ') ? undefined : json['LOQ'],
        'referenceToLiterature': !exists(json, 'referenceToLiterature') ? undefined : json['referenceToLiterature'],
        'unit': !exists(json, 'unit') ? undefined : json['unit'],
        'analyticalMethodName': !exists(json, 'analyticalMethodName') ? undefined : json['analyticalMethodName'],
        'validation': !exists(json, 'validation') ? undefined : json['validation'],
        'uncertainty': !exists(json, 'uncertainty') ? undefined : json['uncertainty'],
        'anMethodType': !exists(json, 'anMethodType') ? undefined : ItemResultFromJSON(json['anMethodType']),
        'anMethodicType': !exists(json, 'anMethodicType') ? undefined : ItemResultFromJSON(json['anMethodicType']),
        'unitType': !exists(json, 'unitType') ? undefined : ItemResultFromJSON(json['unitType']),
        'determinantType': !exists(json, 'determinantType') ? undefined : ItemResultFromJSON(json['determinantType']),
        'status': !exists(json, 'status') ? undefined : json['status'],
        'lineNumber': !exists(json, 'lineNumber') ? undefined : json['lineNumber'],
    };
}

export function MethodicToJSON(value?: Methodic | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'determinantCode': value.determinantCode,
        'analyticalMethodCode': value.analyticalMethodCode,
        'LOQ': value.lOQ,
        'referenceToLiterature': value.referenceToLiterature,
        'unit': value.unit,
        'analyticalMethodName': value.analyticalMethodName,
        'validation': value.validation,
        'uncertainty': value.uncertainty,
        'anMethodType': ItemResultToJSON(value.anMethodType),
        'anMethodicType': ItemResultToJSON(value.anMethodicType),
        'unitType': ItemResultToJSON(value.unitType),
        'determinantType': ItemResultToJSON(value.determinantType),
        'status': value.status,
        'lineNumber': value.lineNumber,
    };
}

