import { useSyncExternalStore } from 'react';

export enum Breakpoint {
    sm = 640,
    md = 768,
    lg = 1024,
    xl = 1280,
    '2xl' = 1536
}

/**
 * Returns true if breakpoint is >= than provided parameter.
 *
 * @param {Breakpoint}  breakpoint - watched breakpoint.
 * @returns {boolean} boolean
 */
const useBreakPoint = (breakpoint: Breakpoint): boolean => {
    return useSyncExternalStore(subscribe, getSnapshot.bind(this, breakpoint));
};

function subscribe(onStoreChange: () => void) {
    window.addEventListener('resize', onStoreChange);
    return () => window.removeEventListener('resize', onStoreChange);
}

function getSnapshot(breakpoint: Breakpoint) {
    const w = window.innerWidth;
    return w >= breakpoint;
}

export default useBreakPoint;
