import React, { ErrorInfo, ReactNode } from 'react';

interface ErrorBoundaryProps {
    title?: string;
    children?: ReactNode;
}

interface ErrorBoundaryState {
    hasError: boolean;
    errorMessage?: string;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
    public state: ErrorBoundaryState = {
        hasError: false
    };

    static getDerivedStateFromError(error: Error): ErrorBoundaryState {
        return { hasError: true, errorMessage: error.message };
    }

    componentDidCatch(error: Error, info: ErrorInfo) {
        console.error('Nastala chyba:', error, info);
    }

    render() {
        if (this.state.hasError) {
            return (
                <div className='p-12 flex flex-col items-center'>
                    <div className='text-center text-light-primary dark:text-dark-primary font-bold text-xl'>
                        {this.props.title ?? 'Nastala chyba'}
                    </div>
                    <p className='border p-3 m-3 text-left text-light-textDark dark:text-dark-textDark'>
                        {this.state.errorMessage ?? 'Počas vykresľovania obrazovky nastala neznáma chyba.'}
                    </p>
                </div>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
