import { useCallback, useMemo } from 'react';
import useRole, { ROLES } from './use-role';
// import { allMonPointRoles, editingMonPointRoles } from '../components/monitoringPoint/MonPointsDefinitions';

const useMonPointTypeRole = () => {
    const { hasSome } = useRole();
    const hasEditorRole = useCallback(
        (monPointType?: string) => {
            switch (monPointType) {
                case '27000001':
                    return hasSome([
                        ROLES.ADMINISTRATOR,
                        ROLES.MON_POINT_ALL_WRITE,
                        ROLES.MON_POINT_QUALITY_PV_WRITE,
                        ROLES.MON_POINT_HYMO_PV_WRITE
                    ]);
                case '27000002':
                    return hasSome([ROLES.ADMINISTRATOR, ROLES.MON_POINT_ALL_WRITE, ROLES.MON_POINT_ABSTRACTION_PV_WRITE]);
                case '27000003':
                    return hasSome([ROLES.ADMINISTRATOR, ROLES.MON_POINT_ALL_WRITE, ROLES.MON_POINT_DISCHARGE_PV_WRITE]);
                case '27000005':
                    return hasSome([ROLES.ADMINISTRATOR, ROLES.MON_POINT_ALL_WRITE, ROLES.MON_POINT_QUANTITY_PZV_WRITE]);
                case '27000006':
                    return hasSome([ROLES.ADMINISTRATOR, ROLES.MON_POINT_ALL_WRITE, ROLES.MON_POINT_ABSTRACTON_PZV_WRITE]);
                case '27000007':
                    return hasSome([ROLES.ADMINISTRATOR, ROLES.MON_POINT_ALL_WRITE, ROLES.MON_POINT_QUANTITY_PZV_WRITE]);
                case '27000008':
                    return hasSome([
                        ROLES.ADMINISTRATOR,
                        ROLES.MON_POINT_ALL_WRITE,
                        ROLES.MON_POINT_QUANTITY_PV_WRITE,
                        ROLES.MON_POINT_HYMO_PV_WRITE
                    ]);
                case '27000009':
                    return hasSome([ROLES.ADMINISTRATOR, ROLES.MON_POINT_ALL_WRITE, ROLES.MON_POINT_QUANTITY_VHB_PV_WRITE]);
                case '27000011':
                    return hasSome([ROLES.ADMINISTRATOR, ROLES.MON_POINT_ALL_WRITE, ROLES.MON_POINT_TNMN_PV_WRITE]);
                case '27000012':
                    return hasSome([ROLES.ADMINISTRATOR, ROLES.MON_POINT_ALL_WRITE, ROLES.MON_POINT_QUANTITY_VHB_PV_WRITE]);
                case '27000013':
                    return hasSome([ROLES.ADMINISTRATOR, ROLES.MON_POINT_ALL_WRITE, ROLES.MON_POINT_QUANTITY_VHB_PV_WRITE]);
                case '27000014':
                    return hasSome([ROLES.ADMINISTRATOR, ROLES.MON_POINT_ALL_WRITE, ROLES.MON_POINT_QUALITY_TESTIMONIALS_WRITE]);
                case '27000015':
                    return hasSome([ROLES.ADMINISTRATOR, ROLES.MON_POINT_ALL_WRITE, ROLES.MON_POINT_QUANTITY_TESTIMONIALS_WRITE]);
                case '27000016':
                    return hasSome([
                        ROLES.ADMINISTRATOR,
                        ROLES.MON_POINT_ALL_WRITE,
                        ROLES.MON_POINT_QUANTITY_VHB_PV_WRITE,
                        ROLES.MON_POINT_QUANTITY_VHB_PZV_WRITE
                    ]);
                case '27000017':
                    return hasSome([ROLES.ADMINISTRATOR, ROLES.MON_POINT_ALL_WRITE, ROLES.MON_POINT_ABSTRACTON_PZV_WRITE]);
                case '27000018':
                    return hasSome([ROLES.ADMINISTRATOR, ROLES.MON_POINT_ALL_WRITE, ROLES.MON_POINT_QUANTITY_VHB_PZV_WRITE]);
                case '27000019':
                    return hasSome([ROLES.ADMINISTRATOR, ROLES.MON_POINT_ALL_WRITE, ROLES.MON_POINT_HYMO_PV_WRITE]);
                case '27000020':
                    return hasSome([ROLES.ADMINISTRATOR, ROLES.MON_POINT_ALL_WRITE, ROLES.MON_POINT_DISCHARGE_PZV_WRITE]);
                case '27000021':
                    return hasSome([ROLES.ADMINISTRATOR, ROLES.MON_POINT_ALL_WRITE, ROLES.MON_POINT_QUALITY_PZV_WRITE]);
                case '27000022':
                    return hasSome([ROLES.ADMINISTRATOR, ROLES.MON_POINT_ALL_WRITE, ROLES.MON_POINT_QUALITY_PZV_WRITE]);
                case '27000023':
                    return hasSome([
                        ROLES.ADMINISTRATOR,
                        ROLES.MON_POINT_ALL_WRITE,
                        ROLES.MON_POINT_QUANTITY_PV_WRITE,
                        ROLES.MON_POINT_HYMO_PV_WRITE
                    ]);
                // case '27000024':
                //     return hasSome(editingMonPointRoles);
                default:
                    return false;
            }
        },
        [hasSome]
    );

    const hasReadOrEditorRole = useCallback(
        (monPointType?: string) => {
            switch (monPointType) {
                case '27000001':
                    return hasSome([
                        ROLES.ADMINISTRATOR,
                        ROLES.MON_POINT_ALL_READ,
                        ROLES.MON_POINT_ALL_WRITE,
                        ROLES.MON_POINT_QUALITY_PV_READ,
                        ROLES.MON_POINT_QUALITY_PV_WRITE,
                        ROLES.MON_POINT_HYMO_PV_READ,
                        ROLES.MON_POINT_HYMO_PV_WRITE
                    ]);
                case '27000002':
                    return hasSome([
                        ROLES.ADMINISTRATOR,
                        ROLES.MON_POINT_ALL_READ,
                        ROLES.MON_POINT_ALL_WRITE,
                        ROLES.MON_POINT_ABSTRACTION_PV_READ,
                        ROLES.MON_POINT_ABSTRACTION_PV_WRITE
                    ]);
                case '27000003':
                    return hasSome([
                        ROLES.ADMINISTRATOR,
                        ROLES.MON_POINT_ALL_READ,
                        ROLES.MON_POINT_ALL_WRITE,
                        ROLES.MON_POINT_DISCHARGE_PV_READ,
                        ROLES.MON_POINT_DISCHARGE_PV_WRITE
                    ]);
                case '27000005':
                    return hasSome([
                        ROLES.ADMINISTRATOR,
                        ROLES.MON_POINT_ALL_READ,
                        ROLES.MON_POINT_ALL_WRITE,
                        ROLES.MON_POINT_QUANTITY_PZV_READ,
                        ROLES.MON_POINT_QUANTITY_PZV_WRITE
                    ]);
                case '27000006':
                    return hasSome([
                        ROLES.ADMINISTRATOR,
                        ROLES.MON_POINT_ALL_READ,
                        ROLES.MON_POINT_ALL_WRITE,
                        ROLES.MON_POINT_ABSTRACTON_PZV_READ,
                        ROLES.MON_POINT_ABSTRACTON_PZV_WRITE
                    ]);
                case '27000007':
                    return hasSome([
                        ROLES.ADMINISTRATOR,
                        ROLES.MON_POINT_ALL_READ,
                        ROLES.MON_POINT_ALL_WRITE,
                        ROLES.MON_POINT_QUANTITY_PZV_READ,
                        ROLES.MON_POINT_QUANTITY_PZV_WRITE
                    ]);
                case '27000008':
                    return hasSome([
                        ROLES.ADMINISTRATOR,
                        ROLES.MON_POINT_ALL_READ,
                        ROLES.MON_POINT_ALL_WRITE,
                        ROLES.MON_POINT_QUANTITY_PV_READ,
                        ROLES.MON_POINT_QUANTITY_PV_WRITE,
                        ROLES.MON_POINT_HYMO_PV_READ,
                        ROLES.MON_POINT_HYMO_PV_WRITE
                    ]);
                case '27000009':
                    return hasSome([
                        ROLES.ADMINISTRATOR,
                        ROLES.MON_POINT_ALL_READ,
                        ROLES.MON_POINT_ALL_WRITE,
                        ROLES.MON_POINT_QUANTITY_VHB_PV_READ,
                        ROLES.MON_POINT_QUANTITY_VHB_PV_WRITE
                    ]);
                case '27000011':
                    return hasSome([
                        ROLES.ADMINISTRATOR,
                        ROLES.MON_POINT_ALL_READ,
                        ROLES.MON_POINT_ALL_WRITE,
                        ROLES.MON_POINT_TNMN_PV_READ,
                        ROLES.MON_POINT_TNMN_PV_WRITE
                    ]);
                case '27000012':
                    return hasSome([
                        ROLES.ADMINISTRATOR,
                        ROLES.MON_POINT_ALL_READ,
                        ROLES.MON_POINT_ALL_WRITE,
                        ROLES.MON_POINT_QUANTITY_VHB_PV_READ,
                        ROLES.MON_POINT_QUANTITY_VHB_PV_WRITE
                    ]);
                case '27000013':
                    return hasSome([
                        ROLES.ADMINISTRATOR,
                        ROLES.MON_POINT_ALL_READ,
                        ROLES.MON_POINT_ALL_WRITE,
                        ROLES.MON_POINT_QUANTITY_VHB_PV_READ,
                        ROLES.MON_POINT_QUANTITY_VHB_PV_WRITE
                    ]);
                case '27000014':
                    return hasSome([
                        ROLES.ADMINISTRATOR,
                        ROLES.MON_POINT_ALL_READ,
                        ROLES.MON_POINT_ALL_WRITE,
                        ROLES.MON_POINT_QUALITY_TESTIMONIALS_READ,
                        ROLES.MON_POINT_QUALITY_TESTIMONIALS_WRITE
                    ]);
                case '27000015':
                    return hasSome([
                        ROLES.ADMINISTRATOR,
                        ROLES.MON_POINT_ALL_READ,
                        ROLES.MON_POINT_ALL_WRITE,
                        ROLES.MON_POINT_QUANTITY_TESTIMONIALS_READ,
                        ROLES.MON_POINT_QUANTITY_TESTIMONIALS_WRITE
                    ]);
                case '27000016':
                    return hasSome([
                        ROLES.ADMINISTRATOR,
                        ROLES.MON_POINT_ALL_READ,
                        ROLES.MON_POINT_ALL_WRITE,
                        ROLES.MON_POINT_QUANTITY_VHB_PV_READ,
                        ROLES.MON_POINT_QUANTITY_VHB_PV_WRITE,
                        ROLES.MON_POINT_QUANTITY_VHB_PZV_READ,
                        ROLES.MON_POINT_QUANTITY_VHB_PZV_WRITE
                    ]);
                case '27000017':
                    return hasSome([
                        ROLES.ADMINISTRATOR,
                        ROLES.MON_POINT_ALL_READ,
                        ROLES.MON_POINT_ALL_WRITE,
                        ROLES.MON_POINT_ABSTRACTON_PZV_READ,
                        ROLES.MON_POINT_ABSTRACTON_PZV_WRITE
                    ]);
                case '27000018':
                    return hasSome([
                        ROLES.ADMINISTRATOR,
                        ROLES.MON_POINT_ALL_READ,
                        ROLES.MON_POINT_ALL_WRITE,
                        ROLES.MON_POINT_QUANTITY_VHB_PZV_READ,
                        ROLES.MON_POINT_QUANTITY_VHB_PZV_WRITE
                    ]);
                case '27000019':
                    return hasSome([
                        ROLES.ADMINISTRATOR,
                        ROLES.MON_POINT_ALL_READ,
                        ROLES.MON_POINT_ALL_WRITE,
                        ROLES.MON_POINT_HYMO_PV_READ,
                        ROLES.MON_POINT_HYMO_PV_WRITE
                    ]);
                case '27000020':
                    return hasSome([
                        ROLES.ADMINISTRATOR,
                        ROLES.MON_POINT_ALL_READ,
                        ROLES.MON_POINT_ALL_WRITE,
                        ROLES.MON_POINT_DISCHARGE_PZV_READ,
                        ROLES.MON_POINT_DISCHARGE_PZV_WRITE
                    ]);
                case '27000021':
                    return hasSome([
                        ROLES.ADMINISTRATOR,
                        ROLES.MON_POINT_ALL_READ,
                        ROLES.MON_POINT_ALL_WRITE,
                        ROLES.MON_POINT_QUALITY_PZV_READ,
                        ROLES.MON_POINT_QUALITY_PZV_WRITE
                    ]);
                case '27000022':
                    return hasSome([
                        ROLES.ADMINISTRATOR,
                        ROLES.MON_POINT_ALL_READ,
                        ROLES.MON_POINT_ALL_WRITE,
                        ROLES.MON_POINT_QUALITY_PZV_READ,
                        ROLES.MON_POINT_QUALITY_PZV_WRITE
                    ]);
                case '27000023':
                    return hasSome([
                        ROLES.ADMINISTRATOR,
                        ROLES.MON_POINT_ALL_READ,
                        ROLES.MON_POINT_ALL_WRITE,
                        ROLES.MON_POINT_QUANTITY_PV_READ,
                        ROLES.MON_POINT_QUANTITY_PV_WRITE,
                        ROLES.MON_POINT_HYMO_PV_READ,
                        ROLES.MON_POINT_HYMO_PV_WRITE
                    ]);
                // case '27000024':
                //     return hasSome(allMonPointRoles);
                default:
                    return false;
            }
        },
        [hasSome]
    );

    const result = useMemo(() => {
        return { hasEditorRole, hasReadOrEditorRole };
    }, [hasEditorRole, hasReadOrEditorRole]);

    return result;
};

export default useMonPointTypeRole;
