/* tslint:disable */
/* eslint-disable */
/**
 * Method import API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MethodicCriteria
 */
export interface MethodicCriteria {
    /**
     * 
     * @type {number}
     * @memberof MethodicCriteria
     */
    importId: number;
    /**
     * 
     * @type {string}
     * @memberof MethodicCriteria
     */
    searchField?: string;
    /**
     * 
     * @type {string}
     * @memberof MethodicCriteria
     */
    determinantCode?: string;
    /**
     * 
     * @type {string}
     * @memberof MethodicCriteria
     */
    analyticalMethodCode?: string;
    /**
     * 
     * @type {string}
     * @memberof MethodicCriteria
     */
    lOQ?: string;
    /**
     * 
     * @type {string}
     * @memberof MethodicCriteria
     */
    referenceToLiterature?: string;
    /**
     * 
     * @type {string}
     * @memberof MethodicCriteria
     */
    unit?: string;
    /**
     * 
     * @type {string}
     * @memberof MethodicCriteria
     */
    analyticalMethodName?: string;
    /**
     * 
     * @type {string}
     * @memberof MethodicCriteria
     */
    validation?: string;
    /**
     * 
     * @type {string}
     * @memberof MethodicCriteria
     */
    uncertainty?: string;
    /**
     * 
     * @type {string}
     * @memberof MethodicCriteria
     */
    status?: MethodicCriteriaStatusEnum;
}


/**
 * @export
 */
export const MethodicCriteriaStatusEnum = {
    Created: 'created',
    Waiting: 'waiting',
    Importing: 'importing',
    Validating: 'validating',
    Validated: 'validated',
    Imported: 'imported',
    Failed: 'failed',
    Unvalidated: 'unvalidated'
} as const;
export type MethodicCriteriaStatusEnum = typeof MethodicCriteriaStatusEnum[keyof typeof MethodicCriteriaStatusEnum];


/**
 * Check if a given object implements the MethodicCriteria interface.
 */
export function instanceOfMethodicCriteria(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "importId" in value;

    return isInstance;
}

export function MethodicCriteriaFromJSON(json: any): MethodicCriteria {
    return MethodicCriteriaFromJSONTyped(json, false);
}

export function MethodicCriteriaFromJSONTyped(json: any, ignoreDiscriminator: boolean): MethodicCriteria {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'importId': json['importId'],
        'searchField': !exists(json, 'searchField') ? undefined : json['searchField'],
        'determinantCode': !exists(json, 'determinantCode') ? undefined : json['determinantCode'],
        'analyticalMethodCode': !exists(json, 'analyticalMethodCode') ? undefined : json['analyticalMethodCode'],
        'lOQ': !exists(json, 'LOQ') ? undefined : json['LOQ'],
        'referenceToLiterature': !exists(json, 'referenceToLiterature') ? undefined : json['referenceToLiterature'],
        'unit': !exists(json, 'unit') ? undefined : json['unit'],
        'analyticalMethodName': !exists(json, 'analyticalMethodName') ? undefined : json['analyticalMethodName'],
        'validation': !exists(json, 'validation') ? undefined : json['validation'],
        'uncertainty': !exists(json, 'uncertainty') ? undefined : json['uncertainty'],
        'status': !exists(json, 'status') ? undefined : json['status'],
    };
}

export function MethodicCriteriaToJSON(value?: MethodicCriteria | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'importId': value.importId,
        'searchField': value.searchField,
        'determinantCode': value.determinantCode,
        'analyticalMethodCode': value.analyticalMethodCode,
        'LOQ': value.lOQ,
        'referenceToLiterature': value.referenceToLiterature,
        'unit': value.unit,
        'analyticalMethodName': value.analyticalMethodName,
        'validation': value.validation,
        'uncertainty': value.uncertainty,
        'status': value.status,
    };
}

