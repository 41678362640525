/* tslint:disable */
/* eslint-disable */
/**
 * Data import
 * Modul for importing data to Data Registry.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: schvarcz@bb.microcomp.sk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AddRowRequest,
  DataAttributeResponse,
  DataAttributeRowResponses,
  PagePrimaryDataAttributeResponse,
  PreviewImportFilePartMetadataResponse,
  PrimaryDataAttributeResponse,
  RemoveRowsRequest,
  UpdateAttributeRequest,
} from '../models/index';
import {
    AddRowRequestFromJSON,
    AddRowRequestToJSON,
    DataAttributeResponseFromJSON,
    DataAttributeResponseToJSON,
    DataAttributeRowResponsesFromJSON,
    DataAttributeRowResponsesToJSON,
    PagePrimaryDataAttributeResponseFromJSON,
    PagePrimaryDataAttributeResponseToJSON,
    PreviewImportFilePartMetadataResponseFromJSON,
    PreviewImportFilePartMetadataResponseToJSON,
    PrimaryDataAttributeResponseFromJSON,
    PrimaryDataAttributeResponseToJSON,
    RemoveRowsRequestFromJSON,
    RemoveRowsRequestToJSON,
    UpdateAttributeRequestFromJSON,
    UpdateAttributeRequestToJSON,
} from '../models/index';

export interface DataAttributeApiAddRowOperationRequest {
    addRowRequest: AddRowRequest;
}

export interface DataAttributeApiAdditionalCalculationsRequest {
    documentId: string;
}

export interface DataAttributeApiGetDataAttributeLinesRequest {
    documentId: string;
    page?: number;
    pageSize?: number;
}

export interface DataAttributeApiGetDataAttributesRequest {
    documentId: string;
    page?: number;
    pageSize?: number;
}

export interface DataAttributeApiGetPreviewMetadataRequest {
    documentId: string;
}

export interface DataAttributeApiGetPrimaryDataAttributesRequest {
    documentId: string;
    importFilePartDefinitionId: string;
    sortDirections: Array<string>;
    sortProperties: Array<string>;
    dataSet?: number;
    page?: number;
    pageSize?: number;
}

export interface DataAttributeApiRemoveRowsOperationRequest {
    removeRowsRequest: RemoveRowsRequest;
}

export interface DataAttributeApiSendToDataRegistryModuleRequest {
    documentId: string;
}

export interface DataAttributeApiUpdateAttributeOperationRequest {
    updateAttributeRequest: UpdateAttributeRequest;
}

/**
 * 
 */
export class DataAttributeApi extends runtime.BaseAPI {

    /**
     * Add row
     */
    async addRowRaw(requestParameters: DataAttributeApiAddRowOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.addRowRequest === null || requestParameters.addRowRequest === undefined) {
            throw new runtime.RequiredError('addRowRequest','Required parameter requestParameters.addRowRequest was null or undefined when calling addRow.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data-attribute/add-row`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AddRowRequestToJSON(requestParameters.addRowRequest),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Add row
     */
    async addRow(addRowRequest: AddRowRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.addRowRaw({ addRowRequest: addRowRequest }, initOverrides);
        return await response.value();
    }

    /**
     * Generate additional attribute
     */
    async additionalCalculationsRaw(requestParameters: DataAttributeApiAdditionalCalculationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling additionalCalculations.');
        }

        const queryParameters: any = {};

        if (requestParameters.documentId !== undefined) {
            queryParameters['documentId'] = requestParameters.documentId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data-attribute/additional-calculations`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Generate additional attribute
     */
    async additionalCalculations(documentId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.additionalCalculationsRaw({ documentId: documentId }, initOverrides);
    }

    /**
     * Get all data attributes in lines by document id and importFilePartDefinition id
     */
    async getDataAttributeLinesRaw(requestParameters: DataAttributeApiGetDataAttributeLinesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DataAttributeRowResponses>> {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling getDataAttributeLines.');
        }

        const queryParameters: any = {};

        if (requestParameters.documentId !== undefined) {
            queryParameters['documentId'] = requestParameters.documentId;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data-attribute/lines`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DataAttributeRowResponsesFromJSON(jsonValue));
    }

    /**
     * Get all data attributes in lines by document id and importFilePartDefinition id
     */
    async getDataAttributeLines(documentId: string, page?: number, pageSize?: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DataAttributeRowResponses> {
        const response = await this.getDataAttributeLinesRaw({ documentId: documentId, page: page, pageSize: pageSize }, initOverrides);
        return await response.value();
    }

    /**
     * Get all data attributes by document id
     */
    async getDataAttributesRaw(requestParameters: DataAttributeApiGetDataAttributesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DataAttributeResponse>>> {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling getDataAttributes.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data-attribute/{documentId}`.replace(`{${"documentId"}}`, encodeURIComponent(String(requestParameters.documentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DataAttributeResponseFromJSON));
    }

    /**
     * Get all data attributes by document id
     */
    async getDataAttributes(documentId: string, page?: number, pageSize?: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DataAttributeResponse>> {
        const response = await this.getDataAttributesRaw({ documentId: documentId, page: page, pageSize: pageSize }, initOverrides);
        return await response.value();
    }

    /**
     * Get preview metadata - metadata of primary attributes in columns - these are the attributes from import file
     */
    async getPreviewMetadataRaw(requestParameters: DataAttributeApiGetPreviewMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PreviewImportFilePartMetadataResponse>>> {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling getPreviewMetadata.');
        }

        const queryParameters: any = {};

        if (requestParameters.documentId !== undefined) {
            queryParameters['documentId'] = requestParameters.documentId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data-attribute/previewMetadata`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PreviewImportFilePartMetadataResponseFromJSON));
    }

    /**
     * Get preview metadata - metadata of primary attributes in columns - these are the attributes from import file
     */
    async getPreviewMetadata(documentId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PreviewImportFilePartMetadataResponse>> {
        const response = await this.getPreviewMetadataRaw({ documentId: documentId }, initOverrides);
        return await response.value();
    }

    /**
     * Get primary data attributes in lines by document id and importFilePartDefinition id
     */
    async getPrimaryDataAttributesRaw(requestParameters: DataAttributeApiGetPrimaryDataAttributesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PagePrimaryDataAttributeResponse>> {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling getPrimaryDataAttributes.');
        }

        if (requestParameters.importFilePartDefinitionId === null || requestParameters.importFilePartDefinitionId === undefined) {
            throw new runtime.RequiredError('importFilePartDefinitionId','Required parameter requestParameters.importFilePartDefinitionId was null or undefined when calling getPrimaryDataAttributes.');
        }

        if (requestParameters.sortDirections === null || requestParameters.sortDirections === undefined) {
            throw new runtime.RequiredError('sortDirections','Required parameter requestParameters.sortDirections was null or undefined when calling getPrimaryDataAttributes.');
        }

        if (requestParameters.sortProperties === null || requestParameters.sortProperties === undefined) {
            throw new runtime.RequiredError('sortProperties','Required parameter requestParameters.sortProperties was null or undefined when calling getPrimaryDataAttributes.');
        }

        const queryParameters: any = {};

        if (requestParameters.documentId !== undefined) {
            queryParameters['documentId'] = requestParameters.documentId;
        }

        if (requestParameters.importFilePartDefinitionId !== undefined) {
            queryParameters['importFilePartDefinitionId'] = requestParameters.importFilePartDefinitionId;
        }

        if (requestParameters.dataSet !== undefined) {
            queryParameters['dataSet'] = requestParameters.dataSet;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.sortDirections) {
            queryParameters['sortDirections'] = requestParameters.sortDirections;
        }

        if (requestParameters.sortProperties) {
            queryParameters['sortProperties'] = requestParameters.sortProperties;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data-attribute/lines-primary-attributes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PagePrimaryDataAttributeResponseFromJSON(jsonValue));
    }

    /**
     * Get primary data attributes in lines by document id and importFilePartDefinition id
     */
    async getPrimaryDataAttributes(documentId: string, importFilePartDefinitionId: string, sortDirections: Array<string>, sortProperties: Array<string>, dataSet?: number, page?: number, pageSize?: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PagePrimaryDataAttributeResponse> {
        const response = await this.getPrimaryDataAttributesRaw({ documentId: documentId, importFilePartDefinitionId: importFilePartDefinitionId, sortDirections: sortDirections, sortProperties: sortProperties, dataSet: dataSet, page: page, pageSize: pageSize }, initOverrides);
        return await response.value();
    }

    /**
     * Remove rows
     */
    async removeRowsRaw(requestParameters: DataAttributeApiRemoveRowsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.removeRowsRequest === null || requestParameters.removeRowsRequest === undefined) {
            throw new runtime.RequiredError('removeRowsRequest','Required parameter requestParameters.removeRowsRequest was null or undefined when calling removeRows.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data-attribute/remove-rows`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: RemoveRowsRequestToJSON(requestParameters.removeRowsRequest),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Remove rows
     */
    async removeRows(removeRowsRequest: RemoveRowsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.removeRowsRaw({ removeRowsRequest: removeRowsRequest }, initOverrides);
        return await response.value();
    }

    /**
     * Send to data registry module
     */
    async sendToDataRegistryModuleRaw(requestParameters: DataAttributeApiSendToDataRegistryModuleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling sendToDataRegistryModule.');
        }

        const queryParameters: any = {};

        if (requestParameters.documentId !== undefined) {
            queryParameters['documentId'] = requestParameters.documentId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data-attribute/send-to-data-registry-module`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Send to data registry module
     */
    async sendToDataRegistryModule(documentId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.sendToDataRegistryModuleRaw({ documentId: documentId }, initOverrides);
    }

    /**
     * Update attribute
     */
    async updateAttributeRaw(requestParameters: DataAttributeApiUpdateAttributeOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PrimaryDataAttributeResponse>> {
        if (requestParameters.updateAttributeRequest === null || requestParameters.updateAttributeRequest === undefined) {
            throw new runtime.RequiredError('updateAttributeRequest','Required parameter requestParameters.updateAttributeRequest was null or undefined when calling updateAttribute.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data-attribute/update-attribute`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateAttributeRequestToJSON(requestParameters.updateAttributeRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PrimaryDataAttributeResponseFromJSON(jsonValue));
    }

    /**
     * Update attribute
     */
    async updateAttribute(updateAttributeRequest: UpdateAttributeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PrimaryDataAttributeResponse> {
        const response = await this.updateAttributeRaw({ updateAttributeRequest: updateAttributeRequest }, initOverrides);
        return await response.value();
    }

}
