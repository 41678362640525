/* tslint:disable */
/* eslint-disable */
/**
 * Overview API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SortsSo
 */
export interface SortsSo {
    /**
     * 
     * @type {string}
     * @memberof SortsSo
     */
    column: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SortsSo
     */
    ascending: boolean | null;
}

/**
 * Check if a given object implements the SortsSo interface.
 */
export function instanceOfSortsSo(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "column" in value;
    isInstance = isInstance && "ascending" in value;

    return isInstance;
}

export function SortsSoFromJSON(json: any): SortsSo {
    return SortsSoFromJSONTyped(json, false);
}

export function SortsSoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SortsSo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'column': json['column'],
        'ascending': json['ascending'],
    };
}

export function SortsSoToJSON(value?: SortsSo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'column': value.column,
        'ascending': value.ascending,
    };
}

