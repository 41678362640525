/* tslint:disable */
/* eslint-disable */
/**
 * Data import metadata
 * Administration of metadata for import
 *
 * The version of the OpenAPI document: 1.0
 * Contact: schvarcz@bb.microcomp.sk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RowAttributeTransformationDefinitionRequest } from './RowAttributeTransformationDefinitionRequest';
import {
    RowAttributeTransformationDefinitionRequestFromJSON,
    RowAttributeTransformationDefinitionRequestFromJSONTyped,
    RowAttributeTransformationDefinitionRequestToJSON,
} from './RowAttributeTransformationDefinitionRequest';

/**
 * 
 * @export
 * @interface AssignRowAttributeTransformationDefinitionsRequest
 */
export interface AssignRowAttributeTransformationDefinitionsRequest {
    /**
     * 
     * @type {string}
     * @memberof AssignRowAttributeTransformationDefinitionsRequest
     */
    rowAttributeDefinitionId?: string;
    /**
     * 
     * @type {Array<RowAttributeTransformationDefinitionRequest>}
     * @memberof AssignRowAttributeTransformationDefinitionsRequest
     */
    rowAttributeTransformationDefinitions?: Array<RowAttributeTransformationDefinitionRequest>;
}

/**
 * Check if a given object implements the AssignRowAttributeTransformationDefinitionsRequest interface.
 */
export function instanceOfAssignRowAttributeTransformationDefinitionsRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AssignRowAttributeTransformationDefinitionsRequestFromJSON(json: any): AssignRowAttributeTransformationDefinitionsRequest {
    return AssignRowAttributeTransformationDefinitionsRequestFromJSONTyped(json, false);
}

export function AssignRowAttributeTransformationDefinitionsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AssignRowAttributeTransformationDefinitionsRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'rowAttributeDefinitionId': !exists(json, 'rowAttributeDefinitionId') ? undefined : json['rowAttributeDefinitionId'],
        'rowAttributeTransformationDefinitions': !exists(json, 'rowAttributeTransformationDefinitions') ? undefined : ((json['rowAttributeTransformationDefinitions'] as Array<any>).map(RowAttributeTransformationDefinitionRequestFromJSON)),
    };
}

export function AssignRowAttributeTransformationDefinitionsRequestToJSON(value?: AssignRowAttributeTransformationDefinitionsRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'rowAttributeDefinitionId': value.rowAttributeDefinitionId,
        'rowAttributeTransformationDefinitions': value.rowAttributeTransformationDefinitions === undefined ? undefined : ((value.rowAttributeTransformationDefinitions as Array<any>).map(RowAttributeTransformationDefinitionRequestToJSON)),
    };
}

