/* tslint:disable */
/* eslint-disable */
/**
 * Codelist API
 * Nástroj pre správu metadát a katalógov - koncové služby
 *
 * The version of the OpenAPI document: 0.21.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CodelistCriteria } from './CodelistCriteria';
import {
    CodelistCriteriaFromJSON,
    CodelistCriteriaFromJSONTyped,
    CodelistCriteriaToJSON,
} from './CodelistCriteria';

/**
 * 
 * @export
 * @interface CodelistExportDataSo
 */
export interface CodelistExportDataSo {
    /**
     * 
     * @type {Array<string>}
     * @memberof CodelistExportDataSo
     */
    fieldsToExport: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CodelistExportDataSo
     */
    sort: string;
    /**
     * 
     * @type {string}
     * @memberof CodelistExportDataSo
     */
    csvDelimiter: string;
    /**
     * 
     * @type {boolean}
     * @memberof CodelistExportDataSo
     */
    onlyHeader: boolean;
    /**
     * 
     * @type {CodelistCriteria}
     * @memberof CodelistExportDataSo
     */
    criteria: CodelistCriteria;
}

/**
 * Check if a given object implements the CodelistExportDataSo interface.
 */
export function instanceOfCodelistExportDataSo(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "fieldsToExport" in value;
    isInstance = isInstance && "sort" in value;
    isInstance = isInstance && "csvDelimiter" in value;
    isInstance = isInstance && "onlyHeader" in value;
    isInstance = isInstance && "criteria" in value;

    return isInstance;
}

export function CodelistExportDataSoFromJSON(json: any): CodelistExportDataSo {
    return CodelistExportDataSoFromJSONTyped(json, false);
}

export function CodelistExportDataSoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CodelistExportDataSo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fieldsToExport': json['fieldsToExport'],
        'sort': json['sort'],
        'csvDelimiter': json['csvDelimiter'],
        'onlyHeader': json['onlyHeader'],
        'criteria': CodelistCriteriaFromJSON(json['criteria']),
    };
}

export function CodelistExportDataSoToJSON(value?: CodelistExportDataSo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fieldsToExport': value.fieldsToExport,
        'sort': value.sort,
        'csvDelimiter': value.csvDelimiter,
        'onlyHeader': value.onlyHeader,
        'criteria': CodelistCriteriaToJSON(value.criteria),
    };
}

