/* tslint:disable */
/* eslint-disable */
/**
 * Codelist API
 * Nástroj pre správu metadát a katalógov - koncové služby
 *
 * The version of the OpenAPI document: 0.21.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CodelistItemAttribute } from './CodelistItemAttribute';
import {
    CodelistItemAttributeFromJSON,
    CodelistItemAttributeFromJSONTyped,
    CodelistItemAttributeToJSON,
} from './CodelistItemAttribute';
import type { CodelistItemLocalization } from './CodelistItemLocalization';
import {
    CodelistItemLocalizationFromJSON,
    CodelistItemLocalizationFromJSONTyped,
    CodelistItemLocalizationToJSON,
} from './CodelistItemLocalization';

/**
 * 
 * @export
 * @interface CodelistItemInput
 */
export interface CodelistItemInput {
    /**
     * 
     * @type {number}
     * @memberof CodelistItemInput
     */
    codelistCode: number;
    /**
     * 
     * @type {string}
     * @memberof CodelistItemInput
     */
    acronym: string;
    /**
     * 
     * @type {string}
     * @memberof CodelistItemInput
     */
    validFrom: string;
    /**
     * 
     * @type {string}
     * @memberof CodelistItemInput
     */
    effectiveFrom: string;
    /**
     * 
     * @type {string}
     * @memberof CodelistItemInput
     */
    effectiveTo: string | null;
    /**
     * 
     * @type {Array<CodelistItemAttribute>}
     * @memberof CodelistItemInput
     */
    attributes: Array<CodelistItemAttribute>;
    /**
     * 
     * @type {Array<CodelistItemLocalization>}
     * @memberof CodelistItemInput
     */
    localizations: Array<CodelistItemLocalization>;
}

/**
 * Check if a given object implements the CodelistItemInput interface.
 */
export function instanceOfCodelistItemInput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "codelistCode" in value;
    isInstance = isInstance && "acronym" in value;
    isInstance = isInstance && "validFrom" in value;
    isInstance = isInstance && "effectiveFrom" in value;
    isInstance = isInstance && "effectiveTo" in value;
    isInstance = isInstance && "attributes" in value;
    isInstance = isInstance && "localizations" in value;

    return isInstance;
}

export function CodelistItemInputFromJSON(json: any): CodelistItemInput {
    return CodelistItemInputFromJSONTyped(json, false);
}

export function CodelistItemInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): CodelistItemInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'codelistCode': json['codelistCode'],
        'acronym': json['acronym'],
        'validFrom': json['validFrom'],
        'effectiveFrom': json['effectiveFrom'],
        'effectiveTo': json['effectiveTo'],
        'attributes': ((json['attributes'] as Array<any>).map(CodelistItemAttributeFromJSON)),
        'localizations': ((json['localizations'] as Array<any>).map(CodelistItemLocalizationFromJSON)),
    };
}

export function CodelistItemInputToJSON(value?: CodelistItemInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'codelistCode': value.codelistCode,
        'acronym': value.acronym,
        'validFrom': value.validFrom,
        'effectiveFrom': value.effectiveFrom,
        'effectiveTo': value.effectiveTo,
        'attributes': ((value.attributes as Array<any>).map(CodelistItemAttributeToJSON)),
        'localizations': ((value.localizations as Array<any>).map(CodelistItemLocalizationToJSON)),
    };
}

