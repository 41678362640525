import React from 'react';
import ReactDOM from 'react-dom/client';

import './index.scss';
import App from './App';
import { AuthProvider, AuthProviderProps } from 'react-oidc-context';

const oidcConfig: AuthProviderProps = {
    authority: ENV.KEYCLOAK_URL + '/realms/' + ENV.KEYCLOAK_REALM,
    /* eslint-disable-next-line camelcase */
    client_id: ENV.KEYCLOAK_CLIENT,
    /* eslint-disable-next-line camelcase */
    redirect_uri: window.location.href,
    automaticSilentRenew: true,
    onSigninCallback: () => {
        const urlParams = new URLSearchParams(window.location.search);
        const formId = urlParams.get('formId');
        const callback = urlParams.get('callback');
        const preserved = new URLSearchParams();
        if (formId && callback) {
            preserved.set('formId', formId);
            preserved.set('callback', callback);
        }
        const preservedQuery = preserved.toString();
        window.history.replaceState(
            { formId, callback },
            document.title,
            window.location.pathname + (preservedQuery ? '?' + preservedQuery : '')
        );
    }
};

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
    <AuthProvider {...oidcConfig}>
        <React.StrictMode>
            <App />
        </React.StrictMode>
    </AuthProvider>
);
