/* tslint:disable */
/* eslint-disable */
/**
 * Data import metadata
 * Administration of metadata for import
 *
 * The version of the OpenAPI document: 1.0
 * Contact: schvarcz@bb.microcomp.sk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AttributeConditionResponse
 */
export interface AttributeConditionResponse {
    /**
     * 
     * @type {string}
     * @memberof AttributeConditionResponse
     */
    rowAttributeCode?: string;
    /**
     * 
     * @type {string}
     * @memberof AttributeConditionResponse
     */
    attributeValue?: string;
    /**
     * 
     * @type {string}
     * @memberof AttributeConditionResponse
     */
    operatorType?: AttributeConditionResponseOperatorTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AttributeConditionResponse
     */
    dataRegistryImportType?: AttributeConditionResponseDataRegistryImportTypeEnum;
}


/**
 * @export
 */
export const AttributeConditionResponseOperatorTypeEnum = {
    Equals: 'EQUALS',
    NotEquals: 'NOT_EQUALS',
    LessThan: 'LESS_THAN',
    LessThanOrEquals: 'LESS_THAN_OR_EQUALS',
    GreaterThan: 'GREATER_THAN',
    GreaterThanOrEquals: 'GREATER_THAN_OR_EQUALS'
} as const;
export type AttributeConditionResponseOperatorTypeEnum = typeof AttributeConditionResponseOperatorTypeEnum[keyof typeof AttributeConditionResponseOperatorTypeEnum];

/**
 * @export
 */
export const AttributeConditionResponseDataRegistryImportTypeEnum = {
    Create: 'CREATE',
    Update: 'UPDATE',
    Merge: 'MERGE'
} as const;
export type AttributeConditionResponseDataRegistryImportTypeEnum = typeof AttributeConditionResponseDataRegistryImportTypeEnum[keyof typeof AttributeConditionResponseDataRegistryImportTypeEnum];


/**
 * Check if a given object implements the AttributeConditionResponse interface.
 */
export function instanceOfAttributeConditionResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AttributeConditionResponseFromJSON(json: any): AttributeConditionResponse {
    return AttributeConditionResponseFromJSONTyped(json, false);
}

export function AttributeConditionResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): AttributeConditionResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'rowAttributeCode': !exists(json, 'rowAttributeCode') ? undefined : json['rowAttributeCode'],
        'attributeValue': !exists(json, 'attributeValue') ? undefined : json['attributeValue'],
        'operatorType': !exists(json, 'operatorType') ? undefined : json['operatorType'],
        'dataRegistryImportType': !exists(json, 'dataRegistryImportType') ? undefined : json['dataRegistryImportType'],
    };
}

export function AttributeConditionResponseToJSON(value?: AttributeConditionResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'rowAttributeCode': value.rowAttributeCode,
        'attributeValue': value.attributeValue,
        'operatorType': value.operatorType,
        'dataRegistryImportType': value.dataRegistryImportType,
    };
}

