/* tslint:disable */
/* eslint-disable */
/**
 * Catalog API
 * Nástroj pre správu metadát a katalógov - koncové služby
 *
 * The version of the OpenAPI document: 0.27.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WaterShapeCriteria
 */
export interface WaterShapeCriteria {
    /**
     * 
     * @type {string}
     * @memberof WaterShapeCriteria
     */
    searchField?: string;
    /**
     * 
     * @type {string}
     * @memberof WaterShapeCriteria
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof WaterShapeCriteria
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof WaterShapeCriteria
     */
    riverKmClosest?: number;
    /**
     * 
     * @type {number}
     * @memberof WaterShapeCriteria
     */
    riverKmFurthest?: number;
    /**
     * 
     * @type {number}
     * @memberof WaterShapeCriteria
     */
    flowLength?: number;
    /**
     * 
     * @type {number}
     * @memberof WaterShapeCriteria
     */
    basinType?: number;
    /**
     * 
     * @type {number}
     * @memberof WaterShapeCriteria
     */
    wbCharacterType?: number;
    /**
     * 
     * @type {number}
     * @memberof WaterShapeCriteria
     */
    wbGroupType?: number;
    /**
     * 
     * @type {number}
     * @memberof WaterShapeCriteria
     */
    wbType?: number;
    /**
     * 
     * @type {number}
     * @memberof WaterShapeCriteria
     */
    jtskXStart?: number;
    /**
     * 
     * @type {number}
     * @memberof WaterShapeCriteria
     */
    jtskXEnd?: number;
    /**
     * 
     * @type {number}
     * @memberof WaterShapeCriteria
     */
    jtskYStart?: number;
    /**
     * 
     * @type {number}
     * @memberof WaterShapeCriteria
     */
    jtskYEnd?: number;
    /**
     * 
     * @type {number}
     * @memberof WaterShapeCriteria
     */
    statusType?: number;
    /**
     * 
     * @type {number}
     * @memberof WaterShapeCriteria
     */
    version?: number;
    /**
     * 
     * @type {string}
     * @memberof WaterShapeCriteria
     */
    effectiveTo?: string;
    /**
     * 
     * @type {string}
     * @memberof WaterShapeCriteria
     */
    effectiveFrom?: string;
    /**
     * 
     * @type {boolean}
     * @memberof WaterShapeCriteria
     */
    deleted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof WaterShapeCriteria
     */
    historical?: boolean;
    /**
     * 
     * @type {number}
     * @memberof WaterShapeCriteria
     */
    cycleType?: number;
    /**
     * 
     * @type {number}
     * @memberof WaterShapeCriteria
     */
    wbCategoryType?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof WaterShapeCriteria
     */
    ids?: Array<number>;
}

/**
 * Check if a given object implements the WaterShapeCriteria interface.
 */
export function instanceOfWaterShapeCriteria(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WaterShapeCriteriaFromJSON(json: any): WaterShapeCriteria {
    return WaterShapeCriteriaFromJSONTyped(json, false);
}

export function WaterShapeCriteriaFromJSONTyped(json: any, ignoreDiscriminator: boolean): WaterShapeCriteria {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'searchField': !exists(json, 'searchField') ? undefined : json['searchField'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'riverKmClosest': !exists(json, 'riverKmClosest') ? undefined : json['riverKmClosest'],
        'riverKmFurthest': !exists(json, 'riverKmFurthest') ? undefined : json['riverKmFurthest'],
        'flowLength': !exists(json, 'flowLength') ? undefined : json['flowLength'],
        'basinType': !exists(json, 'basinType') ? undefined : json['basinType'],
        'wbCharacterType': !exists(json, 'wbCharacterType') ? undefined : json['wbCharacterType'],
        'wbGroupType': !exists(json, 'wbGroupType') ? undefined : json['wbGroupType'],
        'wbType': !exists(json, 'wbType') ? undefined : json['wbType'],
        'jtskXStart': !exists(json, 'jtskXStart') ? undefined : json['jtskXStart'],
        'jtskXEnd': !exists(json, 'jtskXEnd') ? undefined : json['jtskXEnd'],
        'jtskYStart': !exists(json, 'jtskYStart') ? undefined : json['jtskYStart'],
        'jtskYEnd': !exists(json, 'jtskYEnd') ? undefined : json['jtskYEnd'],
        'statusType': !exists(json, 'statusType') ? undefined : json['statusType'],
        'version': !exists(json, 'version') ? undefined : json['version'],
        'effectiveTo': !exists(json, 'effectiveTo') ? undefined : json['effectiveTo'],
        'effectiveFrom': !exists(json, 'effectiveFrom') ? undefined : json['effectiveFrom'],
        'deleted': !exists(json, 'deleted') ? undefined : json['deleted'],
        'historical': !exists(json, 'historical') ? undefined : json['historical'],
        'cycleType': !exists(json, 'cycleType') ? undefined : json['cycleType'],
        'wbCategoryType': !exists(json, 'wbCategoryType') ? undefined : json['wbCategoryType'],
        'ids': !exists(json, 'ids') ? undefined : json['ids'],
    };
}

export function WaterShapeCriteriaToJSON(value?: WaterShapeCriteria | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'searchField': value.searchField,
        'code': value.code,
        'name': value.name,
        'riverKmClosest': value.riverKmClosest,
        'riverKmFurthest': value.riverKmFurthest,
        'flowLength': value.flowLength,
        'basinType': value.basinType,
        'wbCharacterType': value.wbCharacterType,
        'wbGroupType': value.wbGroupType,
        'wbType': value.wbType,
        'jtskXStart': value.jtskXStart,
        'jtskXEnd': value.jtskXEnd,
        'jtskYStart': value.jtskYStart,
        'jtskYEnd': value.jtskYEnd,
        'statusType': value.statusType,
        'version': value.version,
        'effectiveTo': value.effectiveTo,
        'effectiveFrom': value.effectiveFrom,
        'deleted': value.deleted,
        'historical': value.historical,
        'cycleType': value.cycleType,
        'wbCategoryType': value.wbCategoryType,
        'ids': value.ids,
    };
}

