/* tslint:disable */
/* eslint-disable */
/**
 * Data import
 * Modul for importing data to Data Registry.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: schvarcz@bb.microcomp.sk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DataAttributeResponse } from './DataAttributeResponse';
import {
    DataAttributeResponseFromJSON,
    DataAttributeResponseFromJSONTyped,
    DataAttributeResponseToJSON,
} from './DataAttributeResponse';
import type { ValidationResultResponse } from './ValidationResultResponse';
import {
    ValidationResultResponseFromJSON,
    ValidationResultResponseFromJSONTyped,
    ValidationResultResponseToJSON,
} from './ValidationResultResponse';

/**
 * 
 * @export
 * @interface PrimaryDataAttributeResponse
 */
export interface PrimaryDataAttributeResponse {
    /**
     * 
     * @type {string}
     * @memberof PrimaryDataAttributeResponse
     */
    rowId?: string;
    /**
     * 
     * @type {number}
     * @memberof PrimaryDataAttributeResponse
     */
    rowNumber?: number;
    /**
     * 
     * @type {ValidationResultResponse}
     * @memberof PrimaryDataAttributeResponse
     */
    rowValidationResult?: ValidationResultResponse;
    /**
     * 
     * @type {{ [key: string]: DataAttributeResponse; }}
     * @memberof PrimaryDataAttributeResponse
     */
    dataAttributeResponses?: { [key: string]: DataAttributeResponse; };
}

/**
 * Check if a given object implements the PrimaryDataAttributeResponse interface.
 */
export function instanceOfPrimaryDataAttributeResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PrimaryDataAttributeResponseFromJSON(json: any): PrimaryDataAttributeResponse {
    return PrimaryDataAttributeResponseFromJSONTyped(json, false);
}

export function PrimaryDataAttributeResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PrimaryDataAttributeResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'rowId': !exists(json, 'rowId') ? undefined : json['rowId'],
        'rowNumber': !exists(json, 'rowNumber') ? undefined : json['rowNumber'],
        'rowValidationResult': !exists(json, 'rowValidationResult') ? undefined : ValidationResultResponseFromJSON(json['rowValidationResult']),
        'dataAttributeResponses': !exists(json, 'dataAttributeResponses') ? undefined : (mapValues(json['dataAttributeResponses'], DataAttributeResponseFromJSON)),
    };
}

export function PrimaryDataAttributeResponseToJSON(value?: PrimaryDataAttributeResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'rowId': value.rowId,
        'rowNumber': value.rowNumber,
        'rowValidationResult': ValidationResultResponseToJSON(value.rowValidationResult),
        'dataAttributeResponses': value.dataAttributeResponses === undefined ? undefined : (mapValues(value.dataAttributeResponses, DataAttributeResponseToJSON)),
    };
}

