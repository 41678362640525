/* tslint:disable */
/* eslint-disable */
/**
 * Catalog import API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.4.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ImportSimpleResult
 */
export interface ImportSimpleResult {
    /**
     * 
     * @type {string}
     * @memberof ImportSimpleResult
     */
    status?: ImportSimpleResultStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof ImportSimpleResult
     */
    validationErrorCount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ImportSimpleResult
     */
    progress?: number | null;
}


/**
 * @export
 */
export const ImportSimpleResultStatusEnum = {
    Created: 'created',
    Waiting: 'waiting',
    Importing: 'importing',
    Validating: 'validating',
    Validated: 'validated',
    Imported: 'imported',
    Failed: 'failed',
    Unvalidated: 'unvalidated'
} as const;
export type ImportSimpleResultStatusEnum = typeof ImportSimpleResultStatusEnum[keyof typeof ImportSimpleResultStatusEnum];


/**
 * Check if a given object implements the ImportSimpleResult interface.
 */
export function instanceOfImportSimpleResult(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ImportSimpleResultFromJSON(json: any): ImportSimpleResult {
    return ImportSimpleResultFromJSONTyped(json, false);
}

export function ImportSimpleResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImportSimpleResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status': !exists(json, 'status') ? undefined : json['status'],
        'validationErrorCount': !exists(json, 'validationErrorCount') ? undefined : json['validationErrorCount'],
        'progress': !exists(json, 'progress') ? undefined : json['progress'],
    };
}

export function ImportSimpleResultToJSON(value?: ImportSimpleResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': value.status,
        'validationErrorCount': value.validationErrorCount,
        'progress': value.progress,
    };
}

