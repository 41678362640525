/* tslint:disable */
/* eslint-disable */
/**
 * Data import metadata
 * Administration of metadata for import
 *
 * The version of the OpenAPI document: 1.0
 * Contact: schvarcz@bb.microcomp.sk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ColumnMetadataResponse } from './ColumnMetadataResponse';
import {
    ColumnMetadataResponseFromJSON,
    ColumnMetadataResponseFromJSONTyped,
    ColumnMetadataResponseToJSON,
} from './ColumnMetadataResponse';
import type { RowTransformationDefinitionResponse } from './RowTransformationDefinitionResponse';
import {
    RowTransformationDefinitionResponseFromJSON,
    RowTransformationDefinitionResponseFromJSONTyped,
    RowTransformationDefinitionResponseToJSON,
} from './RowTransformationDefinitionResponse';

/**
 * 
 * @export
 * @interface RowTypeMetadataResponse
 */
export interface RowTypeMetadataResponse {
    /**
     * 
     * @type {string}
     * @memberof RowTypeMetadataResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof RowTypeMetadataResponse
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof RowTypeMetadataResponse
     */
    rowPosition?: number;
    /**
     * 
     * @type {string}
     * @memberof RowTypeMetadataResponse
     */
    rowDataType?: RowTypeMetadataResponseRowDataTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof RowTypeMetadataResponse
     */
    rowTypeDataOrder?: number;
    /**
     * 
     * @type {number}
     * @memberof RowTypeMetadataResponse
     */
    dataCount?: number;
    /**
     * 
     * @type {Array<ColumnMetadataResponse>}
     * @memberof RowTypeMetadataResponse
     */
    columns?: Array<ColumnMetadataResponse>;
    /**
     * 
     * @type {Array<RowTransformationDefinitionResponse>}
     * @memberof RowTypeMetadataResponse
     */
    rowTransformations?: Array<RowTransformationDefinitionResponse>;
}


/**
 * @export
 */
export const RowTypeMetadataResponseRowDataTypeEnum = {
    Comment: 'COMMENT',
    Names: 'NAMES',
    Units: 'UNITS',
    Data: 'DATA'
} as const;
export type RowTypeMetadataResponseRowDataTypeEnum = typeof RowTypeMetadataResponseRowDataTypeEnum[keyof typeof RowTypeMetadataResponseRowDataTypeEnum];


/**
 * Check if a given object implements the RowTypeMetadataResponse interface.
 */
export function instanceOfRowTypeMetadataResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RowTypeMetadataResponseFromJSON(json: any): RowTypeMetadataResponse {
    return RowTypeMetadataResponseFromJSONTyped(json, false);
}

export function RowTypeMetadataResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): RowTypeMetadataResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'rowPosition': !exists(json, 'rowPosition') ? undefined : json['rowPosition'],
        'rowDataType': !exists(json, 'rowDataType') ? undefined : json['rowDataType'],
        'rowTypeDataOrder': !exists(json, 'rowTypeDataOrder') ? undefined : json['rowTypeDataOrder'],
        'dataCount': !exists(json, 'dataCount') ? undefined : json['dataCount'],
        'columns': !exists(json, 'columns') ? undefined : ((json['columns'] as Array<any>).map(ColumnMetadataResponseFromJSON)),
        'rowTransformations': !exists(json, 'rowTransformations') ? undefined : ((json['rowTransformations'] as Array<any>).map(RowTransformationDefinitionResponseFromJSON)),
    };
}

export function RowTypeMetadataResponseToJSON(value?: RowTypeMetadataResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'rowPosition': value.rowPosition,
        'rowDataType': value.rowDataType,
        'rowTypeDataOrder': value.rowTypeDataOrder,
        'dataCount': value.dataCount,
        'columns': value.columns === undefined ? undefined : ((value.columns as Array<any>).map(ColumnMetadataResponseToJSON)),
        'rowTransformations': value.rowTransformations === undefined ? undefined : ((value.rowTransformations as Array<any>).map(RowTransformationDefinitionResponseToJSON)),
    };
}

