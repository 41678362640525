/* tslint:disable */
/* eslint-disable */
/**
 * Notification API
 * Nástroj pre posielanie notifikácií - koncové služby
 *
 * The version of the OpenAPI document: 0.8.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AccountSo } from './AccountSo';
import {
    AccountSoFromJSON,
    AccountSoFromJSONTyped,
    AccountSoToJSON,
} from './AccountSo';
import type { MessageSo } from './MessageSo';
import {
    MessageSoFromJSON,
    MessageSoFromJSONTyped,
    MessageSoToJSON,
} from './MessageSo';

/**
 * 
 * @export
 * @interface NotifySo
 */
export interface NotifySo {
    /**
     * 
     * @type {AccountSo}
     * @memberof NotifySo
     */
    account?: AccountSo;
    /**
     * 
     * @type {MessageSo}
     * @memberof NotifySo
     */
    message?: MessageSo;
    /**
     * 
     * @type {boolean}
     * @memberof NotifySo
     */
    sent?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NotifySo
     */
    displayed?: boolean;
}

/**
 * Check if a given object implements the NotifySo interface.
 */
export function instanceOfNotifySo(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function NotifySoFromJSON(json: any): NotifySo {
    return NotifySoFromJSONTyped(json, false);
}

export function NotifySoFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotifySo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'account': !exists(json, 'account') ? undefined : AccountSoFromJSON(json['account']),
        'message': !exists(json, 'message') ? undefined : MessageSoFromJSON(json['message']),
        'sent': !exists(json, 'sent') ? undefined : json['sent'],
        'displayed': !exists(json, 'displayed') ? undefined : json['displayed'],
    };
}

export function NotifySoToJSON(value?: NotifySo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'account': AccountSoToJSON(value.account),
        'message': MessageSoToJSON(value.message),
        'sent': value.sent,
        'displayed': value.displayed,
    };
}

