/* tslint:disable */
/* eslint-disable */
/**
 * Data import metadata
 * Administration of metadata for import
 *
 * The version of the OpenAPI document: 1.0
 * Contact: schvarcz@bb.microcomp.sk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RowAttributeTransformationDefinitionResponse } from './RowAttributeTransformationDefinitionResponse';
import {
    RowAttributeTransformationDefinitionResponseFromJSON,
    RowAttributeTransformationDefinitionResponseFromJSONTyped,
    RowAttributeTransformationDefinitionResponseToJSON,
} from './RowAttributeTransformationDefinitionResponse';

/**
 * 
 * @export
 * @interface RowAttributeMetadataResponse
 */
export interface RowAttributeMetadataResponse {
    /**
     * 
     * @type {string}
     * @memberof RowAttributeMetadataResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof RowAttributeMetadataResponse
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof RowAttributeMetadataResponse
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof RowAttributeMetadataResponse
     */
    dataType?: RowAttributeMetadataResponseDataTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof RowAttributeMetadataResponse
     */
    required?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RowAttributeMetadataResponse
     */
    rowAttributeType?: RowAttributeMetadataResponseRowAttributeTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof RowAttributeMetadataResponse
     */
    skipIfNull?: string;
    /**
     * 
     * @type {Array<RowAttributeTransformationDefinitionResponse>}
     * @memberof RowAttributeMetadataResponse
     */
    rowAttributeTransformations?: Array<RowAttributeTransformationDefinitionResponse>;
}


/**
 * @export
 */
export const RowAttributeMetadataResponseDataTypeEnum = {
    Tinyint: 'TINYINT',
    Smallint: 'SMALLINT',
    Integer: 'INTEGER',
    Bigint: 'BIGINT',
    Real: 'REAL',
    Double: 'DOUBLE',
    Numeric: 'NUMERIC',
    Decimal: 'DECIMAL',
    Char: 'CHAR',
    Varchar: 'VARCHAR',
    Longvarchar: 'LONGVARCHAR',
    Longnvarchar: 'LONGNVARCHAR',
    Date: 'DATE',
    Time: 'TIME',
    Null: 'NULL',
    Timestamp: 'TIMESTAMP',
    Boolean: 'BOOLEAN',
    Rowid: 'ROWID',
    Nchar: 'NCHAR',
    Nvarchar: 'NVARCHAR',
    TimeWithTimezone: 'TIME_WITH_TIMEZONE',
    TimestampWithTimezone: 'TIMESTAMP_WITH_TIMEZONE',
    Bfile: 'BFILE',
    IsoDateTime: 'ISO_DATE_TIME',
    Bit: 'BIT',
    Binary: 'BINARY',
    DmsFileUuid: 'DMS_FILE_UUID'
} as const;
export type RowAttributeMetadataResponseDataTypeEnum = typeof RowAttributeMetadataResponseDataTypeEnum[keyof typeof RowAttributeMetadataResponseDataTypeEnum];

/**
 * @export
 */
export const RowAttributeMetadataResponseRowAttributeTypeEnum = {
    Primary: 'PRIMARY',
    Reference: 'REFERENCE',
    Auxiliary: 'AUXILIARY'
} as const;
export type RowAttributeMetadataResponseRowAttributeTypeEnum = typeof RowAttributeMetadataResponseRowAttributeTypeEnum[keyof typeof RowAttributeMetadataResponseRowAttributeTypeEnum];


/**
 * Check if a given object implements the RowAttributeMetadataResponse interface.
 */
export function instanceOfRowAttributeMetadataResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RowAttributeMetadataResponseFromJSON(json: any): RowAttributeMetadataResponse {
    return RowAttributeMetadataResponseFromJSONTyped(json, false);
}

export function RowAttributeMetadataResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): RowAttributeMetadataResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'dataType': !exists(json, 'dataType') ? undefined : json['dataType'],
        'required': !exists(json, 'required') ? undefined : json['required'],
        'rowAttributeType': !exists(json, 'rowAttributeType') ? undefined : json['rowAttributeType'],
        'skipIfNull': !exists(json, 'skipIfNull') ? undefined : json['skipIfNull'],
        'rowAttributeTransformations': !exists(json, 'rowAttributeTransformations') ? undefined : ((json['rowAttributeTransformations'] as Array<any>).map(RowAttributeTransformationDefinitionResponseFromJSON)),
    };
}

export function RowAttributeMetadataResponseToJSON(value?: RowAttributeMetadataResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'code': value.code,
        'dataType': value.dataType,
        'required': value.required,
        'rowAttributeType': value.rowAttributeType,
        'skipIfNull': value.skipIfNull,
        'rowAttributeTransformations': value.rowAttributeTransformations === undefined ? undefined : ((value.rowAttributeTransformations as Array<any>).map(RowAttributeTransformationDefinitionResponseToJSON)),
    };
}

