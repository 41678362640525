/* tslint:disable */
/* eslint-disable */
/**
 * Catalog import API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.4.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CodelistItemInput } from './CodelistItemInput';
import {
    CodelistItemInputFromJSON,
    CodelistItemInputFromJSONTyped,
    CodelistItemInputToJSON,
} from './CodelistItemInput';
import type { InstallationInputForMonitoringPoint } from './InstallationInputForMonitoringPoint';
import {
    InstallationInputForMonitoringPointFromJSON,
    InstallationInputForMonitoringPointFromJSONTyped,
    InstallationInputForMonitoringPointToJSON,
} from './InstallationInputForMonitoringPoint';

/**
 * 
 * @export
 * @interface WaterWithdrawalInputCodelistItemInput
 */
export interface WaterWithdrawalInputCodelistItemInput {
    /**
     * 
     * @type {string}
     * @memberof WaterWithdrawalInputCodelistItemInput
     */
    balanceCode?: string;
    /**
     * 
     * @type {string}
     * @memberof WaterWithdrawalInputCodelistItemInput
     */
    continuityCodeBackward?: string;
    /**
     * 
     * @type {string}
     * @memberof WaterWithdrawalInputCodelistItemInput
     */
    continuityCodeForward?: string;
    /**
     * 
     * @type {string}
     * @memberof WaterWithdrawalInputCodelistItemInput
     */
    dopsCode?: string;
    /**
     * 
     * @type {string}
     * @memberof WaterWithdrawalInputCodelistItemInput
     */
    guaranteedFlow?: string;
    /**
     * 
     * @type {string}
     * @memberof WaterWithdrawalInputCodelistItemInput
     */
    hydrofundCode?: string;
    /**
     * 
     * @type {number}
     * @memberof WaterWithdrawalInputCodelistItemInput
     */
    orderNumber?: number;
    /**
     * 
     * @type {boolean}
     * @memberof WaterWithdrawalInputCodelistItemInput
     */
    permit: boolean | null;
    /**
     * 
     * @type {CodelistItemInput}
     * @memberof WaterWithdrawalInputCodelistItemInput
     */
    specificPurposeType: CodelistItemInput | null;
    /**
     * 
     * @type {string}
     * @memberof WaterWithdrawalInputCodelistItemInput
     */
    waterLawDecisionNumber?: string;
    /**
     * 
     * @type {CodelistItemInput}
     * @memberof WaterWithdrawalInputCodelistItemInput
     */
    waterType: CodelistItemInput | null;
    /**
     * 
     * @type {InstallationInputForMonitoringPoint}
     * @memberof WaterWithdrawalInputCodelistItemInput
     */
    installation?: InstallationInputForMonitoringPoint;
    /**
     * 
     * @type {CodelistItemInput}
     * @memberof WaterWithdrawalInputCodelistItemInput
     */
    naceType: CodelistItemInput | null;
    /**
     * 
     * @type {CodelistItemInput}
     * @memberof WaterWithdrawalInputCodelistItemInput
     */
    nace2Type: CodelistItemInput | null;
    /**
     * 
     * @type {number}
     * @memberof WaterWithdrawalInputCodelistItemInput
     */
    q1?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterWithdrawalInputCodelistItemInput
     */
    q270?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterWithdrawalInputCodelistItemInput
     */
    q355?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterWithdrawalInputCodelistItemInput
     */
    qa?: number | null;
}

/**
 * Check if a given object implements the WaterWithdrawalInputCodelistItemInput interface.
 */
export function instanceOfWaterWithdrawalInputCodelistItemInput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "permit" in value;
    isInstance = isInstance && "specificPurposeType" in value;
    isInstance = isInstance && "waterType" in value;
    isInstance = isInstance && "naceType" in value;
    isInstance = isInstance && "nace2Type" in value;

    return isInstance;
}

export function WaterWithdrawalInputCodelistItemInputFromJSON(json: any): WaterWithdrawalInputCodelistItemInput {
    return WaterWithdrawalInputCodelistItemInputFromJSONTyped(json, false);
}

export function WaterWithdrawalInputCodelistItemInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): WaterWithdrawalInputCodelistItemInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'balanceCode': !exists(json, 'balanceCode') ? undefined : json['balanceCode'],
        'continuityCodeBackward': !exists(json, 'continuityCodeBackward') ? undefined : json['continuityCodeBackward'],
        'continuityCodeForward': !exists(json, 'continuityCodeForward') ? undefined : json['continuityCodeForward'],
        'dopsCode': !exists(json, 'dopsCode') ? undefined : json['dopsCode'],
        'guaranteedFlow': !exists(json, 'guaranteedFlow') ? undefined : json['guaranteedFlow'],
        'hydrofundCode': !exists(json, 'hydrofundCode') ? undefined : json['hydrofundCode'],
        'orderNumber': !exists(json, 'orderNumber') ? undefined : json['orderNumber'],
        'permit': json['permit'],
        'specificPurposeType': CodelistItemInputFromJSON(json['specificPurposeType']),
        'waterLawDecisionNumber': !exists(json, 'waterLawDecisionNumber') ? undefined : json['waterLawDecisionNumber'],
        'waterType': CodelistItemInputFromJSON(json['waterType']),
        'installation': !exists(json, 'installation') ? undefined : InstallationInputForMonitoringPointFromJSON(json['installation']),
        'naceType': CodelistItemInputFromJSON(json['naceType']),
        'nace2Type': CodelistItemInputFromJSON(json['nace2Type']),
        'q1': !exists(json, 'q1') ? undefined : json['q1'],
        'q270': !exists(json, 'q270') ? undefined : json['q270'],
        'q355': !exists(json, 'q355') ? undefined : json['q355'],
        'qa': !exists(json, 'qa') ? undefined : json['qa'],
    };
}

export function WaterWithdrawalInputCodelistItemInputToJSON(value?: WaterWithdrawalInputCodelistItemInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'balanceCode': value.balanceCode,
        'continuityCodeBackward': value.continuityCodeBackward,
        'continuityCodeForward': value.continuityCodeForward,
        'dopsCode': value.dopsCode,
        'guaranteedFlow': value.guaranteedFlow,
        'hydrofundCode': value.hydrofundCode,
        'orderNumber': value.orderNumber,
        'permit': value.permit,
        'specificPurposeType': CodelistItemInputToJSON(value.specificPurposeType),
        'waterLawDecisionNumber': value.waterLawDecisionNumber,
        'waterType': CodelistItemInputToJSON(value.waterType),
        'installation': InstallationInputForMonitoringPointToJSON(value.installation),
        'naceType': CodelistItemInputToJSON(value.naceType),
        'nace2Type': CodelistItemInputToJSON(value.nace2Type),
        'q1': value.q1,
        'q270': value.q270,
        'q355': value.q355,
        'qa': value.qa,
    };
}

