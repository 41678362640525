/* tslint:disable */
/* eslint-disable */
/**
 * Notification API
 * Nástroj pre posielanie notifikácií - koncové služby
 *
 * The version of the OpenAPI document: 0.8.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NotifyCriteriaSo
 */
export interface NotifyCriteriaSo {
    /**
     * 
     * @type {string}
     * @memberof NotifyCriteriaSo
     */
    searchField?: string;
    /**
     * 
     * @type {string}
     * @memberof NotifyCriteriaSo
     */
    messageType?: NotifyCriteriaSoMessageTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof NotifyCriteriaSo
     */
    text?: string;
    /**
     * 
     * @type {string}
     * @memberof NotifyCriteriaSo
     */
    sentAt?: string;
    /**
     * 
     * @type {boolean}
     * @memberof NotifyCriteriaSo
     */
    sent?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NotifyCriteriaSo
     */
    displayed?: boolean;
}


/**
 * @export
 */
export const NotifyCriteriaSoMessageTypeEnum = {
    Catalog: 'CATALOG',
    Codelist: 'CODELIST',
    MonitoringPoint: 'MONITORING_POINT',
    Installation: 'INSTALLATION',
    Operator: 'OPERATOR',
    WaterShape: 'WATER_SHAPE',
    Environment: 'ENVIRONMENT',
    Dataregistry: 'DATAREGISTRY',
    OverviewDataDownloaded: 'OVERVIEW_DATA_DOWNLOADED',
    OverviewDataUploaded: 'OVERVIEW_DATA_UPLOADED',
    OverviewExported: 'OVERVIEW_EXPORTED'
} as const;
export type NotifyCriteriaSoMessageTypeEnum = typeof NotifyCriteriaSoMessageTypeEnum[keyof typeof NotifyCriteriaSoMessageTypeEnum];


/**
 * Check if a given object implements the NotifyCriteriaSo interface.
 */
export function instanceOfNotifyCriteriaSo(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function NotifyCriteriaSoFromJSON(json: any): NotifyCriteriaSo {
    return NotifyCriteriaSoFromJSONTyped(json, false);
}

export function NotifyCriteriaSoFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotifyCriteriaSo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'searchField': !exists(json, 'searchField') ? undefined : json['searchField'],
        'messageType': !exists(json, 'messageType') ? undefined : json['messageType'],
        'text': !exists(json, 'text') ? undefined : json['text'],
        'sentAt': !exists(json, 'sentAt') ? undefined : json['sentAt'],
        'sent': !exists(json, 'sent') ? undefined : json['sent'],
        'displayed': !exists(json, 'displayed') ? undefined : json['displayed'],
    };
}

export function NotifyCriteriaSoToJSON(value?: NotifyCriteriaSo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'searchField': value.searchField,
        'messageType': value.messageType,
        'text': value.text,
        'sentAt': value.sentAt,
        'sent': value.sent,
        'displayed': value.displayed,
    };
}

