/* tslint:disable */
/* eslint-disable */
/**
 * Data import
 * Modul for importing data to Data Registry.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: schvarcz@bb.microcomp.sk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  UploadFileResponse,
} from '../models/index';
import {
    UploadFileResponseFromJSON,
    UploadFileResponseToJSON,
} from '../models/index';

export interface FileUploadApiDownloadRequest {
    documentId: string;
}

export interface FileUploadApiUploadDataRegistryFileRequest {
    fileType: string;
    importFileDefinitionId: string;
    observer: boolean;
    controlData: boolean;
    ignoreExisting: boolean;
    file: Blob;
    separator?: string;
    encoding?: string;
    laboratoryCode?: string;
    yearOfImportedData?: number;
}

/**
 * 
 */
export class FileUploadApi extends runtime.BaseAPI {

    /**
     * Download file by documentId
     */
    async downloadRaw(requestParameters: FileUploadApiDownloadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling download.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/files/{documentId}`.replace(`{${"documentId"}}`, encodeURIComponent(String(requestParameters.documentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Download file by documentId
     */
    async download(documentId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.downloadRaw({ documentId: documentId }, initOverrides);
        return await response.value();
    }

    /**
     * Process uploaded file
     */
    async uploadDataRegistryFileRaw(requestParameters: FileUploadApiUploadDataRegistryFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UploadFileResponse>> {
        if (requestParameters.fileType === null || requestParameters.fileType === undefined) {
            throw new runtime.RequiredError('fileType','Required parameter requestParameters.fileType was null or undefined when calling uploadDataRegistryFile.');
        }

        if (requestParameters.importFileDefinitionId === null || requestParameters.importFileDefinitionId === undefined) {
            throw new runtime.RequiredError('importFileDefinitionId','Required parameter requestParameters.importFileDefinitionId was null or undefined when calling uploadDataRegistryFile.');
        }

        if (requestParameters.observer === null || requestParameters.observer === undefined) {
            throw new runtime.RequiredError('observer','Required parameter requestParameters.observer was null or undefined when calling uploadDataRegistryFile.');
        }

        if (requestParameters.controlData === null || requestParameters.controlData === undefined) {
            throw new runtime.RequiredError('controlData','Required parameter requestParameters.controlData was null or undefined when calling uploadDataRegistryFile.');
        }

        if (requestParameters.ignoreExisting === null || requestParameters.ignoreExisting === undefined) {
            throw new runtime.RequiredError('ignoreExisting','Required parameter requestParameters.ignoreExisting was null or undefined when calling uploadDataRegistryFile.');
        }

        if (requestParameters.file === null || requestParameters.file === undefined) {
            throw new runtime.RequiredError('file','Required parameter requestParameters.file was null or undefined when calling uploadDataRegistryFile.');
        }

        const queryParameters: any = {};

        if (requestParameters.fileType !== undefined) {
            queryParameters['fileType'] = requestParameters.fileType;
        }

        if (requestParameters.importFileDefinitionId !== undefined) {
            queryParameters['importFileDefinitionId'] = requestParameters.importFileDefinitionId;
        }

        if (requestParameters.separator !== undefined) {
            queryParameters['separator'] = requestParameters.separator;
        }

        if (requestParameters.encoding !== undefined) {
            queryParameters['encoding'] = requestParameters.encoding;
        }

        if (requestParameters.observer !== undefined) {
            queryParameters['observer'] = requestParameters.observer;
        }

        if (requestParameters.controlData !== undefined) {
            queryParameters['controlData'] = requestParameters.controlData;
        }

        if (requestParameters.laboratoryCode !== undefined) {
            queryParameters['laboratoryCode'] = requestParameters.laboratoryCode;
        }

        if (requestParameters.ignoreExisting !== undefined) {
            queryParameters['ignoreExisting'] = requestParameters.ignoreExisting;
        }

        if (requestParameters.yearOfImportedData !== undefined) {
            queryParameters['yearOfImportedData'] = requestParameters.yearOfImportedData;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/files/upload`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UploadFileResponseFromJSON(jsonValue));
    }

    /**
     * Process uploaded file
     */
    async uploadDataRegistryFile(fileType: string, importFileDefinitionId: string, observer: boolean, controlData: boolean, ignoreExisting: boolean, file: Blob, separator?: string, encoding?: string, laboratoryCode?: string, yearOfImportedData?: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UploadFileResponse> {
        const response = await this.uploadDataRegistryFileRaw({ fileType: fileType, importFileDefinitionId: importFileDefinitionId, observer: observer, controlData: controlData, ignoreExisting: ignoreExisting, file: file, separator: separator, encoding: encoding, laboratoryCode: laboratoryCode, yearOfImportedData: yearOfImportedData }, initOverrides);
        return await response.value();
    }

}
