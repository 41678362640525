/* tslint:disable */
/* eslint-disable */
/**
 * Overview API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ServiceErrorSo
 */
export interface ServiceErrorSo {
    /**
     * 
     * @type {number}
     * @memberof ServiceErrorSo
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ServiceErrorSo
     */
    jobName?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceErrorSo
     */
    timestamp?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceErrorSo
     */
    payload?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceErrorSo
     */
    stackTrace?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceErrorSo
     */
    errorMessageEn?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceErrorSo
     */
    errorMessageSk?: string;
}

/**
 * Check if a given object implements the ServiceErrorSo interface.
 */
export function instanceOfServiceErrorSo(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ServiceErrorSoFromJSON(json: any): ServiceErrorSo {
    return ServiceErrorSoFromJSONTyped(json, false);
}

export function ServiceErrorSoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceErrorSo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'jobName': !exists(json, 'jobName') ? undefined : json['jobName'],
        'timestamp': !exists(json, 'timestamp') ? undefined : json['timestamp'],
        'payload': !exists(json, 'payload') ? undefined : json['payload'],
        'stackTrace': !exists(json, 'stackTrace') ? undefined : json['stackTrace'],
        'errorMessageEn': !exists(json, 'errorMessageEn') ? undefined : json['errorMessageEn'],
        'errorMessageSk': !exists(json, 'errorMessageSk') ? undefined : json['errorMessageSk'],
    };
}

export function ServiceErrorSoToJSON(value?: ServiceErrorSo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'jobName': value.jobName,
        'timestamp': value.timestamp,
        'payload': value.payload,
        'stackTrace': value.stackTrace,
        'errorMessageEn': value.errorMessageEn,
        'errorMessageSk': value.errorMessageSk,
    };
}

