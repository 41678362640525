/* tslint:disable */
/* eslint-disable */
/**
 * Overview API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FetchResultSo
 */
export interface FetchResultSo {
    /**
     * 
     * @type {string}
     * @memberof FetchResultSo
     */
    previewReference?: string;
    /**
     * 
     * @type {number}
     * @memberof FetchResultSo
     */
    filterId?: number;
    /**
     * 
     * @type {string}
     * @memberof FetchResultSo
     */
    status?: FetchResultSoStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof FetchResultSo
     */
    progress?: number;
}


/**
 * @export
 */
export const FetchResultSoStatusEnum = {
    None: 'NONE',
    DownloadQueued: 'DOWNLOAD_QUEUED',
    DownloadInProgress: 'DOWNLOAD_IN_PROGRESS',
    FillingGaps: 'FILLING_GAPS',
    DownloadFailed: 'DOWNLOAD_FAILED',
    Downloaded: 'DOWNLOADED',
    Modified: 'MODIFIED',
    ValidationQueued: 'VALIDATION_QUEUED',
    ValidationInProgress: 'VALIDATION_IN_PROGRESS',
    ValidationFailed: 'VALIDATION_FAILED',
    Validated: 'VALIDATED',
    UpToDateCheckQueued: 'UP_TO_DATE_CHECK_QUEUED',
    UpToDateCheckInProgress: 'UP_TO_DATE_CHECK_IN_PROGRESS',
    UpToDateCheckFailed: 'UP_TO_DATE_CHECK_FAILED',
    ChangedInDr: 'CHANGED_IN_DR',
    SavingToDrQueued: 'SAVING_TO_DR_QUEUED',
    SavingToDrInProgress: 'SAVING_TO_DR_IN_PROGRESS',
    SavingToDrFailed: 'SAVING_TO_DR_FAILED',
    SavedToDr: 'SAVED_TO_DR',
    DeleteQueued: 'DELETE_QUEUED',
    DeleteFailed: 'DELETE_FAILED',
    DeleteInProgress: 'DELETE_IN_PROGRESS'
} as const;
export type FetchResultSoStatusEnum = typeof FetchResultSoStatusEnum[keyof typeof FetchResultSoStatusEnum];


/**
 * Check if a given object implements the FetchResultSo interface.
 */
export function instanceOfFetchResultSo(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FetchResultSoFromJSON(json: any): FetchResultSo {
    return FetchResultSoFromJSONTyped(json, false);
}

export function FetchResultSoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FetchResultSo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'previewReference': !exists(json, 'previewReference') ? undefined : json['previewReference'],
        'filterId': !exists(json, 'filterId') ? undefined : json['filterId'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'progress': !exists(json, 'progress') ? undefined : json['progress'],
    };
}

export function FetchResultSoToJSON(value?: FetchResultSo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'previewReference': value.previewReference,
        'filterId': value.filterId,
        'status': value.status,
        'progress': value.progress,
    };
}

