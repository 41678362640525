/* tslint:disable */
/* eslint-disable */
/**
 * Codelist API
 * Nástroj pre správu metadát a katalógov - koncové služby
 *
 * The version of the OpenAPI document: 0.21.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CodelistLocalization
 */
export interface CodelistLocalization {
    /**
     * 
     * @type {string}
     * @memberof CodelistLocalization
     */
    lang: string;
    /**
     * 
     * @type {string}
     * @memberof CodelistLocalization
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CodelistLocalization
     */
    manager: string | null;
    /**
     * 
     * @type {string}
     * @memberof CodelistLocalization
     */
    note: string | null;
}

/**
 * Check if a given object implements the CodelistLocalization interface.
 */
export function instanceOfCodelistLocalization(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "lang" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "manager" in value;
    isInstance = isInstance && "note" in value;

    return isInstance;
}

export function CodelistLocalizationFromJSON(json: any): CodelistLocalization {
    return CodelistLocalizationFromJSONTyped(json, false);
}

export function CodelistLocalizationFromJSONTyped(json: any, ignoreDiscriminator: boolean): CodelistLocalization {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'lang': json['lang'],
        'name': json['name'],
        'manager': json['manager'],
        'note': json['note'],
    };
}

export function CodelistLocalizationToJSON(value?: CodelistLocalization | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'lang': value.lang,
        'name': value.name,
        'manager': value.manager,
        'note': value.note,
    };
}

