/* tslint:disable */
/* eslint-disable */
/**
 * Data import metadata
 * Administration of metadata for import
 *
 * The version of the OpenAPI document: 1.0
 * Contact: schvarcz@bb.microcomp.sk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ColumnDefinitionResponse
 */
export interface ColumnDefinitionResponse {
    /**
     * 
     * @type {string}
     * @memberof ColumnDefinitionResponse
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof ColumnDefinitionResponse
     */
    position?: number;
    /**
     * 
     * @type {number}
     * @memberof ColumnDefinitionResponse
     */
    startPosition?: number;
    /**
     * 
     * @type {number}
     * @memberof ColumnDefinitionResponse
     */
    repeatAfterEach?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ColumnDefinitionResponse
     */
    rowAttributeIds?: Array<string>;
}

/**
 * Check if a given object implements the ColumnDefinitionResponse interface.
 */
export function instanceOfColumnDefinitionResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ColumnDefinitionResponseFromJSON(json: any): ColumnDefinitionResponse {
    return ColumnDefinitionResponseFromJSONTyped(json, false);
}

export function ColumnDefinitionResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ColumnDefinitionResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'position': !exists(json, 'position') ? undefined : json['position'],
        'startPosition': !exists(json, 'startPosition') ? undefined : json['startPosition'],
        'repeatAfterEach': !exists(json, 'repeatAfterEach') ? undefined : json['repeatAfterEach'],
        'rowAttributeIds': !exists(json, 'rowAttributeIds') ? undefined : json['rowAttributeIds'],
    };
}

export function ColumnDefinitionResponseToJSON(value?: ColumnDefinitionResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'position': value.position,
        'startPosition': value.startPosition,
        'repeatAfterEach': value.repeatAfterEach,
        'rowAttributeIds': value.rowAttributeIds,
    };
}

