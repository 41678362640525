/* tslint:disable */
/* eslint-disable */
/**
 * Data import metadata
 * Administration of metadata for import
 *
 * The version of the OpenAPI document: 1.0
 * Contact: schvarcz@bb.microcomp.sk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AssignRowAttributeTransformationDefinitionRequest,
  AssignRowAttributeTransformationDefinitionsRequest,
  RemoveRowAttributeTransformationDefinitionsRequest,
  RowAttributeTransformationDefinitionRequest,
  RowAttributeTransformationDefinitionResponse,
} from '../models/index';
import {
    AssignRowAttributeTransformationDefinitionRequestFromJSON,
    AssignRowAttributeTransformationDefinitionRequestToJSON,
    AssignRowAttributeTransformationDefinitionsRequestFromJSON,
    AssignRowAttributeTransformationDefinitionsRequestToJSON,
    RemoveRowAttributeTransformationDefinitionsRequestFromJSON,
    RemoveRowAttributeTransformationDefinitionsRequestToJSON,
    RowAttributeTransformationDefinitionRequestFromJSON,
    RowAttributeTransformationDefinitionRequestToJSON,
    RowAttributeTransformationDefinitionResponseFromJSON,
    RowAttributeTransformationDefinitionResponseToJSON,
} from '../models/index';

export interface RowAttributeTransformationDefinitionApiAddRowAttributeTransformationDefinitionRequest {
    assignRowAttributeTransformationDefinitionRequest: AssignRowAttributeTransformationDefinitionRequest;
}

export interface RowAttributeTransformationDefinitionApiAddRowAttributeTransformationDefinitionsRequest {
    assignRowAttributeTransformationDefinitionsRequest: AssignRowAttributeTransformationDefinitionsRequest;
}

export interface RowAttributeTransformationDefinitionApiGetRowAttributeTransformationDefinitionByIdRequest {
    id: string;
}

export interface RowAttributeTransformationDefinitionApiGetRowAttributeTransformationDefinitionByRowAttributeDefinitionRequest {
    rowAttributeDefinitionId: string;
}

export interface RowAttributeTransformationDefinitionApiRemoveRowAttributeTransformationDefinitionsOperationRequest {
    removeRowAttributeTransformationDefinitionsRequest: RemoveRowAttributeTransformationDefinitionsRequest;
}

export interface RowAttributeTransformationDefinitionApiUpdateRowAttributeTransformationDefinitionRequest {
    id: string;
    rowAttributeTransformationDefinitionRequest: RowAttributeTransformationDefinitionRequest;
}

/**
 * 
 */
export class RowAttributeTransformationDefinitionApi extends runtime.BaseAPI {

    /**
     * Add one row attribute transformation definition to column
     */
    async addRowAttributeTransformationDefinitionRaw(requestParameters: RowAttributeTransformationDefinitionApiAddRowAttributeTransformationDefinitionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RowAttributeTransformationDefinitionResponse>> {
        if (requestParameters.assignRowAttributeTransformationDefinitionRequest === null || requestParameters.assignRowAttributeTransformationDefinitionRequest === undefined) {
            throw new runtime.RequiredError('assignRowAttributeTransformationDefinitionRequest','Required parameter requestParameters.assignRowAttributeTransformationDefinitionRequest was null or undefined when calling addRowAttributeTransformationDefinition.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/row-attribute-transformation-definition/add`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AssignRowAttributeTransformationDefinitionRequestToJSON(requestParameters.assignRowAttributeTransformationDefinitionRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RowAttributeTransformationDefinitionResponseFromJSON(jsonValue));
    }

    /**
     * Add one row attribute transformation definition to column
     */
    async addRowAttributeTransformationDefinition(assignRowAttributeTransformationDefinitionRequest: AssignRowAttributeTransformationDefinitionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RowAttributeTransformationDefinitionResponse> {
        const response = await this.addRowAttributeTransformationDefinitionRaw({ assignRowAttributeTransformationDefinitionRequest: assignRowAttributeTransformationDefinitionRequest }, initOverrides);
        return await response.value();
    }

    /**
     * Add row attribute transformation definitions to column
     */
    async addRowAttributeTransformationDefinitionsRaw(requestParameters: RowAttributeTransformationDefinitionApiAddRowAttributeTransformationDefinitionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.assignRowAttributeTransformationDefinitionsRequest === null || requestParameters.assignRowAttributeTransformationDefinitionsRequest === undefined) {
            throw new runtime.RequiredError('assignRowAttributeTransformationDefinitionsRequest','Required parameter requestParameters.assignRowAttributeTransformationDefinitionsRequest was null or undefined when calling addRowAttributeTransformationDefinitions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/row-attribute-transformation-definition/add-row-attribute-transformation-definitions`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AssignRowAttributeTransformationDefinitionsRequestToJSON(requestParameters.assignRowAttributeTransformationDefinitionsRequest),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Add row attribute transformation definitions to column
     */
    async addRowAttributeTransformationDefinitions(assignRowAttributeTransformationDefinitionsRequest: AssignRowAttributeTransformationDefinitionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.addRowAttributeTransformationDefinitionsRaw({ assignRowAttributeTransformationDefinitionsRequest: assignRowAttributeTransformationDefinitionsRequest }, initOverrides);
        return await response.value();
    }

    /**
     * Get row attribute transformation definition by id
     */
    async getRowAttributeTransformationDefinitionByIdRaw(requestParameters: RowAttributeTransformationDefinitionApiGetRowAttributeTransformationDefinitionByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RowAttributeTransformationDefinitionResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getRowAttributeTransformationDefinitionById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/row-attribute-transformation-definition/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RowAttributeTransformationDefinitionResponseFromJSON(jsonValue));
    }

    /**
     * Get row attribute transformation definition by id
     */
    async getRowAttributeTransformationDefinitionById(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RowAttributeTransformationDefinitionResponse> {
        const response = await this.getRowAttributeTransformationDefinitionByIdRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     * Get row attribute transformation definitions by row attribute definition
     */
    async getRowAttributeTransformationDefinitionByRowAttributeDefinitionRaw(requestParameters: RowAttributeTransformationDefinitionApiGetRowAttributeTransformationDefinitionByRowAttributeDefinitionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<RowAttributeTransformationDefinitionResponse>>> {
        if (requestParameters.rowAttributeDefinitionId === null || requestParameters.rowAttributeDefinitionId === undefined) {
            throw new runtime.RequiredError('rowAttributeDefinitionId','Required parameter requestParameters.rowAttributeDefinitionId was null or undefined when calling getRowAttributeTransformationDefinitionByRowAttributeDefinition.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/row-attribute-transformation-definition/byRowAttributeDefinition/{rowAttributeDefinitionId}`.replace(`{${"rowAttributeDefinitionId"}}`, encodeURIComponent(String(requestParameters.rowAttributeDefinitionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RowAttributeTransformationDefinitionResponseFromJSON));
    }

    /**
     * Get row attribute transformation definitions by row attribute definition
     */
    async getRowAttributeTransformationDefinitionByRowAttributeDefinition(rowAttributeDefinitionId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<RowAttributeTransformationDefinitionResponse>> {
        const response = await this.getRowAttributeTransformationDefinitionByRowAttributeDefinitionRaw({ rowAttributeDefinitionId: rowAttributeDefinitionId }, initOverrides);
        return await response.value();
    }

    /**
     * Remove row attribute transformation definitions from import part file
     */
    async removeRowAttributeTransformationDefinitionsRaw(requestParameters: RowAttributeTransformationDefinitionApiRemoveRowAttributeTransformationDefinitionsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.removeRowAttributeTransformationDefinitionsRequest === null || requestParameters.removeRowAttributeTransformationDefinitionsRequest === undefined) {
            throw new runtime.RequiredError('removeRowAttributeTransformationDefinitionsRequest','Required parameter requestParameters.removeRowAttributeTransformationDefinitionsRequest was null or undefined when calling removeRowAttributeTransformationDefinitions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/row-attribute-transformation-definition/remove`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: RemoveRowAttributeTransformationDefinitionsRequestToJSON(requestParameters.removeRowAttributeTransformationDefinitionsRequest),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Remove row attribute transformation definitions from import part file
     */
    async removeRowAttributeTransformationDefinitions(removeRowAttributeTransformationDefinitionsRequest: RemoveRowAttributeTransformationDefinitionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.removeRowAttributeTransformationDefinitionsRaw({ removeRowAttributeTransformationDefinitionsRequest: removeRowAttributeTransformationDefinitionsRequest }, initOverrides);
        return await response.value();
    }

    /**
     * Update row attribute transformation definition
     */
    async updateRowAttributeTransformationDefinitionRaw(requestParameters: RowAttributeTransformationDefinitionApiUpdateRowAttributeTransformationDefinitionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RowAttributeTransformationDefinitionResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateRowAttributeTransformationDefinition.');
        }

        if (requestParameters.rowAttributeTransformationDefinitionRequest === null || requestParameters.rowAttributeTransformationDefinitionRequest === undefined) {
            throw new runtime.RequiredError('rowAttributeTransformationDefinitionRequest','Required parameter requestParameters.rowAttributeTransformationDefinitionRequest was null or undefined when calling updateRowAttributeTransformationDefinition.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/row-attribute-transformation-definition/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: RowAttributeTransformationDefinitionRequestToJSON(requestParameters.rowAttributeTransformationDefinitionRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RowAttributeTransformationDefinitionResponseFromJSON(jsonValue));
    }

    /**
     * Update row attribute transformation definition
     */
    async updateRowAttributeTransformationDefinition(id: string, rowAttributeTransformationDefinitionRequest: RowAttributeTransformationDefinitionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RowAttributeTransformationDefinitionResponse> {
        const response = await this.updateRowAttributeTransformationDefinitionRaw({ id: id, rowAttributeTransformationDefinitionRequest: rowAttributeTransformationDefinitionRequest }, initOverrides);
        return await response.value();
    }

}
