/* tslint:disable */
/* eslint-disable */
/**
 * Data import metadata
 * Administration of metadata for import
 *
 * The version of the OpenAPI document: 1.0
 * Contact: schvarcz@bb.microcomp.sk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AssignRowAttributeDefinitionRequest,
  AssignRowAttributeDefinitionsRequest,
  RemoveRowAttributeDefinitionsRequest,
  RowAttributeDefinitionRequest,
  RowAttributeDefinitionResponse,
} from '../models/index';
import {
    AssignRowAttributeDefinitionRequestFromJSON,
    AssignRowAttributeDefinitionRequestToJSON,
    AssignRowAttributeDefinitionsRequestFromJSON,
    AssignRowAttributeDefinitionsRequestToJSON,
    RemoveRowAttributeDefinitionsRequestFromJSON,
    RemoveRowAttributeDefinitionsRequestToJSON,
    RowAttributeDefinitionRequestFromJSON,
    RowAttributeDefinitionRequestToJSON,
    RowAttributeDefinitionResponseFromJSON,
    RowAttributeDefinitionResponseToJSON,
} from '../models/index';

export interface RowAttributeDefinitionApiAddRowAttributeDefinitionRequest {
    assignRowAttributeDefinitionRequest: AssignRowAttributeDefinitionRequest;
}

export interface RowAttributeDefinitionApiAddRowAttributeDefinitionsRequest {
    assignRowAttributeDefinitionsRequest: AssignRowAttributeDefinitionsRequest;
}

export interface RowAttributeDefinitionApiGetRowAttributeDefinitionByColumnDefinitionRequest {
    columnDefinitionId: string;
}

export interface RowAttributeDefinitionApiGetRowAttributeDefinitionByIdRequest {
    id: string;
}

export interface RowAttributeDefinitionApiRemoveRowAttributeDefinitionsOperationRequest {
    removeRowAttributeDefinitionsRequest: RemoveRowAttributeDefinitionsRequest;
}

export interface RowAttributeDefinitionApiUpdateRowAttributeDefinitionRequest {
    id: string;
    rowAttributeDefinitionRequest: RowAttributeDefinitionRequest;
}

/**
 * 
 */
export class RowAttributeDefinitionApi extends runtime.BaseAPI {

    /**
     * Add one row attribute definition to column
     */
    async addRowAttributeDefinitionRaw(requestParameters: RowAttributeDefinitionApiAddRowAttributeDefinitionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RowAttributeDefinitionResponse>> {
        if (requestParameters.assignRowAttributeDefinitionRequest === null || requestParameters.assignRowAttributeDefinitionRequest === undefined) {
            throw new runtime.RequiredError('assignRowAttributeDefinitionRequest','Required parameter requestParameters.assignRowAttributeDefinitionRequest was null or undefined when calling addRowAttributeDefinition.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/row-attribute-definition/add`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AssignRowAttributeDefinitionRequestToJSON(requestParameters.assignRowAttributeDefinitionRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RowAttributeDefinitionResponseFromJSON(jsonValue));
    }

    /**
     * Add one row attribute definition to column
     */
    async addRowAttributeDefinition(assignRowAttributeDefinitionRequest: AssignRowAttributeDefinitionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RowAttributeDefinitionResponse> {
        const response = await this.addRowAttributeDefinitionRaw({ assignRowAttributeDefinitionRequest: assignRowAttributeDefinitionRequest }, initOverrides);
        return await response.value();
    }

    /**
     * Add row attribute definitions to column
     */
    async addRowAttributeDefinitionsRaw(requestParameters: RowAttributeDefinitionApiAddRowAttributeDefinitionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.assignRowAttributeDefinitionsRequest === null || requestParameters.assignRowAttributeDefinitionsRequest === undefined) {
            throw new runtime.RequiredError('assignRowAttributeDefinitionsRequest','Required parameter requestParameters.assignRowAttributeDefinitionsRequest was null or undefined when calling addRowAttributeDefinitions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/row-attribute-definition/add-row-attribute-definitions`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AssignRowAttributeDefinitionsRequestToJSON(requestParameters.assignRowAttributeDefinitionsRequest),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Add row attribute definitions to column
     */
    async addRowAttributeDefinitions(assignRowAttributeDefinitionsRequest: AssignRowAttributeDefinitionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.addRowAttributeDefinitionsRaw({ assignRowAttributeDefinitionsRequest: assignRowAttributeDefinitionsRequest }, initOverrides);
        return await response.value();
    }

    /**
     * Get row attribute definitions by column definition
     */
    async getRowAttributeDefinitionByColumnDefinitionRaw(requestParameters: RowAttributeDefinitionApiGetRowAttributeDefinitionByColumnDefinitionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<RowAttributeDefinitionResponse>>> {
        if (requestParameters.columnDefinitionId === null || requestParameters.columnDefinitionId === undefined) {
            throw new runtime.RequiredError('columnDefinitionId','Required parameter requestParameters.columnDefinitionId was null or undefined when calling getRowAttributeDefinitionByColumnDefinition.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/row-attribute-definition/byColumnDefinition/{columnDefinitionId}`.replace(`{${"columnDefinitionId"}}`, encodeURIComponent(String(requestParameters.columnDefinitionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RowAttributeDefinitionResponseFromJSON));
    }

    /**
     * Get row attribute definitions by column definition
     */
    async getRowAttributeDefinitionByColumnDefinition(columnDefinitionId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<RowAttributeDefinitionResponse>> {
        const response = await this.getRowAttributeDefinitionByColumnDefinitionRaw({ columnDefinitionId: columnDefinitionId }, initOverrides);
        return await response.value();
    }

    /**
     * Get row attribute definition by id
     */
    async getRowAttributeDefinitionByIdRaw(requestParameters: RowAttributeDefinitionApiGetRowAttributeDefinitionByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RowAttributeDefinitionResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getRowAttributeDefinitionById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/row-attribute-definition/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RowAttributeDefinitionResponseFromJSON(jsonValue));
    }

    /**
     * Get row attribute definition by id
     */
    async getRowAttributeDefinitionById(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RowAttributeDefinitionResponse> {
        const response = await this.getRowAttributeDefinitionByIdRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     * Remove row attribute definitions from import part file
     */
    async removeRowAttributeDefinitionsRaw(requestParameters: RowAttributeDefinitionApiRemoveRowAttributeDefinitionsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.removeRowAttributeDefinitionsRequest === null || requestParameters.removeRowAttributeDefinitionsRequest === undefined) {
            throw new runtime.RequiredError('removeRowAttributeDefinitionsRequest','Required parameter requestParameters.removeRowAttributeDefinitionsRequest was null or undefined when calling removeRowAttributeDefinitions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/row-attribute-definition/remove`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: RemoveRowAttributeDefinitionsRequestToJSON(requestParameters.removeRowAttributeDefinitionsRequest),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Remove row attribute definitions from import part file
     */
    async removeRowAttributeDefinitions(removeRowAttributeDefinitionsRequest: RemoveRowAttributeDefinitionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.removeRowAttributeDefinitionsRaw({ removeRowAttributeDefinitionsRequest: removeRowAttributeDefinitionsRequest }, initOverrides);
        return await response.value();
    }

    /**
     * Update row attribute definition
     */
    async updateRowAttributeDefinitionRaw(requestParameters: RowAttributeDefinitionApiUpdateRowAttributeDefinitionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RowAttributeDefinitionResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateRowAttributeDefinition.');
        }

        if (requestParameters.rowAttributeDefinitionRequest === null || requestParameters.rowAttributeDefinitionRequest === undefined) {
            throw new runtime.RequiredError('rowAttributeDefinitionRequest','Required parameter requestParameters.rowAttributeDefinitionRequest was null or undefined when calling updateRowAttributeDefinition.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/row-attribute-definition/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: RowAttributeDefinitionRequestToJSON(requestParameters.rowAttributeDefinitionRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RowAttributeDefinitionResponseFromJSON(jsonValue));
    }

    /**
     * Update row attribute definition
     */
    async updateRowAttributeDefinition(id: string, rowAttributeDefinitionRequest: RowAttributeDefinitionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RowAttributeDefinitionResponse> {
        const response = await this.updateRowAttributeDefinitionRaw({ id: id, rowAttributeDefinitionRequest: rowAttributeDefinitionRequest }, initOverrides);
        return await response.value();
    }

}
