/* tslint:disable */
/* eslint-disable */
/**
 * Codelist API
 * Nástroj pre správu metadát a katalógov - koncové služby
 *
 * The version of the OpenAPI document: 0.21.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CodelistItemCriteria } from './CodelistItemCriteria';
import {
    CodelistItemCriteriaFromJSON,
    CodelistItemCriteriaFromJSONTyped,
    CodelistItemCriteriaToJSON,
} from './CodelistItemCriteria';

/**
 * 
 * @export
 * @interface CodelistItemExportData
 */
export interface CodelistItemExportData {
    /**
     * 
     * @type {Array<string>}
     * @memberof CodelistItemExportData
     */
    fieldsToExport: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CodelistItemExportData
     */
    sort: string;
    /**
     * 
     * @type {string}
     * @memberof CodelistItemExportData
     */
    csvDelimiter: string;
    /**
     * 
     * @type {boolean}
     * @memberof CodelistItemExportData
     */
    onlyHeader: boolean;
    /**
     * 
     * @type {CodelistItemCriteria}
     * @memberof CodelistItemExportData
     */
    criteria: CodelistItemCriteria;
    /**
     * 
     * @type {boolean}
     * @memberof CodelistItemExportData
     */
    technical: boolean;
}

/**
 * Check if a given object implements the CodelistItemExportData interface.
 */
export function instanceOfCodelistItemExportData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "fieldsToExport" in value;
    isInstance = isInstance && "sort" in value;
    isInstance = isInstance && "csvDelimiter" in value;
    isInstance = isInstance && "onlyHeader" in value;
    isInstance = isInstance && "criteria" in value;
    isInstance = isInstance && "technical" in value;

    return isInstance;
}

export function CodelistItemExportDataFromJSON(json: any): CodelistItemExportData {
    return CodelistItemExportDataFromJSONTyped(json, false);
}

export function CodelistItemExportDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): CodelistItemExportData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fieldsToExport': json['fieldsToExport'],
        'sort': json['sort'],
        'csvDelimiter': json['csvDelimiter'],
        'onlyHeader': json['onlyHeader'],
        'criteria': CodelistItemCriteriaFromJSON(json['criteria']),
        'technical': json['technical'],
    };
}

export function CodelistItemExportDataToJSON(value?: CodelistItemExportData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fieldsToExport': value.fieldsToExport,
        'sort': value.sort,
        'csvDelimiter': value.csvDelimiter,
        'onlyHeader': value.onlyHeader,
        'criteria': CodelistItemCriteriaToJSON(value.criteria),
        'technical': value.technical,
    };
}

