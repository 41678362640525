/* tslint:disable */
/* eslint-disable */
/**
 * Overview API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  DateOrTimeColumn,
  EndpointExceptionBody,
  FilterableAttribute,
  InfoAboutValueSo,
  PreviewSo,
  SetOriginalValueSo,
} from '../models/index';
import {
    DateOrTimeColumnFromJSON,
    DateOrTimeColumnToJSON,
    EndpointExceptionBodyFromJSON,
    EndpointExceptionBodyToJSON,
    FilterableAttributeFromJSON,
    FilterableAttributeToJSON,
    InfoAboutValueSoFromJSON,
    InfoAboutValueSoToJSON,
    PreviewSoFromJSON,
    PreviewSoToJSON,
    SetOriginalValueSoFromJSON,
    SetOriginalValueSoToJSON,
} from '../models/index';

export interface PreviewApiChangeOriginalValueRequest {
    setOriginalValueSo: SetOriginalValueSo;
}

export interface PreviewApiCreatePreviewRequest {
    reference: string;
}

export interface PreviewApiGetByReferenceRequest {
    reference: string;
}

export interface PreviewApiGetFilterableAttributesRequest {
    reference: string;
}

export interface PreviewApiGetInfoAboutValueRequest {
    reference: string;
    rowNumber: number;
    columnReference: string;
}

export interface PreviewApiHadDateOrTimeColumnRequest {
    reference: string;
}

export interface PreviewApiListByDomainRequest {
    domainName: string;
}

export interface PreviewApiUploadToDataRegistryRequest {
    reference: string;
    filerId: number;
}

/**
 * 
 */
export class PreviewApi extends runtime.BaseAPI {

    /**
     * Change Original Value for a RowAttribute
     */
    async changeOriginalValueRaw(requestParameters: PreviewApiChangeOriginalValueRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.setOriginalValueSo === null || requestParameters.setOriginalValueSo === undefined) {
            throw new runtime.RequiredError('setOriginalValueSo','Required parameter requestParameters.setOriginalValueSo was null or undefined when calling changeOriginalValue.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json;charset=UTF-8';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/preview/change-original-value`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SetOriginalValueSoToJSON(requestParameters.setOriginalValueSo),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Change Original Value for a RowAttribute
     */
    async changeOriginalValue(setOriginalValueSo: SetOriginalValueSo, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.changeOriginalValueRaw({ setOriginalValueSo: setOriginalValueSo }, initOverrides);
    }

    /**
     * Creates preview for given reference
     */
    async createPreviewRaw(requestParameters: PreviewApiCreatePreviewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PreviewSo>> {
        if (requestParameters.reference === null || requestParameters.reference === undefined) {
            throw new runtime.RequiredError('reference','Required parameter requestParameters.reference was null or undefined when calling createPreview.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/preview/{reference}`.replace(`{${"reference"}}`, encodeURIComponent(String(requestParameters.reference))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PreviewSoFromJSON(jsonValue));
    }

    /**
     * Creates preview for given reference
     */
    async createPreview(reference: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PreviewSo> {
        const response = await this.createPreviewRaw({ reference: reference }, initOverrides);
        return await response.value();
    }

    /**
     * Gets preview for given reference
     */
    async getByReferenceRaw(requestParameters: PreviewApiGetByReferenceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PreviewSo>> {
        if (requestParameters.reference === null || requestParameters.reference === undefined) {
            throw new runtime.RequiredError('reference','Required parameter requestParameters.reference was null or undefined when calling getByReference.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/preview/metadata-description/{reference}`.replace(`{${"reference"}}`, encodeURIComponent(String(requestParameters.reference))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PreviewSoFromJSON(jsonValue));
    }

    /**
     * Gets preview for given reference
     */
    async getByReference(reference: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PreviewSo> {
        const response = await this.getByReferenceRaw({ reference: reference }, initOverrides);
        return await response.value();
    }

    /**
     * Gets attributes for preview
     */
    async getFilterableAttributesRaw(requestParameters: PreviewApiGetFilterableAttributesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterableAttribute>>> {
        if (requestParameters.reference === null || requestParameters.reference === undefined) {
            throw new runtime.RequiredError('reference','Required parameter requestParameters.reference was null or undefined when calling getFilterableAttributes.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/preview/filter-attributes/{reference}`.replace(`{${"reference"}}`, encodeURIComponent(String(requestParameters.reference))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterableAttributeFromJSON));
    }

    /**
     * Gets attributes for preview
     */
    async getFilterableAttributes(reference: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterableAttribute>> {
        const response = await this.getFilterableAttributesRaw({ reference: reference }, initOverrides);
        return await response.value();
    }

    /**
     * Get information about a value in the preview
     */
    async getInfoAboutValueRaw(requestParameters: PreviewApiGetInfoAboutValueRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InfoAboutValueSo>> {
        if (requestParameters.reference === null || requestParameters.reference === undefined) {
            throw new runtime.RequiredError('reference','Required parameter requestParameters.reference was null or undefined when calling getInfoAboutValue.');
        }

        if (requestParameters.rowNumber === null || requestParameters.rowNumber === undefined) {
            throw new runtime.RequiredError('rowNumber','Required parameter requestParameters.rowNumber was null or undefined when calling getInfoAboutValue.');
        }

        if (requestParameters.columnReference === null || requestParameters.columnReference === undefined) {
            throw new runtime.RequiredError('columnReference','Required parameter requestParameters.columnReference was null or undefined when calling getInfoAboutValue.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/preview/info-about-value/{reference}/{rowNumber}/{columnReference}`.replace(`{${"reference"}}`, encodeURIComponent(String(requestParameters.reference))).replace(`{${"rowNumber"}}`, encodeURIComponent(String(requestParameters.rowNumber))).replace(`{${"columnReference"}}`, encodeURIComponent(String(requestParameters.columnReference))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InfoAboutValueSoFromJSON(jsonValue));
    }

    /**
     * Get information about a value in the preview
     */
    async getInfoAboutValue(reference: string, rowNumber: number, columnReference: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InfoAboutValueSo> {
        const response = await this.getInfoAboutValueRaw({ reference: reference, rowNumber: rowNumber, columnReference: columnReference }, initOverrides);
        return await response.value();
    }

    /**
     * Values corresponding to whether the preview has date or time columns
     */
    async hadDateOrTimeColumnRaw(requestParameters: PreviewApiHadDateOrTimeColumnRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DateOrTimeColumn>> {
        if (requestParameters.reference === null || requestParameters.reference === undefined) {
            throw new runtime.RequiredError('reference','Required parameter requestParameters.reference was null or undefined when calling hadDateOrTimeColumn.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/preview/check-columns/{reference}`.replace(`{${"reference"}}`, encodeURIComponent(String(requestParameters.reference))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DateOrTimeColumnFromJSON(jsonValue));
    }

    /**
     * Values corresponding to whether the preview has date or time columns
     */
    async hadDateOrTimeColumn(reference: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DateOrTimeColumn> {
        const response = await this.hadDateOrTimeColumnRaw({ reference: reference }, initOverrides);
        return await response.value();
    }

    /**
     * List views for given domain
     */
    async listByDomainRaw(requestParameters: PreviewApiListByDomainRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PreviewSo>>> {
        if (requestParameters.domainName === null || requestParameters.domainName === undefined) {
            throw new runtime.RequiredError('domainName','Required parameter requestParameters.domainName was null or undefined when calling listByDomain.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/preview/list/{domainName}`.replace(`{${"domainName"}}`, encodeURIComponent(String(requestParameters.domainName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PreviewSoFromJSON));
    }

    /**
     * List views for given domain
     */
    async listByDomain(domainName: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PreviewSo>> {
        const response = await this.listByDomainRaw({ domainName: domainName }, initOverrides);
        return await response.value();
    }

    /**
     * Upload preview to data-registry
     */
    async uploadToDataRegistryRaw(requestParameters: PreviewApiUploadToDataRegistryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.reference === null || requestParameters.reference === undefined) {
            throw new runtime.RequiredError('reference','Required parameter requestParameters.reference was null or undefined when calling uploadToDataRegistry.');
        }

        if (requestParameters.filerId === null || requestParameters.filerId === undefined) {
            throw new runtime.RequiredError('filerId','Required parameter requestParameters.filerId was null or undefined when calling uploadToDataRegistry.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/preview/{reference}/{filerId}/upload-to-data-registry`.replace(`{${"reference"}}`, encodeURIComponent(String(requestParameters.reference))).replace(`{${"filerId"}}`, encodeURIComponent(String(requestParameters.filerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Upload preview to data-registry
     */
    async uploadToDataRegistry(reference: string, filerId: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.uploadToDataRegistryRaw({ reference: reference, filerId: filerId }, initOverrides);
    }

}
