/* tslint:disable */
/* eslint-disable */
/**
 * Overview API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FilterableAttribute
 */
export interface FilterableAttribute {
    /**
     * 
     * @type {string}
     * @memberof FilterableAttribute
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof FilterableAttribute
     */
    dataType?: FilterableAttributeDataTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof FilterableAttribute
     */
    sortable?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FilterableAttribute
     */
    searchable?: boolean;
}


/**
 * @export
 */
export const FilterableAttributeDataTypeEnum = {
    Bit: 'BIT',
    Tinyint: 'TINYINT',
    Smallint: 'SMALLINT',
    Integer: 'INTEGER',
    Bigint: 'BIGINT',
    Real: 'REAL',
    Float: 'FLOAT',
    Double: 'DOUBLE',
    Numeric: 'NUMERIC',
    Decimal: 'DECIMAL',
    Char: 'CHAR',
    Varchar: 'VARCHAR',
    Longvarchar: 'LONGVARCHAR',
    Date: 'DATE',
    Time: 'TIME',
    Timestamp: 'TIMESTAMP',
    Binary: 'BINARY',
    Varbinary: 'VARBINARY',
    Longvarbinary: 'LONGVARBINARY',
    Null: 'NULL',
    Other: 'OTHER',
    JavaObject: 'JAVA_OBJECT',
    Blob: 'BLOB',
    Clob: 'CLOB',
    Boolean: 'BOOLEAN',
    Rowid: 'ROWID',
    Nchar: 'NCHAR',
    Nvarchar: 'NVARCHAR',
    Longnvarchar: 'LONGNVARCHAR',
    Nclob: 'NCLOB',
    Sqlxml: 'SQLXML',
    TimeWithTimezone: 'TIME_WITH_TIMEZONE',
    TimestampWithTimezone: 'TIMESTAMP_WITH_TIMEZONE',
    Bfile: 'BFILE',
    DmsFileUuid: 'DMS_FILE_UUID'
} as const;
export type FilterableAttributeDataTypeEnum = typeof FilterableAttributeDataTypeEnum[keyof typeof FilterableAttributeDataTypeEnum];


/**
 * Check if a given object implements the FilterableAttribute interface.
 */
export function instanceOfFilterableAttribute(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FilterableAttributeFromJSON(json: any): FilterableAttribute {
    return FilterableAttributeFromJSONTyped(json, false);
}

export function FilterableAttributeFromJSONTyped(json: any, ignoreDiscriminator: boolean): FilterableAttribute {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'dataType': !exists(json, 'dataType') ? undefined : json['dataType'],
        'sortable': !exists(json, 'sortable') ? undefined : json['sortable'],
        'searchable': !exists(json, 'searchable') ? undefined : json['searchable'],
    };
}

export function FilterableAttributeToJSON(value?: FilterableAttribute | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'dataType': value.dataType,
        'sortable': value.sortable,
        'searchable': value.searchable,
    };
}

