/* tslint:disable */
/* eslint-disable */
/**
 * Catalog API
 * Nástroj pre správu metadát a katalógov - koncové služby
 *
 * The version of the OpenAPI document: 0.27.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BooleanResponse,
  EndpointExceptionBody,
  PageThresholdResult,
  PageWaterShapeResult,
  ThresholdCriteria,
  ThresholdInput,
  ThresholdResult,
  WaterShapeCriteria,
  WaterShapeInput,
  WaterShapeResult,
  WaterShapeUpdate,
} from '../models/index';
import {
    BooleanResponseFromJSON,
    BooleanResponseToJSON,
    EndpointExceptionBodyFromJSON,
    EndpointExceptionBodyToJSON,
    PageThresholdResultFromJSON,
    PageThresholdResultToJSON,
    PageWaterShapeResultFromJSON,
    PageWaterShapeResultToJSON,
    ThresholdCriteriaFromJSON,
    ThresholdCriteriaToJSON,
    ThresholdInputFromJSON,
    ThresholdInputToJSON,
    ThresholdResultFromJSON,
    ThresholdResultToJSON,
    WaterShapeCriteriaFromJSON,
    WaterShapeCriteriaToJSON,
    WaterShapeInputFromJSON,
    WaterShapeInputToJSON,
    WaterShapeResultFromJSON,
    WaterShapeResultToJSON,
    WaterShapeUpdateFromJSON,
    WaterShapeUpdateToJSON,
} from '../models/index';

export interface WaterShapeApiCreateThresholdRequest {
    id: number;
    thresholdInput: ThresholdInput;
}

export interface WaterShapeApiCreateWaterShapeRequest {
    waterShapeInput: WaterShapeInput;
}

export interface WaterShapeApiDeleteByIdRequest {
    id: number;
}

export interface WaterShapeApiDeleteThresholdRequest {
    id: number;
}

export interface WaterShapeApiExistsByCodeRequest {
    code: string;
}

export interface WaterShapeApiGetByCodeRequest {
    code: string;
}

export interface WaterShapeApiGetByIdRequest {
    id: number;
}

export interface WaterShapeApiGetThresholdRequest {
    id: number;
}

export interface WaterShapeApiGetThresholdsByCriteriaRequest {
    thresholdCriteria: ThresholdCriteria;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface WaterShapeApiGetWaterShapeByCodeAndEffectiveFromDateRequest {
    code: string;
    date: string;
}

export interface WaterShapeApiGetWaterShapeByCodeAndEffectiveFromDateTimeRequest {
    code: string;
    dateTime: string;
}

export interface WaterShapeApiGetWaterShapeByHistoryGroupIdAndDateTimeRequest {
    historyGroupId: number;
    dateTime: string;
}

export interface WaterShapeApiNewVersionRequest {
    id: number;
    waterShapeUpdate: WaterShapeUpdate;
}

export interface WaterShapeApiRevertWaterShapeRequest {
    id: number;
}

export interface WaterShapeApiSearchByCriteriaRequest {
    waterShapeCriteria: WaterShapeCriteria;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface WaterShapeApiUpdateRequest {
    id: number;
    waterShapeUpdate: WaterShapeUpdate;
}

export interface WaterShapeApiUpdateThresholdRequest {
    thresholdId: number;
    thresholdInput: ThresholdInput;
}

/**
 * 
 */
export class WaterShapeApi extends runtime.BaseAPI {

    /**
     */
    async createThresholdRaw(requestParameters: WaterShapeApiCreateThresholdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ThresholdResult>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling createThreshold.');
        }

        if (requestParameters.thresholdInput === null || requestParameters.thresholdInput === undefined) {
            throw new runtime.RequiredError('thresholdInput','Required parameter requestParameters.thresholdInput was null or undefined when calling createThreshold.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/water-shape/{id}/threshold`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ThresholdInputToJSON(requestParameters.thresholdInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ThresholdResultFromJSON(jsonValue));
    }

    /**
     */
    async createThreshold(id: number, thresholdInput: ThresholdInput, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ThresholdResult> {
        const response = await this.createThresholdRaw({ id: id, thresholdInput: thresholdInput }, initOverrides);
        return await response.value();
    }

    /**
     */
    async createWaterShapeRaw(requestParameters: WaterShapeApiCreateWaterShapeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WaterShapeResult>> {
        if (requestParameters.waterShapeInput === null || requestParameters.waterShapeInput === undefined) {
            throw new runtime.RequiredError('waterShapeInput','Required parameter requestParameters.waterShapeInput was null or undefined when calling createWaterShape.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/water-shape/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WaterShapeInputToJSON(requestParameters.waterShapeInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WaterShapeResultFromJSON(jsonValue));
    }

    /**
     */
    async createWaterShape(waterShapeInput: WaterShapeInput, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WaterShapeResult> {
        const response = await this.createWaterShapeRaw({ waterShapeInput: waterShapeInput }, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteByIdRaw(requestParameters: WaterShapeApiDeleteByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/water-shape/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async deleteById(id: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.deleteByIdRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteThresholdRaw(requestParameters: WaterShapeApiDeleteThresholdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteThreshold.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/water-shape/threshold/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async deleteThreshold(id: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.deleteThresholdRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async existsByCodeRaw(requestParameters: WaterShapeApiExistsByCodeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BooleanResponse>> {
        if (requestParameters.code === null || requestParameters.code === undefined) {
            throw new runtime.RequiredError('code','Required parameter requestParameters.code was null or undefined when calling existsByCode.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/water-shape/code-exists/{code}`.replace(`{${"code"}}`, encodeURIComponent(String(requestParameters.code))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BooleanResponseFromJSON(jsonValue));
    }

    /**
     */
    async existsByCode(code: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BooleanResponse> {
        const response = await this.existsByCodeRaw({ code: code }, initOverrides);
        return await response.value();
    }

    /**
     */
    async getByCodeRaw(requestParameters: WaterShapeApiGetByCodeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<WaterShapeResult>>> {
        if (requestParameters.code === null || requestParameters.code === undefined) {
            throw new runtime.RequiredError('code','Required parameter requestParameters.code was null or undefined when calling getByCode.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/water-shape/list/{code}`.replace(`{${"code"}}`, encodeURIComponent(String(requestParameters.code))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(WaterShapeResultFromJSON));
    }

    /**
     */
    async getByCode(code: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<WaterShapeResult>> {
        const response = await this.getByCodeRaw({ code: code }, initOverrides);
        return await response.value();
    }

    /**
     */
    async getByIdRaw(requestParameters: WaterShapeApiGetByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WaterShapeResult>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/water-shape/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WaterShapeResultFromJSON(jsonValue));
    }

    /**
     */
    async getById(id: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WaterShapeResult> {
        const response = await this.getByIdRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async getThresholdRaw(requestParameters: WaterShapeApiGetThresholdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ThresholdResult>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getThreshold.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/water-shape/threshold/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ThresholdResultFromJSON(jsonValue));
    }

    /**
     */
    async getThreshold(id: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ThresholdResult> {
        const response = await this.getThresholdRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     * Get records by pageable.
     */
    async getThresholdsByCriteriaRaw(requestParameters: WaterShapeApiGetThresholdsByCriteriaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageThresholdResult>> {
        if (requestParameters.thresholdCriteria === null || requestParameters.thresholdCriteria === undefined) {
            throw new runtime.RequiredError('thresholdCriteria','Required parameter requestParameters.thresholdCriteria was null or undefined when calling getThresholdsByCriteria.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/water-shape/threshold/by-criteria`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ThresholdCriteriaToJSON(requestParameters.thresholdCriteria),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageThresholdResultFromJSON(jsonValue));
    }

    /**
     * Get records by pageable.
     */
    async getThresholdsByCriteria(thresholdCriteria: ThresholdCriteria, page?: number, size?: number, sort?: Array<string>, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageThresholdResult> {
        const response = await this.getThresholdsByCriteriaRaw({ thresholdCriteria: thresholdCriteria, page: page, size: size, sort: sort }, initOverrides);
        return await response.value();
    }

    /**
     */
    async getWaterShapeByCodeAndEffectiveFromDateRaw(requestParameters: WaterShapeApiGetWaterShapeByCodeAndEffectiveFromDateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WaterShapeResult>> {
        if (requestParameters.code === null || requestParameters.code === undefined) {
            throw new runtime.RequiredError('code','Required parameter requestParameters.code was null or undefined when calling getWaterShapeByCodeAndEffectiveFromDate.');
        }

        if (requestParameters.date === null || requestParameters.date === undefined) {
            throw new runtime.RequiredError('date','Required parameter requestParameters.date was null or undefined when calling getWaterShapeByCodeAndEffectiveFromDate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/water-shape/by-date/{code}/{date}`.replace(`{${"code"}}`, encodeURIComponent(String(requestParameters.code))).replace(`{${"date"}}`, encodeURIComponent(String(requestParameters.date))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WaterShapeResultFromJSON(jsonValue));
    }

    /**
     */
    async getWaterShapeByCodeAndEffectiveFromDate(code: string, date: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WaterShapeResult> {
        const response = await this.getWaterShapeByCodeAndEffectiveFromDateRaw({ code: code, date: date }, initOverrides);
        return await response.value();
    }

    /**
     */
    async getWaterShapeByCodeAndEffectiveFromDateTimeRaw(requestParameters: WaterShapeApiGetWaterShapeByCodeAndEffectiveFromDateTimeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WaterShapeResult>> {
        if (requestParameters.code === null || requestParameters.code === undefined) {
            throw new runtime.RequiredError('code','Required parameter requestParameters.code was null or undefined when calling getWaterShapeByCodeAndEffectiveFromDateTime.');
        }

        if (requestParameters.dateTime === null || requestParameters.dateTime === undefined) {
            throw new runtime.RequiredError('dateTime','Required parameter requestParameters.dateTime was null or undefined when calling getWaterShapeByCodeAndEffectiveFromDateTime.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/water-shape/by-date-time/{code}/{dateTime}`.replace(`{${"code"}}`, encodeURIComponent(String(requestParameters.code))).replace(`{${"dateTime"}}`, encodeURIComponent(String(requestParameters.dateTime))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WaterShapeResultFromJSON(jsonValue));
    }

    /**
     */
    async getWaterShapeByCodeAndEffectiveFromDateTime(code: string, dateTime: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WaterShapeResult> {
        const response = await this.getWaterShapeByCodeAndEffectiveFromDateTimeRaw({ code: code, dateTime: dateTime }, initOverrides);
        return await response.value();
    }

    /**
     */
    async getWaterShapeByHistoryGroupIdAndDateTimeRaw(requestParameters: WaterShapeApiGetWaterShapeByHistoryGroupIdAndDateTimeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WaterShapeResult>> {
        if (requestParameters.historyGroupId === null || requestParameters.historyGroupId === undefined) {
            throw new runtime.RequiredError('historyGroupId','Required parameter requestParameters.historyGroupId was null or undefined when calling getWaterShapeByHistoryGroupIdAndDateTime.');
        }

        if (requestParameters.dateTime === null || requestParameters.dateTime === undefined) {
            throw new runtime.RequiredError('dateTime','Required parameter requestParameters.dateTime was null or undefined when calling getWaterShapeByHistoryGroupIdAndDateTime.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/water-shape/by-history-group-id-date-time/{historyGroupId}/{dateTime}`.replace(`{${"historyGroupId"}}`, encodeURIComponent(String(requestParameters.historyGroupId))).replace(`{${"dateTime"}}`, encodeURIComponent(String(requestParameters.dateTime))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WaterShapeResultFromJSON(jsonValue));
    }

    /**
     */
    async getWaterShapeByHistoryGroupIdAndDateTime(historyGroupId: number, dateTime: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WaterShapeResult> {
        const response = await this.getWaterShapeByHistoryGroupIdAndDateTimeRaw({ historyGroupId: historyGroupId, dateTime: dateTime }, initOverrides);
        return await response.value();
    }

    /**
     */
    async newVersionRaw(requestParameters: WaterShapeApiNewVersionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WaterShapeResult>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling newVersion.');
        }

        if (requestParameters.waterShapeUpdate === null || requestParameters.waterShapeUpdate === undefined) {
            throw new runtime.RequiredError('waterShapeUpdate','Required parameter requestParameters.waterShapeUpdate was null or undefined when calling newVersion.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/water-shape/new-version/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: WaterShapeUpdateToJSON(requestParameters.waterShapeUpdate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WaterShapeResultFromJSON(jsonValue));
    }

    /**
     */
    async newVersion(id: number, waterShapeUpdate: WaterShapeUpdate, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WaterShapeResult> {
        const response = await this.newVersionRaw({ id: id, waterShapeUpdate: waterShapeUpdate }, initOverrides);
        return await response.value();
    }

    /**
     */
    async revertWaterShapeRaw(requestParameters: WaterShapeApiRevertWaterShapeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WaterShapeResult>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling revertWaterShape.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/water-shape/revert/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WaterShapeResultFromJSON(jsonValue));
    }

    /**
     */
    async revertWaterShape(id: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WaterShapeResult> {
        const response = await this.revertWaterShapeRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async searchByCriteriaRaw(requestParameters: WaterShapeApiSearchByCriteriaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageWaterShapeResult>> {
        if (requestParameters.waterShapeCriteria === null || requestParameters.waterShapeCriteria === undefined) {
            throw new runtime.RequiredError('waterShapeCriteria','Required parameter requestParameters.waterShapeCriteria was null or undefined when calling searchByCriteria.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/water-shape/by-criteria`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WaterShapeCriteriaToJSON(requestParameters.waterShapeCriteria),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageWaterShapeResultFromJSON(jsonValue));
    }

    /**
     */
    async searchByCriteria(waterShapeCriteria: WaterShapeCriteria, page?: number, size?: number, sort?: Array<string>, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageWaterShapeResult> {
        const response = await this.searchByCriteriaRaw({ waterShapeCriteria: waterShapeCriteria, page: page, size: size, sort: sort }, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateRaw(requestParameters: WaterShapeApiUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WaterShapeResult>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling update.');
        }

        if (requestParameters.waterShapeUpdate === null || requestParameters.waterShapeUpdate === undefined) {
            throw new runtime.RequiredError('waterShapeUpdate','Required parameter requestParameters.waterShapeUpdate was null or undefined when calling update.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/water-shape/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: WaterShapeUpdateToJSON(requestParameters.waterShapeUpdate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WaterShapeResultFromJSON(jsonValue));
    }

    /**
     */
    async update(id: number, waterShapeUpdate: WaterShapeUpdate, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WaterShapeResult> {
        const response = await this.updateRaw({ id: id, waterShapeUpdate: waterShapeUpdate }, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateThresholdRaw(requestParameters: WaterShapeApiUpdateThresholdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ThresholdResult>> {
        if (requestParameters.thresholdId === null || requestParameters.thresholdId === undefined) {
            throw new runtime.RequiredError('thresholdId','Required parameter requestParameters.thresholdId was null or undefined when calling updateThreshold.');
        }

        if (requestParameters.thresholdInput === null || requestParameters.thresholdInput === undefined) {
            throw new runtime.RequiredError('thresholdInput','Required parameter requestParameters.thresholdInput was null or undefined when calling updateThreshold.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/water-shape/threshold/{thresholdId}`.replace(`{${"thresholdId"}}`, encodeURIComponent(String(requestParameters.thresholdId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ThresholdInputToJSON(requestParameters.thresholdInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ThresholdResultFromJSON(jsonValue));
    }

    /**
     */
    async updateThreshold(thresholdId: number, thresholdInput: ThresholdInput, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ThresholdResult> {
        const response = await this.updateThresholdRaw({ thresholdId: thresholdId, thresholdInput: thresholdInput }, initOverrides);
        return await response.value();
    }

}
