/* tslint:disable */
/* eslint-disable */
/**
 * Data import metadata
 * Administration of metadata for import
 *
 * The version of the OpenAPI document: 1.0
 * Contact: schvarcz@bb.microcomp.sk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ImportFileDefinitionResponse
 */
export interface ImportFileDefinitionResponse {
    /**
     * 
     * @type {string}
     * @memberof ImportFileDefinitionResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ImportFileDefinitionResponse
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ImportFileDefinitionResponse
     */
    subdomainCode?: string;
    /**
     * 
     * @type {string}
     * @memberof ImportFileDefinitionResponse
     */
    fileType?: ImportFileDefinitionResponseFileTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ImportFileDefinitionResponse
     */
    separator?: string;
    /**
     * 
     * @type {string}
     * @memberof ImportFileDefinitionResponse
     */
    encoding?: string;
    /**
     * 
     * @type {string}
     * @memberof ImportFileDefinitionResponse
     */
    dateFormat?: string;
    /**
     * 
     * @type {string}
     * @memberof ImportFileDefinitionResponse
     */
    laboratoryCode?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ImportFileDefinitionResponse
     */
    fileSuffixes?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ImportFileDefinitionResponse
     */
    fileNameFormat?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ImportFileDefinitionResponse
     */
    fileParts?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ImportFileDefinitionResponse
     */
    fileControls?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ImportFileDefinitionResponse
     */
    dataRegistryProductDefinitions?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof ImportFileDefinitionResponse
     */
    dataCountByHeader?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ImportFileDefinitionResponse
     */
    unnest?: boolean;
}


/**
 * @export
 */
export const ImportFileDefinitionResponseFileTypeEnum = {
    Txt: 'TXT',
    Csv: 'CSV',
    Xml: 'XML',
    Xls: 'XLS',
    Xlsx: 'XLSX'
} as const;
export type ImportFileDefinitionResponseFileTypeEnum = typeof ImportFileDefinitionResponseFileTypeEnum[keyof typeof ImportFileDefinitionResponseFileTypeEnum];


/**
 * Check if a given object implements the ImportFileDefinitionResponse interface.
 */
export function instanceOfImportFileDefinitionResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ImportFileDefinitionResponseFromJSON(json: any): ImportFileDefinitionResponse {
    return ImportFileDefinitionResponseFromJSONTyped(json, false);
}

export function ImportFileDefinitionResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImportFileDefinitionResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'subdomainCode': !exists(json, 'subdomainCode') ? undefined : json['subdomainCode'],
        'fileType': !exists(json, 'fileType') ? undefined : json['fileType'],
        'separator': !exists(json, 'separator') ? undefined : json['separator'],
        'encoding': !exists(json, 'encoding') ? undefined : json['encoding'],
        'dateFormat': !exists(json, 'dateFormat') ? undefined : json['dateFormat'],
        'laboratoryCode': !exists(json, 'laboratoryCode') ? undefined : json['laboratoryCode'],
        'fileSuffixes': !exists(json, 'fileSuffixes') ? undefined : json['fileSuffixes'],
        'fileNameFormat': !exists(json, 'fileNameFormat') ? undefined : json['fileNameFormat'],
        'fileParts': !exists(json, 'fileParts') ? undefined : json['fileParts'],
        'fileControls': !exists(json, 'fileControls') ? undefined : json['fileControls'],
        'dataRegistryProductDefinitions': !exists(json, 'dataRegistryProductDefinitions') ? undefined : json['dataRegistryProductDefinitions'],
        'dataCountByHeader': !exists(json, 'dataCountByHeader') ? undefined : json['dataCountByHeader'],
        'unnest': !exists(json, 'unnest') ? undefined : json['unnest'],
    };
}

export function ImportFileDefinitionResponseToJSON(value?: ImportFileDefinitionResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'subdomainCode': value.subdomainCode,
        'fileType': value.fileType,
        'separator': value.separator,
        'encoding': value.encoding,
        'dateFormat': value.dateFormat,
        'laboratoryCode': value.laboratoryCode,
        'fileSuffixes': value.fileSuffixes,
        'fileNameFormat': value.fileNameFormat,
        'fileParts': value.fileParts,
        'fileControls': value.fileControls,
        'dataRegistryProductDefinitions': value.dataRegistryProductDefinitions,
        'dataCountByHeader': value.dataCountByHeader,
        'unnest': value.unnest,
    };
}

