/* tslint:disable */
/* eslint-disable */
/**
 * Method import API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JobStatus
 */
export interface JobStatus {
    /**
     * 
     * @type {string}
     * @memberof JobStatus
     */
    status?: JobStatusStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof JobStatus
     */
    startTime?: string;
    /**
     * 
     * @type {string}
     * @memberof JobStatus
     */
    createTime?: string;
    /**
     * 
     * @type {string}
     * @memberof JobStatus
     */
    endTime?: string;
    /**
     * 
     * @type {string}
     * @memberof JobStatus
     */
    lastUpdated?: string;
}


/**
 * @export
 */
export const JobStatusStatusEnum = {
    Completed: 'COMPLETED',
    Starting: 'STARTING',
    Started: 'STARTED',
    Stopping: 'STOPPING',
    Stopped: 'STOPPED',
    Failed: 'FAILED',
    Abandoned: 'ABANDONED',
    Unknown: 'UNKNOWN'
} as const;
export type JobStatusStatusEnum = typeof JobStatusStatusEnum[keyof typeof JobStatusStatusEnum];


/**
 * Check if a given object implements the JobStatus interface.
 */
export function instanceOfJobStatus(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function JobStatusFromJSON(json: any): JobStatus {
    return JobStatusFromJSONTyped(json, false);
}

export function JobStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): JobStatus {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status': !exists(json, 'status') ? undefined : json['status'],
        'startTime': !exists(json, 'startTime') ? undefined : json['startTime'],
        'createTime': !exists(json, 'createTime') ? undefined : json['createTime'],
        'endTime': !exists(json, 'endTime') ? undefined : json['endTime'],
        'lastUpdated': !exists(json, 'lastUpdated') ? undefined : json['lastUpdated'],
    };
}

export function JobStatusToJSON(value?: JobStatus | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': value.status,
        'startTime': value.startTime,
        'createTime': value.createTime,
        'endTime': value.endTime,
        'lastUpdated': value.lastUpdated,
    };
}

