/* tslint:disable */
/* eslint-disable */
/**
 * Catalog API
 * Nástroj pre správu metadát a katalógov - koncové služby
 *
 * The version of the OpenAPI document: 0.27.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CodelistItemInput } from './CodelistItemInput';
import {
    CodelistItemInputFromJSON,
    CodelistItemInputFromJSONTyped,
    CodelistItemInputToJSON,
} from './CodelistItemInput';
import type { EnvironmentInputForMonitoringPoint } from './EnvironmentInputForMonitoringPoint';
import {
    EnvironmentInputForMonitoringPointFromJSON,
    EnvironmentInputForMonitoringPointFromJSONTyped,
    EnvironmentInputForMonitoringPointToJSON,
} from './EnvironmentInputForMonitoringPoint';
import type { WaterShapeInputForMonitoringPoint } from './WaterShapeInputForMonitoringPoint';
import {
    WaterShapeInputForMonitoringPointFromJSON,
    WaterShapeInputForMonitoringPointFromJSONTyped,
    WaterShapeInputForMonitoringPointToJSON,
} from './WaterShapeInputForMonitoringPoint';

/**
 * 
 * @export
 * @interface WaterCourseInputForMonitoringPointCodelistItemInput
 */
export interface WaterCourseInputForMonitoringPointCodelistItemInput {
    /**
     * 
     * @type {number}
     * @memberof WaterCourseInputForMonitoringPointCodelistItemInput
     */
    gisKm?: number;
    /**
     * 
     * @type {string}
     * @memberof WaterCourseInputForMonitoringPointCodelistItemInput
     */
    hydrographicNumber?: string;
    /**
     * 
     * @type {number}
     * @memberof WaterCourseInputForMonitoringPointCodelistItemInput
     */
    vhmKm?: number;
    /**
     * 
     * @type {CodelistItemInput}
     * @memberof WaterCourseInputForMonitoringPointCodelistItemInput
     */
    assesmentWaterFlowType: CodelistItemInput | null;
    /**
     * 
     * @type {EnvironmentInputForMonitoringPoint}
     * @memberof WaterCourseInputForMonitoringPointCodelistItemInput
     */
    environment?: EnvironmentInputForMonitoringPoint;
    /**
     * 
     * @type {WaterShapeInputForMonitoringPoint}
     * @memberof WaterCourseInputForMonitoringPointCodelistItemInput
     */
    waterShape?: WaterShapeInputForMonitoringPoint;
}

/**
 * Check if a given object implements the WaterCourseInputForMonitoringPointCodelistItemInput interface.
 */
export function instanceOfWaterCourseInputForMonitoringPointCodelistItemInput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "assesmentWaterFlowType" in value;

    return isInstance;
}

export function WaterCourseInputForMonitoringPointCodelistItemInputFromJSON(json: any): WaterCourseInputForMonitoringPointCodelistItemInput {
    return WaterCourseInputForMonitoringPointCodelistItemInputFromJSONTyped(json, false);
}

export function WaterCourseInputForMonitoringPointCodelistItemInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): WaterCourseInputForMonitoringPointCodelistItemInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'gisKm': !exists(json, 'gisKm') ? undefined : json['gisKm'],
        'hydrographicNumber': !exists(json, 'hydrographicNumber') ? undefined : json['hydrographicNumber'],
        'vhmKm': !exists(json, 'vhmKm') ? undefined : json['vhmKm'],
        'assesmentWaterFlowType': CodelistItemInputFromJSON(json['assesmentWaterFlowType']),
        'environment': !exists(json, 'environment') ? undefined : EnvironmentInputForMonitoringPointFromJSON(json['environment']),
        'waterShape': !exists(json, 'waterShape') ? undefined : WaterShapeInputForMonitoringPointFromJSON(json['waterShape']),
    };
}

export function WaterCourseInputForMonitoringPointCodelistItemInputToJSON(value?: WaterCourseInputForMonitoringPointCodelistItemInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'gisKm': value.gisKm,
        'hydrographicNumber': value.hydrographicNumber,
        'vhmKm': value.vhmKm,
        'assesmentWaterFlowType': CodelistItemInputToJSON(value.assesmentWaterFlowType),
        'environment': EnvironmentInputForMonitoringPointToJSON(value.environment),
        'waterShape': WaterShapeInputForMonitoringPointToJSON(value.waterShape),
    };
}

