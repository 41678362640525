/* tslint:disable */
/* eslint-disable */
/**
 * Data import metadata
 * Administration of metadata for import
 *
 * The version of the OpenAPI document: 1.0
 * Contact: schvarcz@bb.microcomp.sk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  IdentifierResponse,
  ImportFileDefinitionRequest,
  ImportFileDefinitionResponse,
  RowAttributeDefinitionResponse,
  RowAttributeDefinitionWithColumnPositionResponse,
  UploadFileResponse,
} from '../models/index';
import {
    IdentifierResponseFromJSON,
    IdentifierResponseToJSON,
    ImportFileDefinitionRequestFromJSON,
    ImportFileDefinitionRequestToJSON,
    ImportFileDefinitionResponseFromJSON,
    ImportFileDefinitionResponseToJSON,
    RowAttributeDefinitionResponseFromJSON,
    RowAttributeDefinitionResponseToJSON,
    RowAttributeDefinitionWithColumnPositionResponseFromJSON,
    RowAttributeDefinitionWithColumnPositionResponseToJSON,
    UploadFileResponseFromJSON,
    UploadFileResponseToJSON,
} from '../models/index';

export interface ImportFileDefinitionApiCreateImportFileDefinitionRequest {
    importFileDefinitionRequest: ImportFileDefinitionRequest;
}

export interface ImportFileDefinitionApiGetAllAuxiliaryRowAttributeDefinitionsByPrimaryRowAttributeDefinitionCodeRequest {
    primaryRowAttributeDefinitionCode: string;
    importFileDefinitionId: string;
}

export interface ImportFileDefinitionApiGetImportFileDefinitionByIdRequest {
    id: string;
}

export interface ImportFileDefinitionApiGetImportFileDefinitionBySubdomainRequest {
    subdomainCode: string;
}

export interface ImportFileDefinitionApiGetRowAttributeDefinitionByImportFileDefinitionRequest {
    id: string;
}

export interface ImportFileDefinitionApiGetRowAttributeDefinitionIdentifiersForTransformationsRequest {
    id: string;
}

export interface ImportFileDefinitionApiRemoveImportFileDefinitionRequest {
    id: string;
}

export interface ImportFileDefinitionApiUpdateImportFileDefinitionRequest {
    id: string;
    importFileDefinitionRequest: ImportFileDefinitionRequest;
}

export interface ImportFileDefinitionApiUploadImportFileDefinitionFileRequest {
    file: Blob;
}

/**
 * 
 */
export class ImportFileDefinitionApi extends runtime.BaseAPI {

    /**
     * Create import file definition
     */
    async createImportFileDefinitionRaw(requestParameters: ImportFileDefinitionApiCreateImportFileDefinitionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ImportFileDefinitionResponse>> {
        if (requestParameters.importFileDefinitionRequest === null || requestParameters.importFileDefinitionRequest === undefined) {
            throw new runtime.RequiredError('importFileDefinitionRequest','Required parameter requestParameters.importFileDefinitionRequest was null or undefined when calling createImportFileDefinition.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/import-file-definition`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ImportFileDefinitionRequestToJSON(requestParameters.importFileDefinitionRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ImportFileDefinitionResponseFromJSON(jsonValue));
    }

    /**
     * Create import file definition
     */
    async createImportFileDefinition(importFileDefinitionRequest: ImportFileDefinitionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ImportFileDefinitionResponse> {
        const response = await this.createImportFileDefinitionRaw({ importFileDefinitionRequest: importFileDefinitionRequest }, initOverrides);
        return await response.value();
    }

    /**
     * Get row attribute definitions by import file definition and primary row attribute definition code
     */
    async getAllAuxiliaryRowAttributeDefinitionsByPrimaryRowAttributeDefinitionCodeRaw(requestParameters: ImportFileDefinitionApiGetAllAuxiliaryRowAttributeDefinitionsByPrimaryRowAttributeDefinitionCodeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<RowAttributeDefinitionWithColumnPositionResponse>>> {
        if (requestParameters.primaryRowAttributeDefinitionCode === null || requestParameters.primaryRowAttributeDefinitionCode === undefined) {
            throw new runtime.RequiredError('primaryRowAttributeDefinitionCode','Required parameter requestParameters.primaryRowAttributeDefinitionCode was null or undefined when calling getAllAuxiliaryRowAttributeDefinitionsByPrimaryRowAttributeDefinitionCode.');
        }

        if (requestParameters.importFileDefinitionId === null || requestParameters.importFileDefinitionId === undefined) {
            throw new runtime.RequiredError('importFileDefinitionId','Required parameter requestParameters.importFileDefinitionId was null or undefined when calling getAllAuxiliaryRowAttributeDefinitionsByPrimaryRowAttributeDefinitionCode.');
        }

        const queryParameters: any = {};

        if (requestParameters.primaryRowAttributeDefinitionCode !== undefined) {
            queryParameters['primaryRowAttributeDefinitionCode'] = requestParameters.primaryRowAttributeDefinitionCode;
        }

        if (requestParameters.importFileDefinitionId !== undefined) {
            queryParameters['importFileDefinitionId'] = requestParameters.importFileDefinitionId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/import-file-definition/rowAttributeDefinitions-by-primary-rowAttributeDefinition-code`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RowAttributeDefinitionWithColumnPositionResponseFromJSON));
    }

    /**
     * Get row attribute definitions by import file definition and primary row attribute definition code
     */
    async getAllAuxiliaryRowAttributeDefinitionsByPrimaryRowAttributeDefinitionCode(primaryRowAttributeDefinitionCode: string, importFileDefinitionId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<RowAttributeDefinitionWithColumnPositionResponse>> {
        const response = await this.getAllAuxiliaryRowAttributeDefinitionsByPrimaryRowAttributeDefinitionCodeRaw({ primaryRowAttributeDefinitionCode: primaryRowAttributeDefinitionCode, importFileDefinitionId: importFileDefinitionId }, initOverrides);
        return await response.value();
    }

    /**
     * Get import file definition by id
     */
    async getImportFileDefinitionByIdRaw(requestParameters: ImportFileDefinitionApiGetImportFileDefinitionByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ImportFileDefinitionResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getImportFileDefinitionById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/import-file-definition/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ImportFileDefinitionResponseFromJSON(jsonValue));
    }

    /**
     * Get import file definition by id
     */
    async getImportFileDefinitionById(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ImportFileDefinitionResponse> {
        const response = await this.getImportFileDefinitionByIdRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     * Get import file definition by subdomain code
     */
    async getImportFileDefinitionBySubdomainRaw(requestParameters: ImportFileDefinitionApiGetImportFileDefinitionBySubdomainRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ImportFileDefinitionResponse>>> {
        if (requestParameters.subdomainCode === null || requestParameters.subdomainCode === undefined) {
            throw new runtime.RequiredError('subdomainCode','Required parameter requestParameters.subdomainCode was null or undefined when calling getImportFileDefinitionBySubdomain.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/import-file-definition/bySubdomain/{subdomainCode}`.replace(`{${"subdomainCode"}}`, encodeURIComponent(String(requestParameters.subdomainCode))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ImportFileDefinitionResponseFromJSON));
    }

    /**
     * Get import file definition by subdomain code
     */
    async getImportFileDefinitionBySubdomain(subdomainCode: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ImportFileDefinitionResponse>> {
        const response = await this.getImportFileDefinitionBySubdomainRaw({ subdomainCode: subdomainCode }, initOverrides);
        return await response.value();
    }

    /**
     * Get row attribute definitions by import file definition
     */
    async getRowAttributeDefinitionByImportFileDefinitionRaw(requestParameters: ImportFileDefinitionApiGetRowAttributeDefinitionByImportFileDefinitionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<RowAttributeDefinitionResponse>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getRowAttributeDefinitionByImportFileDefinition.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/import-file-definition/rowAttributeDefinitions/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RowAttributeDefinitionResponseFromJSON));
    }

    /**
     * Get row attribute definitions by import file definition
     */
    async getRowAttributeDefinitionByImportFileDefinition(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<RowAttributeDefinitionResponse>> {
        const response = await this.getRowAttributeDefinitionByImportFileDefinitionRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     * Get identifiers of row attribute definitions for transformation definitions by import file definition
     */
    async getRowAttributeDefinitionIdentifiersForTransformationsRaw(requestParameters: ImportFileDefinitionApiGetRowAttributeDefinitionIdentifiersForTransformationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<IdentifierResponse>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getRowAttributeDefinitionIdentifiersForTransformations.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/import-file-definition/rowAttributeDefinitionIdentifiersForTransformations/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(IdentifierResponseFromJSON));
    }

    /**
     * Get identifiers of row attribute definitions for transformation definitions by import file definition
     */
    async getRowAttributeDefinitionIdentifiersForTransformations(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<IdentifierResponse>> {
        const response = await this.getRowAttributeDefinitionIdentifiersForTransformationsRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     * Remove import file definition
     */
    async removeImportFileDefinitionRaw(requestParameters: ImportFileDefinitionApiRemoveImportFileDefinitionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling removeImportFileDefinition.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/import-file-definition/remove/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Remove import file definition
     */
    async removeImportFileDefinition(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.removeImportFileDefinitionRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     * Update import file definition
     */
    async updateImportFileDefinitionRaw(requestParameters: ImportFileDefinitionApiUpdateImportFileDefinitionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ImportFileDefinitionResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateImportFileDefinition.');
        }

        if (requestParameters.importFileDefinitionRequest === null || requestParameters.importFileDefinitionRequest === undefined) {
            throw new runtime.RequiredError('importFileDefinitionRequest','Required parameter requestParameters.importFileDefinitionRequest was null or undefined when calling updateImportFileDefinition.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/import-file-definition/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ImportFileDefinitionRequestToJSON(requestParameters.importFileDefinitionRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ImportFileDefinitionResponseFromJSON(jsonValue));
    }

    /**
     * Update import file definition
     */
    async updateImportFileDefinition(id: string, importFileDefinitionRequest: ImportFileDefinitionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ImportFileDefinitionResponse> {
        const response = await this.updateImportFileDefinitionRaw({ id: id, importFileDefinitionRequest: importFileDefinitionRequest }, initOverrides);
        return await response.value();
    }

    /**
     * Upload json file with import file definition
     */
    async uploadImportFileDefinitionFileRaw(requestParameters: ImportFileDefinitionApiUploadImportFileDefinitionFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UploadFileResponse>> {
        if (requestParameters.file === null || requestParameters.file === undefined) {
            throw new runtime.RequiredError('file','Required parameter requestParameters.file was null or undefined when calling uploadImportFileDefinitionFile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/import-file-definition/upload`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UploadFileResponseFromJSON(jsonValue));
    }

    /**
     * Upload json file with import file definition
     */
    async uploadImportFileDefinitionFile(file: Blob, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UploadFileResponse> {
        const response = await this.uploadImportFileDefinitionFileRaw({ file: file }, initOverrides);
        return await response.value();
    }

}
