/* tslint:disable */
/* eslint-disable */
/**
 * Catalog API
 * Nástroj pre správu metadát a katalógov - koncové služby
 *
 * The version of the OpenAPI document: 0.27.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CodelistItemInputRequired } from './CodelistItemInputRequired';
import {
    CodelistItemInputRequiredFromJSON,
    CodelistItemInputRequiredFromJSONTyped,
    CodelistItemInputRequiredToJSON,
} from './CodelistItemInputRequired';

/**
 * 
 * @export
 * @interface ThresholdInput
 */
export interface ThresholdInput {
    /**
     * 
     * @type {CodelistItemInputRequired}
     * @memberof ThresholdInput
     */
    determinantType: CodelistItemInputRequired;
    /**
     * 
     * @type {number}
     * @memberof ThresholdInput
     */
    value: number;
    /**
     * 
     * @type {number}
     * @memberof ThresholdInput
     */
    valueType: number;
}

/**
 * Check if a given object implements the ThresholdInput interface.
 */
export function instanceOfThresholdInput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "determinantType" in value;
    isInstance = isInstance && "value" in value;
    isInstance = isInstance && "valueType" in value;

    return isInstance;
}

export function ThresholdInputFromJSON(json: any): ThresholdInput {
    return ThresholdInputFromJSONTyped(json, false);
}

export function ThresholdInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): ThresholdInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'determinantType': CodelistItemInputRequiredFromJSON(json['determinantType']),
        'value': json['value'],
        'valueType': json['valueType'],
    };
}

export function ThresholdInputToJSON(value?: ThresholdInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'determinantType': CodelistItemInputRequiredToJSON(value.determinantType),
        'value': value.value,
        'valueType': value.valueType,
    };
}

