/* tslint:disable */
/* eslint-disable */
/**
 * Data import
 * Modul for importing data to Data Registry.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: schvarcz@bb.microcomp.sk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PrimaryDataAttributeResponse } from './PrimaryDataAttributeResponse';
import {
    PrimaryDataAttributeResponseFromJSON,
    PrimaryDataAttributeResponseFromJSONTyped,
    PrimaryDataAttributeResponseToJSON,
} from './PrimaryDataAttributeResponse';

/**
 * 
 * @export
 * @interface PagePrimaryDataAttributeResponse
 */
export interface PagePrimaryDataAttributeResponse {
    /**
     * 
     * @type {number}
     * @memberof PagePrimaryDataAttributeResponse
     */
    totalPages?: number;
    /**
     * 
     * @type {number}
     * @memberof PagePrimaryDataAttributeResponse
     */
    totalElements?: number;
    /**
     * 
     * @type {Array<PrimaryDataAttributeResponse>}
     * @memberof PagePrimaryDataAttributeResponse
     */
    content?: Array<PrimaryDataAttributeResponse>;
}

/**
 * Check if a given object implements the PagePrimaryDataAttributeResponse interface.
 */
export function instanceOfPagePrimaryDataAttributeResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PagePrimaryDataAttributeResponseFromJSON(json: any): PagePrimaryDataAttributeResponse {
    return PagePrimaryDataAttributeResponseFromJSONTyped(json, false);
}

export function PagePrimaryDataAttributeResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PagePrimaryDataAttributeResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalPages': !exists(json, 'totalPages') ? undefined : json['totalPages'],
        'totalElements': !exists(json, 'totalElements') ? undefined : json['totalElements'],
        'content': !exists(json, 'content') ? undefined : ((json['content'] as Array<any>).map(PrimaryDataAttributeResponseFromJSON)),
    };
}

export function PagePrimaryDataAttributeResponseToJSON(value?: PagePrimaryDataAttributeResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'totalPages': value.totalPages,
        'totalElements': value.totalElements,
        'content': value.content === undefined ? undefined : ((value.content as Array<any>).map(PrimaryDataAttributeResponseToJSON)),
    };
}

