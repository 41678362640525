/* tslint:disable */
/* eslint-disable */
/**
 * Data import metadata
 * Administration of metadata for import
 *
 * The version of the OpenAPI document: 1.0
 * Contact: schvarcz@bb.microcomp.sk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ImportFilePartDefinitionResponse
 */
export interface ImportFilePartDefinitionResponse {
    /**
     * 
     * @type {string}
     * @memberof ImportFilePartDefinitionResponse
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof ImportFilePartDefinitionResponse
     */
    blockPosition?: number;
    /**
     * 
     * @type {string}
     * @memberof ImportFilePartDefinitionResponse
     */
    blockSeparator?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ImportFilePartDefinitionResponse
     */
    rowTypeIds?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ImportFilePartDefinitionResponse
     */
    importFilePartControlIds?: Array<string>;
}

/**
 * Check if a given object implements the ImportFilePartDefinitionResponse interface.
 */
export function instanceOfImportFilePartDefinitionResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ImportFilePartDefinitionResponseFromJSON(json: any): ImportFilePartDefinitionResponse {
    return ImportFilePartDefinitionResponseFromJSONTyped(json, false);
}

export function ImportFilePartDefinitionResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImportFilePartDefinitionResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'blockPosition': !exists(json, 'blockPosition') ? undefined : json['blockPosition'],
        'blockSeparator': !exists(json, 'blockSeparator') ? undefined : json['blockSeparator'],
        'rowTypeIds': !exists(json, 'rowTypeIds') ? undefined : json['rowTypeIds'],
        'importFilePartControlIds': !exists(json, 'importFilePartControlIds') ? undefined : json['importFilePartControlIds'],
    };
}

export function ImportFilePartDefinitionResponseToJSON(value?: ImportFilePartDefinitionResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'blockPosition': value.blockPosition,
        'blockSeparator': value.blockSeparator,
        'rowTypeIds': value.rowTypeIds,
        'importFilePartControlIds': value.importFilePartControlIds,
    };
}

