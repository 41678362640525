/* tslint:disable */
/* eslint-disable */
/**
 * Data import
 * Modul for importing data to Data Registry.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: schvarcz@bb.microcomp.sk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  DocumentCriteriaRequest,
  DocumentResponse,
  PageDocumentResponse,
} from '../models/index';
import {
    DocumentCriteriaRequestFromJSON,
    DocumentCriteriaRequestToJSON,
    DocumentResponseFromJSON,
    DocumentResponseToJSON,
    PageDocumentResponseFromJSON,
    PageDocumentResponseToJSON,
} from '../models/index';

export interface DocumentApiDeleteDocumentRequest {
    id: string;
}

export interface DocumentApiGetRequest {
    id: string;
}

export interface DocumentApiGetDocumentByCriteriaRequest {
    sortDirections: Array<string>;
    sortProperties: Array<string>;
    documentCriteriaRequest: DocumentCriteriaRequest;
    page?: number;
    pageSize?: number;
}

export interface DocumentApiGetDocumentsRequest {
    page?: number;
    pageSize?: number;
}

/**
 * 
 */
export class DocumentApi extends runtime.BaseAPI {

    /**
     * Delete document and all data of this document
     */
    async deleteDocumentRaw(requestParameters: DocumentApiDeleteDocumentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteDocument.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/document/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete document and all data of this document
     */
    async deleteDocument(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteDocumentRaw({ id: id }, initOverrides);
    }

    /**
     * Get document by id
     */
    async getRaw(requestParameters: DocumentApiGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DocumentResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling get.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/document/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DocumentResponseFromJSON(jsonValue));
    }

    /**
     * Get document by id
     */
    async get(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DocumentResponse> {
        const response = await this.getRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     * Search by specific criteria and return matching records.
     */
    async getDocumentByCriteriaRaw(requestParameters: DocumentApiGetDocumentByCriteriaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageDocumentResponse>> {
        if (requestParameters.sortDirections === null || requestParameters.sortDirections === undefined) {
            throw new runtime.RequiredError('sortDirections','Required parameter requestParameters.sortDirections was null or undefined when calling getDocumentByCriteria.');
        }

        if (requestParameters.sortProperties === null || requestParameters.sortProperties === undefined) {
            throw new runtime.RequiredError('sortProperties','Required parameter requestParameters.sortProperties was null or undefined when calling getDocumentByCriteria.');
        }

        if (requestParameters.documentCriteriaRequest === null || requestParameters.documentCriteriaRequest === undefined) {
            throw new runtime.RequiredError('documentCriteriaRequest','Required parameter requestParameters.documentCriteriaRequest was null or undefined when calling getDocumentByCriteria.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.sortDirections) {
            queryParameters['sortDirections'] = requestParameters.sortDirections.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.sortProperties) {
            queryParameters['sortProperties'] = requestParameters.sortProperties.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/document/by-criteria`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DocumentCriteriaRequestToJSON(requestParameters.documentCriteriaRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageDocumentResponseFromJSON(jsonValue));
    }

    /**
     * Search by specific criteria and return matching records.
     */
    async getDocumentByCriteria(sortDirections: Array<string>, sortProperties: Array<string>, documentCriteriaRequest: DocumentCriteriaRequest, page?: number, pageSize?: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageDocumentResponse> {
        const response = await this.getDocumentByCriteriaRaw({ sortDirections: sortDirections, sortProperties: sortProperties, documentCriteriaRequest: documentCriteriaRequest, page: page, pageSize: pageSize }, initOverrides);
        return await response.value();
    }

    /**
     * Get all documents
     */
    async getDocumentsRaw(requestParameters: DocumentApiGetDocumentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageDocumentResponse>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/document/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageDocumentResponseFromJSON(jsonValue));
    }

    /**
     * Get all documents
     */
    async getDocuments(page?: number, pageSize?: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageDocumentResponse> {
        const response = await this.getDocumentsRaw({ page: page, pageSize: pageSize }, initOverrides);
        return await response.value();
    }

}
