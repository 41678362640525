import * as yup from 'yup';
import { BooleanSchema, MixedSchema, NumberSchema, ObjectSchema, StringSchema } from 'yup';
import { isValid, parseISO } from 'date-fns';
import { sk } from 'yup-locales';

const isEmpty = (obj: any): boolean => {
    // pre undefined a null vrat true
    if (obj === undefined || obj === null || obj === 'null') {
        return true;
    }
    // ak je prazdny string, vrat true
    if (typeof obj === 'string' || obj instanceof String) {
        return obj.trim() === '';
    }
    // ak je prazdny number, vrat true
    if (typeof obj === 'number' || obj instanceof Number) {
        return obj === null;
    }
    // ak je vyplneny boolean
    if (typeof obj === 'boolean' || obj instanceof Boolean) {
        return false;
    }
    // ak je prazdne pole vrat true
    if (Array.isArray(obj)) {
        return obj.length === 0;
    }
    // ak je objekty otestuj jeho hodnoty
    if (!Array.isArray(obj) && typeof obj === 'object') {
        return Object.values(obj).findIndex((v) => !isEmpty(v)) === -1;
    }
    throw new Error('Chyba validácie, nepodporovaný typ objektu: ' + typeof obj);
};

yup.setLocale(sk);

yup.addMethod(StringSchema, 'emptyAsNull', function () {
    return this.transform((val) => (val === '' ? null : val)).default(null);
});

yup.addMethod(MixedSchema, 'emptyAsNull', function () {
    return this.transform((val) => (val === '' ? null : val)).default(null);
});

yup.addMethod(NumberSchema, 'emptyAsNull', function () {
    return this.transform((val, oVal) => (oVal === '' ? null : val)).default(null);
});

yup.addMethod(BooleanSchema, 'emptyAsNull', function () {
    return this.transform((val) => (val === '' || val === 'null' ? null : val)).default(null);
});

yup.addMethod(BooleanSchema, 'emptyAsUndefined', function () {
    return this.transform((val) => (val === '' || val === 'null' ? undefined : val)).default(undefined);
});

yup.addMethod(StringSchema, 'emptyAsUndefined', function () {
    return this.transform((val) => (val === '' || val === null ? undefined : val)).default(undefined);
});

yup.addMethod(NumberSchema, 'emptyAsUndefined', function () {
    return this.transform((val, oVal) => (oVal === '' || oVal === null ? undefined : val)).default(undefined);
});

yup.addMethod(NumberSchema, 'isMaxNDecimal', function (decimals: number | undefined, fieldLocalisedName: string) {
    let message = `${fieldLocalisedName} môže mať max. ${decimals} desatinných miest`;
    if (decimals === 1) {
        message = `${fieldLocalisedName} môže mať max. ${decimals} desatinné miesto`;
    } else if (decimals !== undefined && decimals > 1 && decimals < 5) {
        message = `${fieldLocalisedName} môže mať max. ${decimals} desatinné miesta`;
    }

    return this.test('hasMaxNDecimalPlaces', message, (field, { originalValue }) => {
        if (!!originalValue && decimals !== undefined) {
            const stringifiedNumber = originalValue.toString();
            if (stringifiedNumber.includes('.')) {
                // ak ma desatinu ciarku
                const splitted = stringifiedNumber.split('.');
                return splitted.length === 2 && splitted[1].length <= decimals;
            }
        }
        return true;
    });
});

// umozni validovat ciselne hodnoty v string formate
yup.addMethod(StringSchema, 'isMaxNDecimal', function (decimals: number | undefined, fieldLocalisedName: string) {
    let message = `${fieldLocalisedName} môže mať max. ${decimals} desatinných miest`;
    if (decimals === 1) {
        message = `${fieldLocalisedName} môže mať max. ${decimals} desatinné miesto`;
    } else if (decimals !== undefined && decimals > 1 && decimals < 5) {
        message = `${fieldLocalisedName} môže mať max. ${decimals} desatinné miesta`;
    }

    return this.test('hasMaxNDecimalPlaces', message, (field) => {
        if (!!field && decimals !== undefined) {
            if (field.includes('.')) {
                // ak ma desatinu ciarku
                const splitted = field.split('.');
                return splitted.length === 2 && splitted[1].length <= decimals;
            }
        }
        return true;
    });
});

yup.addMethod(ObjectSchema, 'emptyAsNull', function () {
    return this.transform((val) => {
        return isEmpty(val) ? null : val;
    }).default(null);
});

yup.addMethod(ObjectSchema, 'emptyCodelistItemAsNull', function () {
    return this.transform((val) => {
        if (val) {
            if (val.code === null || val.code === undefined) {
                return null;
            }
            return val;
        }
        return val;
    }).default(null);
});

yup.addMethod(ObjectSchema, 'emptyCatalogAsUndefined', function () {
    return this.transform((val) => {
        if (val) {
            if (val.id === null || val.id === undefined) {
                return undefined;
            }
            return val;
        }
        return val;
    }).default(undefined);
});

yup.addMethod(ObjectSchema, 'emptyAsUndefined', function () {
    return this.transform((val) => {
        return isEmpty(val) ? undefined : val;
    }).default(undefined);
});

// funguje len s isoDate vo formate YYYY-MM-DD a teda retazcom o dlzke 10 znakov
yup.addMethod(StringSchema, 'isoDate', function (msg?: string) {
    return this.test('isDate', msg ?? 'Zadaná hodnota nie je platný dátum', (value?: string | null): boolean => {
        if (value) {
            return value.length === 10 && isValid(parseISO(value));
        }
        return true;
    });
});

// funguje len s isoDateTime vo formate YYYY-MM-DD'T'HH:mm:ss a teda retazcom o dlzke 19 znakov a viac
yup.addMethod(StringSchema, 'isoDateTime', function (msg?: string) {
    return this.test('isDateTime', msg ?? 'Zadaná hodnota nie je platný dátum s časom', (value?: string | null): boolean => {
        if (value) {
            return value.length >= 19 && isValid(parseISO(value));
        }
        return true;
    });
});

// funguje len s casom vo formate H:mm alebo HH:mm a teda retazcom o dlzke 4 - 5 znakov
yup.addMethod(StringSchema, 'isTime', function (msg?: string) {
    return this.test('isTime', msg ?? 'Zadaná hodnota nie je platný čas vo formáte H:mm alebo HH:mm', (value?: string | null): boolean => {
        if (value) {
            const timeFormat = new RegExp(/^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/);
            return timeFormat.test(value);
        }
        return true;
    });
});

// funguje len s isoDate/isoDateTime vo formate YYYY-MM-DD alebo YYYY-MM-DD'T'HH:mm:ss a teda retazcom o dlzke 10 alebo 19 a viac znakov
yup.addMethod(StringSchema, 'gteIsoDate', function (field: string, fieldLocalisedName: string, msg?: string) {
    return this.test(
        'isGreaterThanOrEqual ',
        (value: string | null | undefined, testContext: yup.TestContext<unknown>): yup.ValidationError | boolean => {
            if (value && testContext && testContext.parent) {
                let fieldDate: Date | null = null;
                if (field.indexOf(',') > -1) {
                    field
                        .split(',')
                        .filter((val) => testContext.parent[val]?.length === 10 || testContext.parent[val]?.length >= 19)
                        .map((val) => parseISO(testContext.parent[val]))
                        .filter((dateTime) => isValid(dateTime))
                        .forEach((dateTime) => {
                            if (fieldDate === null) {
                                fieldDate = dateTime;
                            }
                            if (fieldDate.getTime() < dateTime.getTime()) {
                                fieldDate = dateTime;
                            }
                        });
                } else {
                    fieldDate =
                        testContext.parent[field]?.length === 10 || testContext.parent[field]?.length >= 19
                            ? parseISO(testContext.parent[field])
                            : null;
                }

                const valueDate = value?.length === 10 || value?.length >= 19 ? parseISO(value) : null;

                if (fieldDate === null || valueDate === null || !isValid(fieldDate) || !isValid(valueDate)) {
                    return true; // can't validate invalid dates against each other
                }

                const valid = fieldDate.getTime() <= valueDate.getTime();
                if (!valid) {
                    return testContext.createError({
                        message: (msg ?? 'Dátum musí byť rovný alebo po hodnote "') + fieldLocalisedName + '"'
                    });
                }
            }
            return true;
        }
    );
});

// funguje len s isoDate vo formate YYYY-MM-DD a teda retazcom o dlzke 10 znakov
yup.addMethod(StringSchema, 'gteRefIsoDate', function (refIsoDate: string | null | undefined, fieldLocalisedName: string, msg?: string) {
    return this.test(
        'gteRefIsoDate',
        (msg ?? 'Dátum musí byť rovný alebo po hodnote "') + fieldLocalisedName + '"',
        (value: string | null | undefined) => {
            if (refIsoDate && value) {
                const valueDate = value?.length === 10 ? parseISO(value) : null;
                const referenceDate = refIsoDate?.length === 10 ? parseISO(refIsoDate) : null;

                if (referenceDate === null || valueDate === null || !isValid(referenceDate) || !isValid(valueDate)) {
                    return true; // can't validate invalid dates against each other
                }

                return referenceDate.getTime() <= valueDate.getTime();
            }
            return true;
        }
    );
});

// funguje len s isoDate vo formate YYYY-MM-DD a teda retazcom o dlzke 10 znakov
yup.addMethod(StringSchema, 'lteRefIsoDate', function (refIsoDate: string | null | undefined, fieldLocalisedName: string, msg?: string) {
    return this.test(
        'lteRefIsoDate',
        (msg ?? 'Dátum musí byť rovný alebo pred hodnotou "') + fieldLocalisedName + '"',
        (value: string | null | undefined) => {
            if (refIsoDate && value) {
                const valueDate = value?.length === 10 ? parseISO(value) : null;
                const referenceDate = refIsoDate?.length === 10 ? parseISO(refIsoDate) : null;

                if (referenceDate === null || valueDate === null || !isValid(referenceDate) || !isValid(valueDate)) {
                    return true; // can't validate invalid dates against each other
                }

                return referenceDate.getTime() >= valueDate.getTime();
            }
            return true;
        }
    );
});

yup.addMethod(StringSchema, 'checkName', function (fieldLocalisedName: string) {
    return this.test(
        'table-name-control',
        `${fieldLocalisedName} môže obsahovať iba písmená, čísla a znak "_" a zároveň nesmie začínať číslom`,
        (field) => {
            if (field) {
                const regex = /^[a-zA-Z_][a-zA-Z0-9_]*$/; // môže obsahovať iba písmená, čísla a znak _ a zároveň nesmie začínať číslom
                return regex.test(field);
            }
            return true;
        }
    );
});

yup.addMethod(yup.string, 'email', function validateEmail(message) {
    return this.matches(/^[0-9A-Za-z.\-_+%]+@[0-9A-Za-z.\-_+%]+\.[A-Za-z]{2,63}$/, {
        message: message ?? 'E-mail musí byť platný e-mail',
        name: 'email',
        excludeEmptyString: true
    });
});
