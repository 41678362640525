/* tslint:disable */
/* eslint-disable */
/**
 * Overview API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SortsSo } from './SortsSo';
import {
    SortsSoFromJSON,
    SortsSoFromJSONTyped,
    SortsSoToJSON,
} from './SortsSo';

/**
 * 
 * @export
 * @interface FetchSearchSo
 */
export interface FetchSearchSo {
    /**
     * 
     * @type {string}
     * @memberof FetchSearchSo
     */
    viewReference?: string;
    /**
     * 
     * @type {number}
     * @memberof FetchSearchSo
     */
    filterId?: number;
    /**
     * 
     * @type {string}
     * @memberof FetchSearchSo
     */
    searchField?: string;
    /**
     * 
     * @type {string}
     * @memberof FetchSearchSo
     */
    status?: FetchSearchSoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof FetchSearchSo
     */
    previewName?: string;
    /**
     * 
     * @type {string}
     * @memberof FetchSearchSo
     */
    filterName?: string;
    /**
     * 
     * @type {Array<SortsSo>}
     * @memberof FetchSearchSo
     */
    sorts?: Array<SortsSo>;
    /**
     * 
     * @type {number}
     * @memberof FetchSearchSo
     */
    pageNumber: number;
    /**
     * 
     * @type {number}
     * @memberof FetchSearchSo
     */
    pageSize: number;
}


/**
 * @export
 */
export const FetchSearchSoStatusEnum = {
    None: 'NONE',
    DownloadQueued: 'DOWNLOAD_QUEUED',
    DownloadInProgress: 'DOWNLOAD_IN_PROGRESS',
    FillingGaps: 'FILLING_GAPS',
    DownloadFailed: 'DOWNLOAD_FAILED',
    Downloaded: 'DOWNLOADED',
    Modified: 'MODIFIED',
    ValidationQueued: 'VALIDATION_QUEUED',
    ValidationInProgress: 'VALIDATION_IN_PROGRESS',
    ValidationFailed: 'VALIDATION_FAILED',
    Validated: 'VALIDATED',
    UpToDateCheckQueued: 'UP_TO_DATE_CHECK_QUEUED',
    UpToDateCheckInProgress: 'UP_TO_DATE_CHECK_IN_PROGRESS',
    UpToDateCheckFailed: 'UP_TO_DATE_CHECK_FAILED',
    ChangedInDr: 'CHANGED_IN_DR',
    SavingToDrQueued: 'SAVING_TO_DR_QUEUED',
    SavingToDrInProgress: 'SAVING_TO_DR_IN_PROGRESS',
    SavingToDrFailed: 'SAVING_TO_DR_FAILED',
    SavedToDr: 'SAVED_TO_DR',
    DeleteQueued: 'DELETE_QUEUED',
    DeleteFailed: 'DELETE_FAILED',
    DeleteInProgress: 'DELETE_IN_PROGRESS'
} as const;
export type FetchSearchSoStatusEnum = typeof FetchSearchSoStatusEnum[keyof typeof FetchSearchSoStatusEnum];


/**
 * Check if a given object implements the FetchSearchSo interface.
 */
export function instanceOfFetchSearchSo(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "pageNumber" in value;
    isInstance = isInstance && "pageSize" in value;

    return isInstance;
}

export function FetchSearchSoFromJSON(json: any): FetchSearchSo {
    return FetchSearchSoFromJSONTyped(json, false);
}

export function FetchSearchSoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FetchSearchSo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'viewReference': !exists(json, 'viewReference') ? undefined : json['viewReference'],
        'filterId': !exists(json, 'filterId') ? undefined : json['filterId'],
        'searchField': !exists(json, 'searchField') ? undefined : json['searchField'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'previewName': !exists(json, 'previewName') ? undefined : json['previewName'],
        'filterName': !exists(json, 'filterName') ? undefined : json['filterName'],
        'sorts': !exists(json, 'sorts') ? undefined : ((json['sorts'] as Array<any>).map(SortsSoFromJSON)),
        'pageNumber': json['pageNumber'],
        'pageSize': json['pageSize'],
    };
}

export function FetchSearchSoToJSON(value?: FetchSearchSo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'viewReference': value.viewReference,
        'filterId': value.filterId,
        'searchField': value.searchField,
        'status': value.status,
        'previewName': value.previewName,
        'filterName': value.filterName,
        'sorts': value.sorts === undefined ? undefined : ((value.sorts as Array<any>).map(SortsSoToJSON)),
        'pageNumber': value.pageNumber,
        'pageSize': value.pageSize,
    };
}

