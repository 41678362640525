/* tslint:disable */
/* eslint-disable */
/**
 * Data import metadata
 * Administration of metadata for import
 *
 * The version of the OpenAPI document: 1.0
 * Contact: schvarcz@bb.microcomp.sk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RowTypeDefinitionRequest } from './RowTypeDefinitionRequest';
import {
    RowTypeDefinitionRequestFromJSON,
    RowTypeDefinitionRequestFromJSONTyped,
    RowTypeDefinitionRequestToJSON,
} from './RowTypeDefinitionRequest';

/**
 * 
 * @export
 * @interface AssignRowTypeDefinitionRequest
 */
export interface AssignRowTypeDefinitionRequest {
    /**
     * 
     * @type {string}
     * @memberof AssignRowTypeDefinitionRequest
     */
    importFilePartDefinitionId?: string;
    /**
     * 
     * @type {RowTypeDefinitionRequest}
     * @memberof AssignRowTypeDefinitionRequest
     */
    rowTypeDefinitionRequest?: RowTypeDefinitionRequest;
}

/**
 * Check if a given object implements the AssignRowTypeDefinitionRequest interface.
 */
export function instanceOfAssignRowTypeDefinitionRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AssignRowTypeDefinitionRequestFromJSON(json: any): AssignRowTypeDefinitionRequest {
    return AssignRowTypeDefinitionRequestFromJSONTyped(json, false);
}

export function AssignRowTypeDefinitionRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AssignRowTypeDefinitionRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'importFilePartDefinitionId': !exists(json, 'importFilePartDefinitionId') ? undefined : json['importFilePartDefinitionId'],
        'rowTypeDefinitionRequest': !exists(json, 'rowTypeDefinitionRequest') ? undefined : RowTypeDefinitionRequestFromJSON(json['rowTypeDefinitionRequest']),
    };
}

export function AssignRowTypeDefinitionRequestToJSON(value?: AssignRowTypeDefinitionRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'importFilePartDefinitionId': value.importFilePartDefinitionId,
        'rowTypeDefinitionRequest': RowTypeDefinitionRequestToJSON(value.rowTypeDefinitionRequest),
    };
}

