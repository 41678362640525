/* tslint:disable */
/* eslint-disable */
/**
 * Codelist API
 * Nástroj pre správu metadát a katalógov - koncové služby
 *
 * The version of the OpenAPI document: 0.21.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserGroupUpdate
 */
export interface UserGroupUpdate {
    /**
     * 
     * @type {string}
     * @memberof UserGroupUpdate
     */
    preferredUsername: string;
    /**
     * 
     * @type {string}
     * @memberof UserGroupUpdate
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof UserGroupUpdate
     */
    note?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserGroupUpdate
     */
    section: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof UserGroupUpdate
     */
    codeCodelist: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof UserGroupUpdate
     */
    codeItems?: Array<number>;
}

/**
 * Check if a given object implements the UserGroupUpdate interface.
 */
export function instanceOfUserGroupUpdate(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "preferredUsername" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "section" in value;
    isInstance = isInstance && "codeCodelist" in value;

    return isInstance;
}

export function UserGroupUpdateFromJSON(json: any): UserGroupUpdate {
    return UserGroupUpdateFromJSONTyped(json, false);
}

export function UserGroupUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserGroupUpdate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'preferredUsername': json['preferredUsername'],
        'name': json['name'],
        'note': !exists(json, 'note') ? undefined : json['note'],
        'section': json['section'],
        'codeCodelist': json['codeCodelist'],
        'codeItems': !exists(json, 'codeItems') ? undefined : json['codeItems'],
    };
}

export function UserGroupUpdateToJSON(value?: UserGroupUpdate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'preferredUsername': value.preferredUsername,
        'name': value.name,
        'note': value.note,
        'section': value.section,
        'codeCodelist': value.codeCodelist,
        'codeItems': value.codeItems,
    };
}

