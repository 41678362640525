/* tslint:disable */
/* eslint-disable */
/**
 * Overview API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SetOriginalValueSo
 */
export interface SetOriginalValueSo {
    /**
     * 
     * @type {string}
     * @memberof SetOriginalValueSo
     */
    reference: string;
    /**
     * 
     * @type {number}
     * @memberof SetOriginalValueSo
     */
    filterId: number;
    /**
     * 
     * @type {number}
     * @memberof SetOriginalValueSo
     */
    rowNumber: number;
    /**
     * 
     * @type {string}
     * @memberof SetOriginalValueSo
     */
    columReference: string;
    /**
     * 
     * @type {boolean}
     * @memberof SetOriginalValueSo
     */
    useLocal: boolean;
}

/**
 * Check if a given object implements the SetOriginalValueSo interface.
 */
export function instanceOfSetOriginalValueSo(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "reference" in value;
    isInstance = isInstance && "filterId" in value;
    isInstance = isInstance && "rowNumber" in value;
    isInstance = isInstance && "columReference" in value;
    isInstance = isInstance && "useLocal" in value;

    return isInstance;
}

export function SetOriginalValueSoFromJSON(json: any): SetOriginalValueSo {
    return SetOriginalValueSoFromJSONTyped(json, false);
}

export function SetOriginalValueSoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SetOriginalValueSo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'reference': json['reference'],
        'filterId': json['filterId'],
        'rowNumber': json['rowNumber'],
        'columReference': json['columReference'],
        'useLocal': json['useLocal'],
    };
}

export function SetOriginalValueSoToJSON(value?: SetOriginalValueSo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'reference': value.reference,
        'filterId': value.filterId,
        'rowNumber': value.rowNumber,
        'columReference': value.columReference,
        'useLocal': value.useLocal,
    };
}

