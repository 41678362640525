/* tslint:disable */
/* eslint-disable */
/**
 * Overview API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CreateRowAttributeSo } from './CreateRowAttributeSo';
import {
    CreateRowAttributeSoFromJSON,
    CreateRowAttributeSoFromJSONTyped,
    CreateRowAttributeSoToJSON,
} from './CreateRowAttributeSo';

/**
 * 
 * @export
 * @interface PreviewDataCreateSo
 */
export interface PreviewDataCreateSo {
    /**
     * 
     * @type {string}
     * @memberof PreviewDataCreateSo
     */
    reference: string;
    /**
     * 
     * @type {number}
     * @memberof PreviewDataCreateSo
     */
    filterId: number;
    /**
     * 
     * @type {Array<CreateRowAttributeSo>}
     * @memberof PreviewDataCreateSo
     */
    data: Array<CreateRowAttributeSo>;
}

/**
 * Check if a given object implements the PreviewDataCreateSo interface.
 */
export function instanceOfPreviewDataCreateSo(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "reference" in value;
    isInstance = isInstance && "filterId" in value;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function PreviewDataCreateSoFromJSON(json: any): PreviewDataCreateSo {
    return PreviewDataCreateSoFromJSONTyped(json, false);
}

export function PreviewDataCreateSoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PreviewDataCreateSo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'reference': json['reference'],
        'filterId': json['filterId'],
        'data': ((json['data'] as Array<any>).map(CreateRowAttributeSoFromJSON)),
    };
}

export function PreviewDataCreateSoToJSON(value?: PreviewDataCreateSo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'reference': value.reference,
        'filterId': value.filterId,
        'data': ((value.data as Array<any>).map(CreateRowAttributeSoToJSON)),
    };
}

