/* tslint:disable */
/* eslint-disable */
/**
 * Catalog API
 * Nástroj pre správu metadát a katalógov - koncové služby
 *
 * The version of the OpenAPI document: 0.27.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AddressCodelistItemInput } from './AddressCodelistItemInput';
import {
    AddressCodelistItemInputFromJSON,
    AddressCodelistItemInputFromJSONTyped,
    AddressCodelistItemInputToJSON,
} from './AddressCodelistItemInput';
import type { CodelistItemInput } from './CodelistItemInput';
import {
    CodelistItemInputFromJSON,
    CodelistItemInputFromJSONTyped,
    CodelistItemInputToJSON,
} from './CodelistItemInput';
import type { ContactPerson } from './ContactPerson';
import {
    ContactPersonFromJSON,
    ContactPersonFromJSONTyped,
    ContactPersonToJSON,
} from './ContactPerson';

/**
 * 
 * @export
 * @interface OperatorInput
 */
export interface OperatorInput {
    /**
     * 
     * @type {number}
     * @memberof OperatorInput
     */
    technicalCode: number | null;
    /**
     * 
     * @type {string}
     * @memberof OperatorInput
     */
    name: string | null;
    /**
     * 
     * @type {string}
     * @memberof OperatorInput
     */
    operatorType: OperatorInputOperatorTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof OperatorInput
     */
    ippc: number | null;
    /**
     * 
     * @type {string}
     * @memberof OperatorInput
     */
    ico: string | null;
    /**
     * 
     * @type {string}
     * @memberof OperatorInput
     */
    dic: string | null;
    /**
     * 
     * @type {CodelistItemInput}
     * @memberof OperatorInput
     */
    naceType: CodelistItemInput | null;
    /**
     * 
     * @type {CodelistItemInput}
     * @memberof OperatorInput
     */
    nace2Type: CodelistItemInput | null;
    /**
     * 
     * @type {AddressCodelistItemInput}
     * @memberof OperatorInput
     */
    address: AddressCodelistItemInput | null;
    /**
     * 
     * @type {boolean}
     * @memberof OperatorInput
     */
    notificationDuty: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof OperatorInput
     */
    evaluated: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof OperatorInput
     */
    note: string | null;
    /**
     * 
     * @type {string}
     * @memberof OperatorInput
     */
    effectiveFrom: string;
    /**
     * 
     * @type {string}
     * @memberof OperatorInput
     */
    effectiveTo: string | null;
    /**
     * 
     * @type {string}
     * @memberof OperatorInput
     */
    code: string | null;
    /**
     * 
     * @type {Array<ContactPerson>}
     * @memberof OperatorInput
     */
    contactPerson: Array<ContactPerson> | null;
}


/**
 * @export
 */
export const OperatorInputOperatorTypeEnum = {
    P: 'P',
    F: 'F',
    Z: 'Z'
} as const;
export type OperatorInputOperatorTypeEnum = typeof OperatorInputOperatorTypeEnum[keyof typeof OperatorInputOperatorTypeEnum];


/**
 * Check if a given object implements the OperatorInput interface.
 */
export function instanceOfOperatorInput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "technicalCode" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "operatorType" in value;
    isInstance = isInstance && "ippc" in value;
    isInstance = isInstance && "ico" in value;
    isInstance = isInstance && "dic" in value;
    isInstance = isInstance && "naceType" in value;
    isInstance = isInstance && "nace2Type" in value;
    isInstance = isInstance && "address" in value;
    isInstance = isInstance && "notificationDuty" in value;
    isInstance = isInstance && "evaluated" in value;
    isInstance = isInstance && "note" in value;
    isInstance = isInstance && "effectiveFrom" in value;
    isInstance = isInstance && "effectiveTo" in value;
    isInstance = isInstance && "code" in value;
    isInstance = isInstance && "contactPerson" in value;

    return isInstance;
}

export function OperatorInputFromJSON(json: any): OperatorInput {
    return OperatorInputFromJSONTyped(json, false);
}

export function OperatorInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): OperatorInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'technicalCode': json['technicalCode'],
        'name': json['name'],
        'operatorType': json['operatorType'],
        'ippc': json['ippc'],
        'ico': json['ico'],
        'dic': json['dic'],
        'naceType': CodelistItemInputFromJSON(json['naceType']),
        'nace2Type': CodelistItemInputFromJSON(json['nace2Type']),
        'address': AddressCodelistItemInputFromJSON(json['address']),
        'notificationDuty': json['notificationDuty'],
        'evaluated': json['evaluated'],
        'note': json['note'],
        'effectiveFrom': json['effectiveFrom'],
        'effectiveTo': json['effectiveTo'],
        'code': json['code'],
        'contactPerson': (json['contactPerson'] === null ? null : (json['contactPerson'] as Array<any>).map(ContactPersonFromJSON)),
    };
}

export function OperatorInputToJSON(value?: OperatorInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'technicalCode': value.technicalCode,
        'name': value.name,
        'operatorType': value.operatorType,
        'ippc': value.ippc,
        'ico': value.ico,
        'dic': value.dic,
        'naceType': CodelistItemInputToJSON(value.naceType),
        'nace2Type': CodelistItemInputToJSON(value.nace2Type),
        'address': AddressCodelistItemInputToJSON(value.address),
        'notificationDuty': value.notificationDuty,
        'evaluated': value.evaluated,
        'note': value.note,
        'effectiveFrom': value.effectiveFrom,
        'effectiveTo': value.effectiveTo,
        'code': value.code,
        'contactPerson': (value.contactPerson === null ? null : (value.contactPerson as Array<any>).map(ContactPersonToJSON)),
    };
}

