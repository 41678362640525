/* tslint:disable */
/* eslint-disable */
/**
 * Data import metadata
 * Administration of metadata for import
 *
 * The version of the OpenAPI document: 1.0
 * Contact: schvarcz@bb.microcomp.sk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RowTypeDefinitionRequest } from './RowTypeDefinitionRequest';
import {
    RowTypeDefinitionRequestFromJSON,
    RowTypeDefinitionRequestFromJSONTyped,
    RowTypeDefinitionRequestToJSON,
} from './RowTypeDefinitionRequest';

/**
 * 
 * @export
 * @interface AssignRowTypeDefinitionsRequest
 */
export interface AssignRowTypeDefinitionsRequest {
    /**
     * 
     * @type {string}
     * @memberof AssignRowTypeDefinitionsRequest
     */
    importFilePartDefinitionId?: string;
    /**
     * 
     * @type {Array<RowTypeDefinitionRequest>}
     * @memberof AssignRowTypeDefinitionsRequest
     */
    rowTypeDefinitionRequests?: Array<RowTypeDefinitionRequest>;
}

/**
 * Check if a given object implements the AssignRowTypeDefinitionsRequest interface.
 */
export function instanceOfAssignRowTypeDefinitionsRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AssignRowTypeDefinitionsRequestFromJSON(json: any): AssignRowTypeDefinitionsRequest {
    return AssignRowTypeDefinitionsRequestFromJSONTyped(json, false);
}

export function AssignRowTypeDefinitionsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AssignRowTypeDefinitionsRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'importFilePartDefinitionId': !exists(json, 'importFilePartDefinitionId') ? undefined : json['importFilePartDefinitionId'],
        'rowTypeDefinitionRequests': !exists(json, 'rowTypeDefinitionRequests') ? undefined : ((json['rowTypeDefinitionRequests'] as Array<any>).map(RowTypeDefinitionRequestFromJSON)),
    };
}

export function AssignRowTypeDefinitionsRequestToJSON(value?: AssignRowTypeDefinitionsRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'importFilePartDefinitionId': value.importFilePartDefinitionId,
        'rowTypeDefinitionRequests': value.rowTypeDefinitionRequests === undefined ? undefined : ((value.rowTypeDefinitionRequests as Array<any>).map(RowTypeDefinitionRequestToJSON)),
    };
}

