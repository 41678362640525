/* tslint:disable */
/* eslint-disable */
/**
 * Codelist API
 * Nástroj pre správu metadát a katalógov - koncové služby
 *
 * The version of the OpenAPI document: 0.21.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CsvStatusResponse
 */
export interface CsvStatusResponse {
    /**
     * 
     * @type {string}
     * @memberof CsvStatusResponse
     */
    status?: CsvStatusResponseStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof CsvStatusResponse
     */
    percent?: number;
    /**
     * 
     * @type {string}
     * @memberof CsvStatusResponse
     */
    errorMessage?: string;
}


/**
 * @export
 */
export const CsvStatusResponseStatusEnum = {
    InProgress: 'IN_PROGRESS',
    Finished: 'FINISHED',
    Error: 'ERROR'
} as const;
export type CsvStatusResponseStatusEnum = typeof CsvStatusResponseStatusEnum[keyof typeof CsvStatusResponseStatusEnum];


/**
 * Check if a given object implements the CsvStatusResponse interface.
 */
export function instanceOfCsvStatusResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CsvStatusResponseFromJSON(json: any): CsvStatusResponse {
    return CsvStatusResponseFromJSONTyped(json, false);
}

export function CsvStatusResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CsvStatusResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status': !exists(json, 'status') ? undefined : json['status'],
        'percent': !exists(json, 'percent') ? undefined : json['percent'],
        'errorMessage': !exists(json, 'errorMessage') ? undefined : json['errorMessage'],
    };
}

export function CsvStatusResponseToJSON(value?: CsvStatusResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': value.status,
        'percent': value.percent,
        'errorMessage': value.errorMessage,
    };
}

