/* tslint:disable */
/* eslint-disable */
/**
 * Catalog API
 * Nástroj pre správu metadát a katalógov - koncové služby
 *
 * The version of the OpenAPI document: 0.27.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BooleanResponse,
  EndpointExceptionBody,
  EnvironmentCriteria,
  EnvironmentInput,
  EnvironmentResult,
  EnvironmentUpdate,
  PageEnvironmentResult,
  PageEnvironmentSimpleResultSo,
} from '../models/index';
import {
    BooleanResponseFromJSON,
    BooleanResponseToJSON,
    EndpointExceptionBodyFromJSON,
    EndpointExceptionBodyToJSON,
    EnvironmentCriteriaFromJSON,
    EnvironmentCriteriaToJSON,
    EnvironmentInputFromJSON,
    EnvironmentInputToJSON,
    EnvironmentResultFromJSON,
    EnvironmentResultToJSON,
    EnvironmentUpdateFromJSON,
    EnvironmentUpdateToJSON,
    PageEnvironmentResultFromJSON,
    PageEnvironmentResultToJSON,
    PageEnvironmentSimpleResultSoFromJSON,
    PageEnvironmentSimpleResultSoToJSON,
} from '../models/index';

export interface EnvironmentApiActivateEnvironmentRequest {
    id: number;
}

export interface EnvironmentApiCreateEnvironmentRequest {
    environmentInput: EnvironmentInput;
}

export interface EnvironmentApiDeactivateEnvironmentRequest {
    id: number;
}

export interface EnvironmentApiDeleteEnvironmentRequest {
    id: number;
}

export interface EnvironmentApiEnvironmentExistByCodeRequest {
    code: string;
}

export interface EnvironmentApiFindEnvironmentBySearchCriteriaRequest {
    environmentCriteria: EnvironmentCriteria;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface EnvironmentApiFindEnvironmentSimpleBySearchCriteriaRequest {
    environmentCriteria: EnvironmentCriteria;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface EnvironmentApiGetByCodeAndDateRequest {
    code: string;
    date: string;
}

export interface EnvironmentApiGetByCodeAndDateTimeRequest {
    code: string;
    dateTime: string;
}

export interface EnvironmentApiGetEnvironmentByCodeRequest {
    code: string;
}

export interface EnvironmentApiGetEnvironmentByHistoryGroupIdAndDateTimeRequest {
    historyGroupId: number;
    dateTime: string;
}

export interface EnvironmentApiGetEnvironmentByIdRequest {
    id: number;
}

export interface EnvironmentApiRevertEnvironmentRequest {
    id: number;
}

export interface EnvironmentApiUpdateEnvironmentRequest {
    id: number;
    environmentUpdate: EnvironmentUpdate;
}

export interface EnvironmentApiUpdateEnvironmentNewVersionRequest {
    id: number;
    environmentUpdate: EnvironmentUpdate;
}

/**
 * 
 */
export class EnvironmentApi extends runtime.BaseAPI {

    /**
     */
    async activateEnvironmentRaw(requestParameters: EnvironmentApiActivateEnvironmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling activateEnvironment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/environment/activate/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async activateEnvironment(id: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.activateEnvironmentRaw({ id: id }, initOverrides);
    }

    /**
     */
    async createEnvironmentRaw(requestParameters: EnvironmentApiCreateEnvironmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EnvironmentResult>> {
        if (requestParameters.environmentInput === null || requestParameters.environmentInput === undefined) {
            throw new runtime.RequiredError('environmentInput','Required parameter requestParameters.environmentInput was null or undefined when calling createEnvironment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/environment/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EnvironmentInputToJSON(requestParameters.environmentInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EnvironmentResultFromJSON(jsonValue));
    }

    /**
     */
    async createEnvironment(environmentInput: EnvironmentInput, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EnvironmentResult> {
        const response = await this.createEnvironmentRaw({ environmentInput: environmentInput }, initOverrides);
        return await response.value();
    }

    /**
     */
    async deactivateEnvironmentRaw(requestParameters: EnvironmentApiDeactivateEnvironmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deactivateEnvironment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/environment/deactivate/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deactivateEnvironment(id: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deactivateEnvironmentRaw({ id: id }, initOverrides);
    }

    /**
     */
    async deleteEnvironmentRaw(requestParameters: EnvironmentApiDeleteEnvironmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteEnvironment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/environment/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteEnvironment(id: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteEnvironmentRaw({ id: id }, initOverrides);
    }

    /**
     */
    async environmentExistByCodeRaw(requestParameters: EnvironmentApiEnvironmentExistByCodeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BooleanResponse>> {
        if (requestParameters.code === null || requestParameters.code === undefined) {
            throw new runtime.RequiredError('code','Required parameter requestParameters.code was null or undefined when calling environmentExistByCode.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/environment/code-exists/{code}`.replace(`{${"code"}}`, encodeURIComponent(String(requestParameters.code))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BooleanResponseFromJSON(jsonValue));
    }

    /**
     */
    async environmentExistByCode(code: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BooleanResponse> {
        const response = await this.environmentExistByCodeRaw({ code: code }, initOverrides);
        return await response.value();
    }

    /**
     */
    async findEnvironmentBySearchCriteriaRaw(requestParameters: EnvironmentApiFindEnvironmentBySearchCriteriaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageEnvironmentResult>> {
        if (requestParameters.environmentCriteria === null || requestParameters.environmentCriteria === undefined) {
            throw new runtime.RequiredError('environmentCriteria','Required parameter requestParameters.environmentCriteria was null or undefined when calling findEnvironmentBySearchCriteria.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/environment/by-criteria`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EnvironmentCriteriaToJSON(requestParameters.environmentCriteria),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageEnvironmentResultFromJSON(jsonValue));
    }

    /**
     */
    async findEnvironmentBySearchCriteria(environmentCriteria: EnvironmentCriteria, page?: number, size?: number, sort?: Array<string>, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageEnvironmentResult> {
        const response = await this.findEnvironmentBySearchCriteriaRaw({ environmentCriteria: environmentCriteria, page: page, size: size, sort: sort }, initOverrides);
        return await response.value();
    }

    /**
     */
    async findEnvironmentSimpleBySearchCriteriaRaw(requestParameters: EnvironmentApiFindEnvironmentSimpleBySearchCriteriaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageEnvironmentSimpleResultSo>> {
        if (requestParameters.environmentCriteria === null || requestParameters.environmentCriteria === undefined) {
            throw new runtime.RequiredError('environmentCriteria','Required parameter requestParameters.environmentCriteria was null or undefined when calling findEnvironmentSimpleBySearchCriteria.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/environment/simple-by-criteria`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EnvironmentCriteriaToJSON(requestParameters.environmentCriteria),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageEnvironmentSimpleResultSoFromJSON(jsonValue));
    }

    /**
     */
    async findEnvironmentSimpleBySearchCriteria(environmentCriteria: EnvironmentCriteria, page?: number, size?: number, sort?: Array<string>, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageEnvironmentSimpleResultSo> {
        const response = await this.findEnvironmentSimpleBySearchCriteriaRaw({ environmentCriteria: environmentCriteria, page: page, size: size, sort: sort }, initOverrides);
        return await response.value();
    }

    /**
     */
    async getByCodeAndDateRaw(requestParameters: EnvironmentApiGetByCodeAndDateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EnvironmentResult>> {
        if (requestParameters.code === null || requestParameters.code === undefined) {
            throw new runtime.RequiredError('code','Required parameter requestParameters.code was null or undefined when calling getByCodeAndDate.');
        }

        if (requestParameters.date === null || requestParameters.date === undefined) {
            throw new runtime.RequiredError('date','Required parameter requestParameters.date was null or undefined when calling getByCodeAndDate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/environment/by-date/{code}/{date}`.replace(`{${"code"}}`, encodeURIComponent(String(requestParameters.code))).replace(`{${"date"}}`, encodeURIComponent(String(requestParameters.date))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EnvironmentResultFromJSON(jsonValue));
    }

    /**
     */
    async getByCodeAndDate(code: string, date: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EnvironmentResult> {
        const response = await this.getByCodeAndDateRaw({ code: code, date: date }, initOverrides);
        return await response.value();
    }

    /**
     */
    async getByCodeAndDateTimeRaw(requestParameters: EnvironmentApiGetByCodeAndDateTimeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EnvironmentResult>> {
        if (requestParameters.code === null || requestParameters.code === undefined) {
            throw new runtime.RequiredError('code','Required parameter requestParameters.code was null or undefined when calling getByCodeAndDateTime.');
        }

        if (requestParameters.dateTime === null || requestParameters.dateTime === undefined) {
            throw new runtime.RequiredError('dateTime','Required parameter requestParameters.dateTime was null or undefined when calling getByCodeAndDateTime.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/environment/by-date-time/{code}/{dateTime}`.replace(`{${"code"}}`, encodeURIComponent(String(requestParameters.code))).replace(`{${"dateTime"}}`, encodeURIComponent(String(requestParameters.dateTime))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EnvironmentResultFromJSON(jsonValue));
    }

    /**
     */
    async getByCodeAndDateTime(code: string, dateTime: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EnvironmentResult> {
        const response = await this.getByCodeAndDateTimeRaw({ code: code, dateTime: dateTime }, initOverrides);
        return await response.value();
    }

    /**
     */
    async getEnvironmentByCodeRaw(requestParameters: EnvironmentApiGetEnvironmentByCodeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<EnvironmentResult>>> {
        if (requestParameters.code === null || requestParameters.code === undefined) {
            throw new runtime.RequiredError('code','Required parameter requestParameters.code was null or undefined when calling getEnvironmentByCode.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/environment/list/{code}`.replace(`{${"code"}}`, encodeURIComponent(String(requestParameters.code))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(EnvironmentResultFromJSON));
    }

    /**
     */
    async getEnvironmentByCode(code: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<EnvironmentResult>> {
        const response = await this.getEnvironmentByCodeRaw({ code: code }, initOverrides);
        return await response.value();
    }

    /**
     */
    async getEnvironmentByHistoryGroupIdAndDateTimeRaw(requestParameters: EnvironmentApiGetEnvironmentByHistoryGroupIdAndDateTimeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EnvironmentResult>> {
        if (requestParameters.historyGroupId === null || requestParameters.historyGroupId === undefined) {
            throw new runtime.RequiredError('historyGroupId','Required parameter requestParameters.historyGroupId was null or undefined when calling getEnvironmentByHistoryGroupIdAndDateTime.');
        }

        if (requestParameters.dateTime === null || requestParameters.dateTime === undefined) {
            throw new runtime.RequiredError('dateTime','Required parameter requestParameters.dateTime was null or undefined when calling getEnvironmentByHistoryGroupIdAndDateTime.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/environment/by-history-group-id-date-time/{historyGroupId}/{dateTime}`.replace(`{${"historyGroupId"}}`, encodeURIComponent(String(requestParameters.historyGroupId))).replace(`{${"dateTime"}}`, encodeURIComponent(String(requestParameters.dateTime))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EnvironmentResultFromJSON(jsonValue));
    }

    /**
     */
    async getEnvironmentByHistoryGroupIdAndDateTime(historyGroupId: number, dateTime: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EnvironmentResult> {
        const response = await this.getEnvironmentByHistoryGroupIdAndDateTimeRaw({ historyGroupId: historyGroupId, dateTime: dateTime }, initOverrides);
        return await response.value();
    }

    /**
     */
    async getEnvironmentByIdRaw(requestParameters: EnvironmentApiGetEnvironmentByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EnvironmentResult>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getEnvironmentById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/environment/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EnvironmentResultFromJSON(jsonValue));
    }

    /**
     */
    async getEnvironmentById(id: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EnvironmentResult> {
        const response = await this.getEnvironmentByIdRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async revertEnvironmentRaw(requestParameters: EnvironmentApiRevertEnvironmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EnvironmentResult>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling revertEnvironment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/environment/revert/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EnvironmentResultFromJSON(jsonValue));
    }

    /**
     */
    async revertEnvironment(id: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EnvironmentResult> {
        const response = await this.revertEnvironmentRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateEnvironmentRaw(requestParameters: EnvironmentApiUpdateEnvironmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EnvironmentResult>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateEnvironment.');
        }

        if (requestParameters.environmentUpdate === null || requestParameters.environmentUpdate === undefined) {
            throw new runtime.RequiredError('environmentUpdate','Required parameter requestParameters.environmentUpdate was null or undefined when calling updateEnvironment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/environment/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: EnvironmentUpdateToJSON(requestParameters.environmentUpdate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EnvironmentResultFromJSON(jsonValue));
    }

    /**
     */
    async updateEnvironment(id: number, environmentUpdate: EnvironmentUpdate, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EnvironmentResult> {
        const response = await this.updateEnvironmentRaw({ id: id, environmentUpdate: environmentUpdate }, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateEnvironmentNewVersionRaw(requestParameters: EnvironmentApiUpdateEnvironmentNewVersionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EnvironmentResult>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateEnvironmentNewVersion.');
        }

        if (requestParameters.environmentUpdate === null || requestParameters.environmentUpdate === undefined) {
            throw new runtime.RequiredError('environmentUpdate','Required parameter requestParameters.environmentUpdate was null or undefined when calling updateEnvironmentNewVersion.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/environment/new-version/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: EnvironmentUpdateToJSON(requestParameters.environmentUpdate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EnvironmentResultFromJSON(jsonValue));
    }

    /**
     */
    async updateEnvironmentNewVersion(id: number, environmentUpdate: EnvironmentUpdate, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EnvironmentResult> {
        const response = await this.updateEnvironmentNewVersionRaw({ id: id, environmentUpdate: environmentUpdate }, initOverrides);
        return await response.value();
    }

}
