/* tslint:disable */
/* eslint-disable */
/**
 * Method import API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  EndpointExceptionBody,
  ImportFile,
  ImportFileCriteria,
  JobStatus,
  PageImportFile,
} from '../models/index';
import {
    EndpointExceptionBodyFromJSON,
    EndpointExceptionBodyToJSON,
    ImportFileFromJSON,
    ImportFileToJSON,
    ImportFileCriteriaFromJSON,
    ImportFileCriteriaToJSON,
    JobStatusFromJSON,
    JobStatusToJSON,
    PageImportFileFromJSON,
    PageImportFileToJSON,
} from '../models/index';

export interface ImportFileControllerApiDeleteImportFileRequest {
    id: number;
}

export interface ImportFileControllerApiFindImportsBySearchCriteriaRequest {
    importFileCriteria: ImportFileCriteria;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface ImportFileControllerApiGetJobRequest {
    id: number;
}

export interface ImportFileControllerApiUpdateMethodicItemsRequest {
    id: number;
}

export interface ImportFileControllerApiUploadFileRequest {
    delimiter: string;
    encoding: string;
    update: boolean;
    multipartFile: Blob;
}

/**
 * 
 */
export class ImportFileControllerApi extends runtime.BaseAPI {

    /**
     */
    async deleteImportFileRaw(requestParameters: ImportFileControllerApiDeleteImportFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteImportFile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/import-file/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteImportFile(id: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteImportFileRaw({ id: id }, initOverrides);
    }

    /**
     */
    async findImportsBySearchCriteriaRaw(requestParameters: ImportFileControllerApiFindImportsBySearchCriteriaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageImportFile>> {
        if (requestParameters.importFileCriteria === null || requestParameters.importFileCriteria === undefined) {
            throw new runtime.RequiredError('importFileCriteria','Required parameter requestParameters.importFileCriteria was null or undefined when calling findImportsBySearchCriteria.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/import-file/by-criteria`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ImportFileCriteriaToJSON(requestParameters.importFileCriteria),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageImportFileFromJSON(jsonValue));
    }

    /**
     */
    async findImportsBySearchCriteria(importFileCriteria: ImportFileCriteria, page?: number, size?: number, sort?: Array<string>, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageImportFile> {
        const response = await this.findImportsBySearchCriteriaRaw({ importFileCriteria: importFileCriteria, page: page, size: size, sort: sort }, initOverrides);
        return await response.value();
    }

    /**
     */
    async getJobRaw(requestParameters: ImportFileControllerApiGetJobRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<JobStatus>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getJob.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/import-file/job/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => JobStatusFromJSON(jsonValue));
    }

    /**
     */
    async getJob(id: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<JobStatus> {
        const response = await this.getJobRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateMethodicItemsRaw(requestParameters: ImportFileControllerApiUpdateMethodicItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateMethodicItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/import-file/update-methodic-items/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateMethodicItems(id: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateMethodicItemsRaw({ id: id }, initOverrides);
    }

    /**
     */
    async uploadFileRaw(requestParameters: ImportFileControllerApiUploadFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ImportFile>> {
        if (requestParameters.delimiter === null || requestParameters.delimiter === undefined) {
            throw new runtime.RequiredError('delimiter','Required parameter requestParameters.delimiter was null or undefined when calling uploadFile.');
        }

        if (requestParameters.encoding === null || requestParameters.encoding === undefined) {
            throw new runtime.RequiredError('encoding','Required parameter requestParameters.encoding was null or undefined when calling uploadFile.');
        }

        if (requestParameters.update === null || requestParameters.update === undefined) {
            throw new runtime.RequiredError('update','Required parameter requestParameters.update was null or undefined when calling uploadFile.');
        }

        if (requestParameters.multipartFile === null || requestParameters.multipartFile === undefined) {
            throw new runtime.RequiredError('multipartFile','Required parameter requestParameters.multipartFile was null or undefined when calling uploadFile.');
        }

        const queryParameters: any = {};

        if (requestParameters.delimiter !== undefined) {
            queryParameters['delimiter'] = requestParameters.delimiter;
        }

        if (requestParameters.encoding !== undefined) {
            queryParameters['encoding'] = requestParameters.encoding;
        }

        if (requestParameters.update !== undefined) {
            queryParameters['update'] = requestParameters.update;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.multipartFile !== undefined) {
            formParams.append('multipartFile', requestParameters.multipartFile as any);
        }

        const response = await this.request({
            path: `/import-file/upload-file`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ImportFileFromJSON(jsonValue));
    }

    /**
     */
    async uploadFile(delimiter: string, encoding: string, update: boolean, multipartFile: Blob, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ImportFile> {
        const response = await this.uploadFileRaw({ delimiter: delimiter, encoding: encoding, update: update, multipartFile: multipartFile }, initOverrides);
        return await response.value();
    }

}
