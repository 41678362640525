/* tslint:disable */
/* eslint-disable */
/**
 * User Profile API
 * Nástroj pre ukladanie preferencií používateľa
 *
 * The version of the OpenAPI document: 0.3.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Attribute,
  AttributeCreate,
  EndpointExceptionBody,
} from '../models/index';
import {
    AttributeFromJSON,
    AttributeToJSON,
    AttributeCreateFromJSON,
    AttributeCreateToJSON,
    EndpointExceptionBodyFromJSON,
    EndpointExceptionBodyToJSON,
} from '../models/index';

export interface UserProfileApiCreateAttributeRequest {
    attributeCreate: AttributeCreate;
}

export interface UserProfileApiCreateAttributesRequest {
    attributeCreate: Array<AttributeCreate>;
}

export interface UserProfileApiGetAttributeRequest {
    domain: string;
    key: string;
}

export interface UserProfileApiGetAttributesRequest {
    domain: string;
}

/**
 * 
 */
export class UserProfileApi extends runtime.BaseAPI {

    /**
     */
    async createAttributeRaw(requestParameters: UserProfileApiCreateAttributeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Attribute>> {
        if (requestParameters.attributeCreate === null || requestParameters.attributeCreate === undefined) {
            throw new runtime.RequiredError('attributeCreate','Required parameter requestParameters.attributeCreate was null or undefined when calling createAttribute.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AttributeCreateToJSON(requestParameters.attributeCreate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AttributeFromJSON(jsonValue));
    }

    /**
     */
    async createAttribute(attributeCreate: AttributeCreate, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Attribute> {
        const response = await this.createAttributeRaw({ attributeCreate: attributeCreate }, initOverrides);
        return await response.value();
    }

    /**
     */
    async createAttributesRaw(requestParameters: UserProfileApiCreateAttributesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Attribute>>> {
        if (requestParameters.attributeCreate === null || requestParameters.attributeCreate === undefined) {
            throw new runtime.RequiredError('attributeCreate','Required parameter requestParameters.attributeCreate was null or undefined when calling createAttributes.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/list`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.attributeCreate.map(AttributeCreateToJSON),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AttributeFromJSON));
    }

    /**
     */
    async createAttributes(attributeCreate: Array<AttributeCreate>, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Attribute>> {
        const response = await this.createAttributesRaw({ attributeCreate: attributeCreate }, initOverrides);
        return await response.value();
    }

    /**
     */
    async getAttributeRaw(requestParameters: UserProfileApiGetAttributeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Attribute>> {
        if (requestParameters.domain === null || requestParameters.domain === undefined) {
            throw new runtime.RequiredError('domain','Required parameter requestParameters.domain was null or undefined when calling getAttribute.');
        }

        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling getAttribute.');
        }

        const queryParameters: any = {};

        if (requestParameters.domain !== undefined) {
            queryParameters['domain'] = requestParameters.domain;
        }

        if (requestParameters.key !== undefined) {
            queryParameters['key'] = requestParameters.key;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AttributeFromJSON(jsonValue));
    }

    /**
     */
    async getAttribute(domain: string, key: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Attribute> {
        const response = await this.getAttributeRaw({ domain: domain, key: key }, initOverrides);
        return await response.value();
    }

    /**
     */
    async getAttributesRaw(requestParameters: UserProfileApiGetAttributesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Attribute>>> {
        if (requestParameters.domain === null || requestParameters.domain === undefined) {
            throw new runtime.RequiredError('domain','Required parameter requestParameters.domain was null or undefined when calling getAttributes.');
        }

        const queryParameters: any = {};

        if (requestParameters.domain !== undefined) {
            queryParameters['domain'] = requestParameters.domain;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AttributeFromJSON));
    }

    /**
     */
    async getAttributes(domain: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Attribute>> {
        const response = await this.getAttributesRaw({ domain: domain }, initOverrides);
        return await response.value();
    }

}
