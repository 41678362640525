/* tslint:disable */
/* eslint-disable */
/**
 * Overview API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Condition
 */
export interface Condition {
    /**
     * 
     * @type {string}
     * @memberof Condition
     */
    column: string;
    /**
     * 
     * @type {string}
     * @memberof Condition
     */
    operator: ConditionOperatorEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof Condition
     */
    values?: Array<string>;
}


/**
 * @export
 */
export const ConditionOperatorEnum = {
    Lt: 'LT',
    Le: 'LE',
    Eq: 'EQ',
    Ne: 'NE',
    Ge: 'GE',
    Gt: 'GT',
    Between: 'BETWEEN',
    In: 'IN',
    InUserGroup: 'IN_USER_GROUP',
    InCommaSeparatedArray: 'IN_COMMA_SEPARATED_ARRAY'
} as const;
export type ConditionOperatorEnum = typeof ConditionOperatorEnum[keyof typeof ConditionOperatorEnum];


/**
 * Check if a given object implements the Condition interface.
 */
export function instanceOfCondition(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "column" in value;
    isInstance = isInstance && "operator" in value;

    return isInstance;
}

export function ConditionFromJSON(json: any): Condition {
    return ConditionFromJSONTyped(json, false);
}

export function ConditionFromJSONTyped(json: any, ignoreDiscriminator: boolean): Condition {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'column': json['column'],
        'operator': json['operator'],
        'values': !exists(json, 'values') ? undefined : json['values'],
    };
}

export function ConditionToJSON(value?: Condition | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'column': value.column,
        'operator': value.operator,
        'values': value.values,
    };
}

