/* tslint:disable */
/* eslint-disable */
/**
 * Overview API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExportSo
 */
export interface ExportSo {
    /**
     * 
     * @type {number}
     * @memberof ExportSo
     */
    progress?: number;
    /**
     * 
     * @type {string}
     * @memberof ExportSo
     */
    owner?: string;
    /**
     * 
     * @type {string}
     * @memberof ExportSo
     */
    status?: ExportSoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ExportSo
     */
    format?: ExportSoFormatEnum;
    /**
     * 
     * @type {string}
     * @memberof ExportSo
     */
    error?: string;
    /**
     * 
     * @type {string}
     * @memberof ExportSo
     */
    errorMessage?: string;
}


/**
 * @export
 */
export const ExportSoStatusEnum = {
    Queued: 'QUEUED',
    InProgress: 'IN_PROGRESS',
    Error: 'ERROR',
    Finished: 'FINISHED'
} as const;
export type ExportSoStatusEnum = typeof ExportSoStatusEnum[keyof typeof ExportSoStatusEnum];

/**
 * @export
 */
export const ExportSoFormatEnum = {
    Csv: 'CSV',
    Xlsx: 'XLSX'
} as const;
export type ExportSoFormatEnum = typeof ExportSoFormatEnum[keyof typeof ExportSoFormatEnum];


/**
 * Check if a given object implements the ExportSo interface.
 */
export function instanceOfExportSo(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExportSoFromJSON(json: any): ExportSo {
    return ExportSoFromJSONTyped(json, false);
}

export function ExportSoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExportSo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'progress': !exists(json, 'progress') ? undefined : json['progress'],
        'owner': !exists(json, 'owner') ? undefined : json['owner'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'format': !exists(json, 'format') ? undefined : json['format'],
        'error': !exists(json, 'error') ? undefined : json['error'],
        'errorMessage': !exists(json, 'errorMessage') ? undefined : json['errorMessage'],
    };
}

export function ExportSoToJSON(value?: ExportSo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'progress': value.progress,
        'owner': value.owner,
        'status': value.status,
        'format': value.format,
        'error': value.error,
        'errorMessage': value.errorMessage,
    };
}

