/* tslint:disable */
/* eslint-disable */
/**
 * Data import metadata
 * Administration of metadata for import
 *
 * The version of the OpenAPI document: 1.0
 * Contact: schvarcz@bb.microcomp.sk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ColumnDefinitionRequest } from './ColumnDefinitionRequest';
import {
    ColumnDefinitionRequestFromJSON,
    ColumnDefinitionRequestFromJSONTyped,
    ColumnDefinitionRequestToJSON,
} from './ColumnDefinitionRequest';

/**
 * 
 * @export
 * @interface AssignColumnDefinitionsRequest
 */
export interface AssignColumnDefinitionsRequest {
    /**
     * 
     * @type {string}
     * @memberof AssignColumnDefinitionsRequest
     */
    rowTypeDefinitionId?: string;
    /**
     * 
     * @type {Array<ColumnDefinitionRequest>}
     * @memberof AssignColumnDefinitionsRequest
     */
    columnDefinitions?: Array<ColumnDefinitionRequest>;
}

/**
 * Check if a given object implements the AssignColumnDefinitionsRequest interface.
 */
export function instanceOfAssignColumnDefinitionsRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AssignColumnDefinitionsRequestFromJSON(json: any): AssignColumnDefinitionsRequest {
    return AssignColumnDefinitionsRequestFromJSONTyped(json, false);
}

export function AssignColumnDefinitionsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AssignColumnDefinitionsRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'rowTypeDefinitionId': !exists(json, 'rowTypeDefinitionId') ? undefined : json['rowTypeDefinitionId'],
        'columnDefinitions': !exists(json, 'columnDefinitions') ? undefined : ((json['columnDefinitions'] as Array<any>).map(ColumnDefinitionRequestFromJSON)),
    };
}

export function AssignColumnDefinitionsRequestToJSON(value?: AssignColumnDefinitionsRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'rowTypeDefinitionId': value.rowTypeDefinitionId,
        'columnDefinitions': value.columnDefinitions === undefined ? undefined : ((value.columnDefinitions as Array<any>).map(ColumnDefinitionRequestToJSON)),
    };
}

