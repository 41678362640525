/* tslint:disable */
/* eslint-disable */
/**
 * Data import metadata
 * Administration of metadata for import
 *
 * The version of the OpenAPI document: 1.0
 * Contact: schvarcz@bb.microcomp.sk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RowAttributeDefinitionRequest } from './RowAttributeDefinitionRequest';
import {
    RowAttributeDefinitionRequestFromJSON,
    RowAttributeDefinitionRequestFromJSONTyped,
    RowAttributeDefinitionRequestToJSON,
} from './RowAttributeDefinitionRequest';

/**
 * 
 * @export
 * @interface AssignRowAttributeDefinitionRequest
 */
export interface AssignRowAttributeDefinitionRequest {
    /**
     * 
     * @type {string}
     * @memberof AssignRowAttributeDefinitionRequest
     */
    columnDefinitionId?: string;
    /**
     * 
     * @type {RowAttributeDefinitionRequest}
     * @memberof AssignRowAttributeDefinitionRequest
     */
    rowAttributeDefinition?: RowAttributeDefinitionRequest;
}

/**
 * Check if a given object implements the AssignRowAttributeDefinitionRequest interface.
 */
export function instanceOfAssignRowAttributeDefinitionRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AssignRowAttributeDefinitionRequestFromJSON(json: any): AssignRowAttributeDefinitionRequest {
    return AssignRowAttributeDefinitionRequestFromJSONTyped(json, false);
}

export function AssignRowAttributeDefinitionRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AssignRowAttributeDefinitionRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'columnDefinitionId': !exists(json, 'columnDefinitionId') ? undefined : json['columnDefinitionId'],
        'rowAttributeDefinition': !exists(json, 'rowAttributeDefinition') ? undefined : RowAttributeDefinitionRequestFromJSON(json['rowAttributeDefinition']),
    };
}

export function AssignRowAttributeDefinitionRequestToJSON(value?: AssignRowAttributeDefinitionRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'columnDefinitionId': value.columnDefinitionId,
        'rowAttributeDefinition': RowAttributeDefinitionRequestToJSON(value.rowAttributeDefinition),
    };
}

