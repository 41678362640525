/* tslint:disable */
/* eslint-disable */
/**
 * Data import metadata
 * Administration of metadata for import
 *
 * The version of the OpenAPI document: 1.0
 * Contact: schvarcz@bb.microcomp.sk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  DataRegistryProductResponse,
  DomainResponse,
  SubdomainResponse,
} from '../models/index';
import {
    DataRegistryProductResponseFromJSON,
    DataRegistryProductResponseToJSON,
    DomainResponseFromJSON,
    DomainResponseToJSON,
    SubdomainResponseFromJSON,
    SubdomainResponseToJSON,
} from '../models/index';

export interface DomainApiGetDataRegistryProductsRequest {
    subdomainCode: string;
}

export interface DomainApiGetSubdomainsRequest {
    domainCode: string;
}

/**
 * 
 */
export class DomainApi extends runtime.BaseAPI {

    /**
     * Get all data registry products by subdomain code
     */
    async getDataRegistryProductsRaw(requestParameters: DomainApiGetDataRegistryProductsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DataRegistryProductResponse>>> {
        if (requestParameters.subdomainCode === null || requestParameters.subdomainCode === undefined) {
            throw new runtime.RequiredError('subdomainCode','Required parameter requestParameters.subdomainCode was null or undefined when calling getDataRegistryProducts.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/domains/data-registry-products/{subdomainCode}`.replace(`{${"subdomainCode"}}`, encodeURIComponent(String(requestParameters.subdomainCode))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DataRegistryProductResponseFromJSON));
    }

    /**
     * Get all data registry products by subdomain code
     */
    async getDataRegistryProducts(subdomainCode: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DataRegistryProductResponse>> {
        const response = await this.getDataRegistryProductsRaw({ subdomainCode: subdomainCode }, initOverrides);
        return await response.value();
    }

    /**
     * Get all domains
     */
    async getDomainsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DomainResponse>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/domains/domains`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DomainResponseFromJSON));
    }

    /**
     * Get all domains
     */
    async getDomains(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DomainResponse>> {
        const response = await this.getDomainsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get all subdomains by domain code
     */
    async getSubdomainsRaw(requestParameters: DomainApiGetSubdomainsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SubdomainResponse>>> {
        if (requestParameters.domainCode === null || requestParameters.domainCode === undefined) {
            throw new runtime.RequiredError('domainCode','Required parameter requestParameters.domainCode was null or undefined when calling getSubdomains.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/domains/subdomains/{domainCode}`.replace(`{${"domainCode"}}`, encodeURIComponent(String(requestParameters.domainCode))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SubdomainResponseFromJSON));
    }

    /**
     * Get all subdomains by domain code
     */
    async getSubdomains(domainCode: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SubdomainResponse>> {
        const response = await this.getSubdomainsRaw({ domainCode: domainCode }, initOverrides);
        return await response.value();
    }

}
