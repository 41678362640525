/* tslint:disable */
/* eslint-disable */
/**
 * Method import API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EndpointExceptionBody } from './EndpointExceptionBody';
import {
    EndpointExceptionBodyFromJSON,
    EndpointExceptionBodyFromJSONTyped,
    EndpointExceptionBodyToJSON,
} from './EndpointExceptionBody';

/**
 * 
 * @export
 * @interface ItemResult
 */
export interface ItemResult {
    /**
     * 
     * @type {number}
     * @memberof ItemResult
     */
    code: number | null;
    /**
     * 
     * @type {EndpointExceptionBody}
     * @memberof ItemResult
     */
    endpointExceptionBody: EndpointExceptionBody;
    /**
     * 
     * @type {boolean}
     * @memberof ItemResult
     */
    exist: boolean | null;
}

/**
 * Check if a given object implements the ItemResult interface.
 */
export function instanceOfItemResult(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "code" in value;
    isInstance = isInstance && "endpointExceptionBody" in value;
    isInstance = isInstance && "exist" in value;

    return isInstance;
}

export function ItemResultFromJSON(json: any): ItemResult {
    return ItemResultFromJSONTyped(json, false);
}

export function ItemResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): ItemResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'code': json['code'],
        'endpointExceptionBody': EndpointExceptionBodyFromJSON(json['endpointExceptionBody']),
        'exist': json['exist'],
    };
}

export function ItemResultToJSON(value?: ItemResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'code': value.code,
        'endpointExceptionBody': EndpointExceptionBodyToJSON(value.endpointExceptionBody),
        'exist': value.exist,
    };
}

