/* tslint:disable */
/* eslint-disable */
/**
 * Catalog import API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.4.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CodelistItemInput } from './CodelistItemInput';
import {
    CodelistItemInputFromJSON,
    CodelistItemInputFromJSONTyped,
    CodelistItemInputToJSON,
} from './CodelistItemInput';
import type { GwBodyItemInput } from './GwBodyItemInput';
import {
    GwBodyItemInputFromJSON,
    GwBodyItemInputFromJSONTyped,
    GwBodyItemInputToJSON,
} from './GwBodyItemInput';

/**
 * 
 * @export
 * @interface UndergroundWaterCodelistItemInputGwBodyItemInput
 */
export interface UndergroundWaterCodelistItemInputGwBodyItemInput {
    /**
     * 
     * @type {number}
     * @memberof UndergroundWaterCodelistItemInputGwBodyItemInput
     */
    basicGeologicalDivision?: number;
    /**
     * 
     * @type {CodelistItemInput}
     * @memberof UndergroundWaterCodelistItemInputGwBodyItemInput
     */
    buildInFormType: CodelistItemInput | null;
    /**
     * 
     * @type {number}
     * @memberof UndergroundWaterCodelistItemInputGwBodyItemInput
     */
    cleanMonitorPlaceYear?: number;
    /**
     * 
     * @type {string}
     * @memberof UndergroundWaterCodelistItemInputGwBodyItemInput
     */
    commonCodePzV?: string;
    /**
     * 
     * @type {number}
     * @memberof UndergroundWaterCodelistItemInputGwBodyItemInput
     */
    depth?: number;
    /**
     * 
     * @type {GwBodyItemInput}
     * @memberof UndergroundWaterCodelistItemInputGwBodyItemInput
     */
    gwBodyType: GwBodyItemInput | null;
    /**
     * 
     * @type {number}
     * @memberof UndergroundWaterCodelistItemInputGwBodyItemInput
     */
    height?: number;
    /**
     * 
     * @type {CodelistItemInput}
     * @memberof UndergroundWaterCodelistItemInputGwBodyItemInput
     */
    institutionType: CodelistItemInput | null;
    /**
     * 
     * @type {number}
     * @memberof UndergroundWaterCodelistItemInputGwBodyItemInput
     */
    level?: number;
    /**
     * 
     * @type {CodelistItemInput}
     * @memberof UndergroundWaterCodelistItemInputGwBodyItemInput
     */
    mainHgrType: CodelistItemInput | null;
    /**
     * 
     * @type {CodelistItemInput}
     * @memberof UndergroundWaterCodelistItemInputGwBodyItemInput
     */
    partialHgrType: CodelistItemInput | null;
    /**
     * 
     * @type {number}
     * @memberof UndergroundWaterCodelistItemInputGwBodyItemInput
     */
    perforationLower?: number;
    /**
     * 
     * @type {number}
     * @memberof UndergroundWaterCodelistItemInputGwBodyItemInput
     */
    perforationUpper?: number;
    /**
     * 
     * @type {CodelistItemInput}
     * @memberof UndergroundWaterCodelistItemInputGwBodyItemInput
     */
    sampleAreaType: CodelistItemInput | null;
    /**
     * 
     * @type {number}
     * @memberof UndergroundWaterCodelistItemInputGwBodyItemInput
     */
    samplingDepth?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UndergroundWaterCodelistItemInputGwBodyItemInput
     */
    utilization: boolean | null;
}

/**
 * Check if a given object implements the UndergroundWaterCodelistItemInputGwBodyItemInput interface.
 */
export function instanceOfUndergroundWaterCodelistItemInputGwBodyItemInput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "buildInFormType" in value;
    isInstance = isInstance && "gwBodyType" in value;
    isInstance = isInstance && "institutionType" in value;
    isInstance = isInstance && "mainHgrType" in value;
    isInstance = isInstance && "partialHgrType" in value;
    isInstance = isInstance && "sampleAreaType" in value;
    isInstance = isInstance && "utilization" in value;

    return isInstance;
}

export function UndergroundWaterCodelistItemInputGwBodyItemInputFromJSON(json: any): UndergroundWaterCodelistItemInputGwBodyItemInput {
    return UndergroundWaterCodelistItemInputGwBodyItemInputFromJSONTyped(json, false);
}

export function UndergroundWaterCodelistItemInputGwBodyItemInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): UndergroundWaterCodelistItemInputGwBodyItemInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'basicGeologicalDivision': !exists(json, 'basicGeologicalDivision') ? undefined : json['basicGeologicalDivision'],
        'buildInFormType': CodelistItemInputFromJSON(json['buildInFormType']),
        'cleanMonitorPlaceYear': !exists(json, 'cleanMonitorPlaceYear') ? undefined : json['cleanMonitorPlaceYear'],
        'commonCodePzV': !exists(json, 'commonCodePzV') ? undefined : json['commonCodePzV'],
        'depth': !exists(json, 'depth') ? undefined : json['depth'],
        'gwBodyType': GwBodyItemInputFromJSON(json['gwBodyType']),
        'height': !exists(json, 'height') ? undefined : json['height'],
        'institutionType': CodelistItemInputFromJSON(json['institutionType']),
        'level': !exists(json, 'level') ? undefined : json['level'],
        'mainHgrType': CodelistItemInputFromJSON(json['mainHgrType']),
        'partialHgrType': CodelistItemInputFromJSON(json['partialHgrType']),
        'perforationLower': !exists(json, 'perforationLower') ? undefined : json['perforationLower'],
        'perforationUpper': !exists(json, 'perforationUpper') ? undefined : json['perforationUpper'],
        'sampleAreaType': CodelistItemInputFromJSON(json['sampleAreaType']),
        'samplingDepth': !exists(json, 'samplingDepth') ? undefined : json['samplingDepth'],
        'utilization': json['utilization'],
    };
}

export function UndergroundWaterCodelistItemInputGwBodyItemInputToJSON(value?: UndergroundWaterCodelistItemInputGwBodyItemInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'basicGeologicalDivision': value.basicGeologicalDivision,
        'buildInFormType': CodelistItemInputToJSON(value.buildInFormType),
        'cleanMonitorPlaceYear': value.cleanMonitorPlaceYear,
        'commonCodePzV': value.commonCodePzV,
        'depth': value.depth,
        'gwBodyType': GwBodyItemInputToJSON(value.gwBodyType),
        'height': value.height,
        'institutionType': CodelistItemInputToJSON(value.institutionType),
        'level': value.level,
        'mainHgrType': CodelistItemInputToJSON(value.mainHgrType),
        'partialHgrType': CodelistItemInputToJSON(value.partialHgrType),
        'perforationLower': value.perforationLower,
        'perforationUpper': value.perforationUpper,
        'sampleAreaType': CodelistItemInputToJSON(value.sampleAreaType),
        'samplingDepth': value.samplingDepth,
        'utilization': value.utilization,
    };
}

