/* tslint:disable */
/* eslint-disable */
/**
 * Overview API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ColumnHistory
 */
export interface ColumnHistory {
    /**
     * 
     * @type {string}
     * @memberof ColumnHistory
     */
    time?: string;
    /**
     * 
     * @type {string}
     * @memberof ColumnHistory
     */
    action?: ColumnHistoryActionEnum;
    /**
     * 
     * @type {string}
     * @memberof ColumnHistory
     */
    value?: string;
    /**
     * 
     * @type {string}
     * @memberof ColumnHistory
     */
    source?: ColumnHistorySourceEnum;
    /**
     * 
     * @type {string}
     * @memberof ColumnHistory
     */
    user?: string;
    /**
     * 
     * @type {string}
     * @memberof ColumnHistory
     */
    comment?: string;
}


/**
 * @export
 */
export const ColumnHistoryActionEnum = {
    Create: 'CREATE',
    Update: 'UPDATE',
    Delete: 'DELETE'
} as const;
export type ColumnHistoryActionEnum = typeof ColumnHistoryActionEnum[keyof typeof ColumnHistoryActionEnum];

/**
 * @export
 */
export const ColumnHistorySourceEnum = {
    Import: 'IMPORT',
    ImportForObservation: 'IMPORT_FOR_OBSERVATION',
    Edit: 'EDIT',
    Calculated: 'CALCULATED'
} as const;
export type ColumnHistorySourceEnum = typeof ColumnHistorySourceEnum[keyof typeof ColumnHistorySourceEnum];


/**
 * Check if a given object implements the ColumnHistory interface.
 */
export function instanceOfColumnHistory(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ColumnHistoryFromJSON(json: any): ColumnHistory {
    return ColumnHistoryFromJSONTyped(json, false);
}

export function ColumnHistoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): ColumnHistory {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'time': !exists(json, 'time') ? undefined : json['time'],
        'action': !exists(json, 'action') ? undefined : json['action'],
        'value': !exists(json, 'value') ? undefined : json['value'],
        'source': !exists(json, 'source') ? undefined : json['source'],
        'user': !exists(json, 'user') ? undefined : json['user'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
    };
}

export function ColumnHistoryToJSON(value?: ColumnHistory | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'time': value.time,
        'action': value.action,
        'value': value.value,
        'source': value.source,
        'user': value.user,
        'comment': value.comment,
    };
}

