/* tslint:disable */
/* eslint-disable */
/**
 * Data import metadata
 * Administration of metadata for import
 *
 * The version of the OpenAPI document: 1.0
 * Contact: schvarcz@bb.microcomp.sk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ImportFilePartDefinitionRequest
 */
export interface ImportFilePartDefinitionRequest {
    /**
     * 
     * @type {number}
     * @memberof ImportFilePartDefinitionRequest
     */
    blockPosition?: number;
    /**
     * 
     * @type {string}
     * @memberof ImportFilePartDefinitionRequest
     */
    blockSeparator?: string;
}

/**
 * Check if a given object implements the ImportFilePartDefinitionRequest interface.
 */
export function instanceOfImportFilePartDefinitionRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ImportFilePartDefinitionRequestFromJSON(json: any): ImportFilePartDefinitionRequest {
    return ImportFilePartDefinitionRequestFromJSONTyped(json, false);
}

export function ImportFilePartDefinitionRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImportFilePartDefinitionRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'blockPosition': !exists(json, 'blockPosition') ? undefined : json['blockPosition'],
        'blockSeparator': !exists(json, 'blockSeparator') ? undefined : json['blockSeparator'],
    };
}

export function ImportFilePartDefinitionRequestToJSON(value?: ImportFilePartDefinitionRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'blockPosition': value.blockPosition,
        'blockSeparator': value.blockSeparator,
    };
}

