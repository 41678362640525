import React, { FunctionComponent, PropsWithChildren } from 'react';
import { Transition } from '@headlessui/react';
import { Link, To } from 'react-router-dom';

export interface LoadingOverlayProps {
    loading: boolean;
    failed?: boolean;
    backLink?: {
        to?: To;
        title?: string;
        state?: any;
    };
    fullscreen?: boolean;
}

const LoadingOverlay: FunctionComponent<PropsWithChildren<LoadingOverlayProps>> = ({
    loading,
    fullscreen = false,
    failed = false,
    backLink,
    children
}) => {
    return (
        <div className='relative h-full'>
            <Transition
                show={loading || failed}
                enter='transition-opacity duration-75'
                enterFrom='opacity-0'
                enterTo='opacity-100'
                leave='transition-opacity duration-150'
                leaveFrom='opacity-100'
                leaveTo='opacity-0'
            >
                {failed && (
                    <div
                        key='error'
                        className='fixed bottom-0 left-0 right-0 top-0 z-50 flex justify-center bg-light-backgroundLight opacity-90 dark:bg-dark-backgroundLight dark:text-dark-textDark'
                        role='status'
                    >
                        <div className='flex self-center gap-1'>
                            Načítavanie obsahu zlyhalo.
                            <Link
                                to={backLink?.to ?? '/'}
                                state={backLink?.state}
                                className='underline font-bold text-light-text hover:text-light-textHover dark:text-dark-text dark:hover:text-dark-textHover'
                            >
                                {backLink?.title ?? 'Späť na základnú obrazovku.'}
                            </Link>
                        </div>
                    </div>
                )}
                {!failed && (
                    <div
                        key='loader'
                        className={`${
                            fullscreen ? 'fixed' : 'absolute'
                        } bottom-0 left-0 right-0 top-0 z-20 flex justify-center bg-light-backgroundLight opacity-75 dark:bg-dark-backgroundLight`}
                        role='status'
                    >
                        <div className='flex items-stretch self-center'>
                            <div className='h-12 w-12 animate-spin rounded-full border-5 border-x-light-text border-y-transparent dark:border-x-dark-text'></div>
                        </div>
                        <span className='sr-only'>Načítavam...</span>
                    </div>
                )}
            </Transition>
            {children}
        </div>
    );
};

export default LoadingOverlay;
