/* tslint:disable */
/* eslint-disable */
/**
 * Overview API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RowAttributeSo } from './RowAttributeSo';
import {
    RowAttributeSoFromJSON,
    RowAttributeSoFromJSONTyped,
    RowAttributeSoToJSON,
} from './RowAttributeSo';

/**
 * 
 * @export
 * @interface PreviewDataSo
 */
export interface PreviewDataSo {
    /**
     * 
     * @type {number}
     * @memberof PreviewDataSo
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof PreviewDataSo
     */
    reference: string;
    /**
     * 
     * @type {number}
     * @memberof PreviewDataSo
     */
    rowNumber: number;
    /**
     * 
     * @type {number}
     * @memberof PreviewDataSo
     */
    filterId: number;
    /**
     * 
     * @type {Array<RowAttributeSo>}
     * @memberof PreviewDataSo
     */
    data: Array<RowAttributeSo>;
    /**
     * 
     * @type {boolean}
     * @memberof PreviewDataSo
     */
    deleted: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PreviewDataSo
     */
    generated?: boolean;
}

/**
 * Check if a given object implements the PreviewDataSo interface.
 */
export function instanceOfPreviewDataSo(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "reference" in value;
    isInstance = isInstance && "rowNumber" in value;
    isInstance = isInstance && "filterId" in value;
    isInstance = isInstance && "data" in value;
    isInstance = isInstance && "deleted" in value;

    return isInstance;
}

export function PreviewDataSoFromJSON(json: any): PreviewDataSo {
    return PreviewDataSoFromJSONTyped(json, false);
}

export function PreviewDataSoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PreviewDataSo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'reference': json['reference'],
        'rowNumber': json['rowNumber'],
        'filterId': json['filterId'],
        'data': ((json['data'] as Array<any>).map(RowAttributeSoFromJSON)),
        'deleted': json['deleted'],
        'generated': !exists(json, 'generated') ? undefined : json['generated'],
    };
}

export function PreviewDataSoToJSON(value?: PreviewDataSo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'reference': value.reference,
        'rowNumber': value.rowNumber,
        'filterId': value.filterId,
        'data': ((value.data as Array<any>).map(RowAttributeSoToJSON)),
        'deleted': value.deleted,
        'generated': value.generated,
    };
}

