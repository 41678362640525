/* tslint:disable */
/* eslint-disable */
/**
 * Data import metadata
 * Administration of metadata for import
 *
 * The version of the OpenAPI document: 1.0
 * Contact: schvarcz@bb.microcomp.sk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RowAttributeTransformationDefinitionItemRequest } from './RowAttributeTransformationDefinitionItemRequest';
import {
    RowAttributeTransformationDefinitionItemRequestFromJSON,
    RowAttributeTransformationDefinitionItemRequestFromJSONTyped,
    RowAttributeTransformationDefinitionItemRequestToJSON,
} from './RowAttributeTransformationDefinitionItemRequest';

/**
 * 
 * @export
 * @interface RowAttributeTransformationDefinitionRequest
 */
export interface RowAttributeTransformationDefinitionRequest {
    /**
     * 
     * @type {string}
     * @memberof RowAttributeTransformationDefinitionRequest
     */
    rowAttributeTransformationType?: RowAttributeTransformationDefinitionRequestRowAttributeTransformationTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof RowAttributeTransformationDefinitionRequest
     */
    referenceCode?: string;
    /**
     * 
     * @type {number}
     * @memberof RowAttributeTransformationDefinitionRequest
     */
    sortNumber?: number;
    /**
     * 
     * @type {string}
     * @memberof RowAttributeTransformationDefinitionRequest
     */
    outputItemCode?: string;
    /**
     * 
     * @type {string}
     * @memberof RowAttributeTransformationDefinitionRequest
     */
    outputItemReferenceAttributeType?: RowAttributeTransformationDefinitionRequestOutputItemReferenceAttributeTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof RowAttributeTransformationDefinitionRequest
     */
    processMultipleValues?: boolean;
    /**
     * 
     * @type {Array<RowAttributeTransformationDefinitionItemRequest>}
     * @memberof RowAttributeTransformationDefinitionRequest
     */
    rowAttributeTransformationDefinitionItems?: Array<RowAttributeTransformationDefinitionItemRequest>;
}


/**
 * @export
 */
export const RowAttributeTransformationDefinitionRequestRowAttributeTransformationTypeEnum = {
    ToNumber: 'TO_NUMBER',
    CreateDateTime: 'CREATE_DATE_TIME',
    FindMonPointByCode: 'FIND_MON_POINT_BY_CODE',
    FindUnderDetectionLimit: 'FIND_UNDER_DETECTION_LIMIT',
    FindOverDetectionLimit: 'FIND_OVER_DETECTION_LIMIT',
    UnderDetectionLimitCorrectionBasedOnCondition: 'UNDER_DETECTION_LIMIT_CORRECTION_BASED_ON_CONDITION',
    CreateDate: 'CREATE_DATE',
    ValueOrAbs: 'VALUE_OR_ABS',
    GetDataId: 'GET_DATA_ID',
    GenerateId: 'GENERATE_ID',
    SetConstant: 'SET_CONSTANT',
    FindLaboratoryCode: 'FIND_LABORATORY_CODE',
    FindCodeitemCode: 'FIND_CODEITEM_CODE',
    ActualDatetime: 'ACTUAL_DATETIME',
    ActualLoggedUsername: 'ACTUAL_LOGGED_USERNAME',
    GenerateIdAndCached: 'GENERATE_ID_AND_CACHED',
    Decode: 'DECODE',
    DecodeFloodingMonth: 'DECODE_FLOODING_MONTH',
    CorrectionDeterminant: 'CORRECTION_DETERMINANT',
    CorrectionNull: 'CORRECTION_NULL',
    CorrectionMethodId: 'CORRECTION_METHOD_ID',
    CorrectionValueNum: 'CORRECTION_VALUE_NUM',
    CorrectionValueStr: 'CORRECTION_VALUE_STR',
    CorrectionValueUnit: 'CORRECTION_VALUE_UNIT',
    PartsToDateTime: 'PARTS_TO_DATE_TIME',
    ActualDate: 'ACTUAL_DATE',
    GetYearFromDocument: 'GET_YEAR_FROM_DOCUMENT',
    FlowFromVolume: 'FLOW_FROM_VOLUME',
    CorrectionSampleCode: 'CORRECTION_SAMPLE_CODE',
    Substr: 'SUBSTR',
    RegexReplace: 'REGEX_REPLACE',
    RegexIfMatch: 'REGEX_IF_MATCH',
    RegexCaptureGroup: 'REGEX_CAPTURE_GROUP',
    RemoveLeadingZeros: 'REMOVE_LEADING_ZEROS',
    PartsToDate: 'PARTS_TO_DATE',
    GetSeasonCode: 'GET_SEASON_CODE',
    AttributeFromArray: 'ATTRIBUTE_FROM_ARRAY',
    FindCodeitemAttribute: 'FIND_CODEITEM_ATTRIBUTE',
    GetFromCache: 'GET_FROM_CACHE',
    SetToCache: 'SET_TO_CACHE',
    FindMonPointByBalanceCode: 'FIND_MON_POINT_BY_BALANCE_CODE',
    GetSeasonCodeFromYear: 'GET_SEASON_CODE_FROM_YEAR',
    CreateTime: 'CREATE_TIME',
    FindDataRegistryRecord: 'FIND_DATA_REGISTRY_RECORD',
    VolumeFromFlow: 'VOLUME_FROM_FLOW',
    ToBit: 'TO_BIT',
    FindInstallationByMonPointCode: 'FIND_INSTALLATION_BY_MON_POINT_CODE',
    FindOperatorByMonPointCode: 'FIND_OPERATOR_BY_MON_POINT_CODE',
    FindAnalyticalMethodByInternationalCode: 'FIND_ANALYTICAL_METHOD_BY_INTERNATIONAL_CODE',
    FindDeterminantByInternationalCode: 'FIND_DETERMINANT_BY_INTERNATIONAL_CODE',
    JoinStrings: 'JOIN_STRINGS',
    AddLeadingZeros: 'ADD_LEADING_ZEROS',
    ConvertToMultipleValues: 'CONVERT_TO_MULTIPLE_VALUES',
    Sum: 'SUM',
    Quotient: 'QUOTIENT',
    SetOrGetFromCacheByCondition: 'SET_OR_GET_FROM_CACHE_BY_CONDITION',
    ZeroDate: 'ZERO_DATE',
    NullToPresetValue: 'NULL_TO_PRESET_VALUE',
    EnumTransformation: 'ENUM_TRANSFORMATION',
    MonthDetermination: 'MONTH_DETERMINATION',
    FindDeterminantId: 'FIND_DETERMINANT_ID',
    FindMethodId: 'FIND_METHOD_ID',
    ValueCorrectionBasedOnCondition: 'VALUE_CORRECTION_BASED_ON_CONDITION',
    SeasonDetermination: 'SEASON_DETERMINATION',
    GetName: 'GET_NAME',
    GetUnit: 'GET_UNIT',
    ReplaceColonToDot: 'REPLACE_COLON_TO_DOT',
    FindValueControl: 'FIND_VALUE_CONTROL',
    FindDeterminantMaxValue: 'FIND_DETERMINANT_MAX_VALUE',
    FindUnderDetectionLimitOnlyFromValueControlAndValue: 'FIND_UNDER_DETECTION_LIMIT_ONLY_FROM_VALUE_CONTROL_AND_VALUE',
    FixSupplierCode: 'FIX_SUPPLIER_CODE',
    ToNumberNullToZero: 'TO_NUMBER_NULL_TO_ZERO',
    GetIdFromCache: 'GET_ID_FROM_CACHE'
} as const;
export type RowAttributeTransformationDefinitionRequestRowAttributeTransformationTypeEnum = typeof RowAttributeTransformationDefinitionRequestRowAttributeTransformationTypeEnum[keyof typeof RowAttributeTransformationDefinitionRequestRowAttributeTransformationTypeEnum];

/**
 * @export
 */
export const RowAttributeTransformationDefinitionRequestOutputItemReferenceAttributeTypeEnum = {
    ShmuId: 'SHMU_ID',
    Date: 'DATE',
    CatalogCode: 'CATALOG_CODE',
    MonPointType: 'MON_POINT_TYPE',
    MonitoringType: 'MONITORING_TYPE',
    ValueControl: 'VALUE_CONTROL',
    ValueNumAux: 'VALUE_NUM_AUX',
    DetId: 'DET_ID',
    LaboratoryId: 'LABORATORY_ID',
    DateStr: 'DATE_STR',
    DetCode: 'DET_CODE',
    DetValueMax: 'DET_VALUE_MAX',
    SampleCode: 'SAMPLE_CODE',
    ItemToDecode: 'ITEM_TO_DECODE',
    DecodeMap: 'DECODE_MAP',
    DecodeDefault: 'DECODE_DEFAULT',
    DateFormat: 'DATE_FORMAT',
    SeasonYearShift: 'SEASON_YEAR_SHIFT',
    SeasonDefault: 'SEASON_DEFAULT',
    Time: 'TIME',
    MethodId: 'METHOD_ID',
    MethodCode: 'METHOD_CODE',
    StateOfValue: 'STATE_OF_VALUE',
    Value: 'VALUE',
    NullValue: 'NULL_VALUE',
    ValueUnit: 'VALUE_UNIT',
    ReferenceAttributeCode: 'REFERENCE_ATTRIBUTE_CODE',
    Day: 'DAY',
    Month: 'MONTH',
    Year: 'YEAR',
    Hour: 'HOUR',
    Minute: 'MINUTE',
    Volume: 'VOLUME',
    Scale: 'SCALE',
    Shift: 'SHIFT',
    LaboratoryCode: 'LABORATORY_CODE',
    StateOfValueReference: 'STATE_OF_VALUE_REFERENCE',
    BeginIndex: 'BEGIN_INDEX',
    EndIndex: 'END_INDEX',
    Regex: 'REGEX',
    Source: 'SOURCE',
    Replacement: 'REPLACEMENT',
    ResultIfMatch: 'RESULT_IF_MATCH',
    ResultIfNoMatch: 'RESULT_IF_NO_MATCH',
    YearToDetermineCentury: 'YEAR_TO_DETERMINE_CENTURY',
    MonPointId: 'MON_POINT_ID',
    CodelistItemCode: 'CODELIST_ITEM_CODE',
    CodelistItemCodeConstant: 'CODELIST_ITEM_CODE_CONSTANT',
    SourceDate: 'SOURCE_DATE',
    SourceTime: 'SOURCE_TIME',
    CodelistItemAttribute: 'CODELIST_ITEM_ATTRIBUTE',
    ConstantValue: 'CONSTANT_VALUE',
    DateSource: 'DATE_SOURCE',
    ConstantSource: 'CONSTANT_SOURCE',
    ArraySource: 'ARRAY_SOURCE',
    NumberSource: 'NUMBER_SOURCE',
    Result: 'RESULT',
    DecodeDefaultConstant: 'DECODE_DEFAULT_CONSTANT',
    CorrectionNullSource: 'CORRECTION_NULL_SOURCE',
    CorrectionDeterminantSource: 'CORRECTION_DETERMINANT_SOURCE',
    CorrectionUnderDetectionLimitSource: 'CORRECTION_UNDER_DETECTION_LIMIT_SOURCE',
    CorrectionValueStrSource: 'CORRECTION_VALUE_STR_SOURCE',
    CorrectionValueNumSource: 'CORRECTION_VALUE_NUM_SOURCE',
    GenerateIdSource: 'GENERATE_ID_SOURCE',
    RegexReplaceSource: 'REGEX_REPLACE_SOURCE',
    RegexReplaceRegex: 'REGEX_REPLACE_REGEX',
    RegexReplaceReplacement: 'REGEX_REPLACE_REPLACEMENT',
    DecodeFloodingMonthSource: 'DECODE_FLOODING_MONTH_SOURCE',
    GetFromCacheKey: 'GET_FROM_CACHE_KEY',
    GetFromCacheDataCode: 'GET_FROM_CACHE_DATA_CODE',
    SetToCacheKey: 'SET_TO_CACHE_KEY',
    SetToCacheDataCode: 'SET_TO_CACHE_DATA_CODE',
    AdditionalAttributeName: 'ADDITIONAL_ATTRIBUTE_NAME',
    AdditionalAttributeValue: 'ADDITIONAL_ATTRIBUTE_VALUE',
    SubstringSource: 'SUBSTRING_SOURCE',
    SubstringBeginIndex: 'SUBSTRING_BEGIN_INDEX',
    SubstringEndIndex: 'SUBSTRING_END_INDEX',
    CorrectionSampleCodeSource: 'CORRECTION_SAMPLE_CODE_SOURCE',
    CorrectionSampleCodeShift: 'CORRECTION_SAMPLE_CODE_SHIFT',
    CorrectionSampleCodeSupplier: 'CORRECTION_SAMPLE_CODE_SUPPLIER',
    FlowFromVolumeSource: 'FLOW_FROM_VOLUME_SOURCE',
    FlowFromVolumeMonth: 'FLOW_FROM_VOLUME_MONTH',
    FlowFromVolumeYear: 'FLOW_FROM_VOLUME_YEAR',
    FlowFromVolumeScale: 'FLOW_FROM_VOLUME_SCALE',
    GetDataIdShift: 'GET_DATA_ID_SHIFT',
    MonPointBalanceCode: 'MON_POINT_BALANCE_CODE',
    SeasonCodeFromYearSource: 'SEASON_CODE_FROM_YEAR_SOURCE',
    TimeFormat: 'TIME_FORMAT',
    NumberNullValue: 'NUMBER_NULL_VALUE',
    DataRegistryProductName: 'DATA_REGISTRY_PRODUCT_NAME',
    DataRegistryResultAttributeCode: 'DATA_REGISTRY_RESULT_ATTRIBUTE_CODE',
    DataRegistryVariableName1: 'DATA_REGISTRY_VARIABLE_NAME_1',
    DataRegistryVariableName2: 'DATA_REGISTRY_VARIABLE_NAME_2',
    DataRegistryVariableName3: 'DATA_REGISTRY_VARIABLE_NAME_3',
    DataRegistryVariableName4: 'DATA_REGISTRY_VARIABLE_NAME_4',
    DataRegistryVariableName5: 'DATA_REGISTRY_VARIABLE_NAME_5',
    DataRegistryVariableName6: 'DATA_REGISTRY_VARIABLE_NAME_6',
    DataRegistryVariableName7: 'DATA_REGISTRY_VARIABLE_NAME_7',
    DataRegistryVariableName8: 'DATA_REGISTRY_VARIABLE_NAME_8',
    DataRegistryVariableName9: 'DATA_REGISTRY_VARIABLE_NAME_9',
    DataRegistryVariableName10: 'DATA_REGISTRY_VARIABLE_NAME_10',
    DataRegistryVariableValue1: 'DATA_REGISTRY_VARIABLE_VALUE_1',
    DataRegistryVariableValue2: 'DATA_REGISTRY_VARIABLE_VALUE_2',
    DataRegistryVariableValue3: 'DATA_REGISTRY_VARIABLE_VALUE_3',
    DataRegistryVariableValue4: 'DATA_REGISTRY_VARIABLE_VALUE_4',
    DataRegistryVariableValue5: 'DATA_REGISTRY_VARIABLE_VALUE_5',
    DataRegistryVariableValue6: 'DATA_REGISTRY_VARIABLE_VALUE_6',
    DataRegistryVariableValue7: 'DATA_REGISTRY_VARIABLE_VALUE_7',
    DataRegistryVariableValue8: 'DATA_REGISTRY_VARIABLE_VALUE_8',
    DataRegistryVariableValue9: 'DATA_REGISTRY_VARIABLE_VALUE_9',
    DataRegistryVariableValue10: 'DATA_REGISTRY_VARIABLE_VALUE_10',
    DataRegistryVariableOperator1: 'DATA_REGISTRY_VARIABLE_OPERATOR_1',
    DataRegistryVariableOperator2: 'DATA_REGISTRY_VARIABLE_OPERATOR_2',
    DataRegistryVariableOperator3: 'DATA_REGISTRY_VARIABLE_OPERATOR_3',
    DataRegistryVariableOperator4: 'DATA_REGISTRY_VARIABLE_OPERATOR_4',
    DataRegistryVariableOperator5: 'DATA_REGISTRY_VARIABLE_OPERATOR_5',
    DataRegistryVariableOperator6: 'DATA_REGISTRY_VARIABLE_OPERATOR_6',
    DataRegistryVariableOperator7: 'DATA_REGISTRY_VARIABLE_OPERATOR_7',
    DataRegistryVariableOperator8: 'DATA_REGISTRY_VARIABLE_OPERATOR_8',
    DataRegistryVariableOperator9: 'DATA_REGISTRY_VARIABLE_OPERATOR_9',
    DataRegistryVariableOperator10: 'DATA_REGISTRY_VARIABLE_OPERATOR_10',
    VolumeFromFlowSource: 'VOLUME_FROM_FLOW_SOURCE',
    VolumeFromFlowMonth: 'VOLUME_FROM_FLOW_MONTH',
    VolumeFromFlowYear: 'VOLUME_FROM_FLOW_YEAR',
    VolumeFromFlowScale: 'VOLUME_FROM_FLOW_SCALE',
    BitSource: 'BIT_SOURCE',
    PositionType: 'POSITION_TYPE',
    RegexReplaceIsPartOfString: 'REGEX_REPLACE_IS_PART_OF_STRING',
    JoinStringSource: 'JOIN_STRING_SOURCE',
    MethodicMethodCodeInt: 'METHODIC_METHOD_CODE_INT',
    MethodicDeterminantCodeInt: 'METHODIC_DETERMINANT_CODE_INT',
    AddLeadingZerosSource: 'ADD_LEADING_ZEROS_SOURCE',
    AddLeadingZerosPrecision: 'ADD_LEADING_ZEROS_PRECISION',
    ConvertToMultipleValuesSource: 'CONVERT_TO_MULTIPLE_VALUES_SOURCE',
    SumSource: 'SUM_SOURCE',
    Dividend: 'DIVIDEND',
    Divisor: 'DIVISOR',
    CachedData: 'CACHED_DATA',
    CacheKey: 'CACHE_KEY',
    Condition: 'CONDITION'
} as const;
export type RowAttributeTransformationDefinitionRequestOutputItemReferenceAttributeTypeEnum = typeof RowAttributeTransformationDefinitionRequestOutputItemReferenceAttributeTypeEnum[keyof typeof RowAttributeTransformationDefinitionRequestOutputItemReferenceAttributeTypeEnum];


/**
 * Check if a given object implements the RowAttributeTransformationDefinitionRequest interface.
 */
export function instanceOfRowAttributeTransformationDefinitionRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RowAttributeTransformationDefinitionRequestFromJSON(json: any): RowAttributeTransformationDefinitionRequest {
    return RowAttributeTransformationDefinitionRequestFromJSONTyped(json, false);
}

export function RowAttributeTransformationDefinitionRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): RowAttributeTransformationDefinitionRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'rowAttributeTransformationType': !exists(json, 'rowAttributeTransformationType') ? undefined : json['rowAttributeTransformationType'],
        'referenceCode': !exists(json, 'referenceCode') ? undefined : json['referenceCode'],
        'sortNumber': !exists(json, 'sortNumber') ? undefined : json['sortNumber'],
        'outputItemCode': !exists(json, 'outputItemCode') ? undefined : json['outputItemCode'],
        'outputItemReferenceAttributeType': !exists(json, 'outputItemReferenceAttributeType') ? undefined : json['outputItemReferenceAttributeType'],
        'processMultipleValues': !exists(json, 'processMultipleValues') ? undefined : json['processMultipleValues'],
        'rowAttributeTransformationDefinitionItems': !exists(json, 'rowAttributeTransformationDefinitionItems') ? undefined : ((json['rowAttributeTransformationDefinitionItems'] as Array<any>).map(RowAttributeTransformationDefinitionItemRequestFromJSON)),
    };
}

export function RowAttributeTransformationDefinitionRequestToJSON(value?: RowAttributeTransformationDefinitionRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'rowAttributeTransformationType': value.rowAttributeTransformationType,
        'referenceCode': value.referenceCode,
        'sortNumber': value.sortNumber,
        'outputItemCode': value.outputItemCode,
        'outputItemReferenceAttributeType': value.outputItemReferenceAttributeType,
        'processMultipleValues': value.processMultipleValues,
        'rowAttributeTransformationDefinitionItems': value.rowAttributeTransformationDefinitionItems === undefined ? undefined : ((value.rowAttributeTransformationDefinitionItems as Array<any>).map(RowAttributeTransformationDefinitionItemRequestToJSON)),
    };
}

