/* tslint:disable */
/* eslint-disable */
/**
 * Data import
 * Modul for importing data to Data Registry.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: schvarcz@bb.microcomp.sk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ValidationReportResponse
 */
export interface ValidationReportResponse {
    /**
     * 
     * @type {string}
     * @memberof ValidationReportResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ValidationReportResponse
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ValidationReportResponse
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof ValidationReportResponse
     */
    value?: string;
    /**
     * 
     * @type {number}
     * @memberof ValidationReportResponse
     */
    positionRow?: number;
    /**
     * 
     * @type {number}
     * @memberof ValidationReportResponse
     */
    positionColumn?: number;
    /**
     * 
     * @type {string}
     * @memberof ValidationReportResponse
     */
    controlCriticalityType?: ValidationReportResponseControlCriticalityTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ValidationReportResponse
     */
    errorMessage?: string;
}


/**
 * @export
 */
export const ValidationReportResponseControlCriticalityTypeEnum = {
    Critical: 'CRITICAL',
    NonCritical: 'NON_CRITICAL',
    Warning: 'WARNING'
} as const;
export type ValidationReportResponseControlCriticalityTypeEnum = typeof ValidationReportResponseControlCriticalityTypeEnum[keyof typeof ValidationReportResponseControlCriticalityTypeEnum];


/**
 * Check if a given object implements the ValidationReportResponse interface.
 */
export function instanceOfValidationReportResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ValidationReportResponseFromJSON(json: any): ValidationReportResponse {
    return ValidationReportResponseFromJSONTyped(json, false);
}

export function ValidationReportResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ValidationReportResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'value': !exists(json, 'value') ? undefined : json['value'],
        'positionRow': !exists(json, 'positionRow') ? undefined : json['positionRow'],
        'positionColumn': !exists(json, 'positionColumn') ? undefined : json['positionColumn'],
        'controlCriticalityType': !exists(json, 'controlCriticalityType') ? undefined : json['controlCriticalityType'],
        'errorMessage': !exists(json, 'errorMessage') ? undefined : json['errorMessage'],
    };
}

export function ValidationReportResponseToJSON(value?: ValidationReportResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'code': value.code,
        'value': value.value,
        'positionRow': value.positionRow,
        'positionColumn': value.positionColumn,
        'controlCriticalityType': value.controlCriticalityType,
        'errorMessage': value.errorMessage,
    };
}

