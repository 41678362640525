/* tslint:disable */
/* eslint-disable */
/**
 * Overview API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BatchUpdateValuesSo,
  ColumnHistory,
  DataFetchSo,
  DataPageRequestSo,
  EndpointExceptionBody,
  ExportPreviewSo,
  ExportSo,
  FetchResultSo,
  FetchSearchSo,
  FetchSo,
  FetchStatusSo,
  NoteSo,
  PageDataFetchSo,
  PagePreviewDataSo,
  PreviewDataCreateSo,
  PreviewDataSo,
  PreviewDataUpdateSo,
  StatisticsSo,
  TimeSeriesStatistics,
} from '../models/index';
import {
    BatchUpdateValuesSoFromJSON,
    BatchUpdateValuesSoToJSON,
    ColumnHistoryFromJSON,
    ColumnHistoryToJSON,
    DataFetchSoFromJSON,
    DataFetchSoToJSON,
    DataPageRequestSoFromJSON,
    DataPageRequestSoToJSON,
    EndpointExceptionBodyFromJSON,
    EndpointExceptionBodyToJSON,
    ExportPreviewSoFromJSON,
    ExportPreviewSoToJSON,
    ExportSoFromJSON,
    ExportSoToJSON,
    FetchResultSoFromJSON,
    FetchResultSoToJSON,
    FetchSearchSoFromJSON,
    FetchSearchSoToJSON,
    FetchSoFromJSON,
    FetchSoToJSON,
    FetchStatusSoFromJSON,
    FetchStatusSoToJSON,
    NoteSoFromJSON,
    NoteSoToJSON,
    PageDataFetchSoFromJSON,
    PageDataFetchSoToJSON,
    PagePreviewDataSoFromJSON,
    PagePreviewDataSoToJSON,
    PreviewDataCreateSoFromJSON,
    PreviewDataCreateSoToJSON,
    PreviewDataSoFromJSON,
    PreviewDataSoToJSON,
    PreviewDataUpdateSoFromJSON,
    PreviewDataUpdateSoToJSON,
    StatisticsSoFromJSON,
    StatisticsSoToJSON,
    TimeSeriesStatisticsFromJSON,
    TimeSeriesStatisticsToJSON,
} from '../models/index';

export interface PreviewDataApiDeleteRequest {
    previewDataId: number;
}

export interface PreviewDataApiCreateNoteRequest {
    previewDataId: number;
    columnReference: string;
    noteSo: NoteSo;
}

export interface PreviewDataApiCreatePreviewDataRequest {
    previewDataCreateSo: PreviewDataCreateSo;
}

export interface PreviewDataApiDeleteAllRequest {
    requestBody: Set<number>;
}

export interface PreviewDataApiDeleteAllByReferenceRequest {
    previewReference: string;
    filterId: number;
}

export interface PreviewDataApiDownloadCsvRequest {
    uuid: string;
}

export interface PreviewDataApiDownloadXlsxRequest {
    uuid: string;
}

export interface PreviewDataApiExportCsvRequest {
    exportPreviewSo: ExportPreviewSo;
}

export interface PreviewDataApiExportXlsxRequest {
    exportPreviewSo: ExportPreviewSo;
}

export interface PreviewDataApiFetchRequest {
    fetchSo?: FetchSo;
}

export interface PreviewDataApiFetchByIdRequest {
    viewId: number;
}

export interface PreviewDataApiFetchGetDataRequest {
    dataPageRequestSo?: DataPageRequestSo;
}

export interface PreviewDataApiFetchListRequest {
    fetchSearchSo: FetchSearchSo;
}

export interface PreviewDataApiFetchStatusRequest {
    previewReference: string;
    filterId: number;
}

export interface PreviewDataApiFetchSubviewDataRequest {
    previewDataId: number;
    columnReference: string;
}

export interface PreviewDataApiGetExportStatusRequest {
    uuid: string;
}

export interface PreviewDataApiGetHistoryRequest {
    viewReference: string;
    viewId: number;
    columnKey: string;
}

export interface PreviewDataApiGetPreviewCountByCriteriaRequest {
    statisticsSo: StatisticsSo;
}

export interface PreviewDataApiGetStatisticsRequest {
    statisticsSo: StatisticsSo;
}

export interface PreviewDataApiRetryFetchRequest {
    previewDataFetchId: number;
}

export interface PreviewDataApiUndeleteRequest {
    previewDataId: number;
}

export interface PreviewDataApiUndeleteAllRequest {
    requestBody: Set<number>;
}

export interface PreviewDataApiUpdatePreviewRequest {
    previewId: number;
    columnReference: string;
    previewDataUpdateSo: PreviewDataUpdateSo;
}

export interface PreviewDataApiUpdateValuesInColumnRequest {
    columnReference: string;
    batchUpdateValuesSo: BatchUpdateValuesSo;
}

/**
 * 
 */
export class PreviewDataApi extends runtime.BaseAPI {

    /**
     * Delete preview data
     */
    async _deleteRaw(requestParameters: PreviewDataApiDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.previewDataId === null || requestParameters.previewDataId === undefined) {
            throw new runtime.RequiredError('previewDataId','Required parameter requestParameters.previewDataId was null or undefined when calling _delete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/preview-data/{preview-data-id}`.replace(`{${"preview-data-id"}}`, encodeURIComponent(String(requestParameters.previewDataId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete preview data
     */
    async _delete(previewDataId: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this._deleteRaw({ previewDataId: previewDataId }, initOverrides);
    }

    /**
     * Creates note for given column
     */
    async createNoteRaw(requestParameters: PreviewDataApiCreateNoteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.previewDataId === null || requestParameters.previewDataId === undefined) {
            throw new runtime.RequiredError('previewDataId','Required parameter requestParameters.previewDataId was null or undefined when calling createNote.');
        }

        if (requestParameters.columnReference === null || requestParameters.columnReference === undefined) {
            throw new runtime.RequiredError('columnReference','Required parameter requestParameters.columnReference was null or undefined when calling createNote.');
        }

        if (requestParameters.noteSo === null || requestParameters.noteSo === undefined) {
            throw new runtime.RequiredError('noteSo','Required parameter requestParameters.noteSo was null or undefined when calling createNote.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/preview-data/{preview-data-id}/{column-reference}/note`.replace(`{${"preview-data-id"}}`, encodeURIComponent(String(requestParameters.previewDataId))).replace(`{${"column-reference"}}`, encodeURIComponent(String(requestParameters.columnReference))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NoteSoToJSON(requestParameters.noteSo),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Creates note for given column
     */
    async createNote(previewDataId: number, columnReference: string, noteSo: NoteSo, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createNoteRaw({ previewDataId: previewDataId, columnReference: columnReference, noteSo: noteSo }, initOverrides);
    }

    /**
     * Create a new Preview Data
     */
    async createPreviewDataRaw(requestParameters: PreviewDataApiCreatePreviewDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PreviewDataSo>> {
        if (requestParameters.previewDataCreateSo === null || requestParameters.previewDataCreateSo === undefined) {
            throw new runtime.RequiredError('previewDataCreateSo','Required parameter requestParameters.previewDataCreateSo was null or undefined when calling createPreviewData.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/preview-data/create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PreviewDataCreateSoToJSON(requestParameters.previewDataCreateSo),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PreviewDataSoFromJSON(jsonValue));
    }

    /**
     * Create a new Preview Data
     */
    async createPreviewData(previewDataCreateSo: PreviewDataCreateSo, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PreviewDataSo> {
        const response = await this.createPreviewDataRaw({ previewDataCreateSo: previewDataCreateSo }, initOverrides);
        return await response.value();
    }

    /**
     * Delete preview data (batch)
     */
    async deleteAllRaw(requestParameters: PreviewDataApiDeleteAllRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
            throw new runtime.RequiredError('requestBody','Required parameter requestParameters.requestBody was null or undefined when calling deleteAll.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/preview-data/delete`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete preview data (batch)
     */
    async deleteAll(requestBody: Set<number>, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteAllRaw({ requestBody: requestBody }, initOverrides);
    }

    /**
     * Delete all preview data by reference and filterId
     */
    async deleteAllByReferenceRaw(requestParameters: PreviewDataApiDeleteAllByReferenceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.previewReference === null || requestParameters.previewReference === undefined) {
            throw new runtime.RequiredError('previewReference','Required parameter requestParameters.previewReference was null or undefined when calling deleteAllByReference.');
        }

        if (requestParameters.filterId === null || requestParameters.filterId === undefined) {
            throw new runtime.RequiredError('filterId','Required parameter requestParameters.filterId was null or undefined when calling deleteAllByReference.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/preview-data/delete-all/{preview-reference}/{filter-id}`.replace(`{${"preview-reference"}}`, encodeURIComponent(String(requestParameters.previewReference))).replace(`{${"filter-id"}}`, encodeURIComponent(String(requestParameters.filterId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete all preview data by reference and filterId
     */
    async deleteAllByReference(previewReference: string, filterId: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteAllByReferenceRaw({ previewReference: previewReference, filterId: filterId }, initOverrides);
    }

    /**
     * Download data by UUID in CSV format
     */
    async downloadCsvRaw(requestParameters: PreviewDataApiDownloadCsvRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling downloadCsv.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/preview-data/export/{uuid}/csv`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Download data by UUID in CSV format
     */
    async downloadCsv(uuid: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.downloadCsvRaw({ uuid: uuid }, initOverrides);
        return await response.value();
    }

    /**
     * Download data by UUID in Xlsx format
     */
    async downloadXlsxRaw(requestParameters: PreviewDataApiDownloadXlsxRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling downloadXlsx.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/preview-data/export/{uuid}/xlsx`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Download data by UUID in Xlsx format
     */
    async downloadXlsx(uuid: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.downloadXlsxRaw({ uuid: uuid }, initOverrides);
        return await response.value();
    }

    /**
     * Export data by preview reference with filter in CSV format
     */
    async exportCsvRaw(requestParameters: PreviewDataApiExportCsvRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.exportPreviewSo === null || requestParameters.exportPreviewSo === undefined) {
            throw new runtime.RequiredError('exportPreviewSo','Required parameter requestParameters.exportPreviewSo was null or undefined when calling exportCsv.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/preview-data/export/csv`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ExportPreviewSoToJSON(requestParameters.exportPreviewSo),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Export data by preview reference with filter in CSV format
     */
    async exportCsv(exportPreviewSo: ExportPreviewSo, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.exportCsvRaw({ exportPreviewSo: exportPreviewSo }, initOverrides);
        return await response.value();
    }

    /**
     * Export data by preview reference with filter in XLSX format
     */
    async exportXlsxRaw(requestParameters: PreviewDataApiExportXlsxRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.exportPreviewSo === null || requestParameters.exportPreviewSo === undefined) {
            throw new runtime.RequiredError('exportPreviewSo','Required parameter requestParameters.exportPreviewSo was null or undefined when calling exportXlsx.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/preview-data/export/xlsx`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ExportPreviewSoToJSON(requestParameters.exportPreviewSo),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Export data by preview reference with filter in XLSX format
     */
    async exportXlsx(exportPreviewSo: ExportPreviewSo, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.exportXlsxRaw({ exportPreviewSo: exportPreviewSo }, initOverrides);
        return await response.value();
    }

    /**
     * Fetch preview data. Get data if downloaded, otherwise, get fetch status
     */
    async fetchRaw(requestParameters: PreviewDataApiFetchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FetchResultSo>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/preview-data/fetch`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FetchSoToJSON(requestParameters.fetchSo),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FetchResultSoFromJSON(jsonValue));
    }

    /**
     * Fetch preview data. Get data if downloaded, otherwise, get fetch status
     */
    async fetch(fetchSo?: FetchSo, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FetchResultSo> {
        const response = await this.fetchRaw({ fetchSo: fetchSo }, initOverrides);
        return await response.value();
    }

    /**
     * Fetch preview data. Get data if downloaded, otherwise, get fetch status
     */
    async fetchByIdRaw(requestParameters: PreviewDataApiFetchByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PreviewDataSo>> {
        if (requestParameters.viewId === null || requestParameters.viewId === undefined) {
            throw new runtime.RequiredError('viewId','Required parameter requestParameters.viewId was null or undefined when calling fetchById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/preview-data/fetch/{view-id}`.replace(`{${"view-id"}}`, encodeURIComponent(String(requestParameters.viewId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PreviewDataSoFromJSON(jsonValue));
    }

    /**
     * Fetch preview data. Get data if downloaded, otherwise, get fetch status
     */
    async fetchById(viewId: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PreviewDataSo> {
        const response = await this.fetchByIdRaw({ viewId: viewId }, initOverrides);
        return await response.value();
    }

    /**
     * Fetch preview data. Get data if downloaded, otherwise, get fetch status
     */
    async fetchGetDataRaw(requestParameters: PreviewDataApiFetchGetDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PagePreviewDataSo>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/preview-data/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DataPageRequestSoToJSON(requestParameters.dataPageRequestSo),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PagePreviewDataSoFromJSON(jsonValue));
    }

    /**
     * Fetch preview data. Get data if downloaded, otherwise, get fetch status
     */
    async fetchGetData(dataPageRequestSo?: DataPageRequestSo, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PagePreviewDataSo> {
        const response = await this.fetchGetDataRaw({ dataPageRequestSo: dataPageRequestSo }, initOverrides);
        return await response.value();
    }

    /**
     * Get preview data
     */
    async fetchListRaw(requestParameters: PreviewDataApiFetchListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageDataFetchSo>> {
        if (requestParameters.fetchSearchSo === null || requestParameters.fetchSearchSo === undefined) {
            throw new runtime.RequiredError('fetchSearchSo','Required parameter requestParameters.fetchSearchSo was null or undefined when calling fetchList.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/preview-data/fetch/list`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FetchSearchSoToJSON(requestParameters.fetchSearchSo),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageDataFetchSoFromJSON(jsonValue));
    }

    /**
     * Get preview data
     */
    async fetchList(fetchSearchSo: FetchSearchSo, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageDataFetchSo> {
        const response = await this.fetchListRaw({ fetchSearchSo: fetchSearchSo }, initOverrides);
        return await response.value();
    }

    /**
     * Get preview data
     */
    async fetchStatusRaw(requestParameters: PreviewDataApiFetchStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FetchStatusSo>> {
        if (requestParameters.previewReference === null || requestParameters.previewReference === undefined) {
            throw new runtime.RequiredError('previewReference','Required parameter requestParameters.previewReference was null or undefined when calling fetchStatus.');
        }

        if (requestParameters.filterId === null || requestParameters.filterId === undefined) {
            throw new runtime.RequiredError('filterId','Required parameter requestParameters.filterId was null or undefined when calling fetchStatus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/preview-data/fetch/{preview-reference}/{filter-id}/status`.replace(`{${"preview-reference"}}`, encodeURIComponent(String(requestParameters.previewReference))).replace(`{${"filter-id"}}`, encodeURIComponent(String(requestParameters.filterId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FetchStatusSoFromJSON(jsonValue));
    }

    /**
     * Get preview data
     */
    async fetchStatus(previewReference: string, filterId: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FetchStatusSo> {
        const response = await this.fetchStatusRaw({ previewReference: previewReference, filterId: filterId }, initOverrides);
        return await response.value();
    }

    /**
     * Fetch subview data
     */
    async fetchSubviewDataRaw(requestParameters: PreviewDataApiFetchSubviewDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DataFetchSo>> {
        if (requestParameters.previewDataId === null || requestParameters.previewDataId === undefined) {
            throw new runtime.RequiredError('previewDataId','Required parameter requestParameters.previewDataId was null or undefined when calling fetchSubviewData.');
        }

        if (requestParameters.columnReference === null || requestParameters.columnReference === undefined) {
            throw new runtime.RequiredError('columnReference','Required parameter requestParameters.columnReference was null or undefined when calling fetchSubviewData.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/preview-data/fetch-subview/{previewDataId}/{columnReference}`.replace(`{${"previewDataId"}}`, encodeURIComponent(String(requestParameters.previewDataId))).replace(`{${"columnReference"}}`, encodeURIComponent(String(requestParameters.columnReference))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DataFetchSoFromJSON(jsonValue));
    }

    /**
     * Fetch subview data
     */
    async fetchSubviewData(previewDataId: number, columnReference: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DataFetchSo> {
        const response = await this.fetchSubviewDataRaw({ previewDataId: previewDataId, columnReference: columnReference }, initOverrides);
        return await response.value();
    }

    /**
     * Get export status
     */
    async getExportStatusRaw(requestParameters: PreviewDataApiGetExportStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ExportSo>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling getExportStatus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/preview-data/export/{uuid}/status`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExportSoFromJSON(jsonValue));
    }

    /**
     * Get export status
     */
    async getExportStatus(uuid: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ExportSo> {
        const response = await this.getExportStatusRaw({ uuid: uuid }, initOverrides);
        return await response.value();
    }

    /**
     * Get history of preview data by criteria
     */
    async getHistoryRaw(requestParameters: PreviewDataApiGetHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ColumnHistory>>> {
        if (requestParameters.viewReference === null || requestParameters.viewReference === undefined) {
            throw new runtime.RequiredError('viewReference','Required parameter requestParameters.viewReference was null or undefined when calling getHistory.');
        }

        if (requestParameters.viewId === null || requestParameters.viewId === undefined) {
            throw new runtime.RequiredError('viewId','Required parameter requestParameters.viewId was null or undefined when calling getHistory.');
        }

        if (requestParameters.columnKey === null || requestParameters.columnKey === undefined) {
            throw new runtime.RequiredError('columnKey','Required parameter requestParameters.columnKey was null or undefined when calling getHistory.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/preview-data/history/{viewReference}/{viewId}/{columnKey}`.replace(`{${"viewReference"}}`, encodeURIComponent(String(requestParameters.viewReference))).replace(`{${"viewId"}}`, encodeURIComponent(String(requestParameters.viewId))).replace(`{${"columnKey"}}`, encodeURIComponent(String(requestParameters.columnKey))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ColumnHistoryFromJSON));
    }

    /**
     * Get history of preview data by criteria
     */
    async getHistory(viewReference: string, viewId: number, columnKey: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ColumnHistory>> {
        const response = await this.getHistoryRaw({ viewReference: viewReference, viewId: viewId, columnKey: columnKey }, initOverrides);
        return await response.value();
    }

    /**
     * Get statistics of preview by criteria
     */
    async getPreviewCountByCriteriaRaw(requestParameters: PreviewDataApiGetPreviewCountByCriteriaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.statisticsSo === null || requestParameters.statisticsSo === undefined) {
            throw new runtime.RequiredError('statisticsSo','Required parameter requestParameters.statisticsSo was null or undefined when calling getPreviewCountByCriteria.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/preview-data/statistics/count`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StatisticsSoToJSON(requestParameters.statisticsSo),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Get statistics of preview by criteria
     */
    async getPreviewCountByCriteria(statisticsSo: StatisticsSo, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.getPreviewCountByCriteriaRaw({ statisticsSo: statisticsSo }, initOverrides);
        return await response.value();
    }

    /**
     * Get statistics of preview by criteria
     */
    async getStatisticsRaw(requestParameters: PreviewDataApiGetStatisticsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TimeSeriesStatistics>> {
        if (requestParameters.statisticsSo === null || requestParameters.statisticsSo === undefined) {
            throw new runtime.RequiredError('statisticsSo','Required parameter requestParameters.statisticsSo was null or undefined when calling getStatistics.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/preview-data/statistics`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StatisticsSoToJSON(requestParameters.statisticsSo),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TimeSeriesStatisticsFromJSON(jsonValue));
    }

    /**
     * Get statistics of preview by criteria
     */
    async getStatistics(statisticsSo: StatisticsSo, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TimeSeriesStatistics> {
        const response = await this.getStatisticsRaw({ statisticsSo: statisticsSo }, initOverrides);
        return await response.value();
    }

    /**
     * Retry data fetch
     */
    async retryFetchRaw(requestParameters: PreviewDataApiRetryFetchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DataFetchSo>> {
        if (requestParameters.previewDataFetchId === null || requestParameters.previewDataFetchId === undefined) {
            throw new runtime.RequiredError('previewDataFetchId','Required parameter requestParameters.previewDataFetchId was null or undefined when calling retryFetch.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/preview-data/fetch/retry/{previewDataFetchId}`.replace(`{${"previewDataFetchId"}}`, encodeURIComponent(String(requestParameters.previewDataFetchId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DataFetchSoFromJSON(jsonValue));
    }

    /**
     * Retry data fetch
     */
    async retryFetch(previewDataFetchId: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DataFetchSo> {
        const response = await this.retryFetchRaw({ previewDataFetchId: previewDataFetchId }, initOverrides);
        return await response.value();
    }

    /**
     * Undo delete preview data
     */
    async undeleteRaw(requestParameters: PreviewDataApiUndeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.previewDataId === null || requestParameters.previewDataId === undefined) {
            throw new runtime.RequiredError('previewDataId','Required parameter requestParameters.previewDataId was null or undefined when calling undelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/preview-data/undelete/{preview-data-id}`.replace(`{${"preview-data-id"}}`, encodeURIComponent(String(requestParameters.previewDataId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Undo delete preview data
     */
    async undelete(previewDataId: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.undeleteRaw({ previewDataId: previewDataId }, initOverrides);
    }

    /**
     * Undo delete preview data (batch)
     */
    async undeleteAllRaw(requestParameters: PreviewDataApiUndeleteAllRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
            throw new runtime.RequiredError('requestBody','Required parameter requestParameters.requestBody was null or undefined when calling undeleteAll.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/preview-data/undelete`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Undo delete preview data (batch)
     */
    async undeleteAll(requestBody: Set<number>, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.undeleteAllRaw({ requestBody: requestBody }, initOverrides);
    }

    /**
     * Update preview with create note
     */
    async updatePreviewRaw(requestParameters: PreviewDataApiUpdatePreviewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.previewId === null || requestParameters.previewId === undefined) {
            throw new runtime.RequiredError('previewId','Required parameter requestParameters.previewId was null or undefined when calling updatePreview.');
        }

        if (requestParameters.columnReference === null || requestParameters.columnReference === undefined) {
            throw new runtime.RequiredError('columnReference','Required parameter requestParameters.columnReference was null or undefined when calling updatePreview.');
        }

        if (requestParameters.previewDataUpdateSo === null || requestParameters.previewDataUpdateSo === undefined) {
            throw new runtime.RequiredError('previewDataUpdateSo','Required parameter requestParameters.previewDataUpdateSo was null or undefined when calling updatePreview.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/preview-data/{preview-id}/{column-reference}`.replace(`{${"preview-id"}}`, encodeURIComponent(String(requestParameters.previewId))).replace(`{${"column-reference"}}`, encodeURIComponent(String(requestParameters.columnReference))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PreviewDataUpdateSoToJSON(requestParameters.previewDataUpdateSo),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update preview with create note
     */
    async updatePreview(previewId: number, columnReference: string, previewDataUpdateSo: PreviewDataUpdateSo, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updatePreviewRaw({ previewId: previewId, columnReference: columnReference, previewDataUpdateSo: previewDataUpdateSo }, initOverrides);
    }

    /**
     * Update values in column
     */
    async updateValuesInColumnRaw(requestParameters: PreviewDataApiUpdateValuesInColumnRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.columnReference === null || requestParameters.columnReference === undefined) {
            throw new runtime.RequiredError('columnReference','Required parameter requestParameters.columnReference was null or undefined when calling updateValuesInColumn.');
        }

        if (requestParameters.batchUpdateValuesSo === null || requestParameters.batchUpdateValuesSo === undefined) {
            throw new runtime.RequiredError('batchUpdateValuesSo','Required parameter requestParameters.batchUpdateValuesSo was null or undefined when calling updateValuesInColumn.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/preview-data/updateValues/{column-reference}`.replace(`{${"column-reference"}}`, encodeURIComponent(String(requestParameters.columnReference))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: BatchUpdateValuesSoToJSON(requestParameters.batchUpdateValuesSo),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update values in column
     */
    async updateValuesInColumn(columnReference: string, batchUpdateValuesSo: BatchUpdateValuesSo, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateValuesInColumnRaw({ columnReference: columnReference, batchUpdateValuesSo: batchUpdateValuesSo }, initOverrides);
    }

}
