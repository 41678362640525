import React, { PropsWithChildren, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { ChevronLeftIcon } from '@heroicons/react/24/outline';
import { IconButton } from '@mc-ui/react-components';

type PageTitleProps = {
    backLink?: {
        title: string;
        to: string;
        state?: any;
    };
    title: string;
    subtitle?: string | ReactNode;
    noBorder?: boolean;
};

const PageTitle: React.FC<PropsWithChildren<PageTitleProps>> = ({ title, subtitle, children, backLink, noBorder = false }) => {
    return (
        <div
            className={`flex items-center justify-between ${
                noBorder ? 'border-b-0' : 'border-b'
            } border-light-border px-4 py-5 dark:border-dark-border sm:px-6 lg:px-8`}
        >
            <div className='flex flex-row items-center gap-4'>
                {backLink && (
                    <IconButton
                        title={backLink.title}
                        as={Link}
                        to={backLink.to}
                        state={backLink.state}
                        icon={ChevronLeftIcon}
                        color='flat'
                        variant='rounded'
                    />
                )}
                <div>
                    <h1 className='flex items-center text-2xl font-semibold text-light-textDark dark:text-dark-textDark'>{title}</h1>
                    <div className='-mt-0.5 text-sm font-medium text-light-textLight dark:text-dark-textLight'>{subtitle}</div>
                </div>
            </div>
            {children}
        </div>
    );
};

export default PageTitle;
