import React, { PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';
import useUserProfileApi from '../hooks/use-user-profile-api';
import { UserProfileApi } from '../openapi/user-profile';
import LoadingOverlay from '../components/common/LoadingOverlay';
import useCodelistApi from '../hooks/use-codelist-api';
import { InfoApi, Language } from '../openapi/codelist';
import { Bars2Icon, Bars3Icon, Bars4Icon } from '@heroicons/react/24/outline';
import { IconType } from '@mc-ui/react-components';

export type Favorite = {
    url: string;
    name: string;
};
export type TableDensity = 'dense' | 'normal' | 'large' | 'none';

export const tableDensityOptions: {
    id: TableDensity;
    name: string;
    icon: IconType;
}[] = [
    { id: 'dense', name: 'Husté zobrazenie', icon: Bars4Icon },
    { id: 'normal', name: 'Normálne zobrazenie', icon: Bars3Icon },
    { id: 'large', name: 'Riedke zobrazenie', icon: Bars2Icon }
];

export type Theme = 'dark' | 'light';
export const pageSizes = [
    { id: 10, name: '10' },
    { id: 25, name: '25' },
    { id: 50, name: '50' },
    { id: 100, name: '100' },
    { id: 250, name: '250' },
    { id: 500, name: '500' }
];

export type CsvDelimiter = ',' | ';' | '|';

export const csvDelimiterOptions: { id: CsvDelimiter; name: string }[] = [
    { id: ',', name: 'Čiarka ,' },
    { id: ';', name: 'Bodkočiarka ;' },
    { id: '|', name: 'Zvislá čiara |' }
];

export type ExportFileType = 'csv' | 'xlsx';

export const exportFileTypeOptions: { id: ExportFileType; name: string }[] = [
    { id: 'csv', name: 'Comma-separated values (*.csv)' },
    { id: 'xlsx', name: 'Microsoft Excel document (*.xlsx)' }
];

const DEFAULT_PAGE_SIZE = 10;
const DEFAULT_FAVORITES: Favorite[] = [];
const DEFAULT_DENSITY: TableDensity = 'normal';
const DEFAULT_MINIMIZED_MENU = false;
const DEFAULT_THEME: Theme = 'light';
const DEFAULT_CSV_DELIMITER: CsvDelimiter = ',';
const DEFAULT_EXPORT_FILE_TYPE: ExportFileType = 'csv';

type PreferencesContextValue = {
    favorites: Favorite[];
    defaultPageSize: number;
    defaultDensity: TableDensity;
    defaultMinimizedMenu: boolean;
    defaultCsvDelimiter: CsvDelimiter;
    defaultExportFileType: ExportFileType;
    defaultTheme: Theme;
    langs: Language[];
    isFavorite: (url: string) => boolean;
    addFavorite: (favorite: Favorite) => Promise<void>;
    removeFavorite: (url: string) => Promise<void>;
    setDefaultPageSize: (pageSize: number) => Promise<void>;
    setDefaultDensity: (density: TableDensity) => Promise<void>;
    setDefaultMinimizedMenu: (menu: boolean) => Promise<void>;
    setDefaultCsvDelimiter: (delimiter: CsvDelimiter) => Promise<void>;
    setDefaultExportFileType: (defaultExportFileType: ExportFileType) => Promise<void>;
    setDefaultTheme: (theme: Theme) => Promise<void>;
};

export const PreferencesContext = React.createContext<PreferencesContextValue>({} as PreferencesContextValue);

const PreferencesProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const userProfileApi = useUserProfileApi(UserProfileApi);
    const infoApi = useCodelistApi(InfoApi);

    const [isInitialized, setInitialized] = useState(false);

    const [savingFavorites, setSavingFavorites] = useState(false);
    const [savingDefaultPageSize, setSavingDefaultPageSize] = useState(false);
    const [savingDefaultDensity, setSavingDefaultDensity] = useState(false);
    const [savingDefaultMinimizedMenu, setSavingDefaultMinimizedMenu] = useState(false);
    const [savingDefaultCsvDelimiter, setSavingDefaultCsvDelimiter] = useState(false);
    const [savingDefaultExportFileType, setSavingDefaultExportFileType] = useState(false);
    const [savingDefaultTheme, setSavingDefaultTheme] = useState(false);

    const [favorites, setFavorites] = useState(DEFAULT_FAVORITES);
    const [defaultPageSize, setDefaultPageSize] = useState(DEFAULT_PAGE_SIZE);
    const [defaultDensity, setDefaultDensity] = useState<TableDensity>(DEFAULT_DENSITY);
    const [defaultMinimizedMenu, setDefaultMinimizedMenu] = useState(DEFAULT_MINIMIZED_MENU);
    const [defaultCsvDelimiter, setDefaultCsvDelimiter] = useState<CsvDelimiter>(DEFAULT_CSV_DELIMITER);
    const [defaultExportFileType, setDefaultExportFileType] = useState<ExportFileType>(DEFAULT_EXPORT_FILE_TYPE);
    const [defaultTheme, setDefaultTheme] = useState<Theme>(DEFAULT_THEME);
    const [langs, setLangs] = useState<Language[]>([]);

    useEffect(() => {
        // Initial loading
        (async () => {
            try {
                const favorites = await userProfileApi.getAttribute('global', 'favorites');
                const defaultPageSize = await userProfileApi.getAttribute('global', 'defaultPageSize');
                const defaultDensity = await userProfileApi.getAttribute('global', 'defaultDensity');
                const defaultMinimizedMenu = await userProfileApi.getAttribute('global', 'defaultMinimizedMenu');
                const defaultCsvDelimiter = await userProfileApi.getAttribute('global', 'defaultCsvDelimiter');
                const defaultExportFileType = await userProfileApi.getAttribute('global', 'defaultExportFileType');
                const defaultTheme = await userProfileApi.getAttribute('global', 'theme');

                if (favorites.value) {
                    setFavorites(JSON.parse(favorites.value) as Favorite[]);
                }
                if (defaultPageSize.value) {
                    setDefaultPageSize(+defaultPageSize.value);
                }
                if (defaultDensity.value) {
                    setDefaultDensity(defaultDensity.value as TableDensity);
                }
                if (defaultMinimizedMenu.value) {
                    setDefaultMinimizedMenu(defaultMinimizedMenu.value === 'true');
                }
                if (defaultCsvDelimiter.value) {
                    setDefaultCsvDelimiter(defaultCsvDelimiter.value as CsvDelimiter);
                }
                if (defaultExportFileType.value) {
                    setDefaultExportFileType(defaultExportFileType.value as ExportFileType);
                }
                if (defaultTheme.value) {
                    setDefaultTheme(defaultTheme.value as Theme);
                }
            } catch (e) {
                console.error('Nepodarilo sa načítať preferencie používateľa', e);
            }

            try {
                const langs = await infoApi.getLanguages().then((langs) => Array.from(langs));
                if (langs) {
                    setLangs(langs);
                }
            } catch (e) {
                console.error('Nepodarilo sa načítať predvolený jazyk číselníkov', e);
            }
        })().finally(() => {
            setInitialized(true);
        });
    }, [userProfileApi, infoApi]);

    const isFavorite = useCallback(
        (url: string) => {
            if (favorites) {
                return favorites.findIndex((f) => f.url === url) > -1;
            } else {
                return false;
            }
        },
        [favorites]
    );

    const addFavoriteHandler = useCallback(
        async (favorite: Favorite) => {
            try {
                setSavingFavorites(true);
                const updatedFavorites = [...(favorites ?? []), favorite].sort((a, b) =>
                    a.name.localeCompare(b.name, undefined, {
                        sensitivity: 'base'
                    })
                );
                setFavorites(updatedFavorites);

                await userProfileApi.createAttribute({
                    domain: 'global',
                    key: 'favorites',
                    value: JSON.stringify(updatedFavorites)
                });
            } finally {
                setSavingFavorites(false);
            }
        },
        [favorites, setFavorites, userProfileApi]
    );

    const removeFavoriteHandler = useCallback(
        async (url: string) => {
            try {
                setSavingFavorites(true);
                const updatedFavorites = favorites?.filter((f) => f.url !== url);

                setFavorites(updatedFavorites);
                await userProfileApi.createAttribute({
                    domain: 'global',
                    key: 'favorites',
                    value: JSON.stringify(updatedFavorites)
                });
            } finally {
                setSavingFavorites(false);
            }
        },
        [favorites, setFavorites, userProfileApi]
    );

    const setDefaultPageSizeHandler = useCallback(
        async (pageSize: number) => {
            try {
                setSavingDefaultPageSize(true);
                setDefaultPageSize(pageSize);
                await userProfileApi.createAttribute({
                    domain: 'global',
                    key: 'defaultPageSize',
                    value: JSON.stringify(pageSize)
                });
            } finally {
                setSavingDefaultPageSize(false);
            }
        },
        [userProfileApi]
    );

    const setDefaultDensityHandler = useCallback(
        async (density: TableDensity) => {
            try {
                setSavingDefaultDensity(true);
                setDefaultDensity(density);
                await userProfileApi.createAttribute({
                    domain: 'global',
                    key: 'defaultDensity',
                    value: density
                });
            } finally {
                setSavingDefaultDensity(false);
            }
        },
        [userProfileApi]
    );

    const setDefaultMinimizedMenuHandler = useCallback(
        async (menu: boolean) => {
            try {
                setSavingDefaultMinimizedMenu(true);
                setDefaultMinimizedMenu(menu);
                await userProfileApi.createAttribute({
                    domain: 'global',
                    key: 'defaultMinimizedMenu',
                    value: JSON.stringify(menu)
                });
            } finally {
                setSavingDefaultMinimizedMenu(false);
            }
        },
        [userProfileApi]
    );

    const setDefaultCsvDelimiterHandler = useCallback(
        async (delimiter: CsvDelimiter) => {
            try {
                setSavingDefaultCsvDelimiter(true);
                setDefaultCsvDelimiter(delimiter);
                await userProfileApi.createAttribute({
                    domain: 'global',
                    key: 'defaultCsvDelimiter',
                    value: delimiter
                });
            } finally {
                setSavingDefaultCsvDelimiter(false);
            }
        },
        [userProfileApi]
    );

    const setDefaultExportFileTypeHandler = useCallback(
        async (fileType: ExportFileType) => {
            try {
                setSavingDefaultExportFileType(true);
                setDefaultExportFileType(fileType);
                await userProfileApi.createAttribute({
                    domain: 'global',
                    key: 'defaultExportFileType',
                    value: fileType
                });
            } finally {
                setSavingDefaultExportFileType(false);
            }
        },
        [userProfileApi]
    );

    useEffect(() => {
        if (defaultTheme) {
            if (defaultTheme === 'dark') {
                document.documentElement.classList.add('dark');
            } else {
                document.documentElement.classList.remove('dark');
            }
        }
    }, [defaultTheme]);

    const setDefaultThemeHandler = useCallback(
        async (theme: Theme) => {
            try {
                setSavingDefaultTheme(true);
                setDefaultTheme(theme);
                await userProfileApi.createAttribute({
                    domain: 'global',
                    key: 'theme',
                    value: theme
                });
            } catch (e) {
                console.error(e);
            } finally {
                setSavingDefaultTheme(false);
            }
        },
        [userProfileApi]
    );

    const isSaving =
        savingFavorites ||
        savingDefaultPageSize ||
        savingDefaultDensity ||
        savingDefaultMinimizedMenu ||
        savingDefaultCsvDelimiter ||
        savingDefaultExportFileType ||
        savingDefaultTheme;

    const context: PreferencesContextValue = useMemo(() => {
        return {
            favorites,
            defaultDensity,
            defaultPageSize,
            defaultMinimizedMenu,
            defaultCsvDelimiter,
            defaultExportFileType,
            defaultTheme,
            langs,
            isFavorite,
            addFavorite: addFavoriteHandler,
            removeFavorite: removeFavoriteHandler,
            setDefaultDensity: setDefaultDensityHandler,
            setDefaultPageSize: setDefaultPageSizeHandler,
            setDefaultMinimizedMenu: setDefaultMinimizedMenuHandler,
            setDefaultCsvDelimiter: setDefaultCsvDelimiterHandler,
            setDefaultExportFileType: setDefaultExportFileTypeHandler,
            setDefaultTheme: setDefaultThemeHandler
        };
    }, [
        favorites,
        defaultDensity,
        defaultPageSize,
        defaultMinimizedMenu,
        defaultCsvDelimiter,
        defaultExportFileType,
        defaultTheme,
        langs,
        isFavorite,
        addFavoriteHandler,
        removeFavoriteHandler,
        setDefaultDensityHandler,
        setDefaultPageSizeHandler,
        setDefaultMinimizedMenuHandler,
        setDefaultCsvDelimiterHandler,
        setDefaultExportFileTypeHandler,
        setDefaultThemeHandler
    ]);

    return (
        <PreferencesContext.Provider value={context}>
            {isInitialized ? <LoadingOverlay loading={isSaving}>{children}</LoadingOverlay> : <LoadingOverlay loading={true} />}
        </PreferencesContext.Provider>
    );
};

export default PreferencesProvider;
