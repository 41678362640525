/* tslint:disable */
/* eslint-disable */
/**
 * Data import metadata
 * Administration of metadata for import
 *
 * The version of the OpenAPI document: 1.0
 * Contact: schvarcz@bb.microcomp.sk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AssignImportFilePartDefinitionRequest,
  AssignImportFilePartDefinitionsRequest,
  ImportFilePartDefinitionResponse,
  RemoveImportFilePartDefinitionsRequest,
} from '../models/index';
import {
    AssignImportFilePartDefinitionRequestFromJSON,
    AssignImportFilePartDefinitionRequestToJSON,
    AssignImportFilePartDefinitionsRequestFromJSON,
    AssignImportFilePartDefinitionsRequestToJSON,
    ImportFilePartDefinitionResponseFromJSON,
    ImportFilePartDefinitionResponseToJSON,
    RemoveImportFilePartDefinitionsRequestFromJSON,
    RemoveImportFilePartDefinitionsRequestToJSON,
} from '../models/index';

export interface ImportFilePartDefinitionApiAddImportFilePartDefinitionRequest {
    assignImportFilePartDefinitionRequest: AssignImportFilePartDefinitionRequest;
}

export interface ImportFilePartDefinitionApiAddImportFilePartDefinitionsRequest {
    assignImportFilePartDefinitionsRequest: AssignImportFilePartDefinitionsRequest;
}

export interface ImportFilePartDefinitionApiGetImportFilePartDefinitionByIdRequest {
    id: string;
}

export interface ImportFilePartDefinitionApiGetImportFilePartDefinitionByImportFileDefinitionRequest {
    importFileDefinitionId: string;
}

export interface ImportFilePartDefinitionApiRemoveImportFilePartDefinitionsOperationRequest {
    removeImportFilePartDefinitionsRequest: RemoveImportFilePartDefinitionsRequest;
}

/**
 * 
 */
export class ImportFilePartDefinitionApi extends runtime.BaseAPI {

    /**
     * Add one import file part definition to import file
     */
    async addImportFilePartDefinitionRaw(requestParameters: ImportFilePartDefinitionApiAddImportFilePartDefinitionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ImportFilePartDefinitionResponse>> {
        if (requestParameters.assignImportFilePartDefinitionRequest === null || requestParameters.assignImportFilePartDefinitionRequest === undefined) {
            throw new runtime.RequiredError('assignImportFilePartDefinitionRequest','Required parameter requestParameters.assignImportFilePartDefinitionRequest was null or undefined when calling addImportFilePartDefinition.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/import-file-part-definition/add`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AssignImportFilePartDefinitionRequestToJSON(requestParameters.assignImportFilePartDefinitionRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ImportFilePartDefinitionResponseFromJSON(jsonValue));
    }

    /**
     * Add one import file part definition to import file
     */
    async addImportFilePartDefinition(assignImportFilePartDefinitionRequest: AssignImportFilePartDefinitionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ImportFilePartDefinitionResponse> {
        const response = await this.addImportFilePartDefinitionRaw({ assignImportFilePartDefinitionRequest: assignImportFilePartDefinitionRequest }, initOverrides);
        return await response.value();
    }

    /**
     * Add import file part definitions to import file
     */
    async addImportFilePartDefinitionsRaw(requestParameters: ImportFilePartDefinitionApiAddImportFilePartDefinitionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.assignImportFilePartDefinitionsRequest === null || requestParameters.assignImportFilePartDefinitionsRequest === undefined) {
            throw new runtime.RequiredError('assignImportFilePartDefinitionsRequest','Required parameter requestParameters.assignImportFilePartDefinitionsRequest was null or undefined when calling addImportFilePartDefinitions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/import-file-part-definition/add-import-file-part-definitions`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AssignImportFilePartDefinitionsRequestToJSON(requestParameters.assignImportFilePartDefinitionsRequest),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Add import file part definitions to import file
     */
    async addImportFilePartDefinitions(assignImportFilePartDefinitionsRequest: AssignImportFilePartDefinitionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.addImportFilePartDefinitionsRaw({ assignImportFilePartDefinitionsRequest: assignImportFilePartDefinitionsRequest }, initOverrides);
        return await response.value();
    }

    /**
     * Get import file part definition by id
     */
    async getImportFilePartDefinitionByIdRaw(requestParameters: ImportFilePartDefinitionApiGetImportFilePartDefinitionByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ImportFilePartDefinitionResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getImportFilePartDefinitionById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/import-file-part-definition/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ImportFilePartDefinitionResponseFromJSON(jsonValue));
    }

    /**
     * Get import file part definition by id
     */
    async getImportFilePartDefinitionById(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ImportFilePartDefinitionResponse> {
        const response = await this.getImportFilePartDefinitionByIdRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     * Get import file part definitions by import file definition
     */
    async getImportFilePartDefinitionByImportFileDefinitionRaw(requestParameters: ImportFilePartDefinitionApiGetImportFilePartDefinitionByImportFileDefinitionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ImportFilePartDefinitionResponse>>> {
        if (requestParameters.importFileDefinitionId === null || requestParameters.importFileDefinitionId === undefined) {
            throw new runtime.RequiredError('importFileDefinitionId','Required parameter requestParameters.importFileDefinitionId was null or undefined when calling getImportFilePartDefinitionByImportFileDefinition.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/import-file-part-definition/byImportFileDefinition/{importFileDefinitionId}`.replace(`{${"importFileDefinitionId"}}`, encodeURIComponent(String(requestParameters.importFileDefinitionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ImportFilePartDefinitionResponseFromJSON));
    }

    /**
     * Get import file part definitions by import file definition
     */
    async getImportFilePartDefinitionByImportFileDefinition(importFileDefinitionId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ImportFilePartDefinitionResponse>> {
        const response = await this.getImportFilePartDefinitionByImportFileDefinitionRaw({ importFileDefinitionId: importFileDefinitionId }, initOverrides);
        return await response.value();
    }

    /**
     * Remove import file part definitions from import file
     */
    async removeImportFilePartDefinitionsRaw(requestParameters: ImportFilePartDefinitionApiRemoveImportFilePartDefinitionsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.removeImportFilePartDefinitionsRequest === null || requestParameters.removeImportFilePartDefinitionsRequest === undefined) {
            throw new runtime.RequiredError('removeImportFilePartDefinitionsRequest','Required parameter requestParameters.removeImportFilePartDefinitionsRequest was null or undefined when calling removeImportFilePartDefinitions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/import-file-part-definition/remove`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: RemoveImportFilePartDefinitionsRequestToJSON(requestParameters.removeImportFilePartDefinitionsRequest),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Remove import file part definitions from import file
     */
    async removeImportFilePartDefinitions(removeImportFilePartDefinitionsRequest: RemoveImportFilePartDefinitionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.removeImportFilePartDefinitionsRaw({ removeImportFilePartDefinitionsRequest: removeImportFilePartDefinitionsRequest }, initOverrides);
        return await response.value();
    }

}
