/* tslint:disable */
/* eslint-disable */
/**
 * Data import
 * Modul for importing data to Data Registry.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: schvarcz@bb.microcomp.sk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DataAttributeRequest
 */
export interface DataAttributeRequest {
    /**
     * 
     * @type {string}
     * @memberof DataAttributeRequest
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof DataAttributeRequest
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof DataAttributeRequest
     */
    value?: string;
    /**
     * 
     * @type {string}
     * @memberof DataAttributeRequest
     */
    note?: string;
}

/**
 * Check if a given object implements the DataAttributeRequest interface.
 */
export function instanceOfDataAttributeRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DataAttributeRequestFromJSON(json: any): DataAttributeRequest {
    return DataAttributeRequestFromJSONTyped(json, false);
}

export function DataAttributeRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): DataAttributeRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'code': !exists(json, 'code') ? undefined : json['code'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'value': !exists(json, 'value') ? undefined : json['value'],
        'note': !exists(json, 'note') ? undefined : json['note'],
    };
}

export function DataAttributeRequestToJSON(value?: DataAttributeRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'code': value.code,
        'name': value.name,
        'value': value.value,
        'note': value.note,
    };
}

