/* tslint:disable */
/* eslint-disable */
/**
 * Data import metadata
 * Administration of metadata for import
 *
 * The version of the OpenAPI document: 1.0
 * Contact: schvarcz@bb.microcomp.sk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AssignRowTypeDefinitionRequest,
  AssignRowTypeDefinitionsRequest,
  RemoveRowTypeDefinitionsRequest,
  RowTypeDefinitionRequest,
  RowTypeDefinitionResponse,
} from '../models/index';
import {
    AssignRowTypeDefinitionRequestFromJSON,
    AssignRowTypeDefinitionRequestToJSON,
    AssignRowTypeDefinitionsRequestFromJSON,
    AssignRowTypeDefinitionsRequestToJSON,
    RemoveRowTypeDefinitionsRequestFromJSON,
    RemoveRowTypeDefinitionsRequestToJSON,
    RowTypeDefinitionRequestFromJSON,
    RowTypeDefinitionRequestToJSON,
    RowTypeDefinitionResponseFromJSON,
    RowTypeDefinitionResponseToJSON,
} from '../models/index';

export interface RowTypeDefinitionApiAddRowTypeDefinitionRequest {
    assignRowTypeDefinitionRequest: AssignRowTypeDefinitionRequest;
}

export interface RowTypeDefinitionApiAddRowTypeDefinitionsRequest {
    assignRowTypeDefinitionsRequest: AssignRowTypeDefinitionsRequest;
}

export interface RowTypeDefinitionApiGetRowTypeDefinitionByIdRequest {
    id: string;
}

export interface RowTypeDefinitionApiGetRowTypeDefinitionByImportFilePartDefinitionRequest {
    importFilePartDefinitionId: string;
}

export interface RowTypeDefinitionApiRemoveRowTypeDefinitionsOperationRequest {
    removeRowTypeDefinitionsRequest: RemoveRowTypeDefinitionsRequest;
}

export interface RowTypeDefinitionApiUpdateRowTypeDefinitionRequest {
    id: string;
    rowTypeDefinitionRequest: RowTypeDefinitionRequest;
}

/**
 * 
 */
export class RowTypeDefinitionApi extends runtime.BaseAPI {

    /**
     * Add one row type definitions to import part file
     */
    async addRowTypeDefinitionRaw(requestParameters: RowTypeDefinitionApiAddRowTypeDefinitionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.assignRowTypeDefinitionRequest === null || requestParameters.assignRowTypeDefinitionRequest === undefined) {
            throw new runtime.RequiredError('assignRowTypeDefinitionRequest','Required parameter requestParameters.assignRowTypeDefinitionRequest was null or undefined when calling addRowTypeDefinition.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/row-type-definition/add`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AssignRowTypeDefinitionRequestToJSON(requestParameters.assignRowTypeDefinitionRequest),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Add one row type definitions to import part file
     */
    async addRowTypeDefinition(assignRowTypeDefinitionRequest: AssignRowTypeDefinitionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.addRowTypeDefinitionRaw({ assignRowTypeDefinitionRequest: assignRowTypeDefinitionRequest }, initOverrides);
        return await response.value();
    }

    /**
     * Add row type definitions to import part file
     */
    async addRowTypeDefinitionsRaw(requestParameters: RowTypeDefinitionApiAddRowTypeDefinitionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.assignRowTypeDefinitionsRequest === null || requestParameters.assignRowTypeDefinitionsRequest === undefined) {
            throw new runtime.RequiredError('assignRowTypeDefinitionsRequest','Required parameter requestParameters.assignRowTypeDefinitionsRequest was null or undefined when calling addRowTypeDefinitions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/row-type-definition/add-row-type-definitions`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AssignRowTypeDefinitionsRequestToJSON(requestParameters.assignRowTypeDefinitionsRequest),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Add row type definitions to import part file
     */
    async addRowTypeDefinitions(assignRowTypeDefinitionsRequest: AssignRowTypeDefinitionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.addRowTypeDefinitionsRaw({ assignRowTypeDefinitionsRequest: assignRowTypeDefinitionsRequest }, initOverrides);
        return await response.value();
    }

    /**
     * Get row type definition by id
     */
    async getRowTypeDefinitionByIdRaw(requestParameters: RowTypeDefinitionApiGetRowTypeDefinitionByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RowTypeDefinitionResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getRowTypeDefinitionById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/row-type-definition/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RowTypeDefinitionResponseFromJSON(jsonValue));
    }

    /**
     * Get row type definition by id
     */
    async getRowTypeDefinitionById(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RowTypeDefinitionResponse> {
        const response = await this.getRowTypeDefinitionByIdRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     * Get row type definitions by import file part definition
     */
    async getRowTypeDefinitionByImportFilePartDefinitionRaw(requestParameters: RowTypeDefinitionApiGetRowTypeDefinitionByImportFilePartDefinitionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<RowTypeDefinitionResponse>>> {
        if (requestParameters.importFilePartDefinitionId === null || requestParameters.importFilePartDefinitionId === undefined) {
            throw new runtime.RequiredError('importFilePartDefinitionId','Required parameter requestParameters.importFilePartDefinitionId was null or undefined when calling getRowTypeDefinitionByImportFilePartDefinition.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/row-type-definition/byImportFilePartDefinition/{importFilePartDefinitionId}`.replace(`{${"importFilePartDefinitionId"}}`, encodeURIComponent(String(requestParameters.importFilePartDefinitionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RowTypeDefinitionResponseFromJSON));
    }

    /**
     * Get row type definitions by import file part definition
     */
    async getRowTypeDefinitionByImportFilePartDefinition(importFilePartDefinitionId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<RowTypeDefinitionResponse>> {
        const response = await this.getRowTypeDefinitionByImportFilePartDefinitionRaw({ importFilePartDefinitionId: importFilePartDefinitionId }, initOverrides);
        return await response.value();
    }

    /**
     * Remove row type definitions from import part file
     */
    async removeRowTypeDefinitionsRaw(requestParameters: RowTypeDefinitionApiRemoveRowTypeDefinitionsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.removeRowTypeDefinitionsRequest === null || requestParameters.removeRowTypeDefinitionsRequest === undefined) {
            throw new runtime.RequiredError('removeRowTypeDefinitionsRequest','Required parameter requestParameters.removeRowTypeDefinitionsRequest was null or undefined when calling removeRowTypeDefinitions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/row-type-definition/remove`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: RemoveRowTypeDefinitionsRequestToJSON(requestParameters.removeRowTypeDefinitionsRequest),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Remove row type definitions from import part file
     */
    async removeRowTypeDefinitions(removeRowTypeDefinitionsRequest: RemoveRowTypeDefinitionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.removeRowTypeDefinitionsRaw({ removeRowTypeDefinitionsRequest: removeRowTypeDefinitionsRequest }, initOverrides);
        return await response.value();
    }

    /**
     * Update row type definition
     */
    async updateRowTypeDefinitionRaw(requestParameters: RowTypeDefinitionApiUpdateRowTypeDefinitionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RowTypeDefinitionResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateRowTypeDefinition.');
        }

        if (requestParameters.rowTypeDefinitionRequest === null || requestParameters.rowTypeDefinitionRequest === undefined) {
            throw new runtime.RequiredError('rowTypeDefinitionRequest','Required parameter requestParameters.rowTypeDefinitionRequest was null or undefined when calling updateRowTypeDefinition.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/row-type-definition/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: RowTypeDefinitionRequestToJSON(requestParameters.rowTypeDefinitionRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RowTypeDefinitionResponseFromJSON(jsonValue));
    }

    /**
     * Update row type definition
     */
    async updateRowTypeDefinition(id: string, rowTypeDefinitionRequest: RowTypeDefinitionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RowTypeDefinitionResponse> {
        const response = await this.updateRowTypeDefinitionRaw({ id: id, rowTypeDefinitionRequest: rowTypeDefinitionRequest }, initOverrides);
        return await response.value();
    }

}
