/* tslint:disable */
/* eslint-disable */
/**
 * Method import API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ImportFileCriteria
 */
export interface ImportFileCriteria {
    /**
     * 
     * @type {string}
     * @memberof ImportFileCriteria
     */
    searchField?: string;
    /**
     * 
     * @type {string}
     * @memberof ImportFileCriteria
     */
    fileName?: string;
    /**
     * 
     * @type {string}
     * @memberof ImportFileCriteria
     */
    status?: ImportFileCriteriaStatusEnum;
}


/**
 * @export
 */
export const ImportFileCriteriaStatusEnum = {
    Created: 'created',
    Waiting: 'waiting',
    Importing: 'importing',
    Validating: 'validating',
    Validated: 'validated',
    Imported: 'imported',
    Failed: 'failed',
    Unvalidated: 'unvalidated'
} as const;
export type ImportFileCriteriaStatusEnum = typeof ImportFileCriteriaStatusEnum[keyof typeof ImportFileCriteriaStatusEnum];


/**
 * Check if a given object implements the ImportFileCriteria interface.
 */
export function instanceOfImportFileCriteria(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ImportFileCriteriaFromJSON(json: any): ImportFileCriteria {
    return ImportFileCriteriaFromJSONTyped(json, false);
}

export function ImportFileCriteriaFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImportFileCriteria {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'searchField': !exists(json, 'searchField') ? undefined : json['searchField'],
        'fileName': !exists(json, 'fileName') ? undefined : json['fileName'],
        'status': !exists(json, 'status') ? undefined : json['status'],
    };
}

export function ImportFileCriteriaToJSON(value?: ImportFileCriteria | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'searchField': value.searchField,
        'fileName': value.fileName,
        'status': value.status,
    };
}

