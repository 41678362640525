/* tslint:disable */
/* eslint-disable */
/**
 * Overview API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  EndpointExceptionBody,
} from '../models/index';
import {
    EndpointExceptionBodyFromJSON,
    EndpointExceptionBodyToJSON,
} from '../models/index';

export interface FileApiDownloadLocalRequest {
    previewDataId: number;
    columnName: string;
}

export interface FileApiDownloadRemoteRequest {
    previewDataId: number;
    columnName: string;
}

export interface FileApiUploadFileRequest {
    previewDataId: number;
    columnName: string;
    file: Blob;
    note?: string;
}

/**
 * 
 */
export class FileApi extends runtime.BaseAPI {

    /**
     * Download local data by preview ID and column Name 
     */
    async downloadLocalRaw(requestParameters: FileApiDownloadLocalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.previewDataId === null || requestParameters.previewDataId === undefined) {
            throw new runtime.RequiredError('previewDataId','Required parameter requestParameters.previewDataId was null or undefined when calling downloadLocal.');
        }

        if (requestParameters.columnName === null || requestParameters.columnName === undefined) {
            throw new runtime.RequiredError('columnName','Required parameter requestParameters.columnName was null or undefined when calling downloadLocal.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/file/local/{previewDataId}/{columnName}`.replace(`{${"previewDataId"}}`, encodeURIComponent(String(requestParameters.previewDataId))).replace(`{${"columnName"}}`, encodeURIComponent(String(requestParameters.columnName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Download local data by preview ID and column Name 
     */
    async downloadLocal(previewDataId: number, columnName: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.downloadLocalRaw({ previewDataId: previewDataId, columnName: columnName }, initOverrides);
        return await response.value();
    }

    /**
     * Download remote data by preview ID and column Name 
     */
    async downloadRemoteRaw(requestParameters: FileApiDownloadRemoteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.previewDataId === null || requestParameters.previewDataId === undefined) {
            throw new runtime.RequiredError('previewDataId','Required parameter requestParameters.previewDataId was null or undefined when calling downloadRemote.');
        }

        if (requestParameters.columnName === null || requestParameters.columnName === undefined) {
            throw new runtime.RequiredError('columnName','Required parameter requestParameters.columnName was null or undefined when calling downloadRemote.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/file/remote/{previewDataId}/{columnName}`.replace(`{${"previewDataId"}}`, encodeURIComponent(String(requestParameters.previewDataId))).replace(`{${"columnName"}}`, encodeURIComponent(String(requestParameters.columnName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Download remote data by preview ID and column Name 
     */
    async downloadRemote(previewDataId: number, columnName: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.downloadRemoteRaw({ previewDataId: previewDataId, columnName: columnName }, initOverrides);
        return await response.value();
    }

    /**
     * Upload local data by preview ID and column Name 
     */
    async uploadFileRaw(requestParameters: FileApiUploadFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.previewDataId === null || requestParameters.previewDataId === undefined) {
            throw new runtime.RequiredError('previewDataId','Required parameter requestParameters.previewDataId was null or undefined when calling uploadFile.');
        }

        if (requestParameters.columnName === null || requestParameters.columnName === undefined) {
            throw new runtime.RequiredError('columnName','Required parameter requestParameters.columnName was null or undefined when calling uploadFile.');
        }

        if (requestParameters.file === null || requestParameters.file === undefined) {
            throw new runtime.RequiredError('file','Required parameter requestParameters.file was null or undefined when calling uploadFile.');
        }

        const queryParameters: any = {};

        if (requestParameters.note !== undefined) {
            queryParameters['note'] = requestParameters.note;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/file/local/{previewDataId}/{columnName}`.replace(`{${"previewDataId"}}`, encodeURIComponent(String(requestParameters.previewDataId))).replace(`{${"columnName"}}`, encodeURIComponent(String(requestParameters.columnName))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Upload local data by preview ID and column Name 
     */
    async uploadFile(previewDataId: number, columnName: string, file: Blob, note?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.uploadFileRaw({ previewDataId: previewDataId, columnName: columnName, file: file, note: note }, initOverrides);
        return await response.value();
    }

}
