/* tslint:disable */
/* eslint-disable */
/**
 * Data import metadata
 * Administration of metadata for import
 *
 * The version of the OpenAPI document: 1.0
 * Contact: schvarcz@bb.microcomp.sk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RowAttributeTransformationDefinitionItemRequest
 */
export interface RowAttributeTransformationDefinitionItemRequest {
    /**
     * 
     * @type {string}
     * @memberof RowAttributeTransformationDefinitionItemRequest
     */
    rowAttributeTransformationItemType?: RowAttributeTransformationDefinitionItemRequestRowAttributeTransformationItemTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof RowAttributeTransformationDefinitionItemRequest
     */
    item?: string;
    /**
     * 
     * @type {string}
     * @memberof RowAttributeTransformationDefinitionItemRequest
     */
    itemDataType?: RowAttributeTransformationDefinitionItemRequestItemDataTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof RowAttributeTransformationDefinitionItemRequest
     */
    referenceAttributeType?: RowAttributeTransformationDefinitionItemRequestReferenceAttributeTypeEnum;
}


/**
 * @export
 */
export const RowAttributeTransformationDefinitionItemRequestRowAttributeTransformationItemTypeEnum = {
    RowAttributeDefinitionCode: 'ROW_ATTRIBUTE_DEFINITION_CODE',
    Value: 'VALUE'
} as const;
export type RowAttributeTransformationDefinitionItemRequestRowAttributeTransformationItemTypeEnum = typeof RowAttributeTransformationDefinitionItemRequestRowAttributeTransformationItemTypeEnum[keyof typeof RowAttributeTransformationDefinitionItemRequestRowAttributeTransformationItemTypeEnum];

/**
 * @export
 */
export const RowAttributeTransformationDefinitionItemRequestItemDataTypeEnum = {
    Tinyint: 'TINYINT',
    Smallint: 'SMALLINT',
    Integer: 'INTEGER',
    Bigint: 'BIGINT',
    Real: 'REAL',
    Double: 'DOUBLE',
    Numeric: 'NUMERIC',
    Decimal: 'DECIMAL',
    Char: 'CHAR',
    Varchar: 'VARCHAR',
    Longvarchar: 'LONGVARCHAR',
    Longnvarchar: 'LONGNVARCHAR',
    Date: 'DATE',
    Time: 'TIME',
    Null: 'NULL',
    Timestamp: 'TIMESTAMP',
    Boolean: 'BOOLEAN',
    Rowid: 'ROWID',
    Nchar: 'NCHAR',
    Nvarchar: 'NVARCHAR',
    TimeWithTimezone: 'TIME_WITH_TIMEZONE',
    TimestampWithTimezone: 'TIMESTAMP_WITH_TIMEZONE',
    Bfile: 'BFILE',
    IsoDateTime: 'ISO_DATE_TIME',
    Bit: 'BIT',
    Binary: 'BINARY',
    DmsFileUuid: 'DMS_FILE_UUID'
} as const;
export type RowAttributeTransformationDefinitionItemRequestItemDataTypeEnum = typeof RowAttributeTransformationDefinitionItemRequestItemDataTypeEnum[keyof typeof RowAttributeTransformationDefinitionItemRequestItemDataTypeEnum];

/**
 * @export
 */
export const RowAttributeTransformationDefinitionItemRequestReferenceAttributeTypeEnum = {
    ShmuId: 'SHMU_ID',
    Date: 'DATE',
    CatalogCode: 'CATALOG_CODE',
    MonPointType: 'MON_POINT_TYPE',
    MonitoringType: 'MONITORING_TYPE',
    ValueControl: 'VALUE_CONTROL',
    ValueNumAux: 'VALUE_NUM_AUX',
    DetId: 'DET_ID',
    LaboratoryId: 'LABORATORY_ID',
    DateStr: 'DATE_STR',
    DetCode: 'DET_CODE',
    DetValueMax: 'DET_VALUE_MAX',
    SampleCode: 'SAMPLE_CODE',
    ItemToDecode: 'ITEM_TO_DECODE',
    DecodeMap: 'DECODE_MAP',
    DecodeDefault: 'DECODE_DEFAULT',
    DateFormat: 'DATE_FORMAT',
    SeasonYearShift: 'SEASON_YEAR_SHIFT',
    SeasonDefault: 'SEASON_DEFAULT',
    Time: 'TIME',
    MethodId: 'METHOD_ID',
    MethodCode: 'METHOD_CODE',
    StateOfValue: 'STATE_OF_VALUE',
    Value: 'VALUE',
    NullValue: 'NULL_VALUE',
    ValueUnit: 'VALUE_UNIT',
    ReferenceAttributeCode: 'REFERENCE_ATTRIBUTE_CODE',
    Day: 'DAY',
    Month: 'MONTH',
    Year: 'YEAR',
    Hour: 'HOUR',
    Minute: 'MINUTE',
    Volume: 'VOLUME',
    Scale: 'SCALE',
    Shift: 'SHIFT',
    LaboratoryCode: 'LABORATORY_CODE',
    StateOfValueReference: 'STATE_OF_VALUE_REFERENCE',
    BeginIndex: 'BEGIN_INDEX',
    EndIndex: 'END_INDEX',
    Regex: 'REGEX',
    Source: 'SOURCE',
    Replacement: 'REPLACEMENT',
    ResultIfMatch: 'RESULT_IF_MATCH',
    ResultIfNoMatch: 'RESULT_IF_NO_MATCH',
    YearToDetermineCentury: 'YEAR_TO_DETERMINE_CENTURY',
    MonPointId: 'MON_POINT_ID',
    CodelistItemCode: 'CODELIST_ITEM_CODE',
    CodelistItemCodeConstant: 'CODELIST_ITEM_CODE_CONSTANT',
    SourceDate: 'SOURCE_DATE',
    SourceTime: 'SOURCE_TIME',
    CodelistItemAttribute: 'CODELIST_ITEM_ATTRIBUTE',
    ConstantValue: 'CONSTANT_VALUE',
    DateSource: 'DATE_SOURCE',
    ConstantSource: 'CONSTANT_SOURCE',
    ArraySource: 'ARRAY_SOURCE',
    NumberSource: 'NUMBER_SOURCE',
    Result: 'RESULT',
    DecodeDefaultConstant: 'DECODE_DEFAULT_CONSTANT',
    CorrectionNullSource: 'CORRECTION_NULL_SOURCE',
    CorrectionDeterminantSource: 'CORRECTION_DETERMINANT_SOURCE',
    CorrectionUnderDetectionLimitSource: 'CORRECTION_UNDER_DETECTION_LIMIT_SOURCE',
    CorrectionValueStrSource: 'CORRECTION_VALUE_STR_SOURCE',
    CorrectionValueNumSource: 'CORRECTION_VALUE_NUM_SOURCE',
    GenerateIdSource: 'GENERATE_ID_SOURCE',
    RegexReplaceSource: 'REGEX_REPLACE_SOURCE',
    RegexReplaceRegex: 'REGEX_REPLACE_REGEX',
    RegexReplaceReplacement: 'REGEX_REPLACE_REPLACEMENT',
    DecodeFloodingMonthSource: 'DECODE_FLOODING_MONTH_SOURCE',
    GetFromCacheKey: 'GET_FROM_CACHE_KEY',
    GetFromCacheDataCode: 'GET_FROM_CACHE_DATA_CODE',
    SetToCacheKey: 'SET_TO_CACHE_KEY',
    SetToCacheDataCode: 'SET_TO_CACHE_DATA_CODE',
    AdditionalAttributeName: 'ADDITIONAL_ATTRIBUTE_NAME',
    AdditionalAttributeValue: 'ADDITIONAL_ATTRIBUTE_VALUE',
    SubstringSource: 'SUBSTRING_SOURCE',
    SubstringBeginIndex: 'SUBSTRING_BEGIN_INDEX',
    SubstringEndIndex: 'SUBSTRING_END_INDEX',
    CorrectionSampleCodeSource: 'CORRECTION_SAMPLE_CODE_SOURCE',
    CorrectionSampleCodeShift: 'CORRECTION_SAMPLE_CODE_SHIFT',
    CorrectionSampleCodeSupplier: 'CORRECTION_SAMPLE_CODE_SUPPLIER',
    FlowFromVolumeSource: 'FLOW_FROM_VOLUME_SOURCE',
    FlowFromVolumeMonth: 'FLOW_FROM_VOLUME_MONTH',
    FlowFromVolumeYear: 'FLOW_FROM_VOLUME_YEAR',
    FlowFromVolumeScale: 'FLOW_FROM_VOLUME_SCALE',
    GetDataIdShift: 'GET_DATA_ID_SHIFT',
    MonPointBalanceCode: 'MON_POINT_BALANCE_CODE',
    SeasonCodeFromYearSource: 'SEASON_CODE_FROM_YEAR_SOURCE',
    TimeFormat: 'TIME_FORMAT',
    NumberNullValue: 'NUMBER_NULL_VALUE',
    DataRegistryProductName: 'DATA_REGISTRY_PRODUCT_NAME',
    DataRegistryResultAttributeCode: 'DATA_REGISTRY_RESULT_ATTRIBUTE_CODE',
    DataRegistryVariableName1: 'DATA_REGISTRY_VARIABLE_NAME_1',
    DataRegistryVariableName2: 'DATA_REGISTRY_VARIABLE_NAME_2',
    DataRegistryVariableName3: 'DATA_REGISTRY_VARIABLE_NAME_3',
    DataRegistryVariableName4: 'DATA_REGISTRY_VARIABLE_NAME_4',
    DataRegistryVariableName5: 'DATA_REGISTRY_VARIABLE_NAME_5',
    DataRegistryVariableName6: 'DATA_REGISTRY_VARIABLE_NAME_6',
    DataRegistryVariableName7: 'DATA_REGISTRY_VARIABLE_NAME_7',
    DataRegistryVariableName8: 'DATA_REGISTRY_VARIABLE_NAME_8',
    DataRegistryVariableName9: 'DATA_REGISTRY_VARIABLE_NAME_9',
    DataRegistryVariableName10: 'DATA_REGISTRY_VARIABLE_NAME_10',
    DataRegistryVariableValue1: 'DATA_REGISTRY_VARIABLE_VALUE_1',
    DataRegistryVariableValue2: 'DATA_REGISTRY_VARIABLE_VALUE_2',
    DataRegistryVariableValue3: 'DATA_REGISTRY_VARIABLE_VALUE_3',
    DataRegistryVariableValue4: 'DATA_REGISTRY_VARIABLE_VALUE_4',
    DataRegistryVariableValue5: 'DATA_REGISTRY_VARIABLE_VALUE_5',
    DataRegistryVariableValue6: 'DATA_REGISTRY_VARIABLE_VALUE_6',
    DataRegistryVariableValue7: 'DATA_REGISTRY_VARIABLE_VALUE_7',
    DataRegistryVariableValue8: 'DATA_REGISTRY_VARIABLE_VALUE_8',
    DataRegistryVariableValue9: 'DATA_REGISTRY_VARIABLE_VALUE_9',
    DataRegistryVariableValue10: 'DATA_REGISTRY_VARIABLE_VALUE_10',
    DataRegistryVariableOperator1: 'DATA_REGISTRY_VARIABLE_OPERATOR_1',
    DataRegistryVariableOperator2: 'DATA_REGISTRY_VARIABLE_OPERATOR_2',
    DataRegistryVariableOperator3: 'DATA_REGISTRY_VARIABLE_OPERATOR_3',
    DataRegistryVariableOperator4: 'DATA_REGISTRY_VARIABLE_OPERATOR_4',
    DataRegistryVariableOperator5: 'DATA_REGISTRY_VARIABLE_OPERATOR_5',
    DataRegistryVariableOperator6: 'DATA_REGISTRY_VARIABLE_OPERATOR_6',
    DataRegistryVariableOperator7: 'DATA_REGISTRY_VARIABLE_OPERATOR_7',
    DataRegistryVariableOperator8: 'DATA_REGISTRY_VARIABLE_OPERATOR_8',
    DataRegistryVariableOperator9: 'DATA_REGISTRY_VARIABLE_OPERATOR_9',
    DataRegistryVariableOperator10: 'DATA_REGISTRY_VARIABLE_OPERATOR_10',
    VolumeFromFlowSource: 'VOLUME_FROM_FLOW_SOURCE',
    VolumeFromFlowMonth: 'VOLUME_FROM_FLOW_MONTH',
    VolumeFromFlowYear: 'VOLUME_FROM_FLOW_YEAR',
    VolumeFromFlowScale: 'VOLUME_FROM_FLOW_SCALE',
    BitSource: 'BIT_SOURCE',
    PositionType: 'POSITION_TYPE',
    RegexReplaceIsPartOfString: 'REGEX_REPLACE_IS_PART_OF_STRING',
    JoinStringSource: 'JOIN_STRING_SOURCE',
    MethodicMethodCodeInt: 'METHODIC_METHOD_CODE_INT',
    MethodicDeterminantCodeInt: 'METHODIC_DETERMINANT_CODE_INT',
    AddLeadingZerosSource: 'ADD_LEADING_ZEROS_SOURCE',
    AddLeadingZerosPrecision: 'ADD_LEADING_ZEROS_PRECISION',
    ConvertToMultipleValuesSource: 'CONVERT_TO_MULTIPLE_VALUES_SOURCE',
    SumSource: 'SUM_SOURCE',
    Dividend: 'DIVIDEND',
    Divisor: 'DIVISOR',
    CachedData: 'CACHED_DATA',
    CacheKey: 'CACHE_KEY',
    Condition: 'CONDITION'
} as const;
export type RowAttributeTransformationDefinitionItemRequestReferenceAttributeTypeEnum = typeof RowAttributeTransformationDefinitionItemRequestReferenceAttributeTypeEnum[keyof typeof RowAttributeTransformationDefinitionItemRequestReferenceAttributeTypeEnum];


/**
 * Check if a given object implements the RowAttributeTransformationDefinitionItemRequest interface.
 */
export function instanceOfRowAttributeTransformationDefinitionItemRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RowAttributeTransformationDefinitionItemRequestFromJSON(json: any): RowAttributeTransformationDefinitionItemRequest {
    return RowAttributeTransformationDefinitionItemRequestFromJSONTyped(json, false);
}

export function RowAttributeTransformationDefinitionItemRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): RowAttributeTransformationDefinitionItemRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'rowAttributeTransformationItemType': !exists(json, 'rowAttributeTransformationItemType') ? undefined : json['rowAttributeTransformationItemType'],
        'item': !exists(json, 'item') ? undefined : json['item'],
        'itemDataType': !exists(json, 'itemDataType') ? undefined : json['itemDataType'],
        'referenceAttributeType': !exists(json, 'referenceAttributeType') ? undefined : json['referenceAttributeType'],
    };
}

export function RowAttributeTransformationDefinitionItemRequestToJSON(value?: RowAttributeTransformationDefinitionItemRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'rowAttributeTransformationItemType': value.rowAttributeTransformationItemType,
        'item': value.item,
        'itemDataType': value.itemDataType,
        'referenceAttributeType': value.referenceAttributeType,
    };
}

