import React, { FC, ReactNode } from 'react';

export type ToastMessageProps = {
    title: string;
    message: ReactNode;
    clipboard?: {
        title: string;
        content: string;
    };
};

const ToastMessage: FC<ToastMessageProps> = ({ title, message, clipboard }) => {
    return (
        <>
            <p className='text-sm font-semibold leading-5'>{title}</p>
            <div className='text-sm font-normal leading-5'>{message}</div>
            {clipboard && (
                <div className='text-sm font-normal leading-5'>
                    <button
                        className='background-transparent mt-1 text-sm font-bold uppercase underline outline-none transition-all duration-150 ease-linear focus:outline-none'
                        onClick={(e) => {
                            e.stopPropagation();
                            navigator.clipboard.writeText(clipboard.content);
                        }}
                    >
                        {clipboard.title}
                    </button>
                </div>
            )}
        </>
    );
};

export default ToastMessage;
