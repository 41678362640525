/* tslint:disable */
/* eslint-disable */
/**
 * Notification API
 * Nástroj pre posielanie notifikácií - koncové služby
 *
 * The version of the OpenAPI document: 0.8.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  EndpointExceptionBody,
  NotifyCriteriaSo,
  NotifySo,
  PageNotifySo,
} from '../models/index';
import {
    EndpointExceptionBodyFromJSON,
    EndpointExceptionBodyToJSON,
    NotifyCriteriaSoFromJSON,
    NotifyCriteriaSoToJSON,
    NotifySoFromJSON,
    NotifySoToJSON,
    PageNotifySoFromJSON,
    PageNotifySoToJSON,
} from '../models/index';

export interface NotifyApiDeleteNotifyRequest {
    body: number;
}

export interface NotifyApiDeleteNotify1Request {
    requestBody: Array<number>;
}

export interface NotifyApiGetByCriteriaRequest {
    notifyCriteriaSo: NotifyCriteriaSo;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface NotifyApiMarkNotifiesAsReadRequest {
    requestBody: Array<number>;
}

export interface NotifyApiMarkNotifyAsReadRequest {
    body: number;
}

/**
 * 
 */
export class NotifyApi extends runtime.BaseAPI {

    /**
     */
    async deleteNotifyRaw(requestParameters: NotifyApiDeleteNotifyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling deleteNotify.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/notify/delete`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async deleteNotify(body: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.deleteNotifyRaw({ body: body }, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteNotify1Raw(requestParameters: NotifyApiDeleteNotify1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
            throw new runtime.RequiredError('requestBody','Required parameter requestParameters.requestBody was null or undefined when calling deleteNotify1.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/notify/delete-list`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async deleteNotify1(requestBody: Array<number>, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.deleteNotify1Raw({ requestBody: requestBody }, initOverrides);
        return await response.value();
    }

    /**
     */
    async getByCriteriaRaw(requestParameters: NotifyApiGetByCriteriaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageNotifySo>> {
        if (requestParameters.notifyCriteriaSo === null || requestParameters.notifyCriteriaSo === undefined) {
            throw new runtime.RequiredError('notifyCriteriaSo','Required parameter requestParameters.notifyCriteriaSo was null or undefined when calling getByCriteria.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/notify/by-criteria`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NotifyCriteriaSoToJSON(requestParameters.notifyCriteriaSo),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageNotifySoFromJSON(jsonValue));
    }

    /**
     */
    async getByCriteria(notifyCriteriaSo: NotifyCriteriaSo, page?: number, size?: number, sort?: Array<string>, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageNotifySo> {
        const response = await this.getByCriteriaRaw({ notifyCriteriaSo: notifyCriteriaSo, page: page, size: size, sort: sort }, initOverrides);
        return await response.value();
    }

    /**
     */
    async getNotificationsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<NotifySo>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/notify/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(NotifySoFromJSON));
    }

    /**
     */
    async getNotifications(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<NotifySo>> {
        const response = await this.getNotificationsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async markNotifiesAsReadRaw(requestParameters: NotifyApiMarkNotifiesAsReadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<NotifySo>>> {
        if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
            throw new runtime.RequiredError('requestBody','Required parameter requestParameters.requestBody was null or undefined when calling markNotifiesAsRead.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/notify/mark-as-read-list`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(NotifySoFromJSON));
    }

    /**
     */
    async markNotifiesAsRead(requestBody: Array<number>, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<NotifySo>> {
        const response = await this.markNotifiesAsReadRaw({ requestBody: requestBody }, initOverrides);
        return await response.value();
    }

    /**
     */
    async markNotifyAsReadRaw(requestParameters: NotifyApiMarkNotifyAsReadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NotifySo>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling markNotifyAsRead.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/notify/mark-as-read`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NotifySoFromJSON(jsonValue));
    }

    /**
     */
    async markNotifyAsRead(body: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NotifySo> {
        const response = await this.markNotifyAsReadRaw({ body: body }, initOverrides);
        return await response.value();
    }

}
