import React, { useContext, useEffect, useMemo, useReducer } from 'react';
import { Link, To, useLocation, useNavigate } from 'react-router-dom';
import {
    ArrowLeftOnRectangleIcon,
    Bars3CenterLeftIcon,
    ChevronDownIcon,
    Cog6ToothIcon,
    Squares2X2Icon,
    MoonIcon,
    SunIcon,
    StarIcon as StarOutline,
    EnvelopeIcon
} from '@heroicons/react/24/outline';
import { UserCircleIcon, HomeIcon, StarIcon as StarSolid } from '@heroicons/react/20/solid';
import { Button, Dropdown, IconButton, Modal, Textarea } from '@mc-ui/react-components';
import { Favorite, PreferencesContext } from '../../store/preferences-context';
import { useAuth } from 'react-oidc-context';
import useNotificatorApi from '../../hooks/use-notificator-api';
import { AccountApi } from '../../openapi/notificator';
import { notificationCountQueryKey } from '../../utils/queries';
import { useQuery, useQueryClient } from '@tanstack/react-query';

export type Breadcrumb = {
    name: string;
    to?: To;
    state?: any;
};

export type HeaderProps = {
    breadcrumbs?: Breadcrumb[];
};

enum actionType {
    OPEN = 'OPEN',
    SET_NAME = 'SET_NAME',
    CLOSE = 'CLOSE'
}

type State = {
    isOpen: boolean;
    data: Favorite;
};

type Action =
    | {
          type: actionType.OPEN;
          payload: Favorite;
      }
    | {
          type: actionType.SET_NAME;
          payload: string;
      }
    | {
          type: actionType.CLOSE;
      };

const initialState: State = {
    isOpen: false,
    data: {
        url: '',
        name: ''
    }
};

const favoriteDialogReducer = (state: State, action: Action): State => {
    switch (action.type) {
        case actionType.OPEN: {
            return {
                isOpen: true,
                data: action.payload
            };
        }
        case actionType.SET_NAME: {
            return {
                ...state,
                data: {
                    url: state.data.url,
                    name: action.payload
                }
            };
        }
        case actionType.CLOSE: {
            return {
                ...state,
                isOpen: false
            };
        }
    }
};

const ACCOUNT_URL =
    ENV.KEYCLOAK_URL +
    '/realms/' +
    ENV.KEYCLOAK_REALM +
    '/account?referrer=' +
    ENV.KEYCLOAK_CLIENT +
    '&referrer_uri=' +
    window.location.href;

const HeaderActions: React.FC<HeaderProps> = ({ breadcrumbs = [] }) => {
    const { signoutRedirect, user } = useAuth();

    const fullName = useMemo(() => {
        if (user && user.profile) {
            if (user.profile.given_name && user.profile.family_name) {
                return `${user.profile.given_name} ${user.profile.family_name}`;
            } else if (user.profile.preferred_username) {
                return user.profile.preferred_username;
            } else if (user.profile.email) {
                return user.profile.email;
            }
        }
        return 'Používateľ';
    }, [user]);

    const email = user?.profile.email;

    const navigate = useNavigate();
    const { isFavorite, addFavorite, removeFavorite, defaultTheme, setDefaultTheme } = useContext(PreferencesContext);
    const { pathname } = useLocation();
    const accountApi = useNotificatorApi(AccountApi, {
        noErrorMiddleware: true
    });
    const queryClient = useQueryClient();

    const hasBreadcrumb = breadcrumbs.length > 0;
    const favorite = useMemo(() => isFavorite(pathname), [pathname, isFavorite]);

    const [favoriteDialog, dispatchFavoriteDialog] = useReducer(favoriteDialogReducer, initialState);

    const toggleFavorite = () => {
        if (favorite) {
            removeFavorite(pathname);
        } else {
            if (hasBreadcrumb) {
                dispatchFavoriteDialog({
                    type: actionType.OPEN,
                    payload: {
                        url: pathname,
                        name: breadcrumbs[breadcrumbs.length - 1].name.substring(0, 39)
                    }
                });
            }
        }
    };

    const favoriteNameHandler = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const val = event.target.value ?? '';
        dispatchFavoriteDialog({
            type: actionType.SET_NAME,
            payload: val
        });
    };

    const addFavoriteConfirmHandler = (confirm: boolean) => {
        if (confirm && favoriteDialog.data) {
            addFavorite(favoriteDialog.data);
        }
        dispatchFavoriteDialog({ type: actionType.CLOSE });
    };

    const logoutHandler = () =>
        signoutRedirect({
            /* eslint-disable-next-line camelcase */
            post_logout_redirect_uri: window.location.origin
        });

    const profileHandler = () => {
        window.location.href = ACCOUNT_URL;
    };

    const { data: notificationCount } = useQuery({
        queryKey: notificationCountQueryKey(),
        queryFn: () => accountApi.unreadNotifications(),
        retry: false,
        refetchInterval: 60000
    });

    useEffect(() => {
        if (pathname) {
            const state = queryClient.getQueryState(notificationCountQueryKey());
            if (Date.now() - (state?.dataUpdatedAt ?? 0) > 5000) {
                queryClient.refetchQueries({
                    queryKey: notificationCountQueryKey()
                });
            }
        }
    }, [pathname, email, queryClient]);

    return (
        <>
            {/* mobil */}
            <div className='flex lg:hidden'>
                <Dropdown
                    items={[
                        ...(hasBreadcrumb
                            ? [
                                  {
                                      label: favorite ? 'Odobrať z obľúbené' : 'Pridať do obľúbené',
                                      onClick: toggleFavorite,
                                      icon: favorite ? StarSolid : StarOutline
                                  }
                              ]
                            : []),
                        {
                            label: 'Téma',
                            onClick: () => {
                                if (defaultTheme === 'light') {
                                    setDefaultTheme('dark');
                                } else {
                                    setDefaultTheme('light');
                                }
                            },
                            icon: defaultTheme === 'light' ? MoonIcon : SunIcon
                        },
                        {
                            label: 'Domovská obrazovka',
                            onClick: () => {
                                navigate('/dashboard');
                            },
                            icon: Squares2X2Icon
                        },
                        'separator',
                        {
                            label: 'Profil používateľa',
                            onClick: profileHandler,
                            icon: UserCircleIcon
                        },
                        {
                            label: 'Nastavenia',
                            onClick: () => {
                                navigate('/settings/appearance');
                            },
                            icon: Cog6ToothIcon
                        },
                        {
                            label: 'Notifikácie: ' + (notificationCount ?? 0),
                            onClick: () => {
                                navigate('/notifications');
                            },
                            icon: EnvelopeIcon
                        },
                        {
                            label: 'Odhlásiť sa',
                            onClick: logoutHandler,
                            icon: ArrowLeftOnRectangleIcon
                        }
                    ]}
                >
                    <IconButton
                        title='Používateľské menu'
                        variant='rounded'
                        size='large'
                        color='flat'
                        icon={Bars3CenterLeftIcon}
                    />
                </Dropdown>
            </div>
            {/* desktop */}
            <div className='hidden items-center justify-end lg:flex'>
                <div className='flex items-center space-x-4'>
                    {hasBreadcrumb && (
                        <IconButton
                            title={favorite ? 'Odobrať z obľúbené' : 'Pridať do obľúbené'}
                            variant='rounded'
                            size='large'
                            color='flat'
                            icon={favorite ? StarSolid : StarOutline}
                            onClick={toggleFavorite}
                        />
                    )}
                    <IconButton
                        title='Téma'
                        variant='rounded'
                        size='large'
                        color='flat'
                        icon={defaultTheme === 'light' ? MoonIcon : SunIcon}
                        onClick={() => {
                            if (defaultTheme === 'light') {
                                setDefaultTheme('dark');
                            } else {
                                setDefaultTheme('light');
                            }
                        }}
                    />
                    <IconButton
                        title='Domovská obrazovka'
                        variant='rounded'
                        size='large'
                        color='flat'
                        icon={Squares2X2Icon}
                        as={Link}
                        to='/dashboard'
                    />
                </div>
                <div className='ml-4 border-l border-light-borderLight pl-4 dark:border-dark-borderLight'>
                    <Dropdown
                        items={[
                            {
                                label: 'Profil používateľa',
                                onClick: profileHandler,
                                icon: UserCircleIcon
                            },
                            {
                                label: 'Nastavenia',
                                onClick: () => {
                                    navigate('/settings/appearance');
                                },
                                icon: Cog6ToothIcon
                            },
                            {
                                label: 'Notifikácie: ' + (notificationCount ?? 0),
                                onClick: () => {
                                    navigate('/notifications');
                                },
                                icon: EnvelopeIcon
                            },
                            {
                                label: 'Odhlásiť sa',
                                onClick: logoutHandler,
                                icon: ArrowLeftOnRectangleIcon
                            }
                        ]}
                    >
                        <Button
                            color='flat'
                            startIcon={UserCircleIcon}
                            startIconClassName='h-8 w-8 text-light-textDark dark:text-dark-textDark'
                            endIcon={ChevronDownIcon}
                            endIconClassName='ml-1 hidden h-5 w-5 flex-shrink-0 text-light-inputAction dark:text-dark-inputAction lg:block'
                        >
                            <span className='ml-3 hidden text-sm font-medium text-light-text  dark:text-dark-text lg:block'>
                                <span className='sr-only'>Otvoriť používateľské menu pre </span>
                                {fullName}
                            </span>
                            {notificationCount !== undefined && notificationCount > 0 && (
                                <span
                                    className='ml-5 mb-6 w-5 h-5 text-sm rounded-full font-medium text-light-textPrimary bg-light-primary dark:text-dark-text dark:bg-dark-primary absolute'
                                    title={'Počet notifikácií: ' + notificationCount}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        navigate('/notifications');
                                    }}
                                >
                                    <span className='sr-only'>Počet notifikácií</span>
                                    {notificationCount > 9 ? '9+' : notificationCount}
                                </span>
                            )}
                        </Button>
                    </Dropdown>
                </div>
            </div>
            <Modal
                isOpen={favoriteDialog.isOpen}
                body={
                    <Textarea
                        label='Názov obľúbenej položky'
                        value={favoriteDialog.data?.name ?? ''}
                        onChange={favoriteNameHandler}
                        maxLength={40}
                    />
                }
                type='positive'
                icon={StarSolid}
                title='Obľúbená položka'
                confirmLabel='Uložiť'
                onClose={addFavoriteConfirmHandler}
            />
        </>
    );
};

const HeaderBreadcrumb: React.FC<HeaderProps> = ({ breadcrumbs = [] }) => {
    return (
        <div
            className='min-w-0 flex-1'
            aria-label='Breadcrumb'
        >
            <ol className='inline-flex h-full w-full space-x-4'>
                <li className='flex items-center'>
                    <Link
                        to='/'
                        className='text-gray-400 hover:text-gray-500'
                        title='Domovská obrazovka'
                    >
                        <HomeIcon
                            className='h-5 w-5 flex-shrink-0'
                            aria-hidden='true'
                        />
                    </Link>
                </li>
                {breadcrumbs.map((page, idx, array) => {
                    const last = array.length === idx + 1;
                    return (
                        <li
                            key={`${page.name}_${idx}`}
                            className='hidden items-center sm:flex'
                            style={{
                                maxWidth: 'calc(' + Math.floor(100 / array.length) + '% - 20px)'
                            }}
                        >
                            <svg
                                className='h-5 w-5 flex-shrink-0 text-gray-300'
                                xmlns='http://www.w3.org/2000/svg'
                                fill='currentColor'
                                viewBox='0 0 20 20'
                                aria-hidden='true'
                            >
                                <path d='M5.555 17.776l8-16 .894.448-8 16-.894-.448z' />
                            </svg>
                            {last || !page.to ? (
                                <span
                                    aria-current={true}
                                    className='ml-4 truncate text-sm font-medium text-gray-400'
                                    title={page.name}
                                >
                                    {page.name}
                                </span>
                            ) : (
                                <Link
                                    to={page.to}
                                    state={page.state}
                                    title={page.name}
                                    className='ml-4 truncate text-sm font-medium text-gray-400 hover:text-gray-700 hover:underline hover:underline-offset-2'
                                >
                                    {page.name}
                                </Link>
                            )}
                        </li>
                    );
                })}
            </ol>
        </div>
    );
};

const Header: React.FC<HeaderProps> = ({ breadcrumbs }) => {
    return (
        <header className='flex justify-between border-b border-light-border py-3 pl-4 pr-2 dark:border-dark-border sm:pl-6 sm:pr-4 lg:pl-8 lg:pr-6'>
            <HeaderBreadcrumb breadcrumbs={breadcrumbs} />
            <HeaderActions breadcrumbs={breadcrumbs} />
        </header>
    );
};

export default Header;
