/* tslint:disable */
/* eslint-disable */
/**
 * Catalog API
 * Nástroj pre správu metadát a katalógov - koncové služby
 *
 * The version of the OpenAPI document: 0.27.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MonitoringExistSo
 */
export interface MonitoringExistSo {
    /**
     * 
     * @type {number}
     * @memberof MonitoringExistSo
     */
    montPointType?: number;
    /**
     * 
     * @type {string}
     * @memberof MonitoringExistSo
     */
    effectiveFrom?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitoringExistSo
     */
    effectiveTo?: string;
    /**
     * 
     * @type {number}
     * @memberof MonitoringExistSo
     */
    historyGroupId?: number;
}

/**
 * Check if a given object implements the MonitoringExistSo interface.
 */
export function instanceOfMonitoringExistSo(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MonitoringExistSoFromJSON(json: any): MonitoringExistSo {
    return MonitoringExistSoFromJSONTyped(json, false);
}

export function MonitoringExistSoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitoringExistSo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'montPointType': !exists(json, 'montPointType') ? undefined : json['montPointType'],
        'effectiveFrom': !exists(json, 'effectiveFrom') ? undefined : json['effectiveFrom'],
        'effectiveTo': !exists(json, 'effectiveTo') ? undefined : json['effectiveTo'],
        'historyGroupId': !exists(json, 'historyGroupId') ? undefined : json['historyGroupId'],
    };
}

export function MonitoringExistSoToJSON(value?: MonitoringExistSo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'montPointType': value.montPointType,
        'effectiveFrom': value.effectiveFrom,
        'effectiveTo': value.effectiveTo,
        'historyGroupId': value.historyGroupId,
    };
}

