/* tslint:disable */
/* eslint-disable */
/**
 * Codelist API
 * Nástroj pre správu metadát a katalógov - koncové služby
 *
 * The version of the OpenAPI document: 0.21.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CodelistItemTemplateAttributeResponse
 */
export interface CodelistItemTemplateAttributeResponse {
    /**
     * 
     * @type {string}
     * @memberof CodelistItemTemplateAttributeResponse
     */
    attributeTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof CodelistItemTemplateAttributeResponse
     */
    attributeKey?: string;
    /**
     * 
     * @type {number}
     * @memberof CodelistItemTemplateAttributeResponse
     */
    columnPosition?: number;
}

/**
 * Check if a given object implements the CodelistItemTemplateAttributeResponse interface.
 */
export function instanceOfCodelistItemTemplateAttributeResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CodelistItemTemplateAttributeResponseFromJSON(json: any): CodelistItemTemplateAttributeResponse {
    return CodelistItemTemplateAttributeResponseFromJSONTyped(json, false);
}

export function CodelistItemTemplateAttributeResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CodelistItemTemplateAttributeResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'attributeTitle': !exists(json, 'attributeTitle') ? undefined : json['attributeTitle'],
        'attributeKey': !exists(json, 'attributeKey') ? undefined : json['attributeKey'],
        'columnPosition': !exists(json, 'columnPosition') ? undefined : json['columnPosition'],
    };
}

export function CodelistItemTemplateAttributeResponseToJSON(value?: CodelistItemTemplateAttributeResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'attributeTitle': value.attributeTitle,
        'attributeKey': value.attributeKey,
        'columnPosition': value.columnPosition,
    };
}

