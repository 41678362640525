/* tslint:disable */
/* eslint-disable */
/**
 * Catalog import API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.4.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  EndpointExceptionBody,
  Import,
  ImportCriteria,
  ImportResult,
  ImportSimpleResult,
  Monitoring,
  PageImportResult,
  PointCategories,
} from '../models/index';
import {
    EndpointExceptionBodyFromJSON,
    EndpointExceptionBodyToJSON,
    ImportFromJSON,
    ImportToJSON,
    ImportCriteriaFromJSON,
    ImportCriteriaToJSON,
    ImportResultFromJSON,
    ImportResultToJSON,
    ImportSimpleResultFromJSON,
    ImportSimpleResultToJSON,
    MonitoringFromJSON,
    MonitoringToJSON,
    PageImportResultFromJSON,
    PageImportResultToJSON,
    PointCategoriesFromJSON,
    PointCategoriesToJSON,
} from '../models/index';

export interface ImportControllerApiAddMonitoringRequest {
    id: number;
    monitoring: Monitoring;
}

export interface ImportControllerApiAddPointCategoriesRequest {
    id: number;
    pointCategories: PointCategories;
}

export interface ImportControllerApiDeleteFileByIdRequest {
    id: number;
}

export interface ImportControllerApiFindFileBySearchCriteriaRequest {
    importCriteria: ImportCriteria;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface ImportControllerApiGetFileByIdRequest {
    id: number;
}

export interface ImportControllerApiGetStatusAndErrorCountByIdRequest {
    id: number;
}

export interface ImportControllerApiUpdateFileByIdRequest {
    id: number;
    _import: Import;
}

export interface ImportControllerApiUploadFileRequest {
    delimiter: string;
    montPointTypeCode: number;
    multipartFile: Blob;
}

export interface ImportControllerApiValidateMontPointDataByFileIdRequest {
    id: number;
}

/**
 * 
 */
export class ImportControllerApi extends runtime.BaseAPI {

    /**
     */
    async addMonitoringRaw(requestParameters: ImportControllerApiAddMonitoringRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ImportResult>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling addMonitoring.');
        }

        if (requestParameters.monitoring === null || requestParameters.monitoring === undefined) {
            throw new runtime.RequiredError('monitoring','Required parameter requestParameters.monitoring was null or undefined when calling addMonitoring.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/import-file/add-monitoring/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MonitoringToJSON(requestParameters.monitoring),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ImportResultFromJSON(jsonValue));
    }

    /**
     */
    async addMonitoring(id: number, monitoring: Monitoring, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ImportResult> {
        const response = await this.addMonitoringRaw({ id: id, monitoring: monitoring }, initOverrides);
        return await response.value();
    }

    /**
     */
    async addPointCategoriesRaw(requestParameters: ImportControllerApiAddPointCategoriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ImportResult>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling addPointCategories.');
        }

        if (requestParameters.pointCategories === null || requestParameters.pointCategories === undefined) {
            throw new runtime.RequiredError('pointCategories','Required parameter requestParameters.pointCategories was null or undefined when calling addPointCategories.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/import-file/add-point-categories/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PointCategoriesToJSON(requestParameters.pointCategories),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ImportResultFromJSON(jsonValue));
    }

    /**
     */
    async addPointCategories(id: number, pointCategories: PointCategories, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ImportResult> {
        const response = await this.addPointCategoriesRaw({ id: id, pointCategories: pointCategories }, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteFileByIdRaw(requestParameters: ImportControllerApiDeleteFileByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteFileById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/import-file/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteFileById(id: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteFileByIdRaw({ id: id }, initOverrides);
    }

    /**
     */
    async findFileBySearchCriteriaRaw(requestParameters: ImportControllerApiFindFileBySearchCriteriaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageImportResult>> {
        if (requestParameters.importCriteria === null || requestParameters.importCriteria === undefined) {
            throw new runtime.RequiredError('importCriteria','Required parameter requestParameters.importCriteria was null or undefined when calling findFileBySearchCriteria.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/import-file/by-criteria`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ImportCriteriaToJSON(requestParameters.importCriteria),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageImportResultFromJSON(jsonValue));
    }

    /**
     */
    async findFileBySearchCriteria(importCriteria: ImportCriteria, page?: number, size?: number, sort?: Array<string>, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageImportResult> {
        const response = await this.findFileBySearchCriteriaRaw({ importCriteria: importCriteria, page: page, size: size, sort: sort }, initOverrides);
        return await response.value();
    }

    /**
     */
    async getFileByIdRaw(requestParameters: ImportControllerApiGetFileByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ImportResult>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getFileById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/import-file/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ImportResultFromJSON(jsonValue));
    }

    /**
     */
    async getFileById(id: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ImportResult> {
        const response = await this.getFileByIdRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async getStatusAndErrorCountByIdRaw(requestParameters: ImportControllerApiGetStatusAndErrorCountByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ImportSimpleResult>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getStatusAndErrorCountById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/import-file/status-error-count/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ImportSimpleResultFromJSON(jsonValue));
    }

    /**
     */
    async getStatusAndErrorCountById(id: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ImportSimpleResult> {
        const response = await this.getStatusAndErrorCountByIdRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateFileByIdRaw(requestParameters: ImportControllerApiUpdateFileByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ImportResult>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateFileById.');
        }

        if (requestParameters._import === null || requestParameters._import === undefined) {
            throw new runtime.RequiredError('_import','Required parameter requestParameters._import was null or undefined when calling updateFileById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/import-file/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ImportToJSON(requestParameters._import),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ImportResultFromJSON(jsonValue));
    }

    /**
     */
    async updateFileById(id: number, _import: Import, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ImportResult> {
        const response = await this.updateFileByIdRaw({ id: id, _import: _import }, initOverrides);
        return await response.value();
    }

    /**
     */
    async uploadFileRaw(requestParameters: ImportControllerApiUploadFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ImportResult>> {
        if (requestParameters.delimiter === null || requestParameters.delimiter === undefined) {
            throw new runtime.RequiredError('delimiter','Required parameter requestParameters.delimiter was null or undefined when calling uploadFile.');
        }

        if (requestParameters.montPointTypeCode === null || requestParameters.montPointTypeCode === undefined) {
            throw new runtime.RequiredError('montPointTypeCode','Required parameter requestParameters.montPointTypeCode was null or undefined when calling uploadFile.');
        }

        if (requestParameters.multipartFile === null || requestParameters.multipartFile === undefined) {
            throw new runtime.RequiredError('multipartFile','Required parameter requestParameters.multipartFile was null or undefined when calling uploadFile.');
        }

        const queryParameters: any = {};

        if (requestParameters.delimiter !== undefined) {
            queryParameters['delimiter'] = requestParameters.delimiter;
        }

        if (requestParameters.montPointTypeCode !== undefined) {
            queryParameters['montPointTypeCode'] = requestParameters.montPointTypeCode;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.multipartFile !== undefined) {
            formParams.append('multipartFile', requestParameters.multipartFile as any);
        }

        const response = await this.request({
            path: `/import-file/upload-file/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ImportResultFromJSON(jsonValue));
    }

    /**
     */
    async uploadFile(delimiter: string, montPointTypeCode: number, multipartFile: Blob, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ImportResult> {
        const response = await this.uploadFileRaw({ delimiter: delimiter, montPointTypeCode: montPointTypeCode, multipartFile: multipartFile }, initOverrides);
        return await response.value();
    }

    /**
     */
    async validateMontPointDataByFileIdRaw(requestParameters: ImportControllerApiValidateMontPointDataByFileIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling validateMontPointDataByFileId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/import-file/validate/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async validateMontPointDataByFileId(id: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.validateMontPointDataByFileIdRaw({ id: id }, initOverrides);
    }

}
