/* tslint:disable */
/* eslint-disable */
/**
 * Data import metadata
 * Administration of metadata for import
 *
 * The version of the OpenAPI document: 1.0
 * Contact: schvarcz@bb.microcomp.sk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RowTransformationDefinitionResponse } from './RowTransformationDefinitionResponse';
import {
    RowTransformationDefinitionResponseFromJSON,
    RowTransformationDefinitionResponseFromJSONTyped,
    RowTransformationDefinitionResponseToJSON,
} from './RowTransformationDefinitionResponse';

/**
 * 
 * @export
 * @interface RowTypeDefinitionResponse
 */
export interface RowTypeDefinitionResponse {
    /**
     * 
     * @type {string}
     * @memberof RowTypeDefinitionResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof RowTypeDefinitionResponse
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof RowTypeDefinitionResponse
     */
    rowPosition?: number;
    /**
     * 
     * @type {string}
     * @memberof RowTypeDefinitionResponse
     */
    rowDataType?: RowTypeDefinitionResponseRowDataTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof RowTypeDefinitionResponse
     */
    rowTypeDataOrder?: number;
    /**
     * 
     * @type {number}
     * @memberof RowTypeDefinitionResponse
     */
    dataCount?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof RowTypeDefinitionResponse
     */
    columnDefinitionIds?: Array<string>;
    /**
     * 
     * @type {Array<RowTransformationDefinitionResponse>}
     * @memberof RowTypeDefinitionResponse
     */
    rowTransformations?: Array<RowTransformationDefinitionResponse>;
}


/**
 * @export
 */
export const RowTypeDefinitionResponseRowDataTypeEnum = {
    Comment: 'COMMENT',
    Names: 'NAMES',
    Units: 'UNITS',
    Data: 'DATA'
} as const;
export type RowTypeDefinitionResponseRowDataTypeEnum = typeof RowTypeDefinitionResponseRowDataTypeEnum[keyof typeof RowTypeDefinitionResponseRowDataTypeEnum];


/**
 * Check if a given object implements the RowTypeDefinitionResponse interface.
 */
export function instanceOfRowTypeDefinitionResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RowTypeDefinitionResponseFromJSON(json: any): RowTypeDefinitionResponse {
    return RowTypeDefinitionResponseFromJSONTyped(json, false);
}

export function RowTypeDefinitionResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): RowTypeDefinitionResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'rowPosition': !exists(json, 'rowPosition') ? undefined : json['rowPosition'],
        'rowDataType': !exists(json, 'rowDataType') ? undefined : json['rowDataType'],
        'rowTypeDataOrder': !exists(json, 'rowTypeDataOrder') ? undefined : json['rowTypeDataOrder'],
        'dataCount': !exists(json, 'dataCount') ? undefined : json['dataCount'],
        'columnDefinitionIds': !exists(json, 'columnDefinitionIds') ? undefined : json['columnDefinitionIds'],
        'rowTransformations': !exists(json, 'rowTransformations') ? undefined : ((json['rowTransformations'] as Array<any>).map(RowTransformationDefinitionResponseFromJSON)),
    };
}

export function RowTypeDefinitionResponseToJSON(value?: RowTypeDefinitionResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'rowPosition': value.rowPosition,
        'rowDataType': value.rowDataType,
        'rowTypeDataOrder': value.rowTypeDataOrder,
        'dataCount': value.dataCount,
        'columnDefinitionIds': value.columnDefinitionIds,
        'rowTransformations': value.rowTransformations === undefined ? undefined : ((value.rowTransformations as Array<any>).map(RowTransformationDefinitionResponseToJSON)),
    };
}

