/* tslint:disable */
/* eslint-disable */
/**
 * Codelist API
 * Nástroj pre správu metadát a katalógov - koncové služby
 *
 * The version of the OpenAPI document: 0.21.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserGroupCriteria
 */
export interface UserGroupCriteria {
    /**
     * 
     * @type {number}
     * @memberof UserGroupCriteria
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof UserGroupCriteria
     */
    searchField?: string;
    /**
     * 
     * @type {string}
     * @memberof UserGroupCriteria
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof UserGroupCriteria
     */
    note?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserGroupCriteria
     */
    sections?: Array<string>;
}

/**
 * Check if a given object implements the UserGroupCriteria interface.
 */
export function instanceOfUserGroupCriteria(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UserGroupCriteriaFromJSON(json: any): UserGroupCriteria {
    return UserGroupCriteriaFromJSONTyped(json, false);
}

export function UserGroupCriteriaFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserGroupCriteria {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'searchField': !exists(json, 'searchField') ? undefined : json['searchField'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'note': !exists(json, 'note') ? undefined : json['note'],
        'sections': !exists(json, 'sections') ? undefined : json['sections'],
    };
}

export function UserGroupCriteriaToJSON(value?: UserGroupCriteria | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'searchField': value.searchField,
        'name': value.name,
        'note': value.note,
        'sections': value.sections,
    };
}

