/* tslint:disable */
/* eslint-disable */
/**
 * Overview API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { NoteSo } from './NoteSo';
import {
    NoteSoFromJSON,
    NoteSoFromJSONTyped,
    NoteSoToJSON,
} from './NoteSo';

/**
 * 
 * @export
 * @interface CreateRowAttributeSo
 */
export interface CreateRowAttributeSo {
    /**
     * 
     * @type {string}
     * @memberof CreateRowAttributeSo
     */
    viewColumnReference: string;
    /**
     * 
     * @type {string}
     * @memberof CreateRowAttributeSo
     */
    value: string;
    /**
     * 
     * @type {NoteSo}
     * @memberof CreateRowAttributeSo
     */
    noteSo?: NoteSo;
}

/**
 * Check if a given object implements the CreateRowAttributeSo interface.
 */
export function instanceOfCreateRowAttributeSo(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "viewColumnReference" in value;
    isInstance = isInstance && "value" in value;

    return isInstance;
}

export function CreateRowAttributeSoFromJSON(json: any): CreateRowAttributeSo {
    return CreateRowAttributeSoFromJSONTyped(json, false);
}

export function CreateRowAttributeSoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateRowAttributeSo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'viewColumnReference': json['viewColumnReference'],
        'value': json['value'],
        'noteSo': !exists(json, 'noteSo') ? undefined : NoteSoFromJSON(json['noteSo']),
    };
}

export function CreateRowAttributeSoToJSON(value?: CreateRowAttributeSo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'viewColumnReference': value.viewColumnReference,
        'value': value.value,
        'noteSo': NoteSoToJSON(value.noteSo),
    };
}

