/* tslint:disable */
/* eslint-disable */
/**
 * Data import metadata
 * Administration of metadata for import
 *
 * The version of the OpenAPI document: 1.0
 * Contact: schvarcz@bb.microcomp.sk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ColumnDefinitionRequest
 */
export interface ColumnDefinitionRequest {
    /**
     * 
     * @type {number}
     * @memberof ColumnDefinitionRequest
     */
    position?: number;
    /**
     * 
     * @type {number}
     * @memberof ColumnDefinitionRequest
     */
    startPosition?: number;
    /**
     * 
     * @type {number}
     * @memberof ColumnDefinitionRequest
     */
    repeatAfterEach?: number;
}

/**
 * Check if a given object implements the ColumnDefinitionRequest interface.
 */
export function instanceOfColumnDefinitionRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ColumnDefinitionRequestFromJSON(json: any): ColumnDefinitionRequest {
    return ColumnDefinitionRequestFromJSONTyped(json, false);
}

export function ColumnDefinitionRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ColumnDefinitionRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'position': !exists(json, 'position') ? undefined : json['position'],
        'startPosition': !exists(json, 'startPosition') ? undefined : json['startPosition'],
        'repeatAfterEach': !exists(json, 'repeatAfterEach') ? undefined : json['repeatAfterEach'],
    };
}

export function ColumnDefinitionRequestToJSON(value?: ColumnDefinitionRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'position': value.position,
        'startPosition': value.startPosition,
        'repeatAfterEach': value.repeatAfterEach,
    };
}

