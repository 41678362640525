/* tslint:disable */
/* eslint-disable */
/**
 * Data import metadata
 * Administration of metadata for import
 *
 * The version of the OpenAPI document: 1.0
 * Contact: schvarcz@bb.microcomp.sk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RemoveRowTypeDefinitionsRequest
 */
export interface RemoveRowTypeDefinitionsRequest {
    /**
     * 
     * @type {string}
     * @memberof RemoveRowTypeDefinitionsRequest
     */
    importFilePartDefinitionId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof RemoveRowTypeDefinitionsRequest
     */
    rowTypeDefinitionIds?: Array<string>;
}

/**
 * Check if a given object implements the RemoveRowTypeDefinitionsRequest interface.
 */
export function instanceOfRemoveRowTypeDefinitionsRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RemoveRowTypeDefinitionsRequestFromJSON(json: any): RemoveRowTypeDefinitionsRequest {
    return RemoveRowTypeDefinitionsRequestFromJSONTyped(json, false);
}

export function RemoveRowTypeDefinitionsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): RemoveRowTypeDefinitionsRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'importFilePartDefinitionId': !exists(json, 'importFilePartDefinitionId') ? undefined : json['importFilePartDefinitionId'],
        'rowTypeDefinitionIds': !exists(json, 'rowTypeDefinitionIds') ? undefined : json['rowTypeDefinitionIds'],
    };
}

export function RemoveRowTypeDefinitionsRequestToJSON(value?: RemoveRowTypeDefinitionsRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'importFilePartDefinitionId': value.importFilePartDefinitionId,
        'rowTypeDefinitionIds': value.rowTypeDefinitionIds,
    };
}

