import React from 'react';
import Header from '../components/layout/Header';
import useCodelistApi from '../hooks/use-codelist-api';
import { InfoApi as CodelistInfoApi } from '../openapi/codelist';
import { useQuery } from '@tanstack/react-query';
import useCatalogApi from '../hooks/use-catalog-api';
import { InfoApi as CatalogInfoApi } from '../openapi/catalog';
import { InfoApi as DataImportInfoApi } from '../openapi/data-import';
import { InfoControllerApi as OverviewInfoApi } from '../openapi/overview';
import { InfoApi as DataImportMetadataInfoApi } from '../openapi/data-import-metadata';
import { InfoApi as NotificatiorInfoApi } from '../openapi/notificator';
import { InfoApi as UserProfileInfoApi } from '../openapi/user-profile';
import { InfoApi as CatalogImportInfoApi } from '../openapi/catalog-import';
import { InfoApi as MethodicImportInfoApi } from '../openapi/methodic-import';
import useDataImportMetadataApi from '../hooks/use-data-import-metadata-api';
import useDataImportApi from '../hooks/use-data-import-api';
import useOverviewApi from '../hooks/use-overview-api';
import useNotificatorApi from '../hooks/use-notificator-api';
import useUserProfileApi from '../hooks/use-user-profile-api';
import useCatalogImportApi from '../hooks/use-catalog-import-api';
import useMethodicImportApi from '../hooks/use-methodic-import-api';

const DashboardPage: React.FC = () => {
    const codelistInfoApi = useCodelistApi(CodelistInfoApi, {
        noErrorMiddleware: true
    });
    const catalogInfoApi = useCatalogApi(CatalogInfoApi, {
        noErrorMiddleware: true
    });
    const dataImportInfoApi = useDataImportApi(DataImportInfoApi, {
        noErrorMiddleware: true
    });
    const dataImportMetadataInfoApi = useDataImportMetadataApi(DataImportMetadataInfoApi, {
        noErrorMiddleware: true
    });
    const overviewInfoApi = useOverviewApi(OverviewInfoApi, {
        noErrorMiddleware: true
    });

    const notificatiorInfoApi = useNotificatorApi(NotificatiorInfoApi, {
        noErrorMiddleware: true
    });
    const userProfileInfoApi = useUserProfileApi(UserProfileInfoApi, {
        noErrorMiddleware: true
    });
    const catalogImportInfoApi = useCatalogImportApi(CatalogImportInfoApi, {
        noErrorMiddleware: true
    });
    const methodicImportInfoApi = useMethodicImportApi(MethodicImportInfoApi, {
        noErrorMiddleware: true
    });

    const codelistVersion = useQuery({
        queryKey: ['codelist', 'version'],
        queryFn: ({ signal }) => codelistInfoApi.version({ signal })
    });

    const catalogVersion = useQuery({
        queryKey: ['catalog', 'version'],
        queryFn: ({ signal }) => catalogInfoApi.version({ signal })
    });

    const dataImportVersion = useQuery({
        queryKey: ['data-import', 'version'],
        queryFn: ({ signal }) => dataImportInfoApi.version({ signal })
    });

    const dataImportMetadataVersion = useQuery({
        queryKey: ['data-import-metadata', 'version'],
        queryFn: ({ signal }) => dataImportMetadataInfoApi.version({ signal })
    });

    const overviewVersion = useQuery({
        queryKey: ['overview', 'version'],
        queryFn: ({ signal }) => overviewInfoApi.version({ signal })
    });

    const notificatorVersion = useQuery({
        queryKey: ['notificator', 'version'],
        queryFn: ({ signal }) => notificatiorInfoApi.version({ signal })
    });

    const userProfileVersion = useQuery({
        queryKey: ['user-profile', 'version'],
        queryFn: ({ signal }) => userProfileInfoApi.version({ signal })
    });

    const catalogImportVersion = useQuery({
        queryKey: ['catalog-import', 'version'],
        queryFn: ({ signal }) => catalogImportInfoApi.version({ signal })
    });

    const methodicImportVersion = useQuery({
        queryKey: ['methodic-import', 'version'],
        queryFn: ({ signal }) => methodicImportInfoApi.version({ signal })
    });

    return (
        <>
            <Header breadcrumbs={[{ name: 'Domovská obrazovka', to: '/dashboard' }]} />

            <h1 className='my-10 text-center text-2xl font-medium underline decoration-2 underline-offset-8'>Vitajte</h1>
            <div className='font-mono absolute bottom-0 right-0 p-2 text-light-textTransparent text-xs'>
                <ul aria-label='Verzie modulov aplikácie'>
                    <li className='flex'>
                        <div className='min-w-64'>Používateľské prostredie:</div>
                        <div>{ENV.APP_VERSION}</div>
                    </li>
                    <li className='flex'>
                        <div className='min-w-64'>Modul číselníkov:</div>
                        <div>
                            {codelistVersion.isError ? (
                                <span className='text-light-negative dark:text-dark-negative'>Nedostupné</span>
                            ) : (
                                <>{codelistVersion.data}</>
                            )}
                        </div>
                    </li>
                    <li className='flex'>
                        <div className='min-w-64'>Modul katalógov:</div>
                        <div>
                            {catalogVersion.isError ? (
                                <span className='text-light-negative dark:text-dark-negative'>Nedostupné</span>
                            ) : (
                                <>{catalogVersion.data}</>
                            )}
                        </div>
                    </li>
                    <li className='flex'>
                        <div className='min-w-64'>Modul importov analytických metód:</div>
                        <div>
                            {methodicImportVersion.isError ? (
                                <span className='text-light-negative dark:text-dark-negative'>Nedostupné</span>
                            ) : (
                                <>{methodicImportVersion.data}</>
                            )}
                        </div>
                    </li>
                    <li className='flex'>
                        <div className='min-w-64'>Modul importov katalógov:</div>
                        <div>
                            {catalogImportVersion.isError ? (
                                <span className='text-light-negative dark:text-dark-negative'>Nedostupné</span>
                            ) : (
                                <>{catalogImportVersion.data}</>
                            )}
                        </div>
                    </li>
                    <li className='flex'>
                        <div className='min-w-64'>Modul importov dát:</div>
                        <div>
                            {dataImportVersion.isError ? (
                                <span className='text-light-negative dark:text-dark-negative'>Nedostupné</span>
                            ) : (
                                <>{dataImportVersion.data}</>
                            )}
                        </div>
                    </li>
                    <li className='flex'>
                        <div className='min-w-64'>Modul metadát importov dát:</div>
                        <div>
                            {dataImportMetadataVersion.isError ? (
                                <span className='text-light-negative dark:text-dark-negative'>Nedostupné</span>
                            ) : (
                                <>{dataImportMetadataVersion.data}</>
                            )}
                        </div>
                    </li>
                    <li className='flex'>
                        <div className='min-w-64'>Modul prehľadov:</div>
                        <div>
                            {overviewVersion.isError ? (
                                <span className='text-light-negative dark:text-dark-negative'>Nedostupné</span>
                            ) : (
                                <>{overviewVersion.data}</>
                            )}
                        </div>
                    </li>
                    <li className='flex'>
                        <div className='min-w-64'>Modul notifikácií:</div>
                        <div>
                            {notificatorVersion.isError ? (
                                <span className='text-light-negative dark:text-dark-negative'>Nedostupné</span>
                            ) : (
                                <>{notificatorVersion.data}</>
                            )}
                        </div>
                    </li>
                    <li className='flex'>
                        <div className='min-w-64'>Modul profilu používateľa:</div>
                        <div>
                            {userProfileVersion.isError ? (
                                <span className='text-light-negative dark:text-dark-negative'>Nedostupné</span>
                            ) : (
                                <>{userProfileVersion.data}</>
                            )}
                        </div>
                    </li>
                </ul>
            </div>
        </>
    );
};

export default DashboardPage;
