/* tslint:disable */
/* eslint-disable */
/**
 * Catalog API
 * Nástroj pre správu metadát a katalógov - koncové služby
 *
 * The version of the OpenAPI document: 0.27.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BooleanResponse,
  EndpointExceptionBody,
  InstallationCriteria,
  InstallationInput,
  InstallationResult,
  InstallationUpdate,
  PageInstallationResult,
} from '../models/index';
import {
    BooleanResponseFromJSON,
    BooleanResponseToJSON,
    EndpointExceptionBodyFromJSON,
    EndpointExceptionBodyToJSON,
    InstallationCriteriaFromJSON,
    InstallationCriteriaToJSON,
    InstallationInputFromJSON,
    InstallationInputToJSON,
    InstallationResultFromJSON,
    InstallationResultToJSON,
    InstallationUpdateFromJSON,
    InstallationUpdateToJSON,
    PageInstallationResultFromJSON,
    PageInstallationResultToJSON,
} from '../models/index';

export interface InstallationApiCreateInstallationRequest {
    installationInput: InstallationInput;
}

export interface InstallationApiDeleteInstallationRequest {
    id: number;
}

export interface InstallationApiGetInstallationByCodeRequest {
    code: string;
}

export interface InstallationApiGetInstallationByHistoryGroupIdRequest {
    historyGroupId: number;
}

export interface InstallationApiGetInstallationByIdRequest {
    id: number;
}

export interface InstallationApiInstallationByCriteriaRequest {
    installationCriteria: InstallationCriteria;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface InstallationApiInstallationExistsByCodeRequest {
    code: string;
}

export interface InstallationApiRevertInstallationRequest {
    id: number;
}

export interface InstallationApiUpdateInstallationRequest {
    id: number;
    installationUpdate: InstallationUpdate;
}

/**
 * 
 */
export class InstallationApi extends runtime.BaseAPI {

    /**
     */
    async createInstallationRaw(requestParameters: InstallationApiCreateInstallationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InstallationResult>> {
        if (requestParameters.installationInput === null || requestParameters.installationInput === undefined) {
            throw new runtime.RequiredError('installationInput','Required parameter requestParameters.installationInput was null or undefined when calling createInstallation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/installation/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InstallationInputToJSON(requestParameters.installationInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InstallationResultFromJSON(jsonValue));
    }

    /**
     */
    async createInstallation(installationInput: InstallationInput, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InstallationResult> {
        const response = await this.createInstallationRaw({ installationInput: installationInput }, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteInstallationRaw(requestParameters: InstallationApiDeleteInstallationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteInstallation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/installation/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async deleteInstallation(id: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.deleteInstallationRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async getInstallationByCodeRaw(requestParameters: InstallationApiGetInstallationByCodeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<InstallationResult>>> {
        if (requestParameters.code === null || requestParameters.code === undefined) {
            throw new runtime.RequiredError('code','Required parameter requestParameters.code was null or undefined when calling getInstallationByCode.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/installation/list/{code}`.replace(`{${"code"}}`, encodeURIComponent(String(requestParameters.code))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(InstallationResultFromJSON));
    }

    /**
     */
    async getInstallationByCode(code: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<InstallationResult>> {
        const response = await this.getInstallationByCodeRaw({ code: code }, initOverrides);
        return await response.value();
    }

    /**
     */
    async getInstallationByHistoryGroupIdRaw(requestParameters: InstallationApiGetInstallationByHistoryGroupIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InstallationResult>> {
        if (requestParameters.historyGroupId === null || requestParameters.historyGroupId === undefined) {
            throw new runtime.RequiredError('historyGroupId','Required parameter requestParameters.historyGroupId was null or undefined when calling getInstallationByHistoryGroupId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/installation/by-history-group-id/{historyGroupId}`.replace(`{${"historyGroupId"}}`, encodeURIComponent(String(requestParameters.historyGroupId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InstallationResultFromJSON(jsonValue));
    }

    /**
     */
    async getInstallationByHistoryGroupId(historyGroupId: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InstallationResult> {
        const response = await this.getInstallationByHistoryGroupIdRaw({ historyGroupId: historyGroupId }, initOverrides);
        return await response.value();
    }

    /**
     */
    async getInstallationByIdRaw(requestParameters: InstallationApiGetInstallationByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InstallationResult>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getInstallationById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/installation/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InstallationResultFromJSON(jsonValue));
    }

    /**
     */
    async getInstallationById(id: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InstallationResult> {
        const response = await this.getInstallationByIdRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async installationByCriteriaRaw(requestParameters: InstallationApiInstallationByCriteriaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageInstallationResult>> {
        if (requestParameters.installationCriteria === null || requestParameters.installationCriteria === undefined) {
            throw new runtime.RequiredError('installationCriteria','Required parameter requestParameters.installationCriteria was null or undefined when calling installationByCriteria.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/installation/installation-by-criteria`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InstallationCriteriaToJSON(requestParameters.installationCriteria),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageInstallationResultFromJSON(jsonValue));
    }

    /**
     */
    async installationByCriteria(installationCriteria: InstallationCriteria, page?: number, size?: number, sort?: Array<string>, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageInstallationResult> {
        const response = await this.installationByCriteriaRaw({ installationCriteria: installationCriteria, page: page, size: size, sort: sort }, initOverrides);
        return await response.value();
    }

    /**
     */
    async installationExistsByCodeRaw(requestParameters: InstallationApiInstallationExistsByCodeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BooleanResponse>> {
        if (requestParameters.code === null || requestParameters.code === undefined) {
            throw new runtime.RequiredError('code','Required parameter requestParameters.code was null or undefined when calling installationExistsByCode.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/installation/code-exists/{code}`.replace(`{${"code"}}`, encodeURIComponent(String(requestParameters.code))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BooleanResponseFromJSON(jsonValue));
    }

    /**
     */
    async installationExistsByCode(code: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BooleanResponse> {
        const response = await this.installationExistsByCodeRaw({ code: code }, initOverrides);
        return await response.value();
    }

    /**
     */
    async revertInstallationRaw(requestParameters: InstallationApiRevertInstallationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InstallationResult>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling revertInstallation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/installation/revert/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InstallationResultFromJSON(jsonValue));
    }

    /**
     */
    async revertInstallation(id: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InstallationResult> {
        const response = await this.revertInstallationRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateInstallationRaw(requestParameters: InstallationApiUpdateInstallationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InstallationResult>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateInstallation.');
        }

        if (requestParameters.installationUpdate === null || requestParameters.installationUpdate === undefined) {
            throw new runtime.RequiredError('installationUpdate','Required parameter requestParameters.installationUpdate was null or undefined when calling updateInstallation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/installation/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: InstallationUpdateToJSON(requestParameters.installationUpdate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InstallationResultFromJSON(jsonValue));
    }

    /**
     */
    async updateInstallation(id: number, installationUpdate: InstallationUpdate, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InstallationResult> {
        const response = await this.updateInstallationRaw({ id: id, installationUpdate: installationUpdate }, initOverrides);
        return await response.value();
    }

}
