/* tslint:disable */
/* eslint-disable */
/**
 * Data import metadata
 * Administration of metadata for import
 *
 * The version of the OpenAPI document: 1.0
 * Contact: schvarcz@bb.microcomp.sk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AttributeConditionResponse } from './AttributeConditionResponse';
import {
    AttributeConditionResponseFromJSON,
    AttributeConditionResponseFromJSONTyped,
    AttributeConditionResponseToJSON,
} from './AttributeConditionResponse';

/**
 * 
 * @export
 * @interface AttributeDefinitionResponse
 */
export interface AttributeDefinitionResponse {
    /**
     * 
     * @type {string}
     * @memberof AttributeDefinitionResponse
     */
    dataRegistryProductAttributeCode?: string;
    /**
     * 
     * @type {string}
     * @memberof AttributeDefinitionResponse
     */
    rowAttributeCode?: string;
    /**
     * 
     * @type {string}
     * @memberof AttributeDefinitionResponse
     */
    productReference?: string;
    /**
     * 
     * @type {string}
     * @memberof AttributeDefinitionResponse
     */
    referenceAttributeCode?: string;
    /**
     * 
     * @type {number}
     * @memberof AttributeDefinitionResponse
     */
    rowTypeDataOrder?: number;
    /**
     * 
     * @type {AttributeConditionResponse}
     * @memberof AttributeDefinitionResponse
     */
    attributeCondition?: AttributeConditionResponse;
}

/**
 * Check if a given object implements the AttributeDefinitionResponse interface.
 */
export function instanceOfAttributeDefinitionResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AttributeDefinitionResponseFromJSON(json: any): AttributeDefinitionResponse {
    return AttributeDefinitionResponseFromJSONTyped(json, false);
}

export function AttributeDefinitionResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): AttributeDefinitionResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dataRegistryProductAttributeCode': !exists(json, 'dataRegistryProductAttributeCode') ? undefined : json['dataRegistryProductAttributeCode'],
        'rowAttributeCode': !exists(json, 'rowAttributeCode') ? undefined : json['rowAttributeCode'],
        'productReference': !exists(json, 'productReference') ? undefined : json['productReference'],
        'referenceAttributeCode': !exists(json, 'referenceAttributeCode') ? undefined : json['referenceAttributeCode'],
        'rowTypeDataOrder': !exists(json, 'rowTypeDataOrder') ? undefined : json['rowTypeDataOrder'],
        'attributeCondition': !exists(json, 'attributeCondition') ? undefined : AttributeConditionResponseFromJSON(json['attributeCondition']),
    };
}

export function AttributeDefinitionResponseToJSON(value?: AttributeDefinitionResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dataRegistryProductAttributeCode': value.dataRegistryProductAttributeCode,
        'rowAttributeCode': value.rowAttributeCode,
        'productReference': value.productReference,
        'referenceAttributeCode': value.referenceAttributeCode,
        'rowTypeDataOrder': value.rowTypeDataOrder,
        'attributeCondition': AttributeConditionResponseToJSON(value.attributeCondition),
    };
}

