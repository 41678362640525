/* tslint:disable */
/* eslint-disable */
/**
 * Overview API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Condition } from './Condition';
import {
    ConditionFromJSON,
    ConditionFromJSONTyped,
    ConditionToJSON,
} from './Condition';
import type { DateCondition } from './DateCondition';
import {
    DateConditionFromJSON,
    DateConditionFromJSONTyped,
    DateConditionToJSON,
} from './DateCondition';
import type { MonitoringPlacesCondition } from './MonitoringPlacesCondition';
import {
    MonitoringPlacesConditionFromJSON,
    MonitoringPlacesConditionFromJSONTyped,
    MonitoringPlacesConditionToJSON,
} from './MonitoringPlacesCondition';
import type { TimeCondition } from './TimeCondition';
import {
    TimeConditionFromJSON,
    TimeConditionFromJSONTyped,
    TimeConditionToJSON,
} from './TimeCondition';

/**
 * 
 * @export
 * @interface FilterDataSo
 */
export interface FilterDataSo {
    /**
     * 
     * @type {number}
     * @memberof FilterDataSo
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof FilterDataSo
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof FilterDataSo
     */
    viewReference: string;
    /**
     * 
     * @type {string}
     * @memberof FilterDataSo
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof FilterDataSo
     */
    description: string | null;
    /**
     * 
     * @type {TimeCondition}
     * @memberof FilterDataSo
     */
    timeCondition?: TimeCondition;
    /**
     * 
     * @type {DateCondition}
     * @memberof FilterDataSo
     */
    dateCondition?: DateCondition;
    /**
     * 
     * @type {MonitoringPlacesCondition}
     * @memberof FilterDataSo
     */
    monitoringPlacesCondition?: MonitoringPlacesCondition;
    /**
     * 
     * @type {Array<Condition>}
     * @memberof FilterDataSo
     */
    conditions?: Array<Condition>;
    /**
     * 
     * @type {boolean}
     * @memberof FilterDataSo
     */
    defaultFilter?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FilterDataSo
     */
    modificationCondition?: FilterDataSoModificationConditionEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof FilterDataSo
     */
    columns?: Array<string>;
}


/**
 * @export
 */
export const FilterDataSoModificationConditionEnum = {
    ModifiedAnyColumn: 'MODIFIED_ANY_COLUMN',
    ModifiedSelectedColumnsOnly: 'MODIFIED_SELECTED_COLUMNS_ONLY',
    AllData: 'ALL_DATA'
} as const;
export type FilterDataSoModificationConditionEnum = typeof FilterDataSoModificationConditionEnum[keyof typeof FilterDataSoModificationConditionEnum];


/**
 * Check if a given object implements the FilterDataSo interface.
 */
export function instanceOfFilterDataSo(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "username" in value;
    isInstance = isInstance && "viewReference" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "description" in value;

    return isInstance;
}

export function FilterDataSoFromJSON(json: any): FilterDataSo {
    return FilterDataSoFromJSONTyped(json, false);
}

export function FilterDataSoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FilterDataSo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'username': json['username'],
        'viewReference': json['viewReference'],
        'name': json['name'],
        'description': json['description'],
        'timeCondition': !exists(json, 'timeCondition') ? undefined : TimeConditionFromJSON(json['timeCondition']),
        'dateCondition': !exists(json, 'dateCondition') ? undefined : DateConditionFromJSON(json['dateCondition']),
        'monitoringPlacesCondition': !exists(json, 'monitoringPlacesCondition') ? undefined : MonitoringPlacesConditionFromJSON(json['monitoringPlacesCondition']),
        'conditions': !exists(json, 'conditions') ? undefined : ((json['conditions'] as Array<any>).map(ConditionFromJSON)),
        'defaultFilter': !exists(json, 'defaultFilter') ? undefined : json['defaultFilter'],
        'modificationCondition': !exists(json, 'modificationCondition') ? undefined : json['modificationCondition'],
        'columns': !exists(json, 'columns') ? undefined : json['columns'],
    };
}

export function FilterDataSoToJSON(value?: FilterDataSo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'username': value.username,
        'viewReference': value.viewReference,
        'name': value.name,
        'description': value.description,
        'timeCondition': TimeConditionToJSON(value.timeCondition),
        'dateCondition': DateConditionToJSON(value.dateCondition),
        'monitoringPlacesCondition': MonitoringPlacesConditionToJSON(value.monitoringPlacesCondition),
        'conditions': value.conditions === undefined ? undefined : ((value.conditions as Array<any>).map(ConditionToJSON)),
        'defaultFilter': value.defaultFilter,
        'modificationCondition': value.modificationCondition,
        'columns': value.columns,
    };
}

