/* tslint:disable */
/* eslint-disable */
/**
 * Data import
 * Modul for importing data to Data Registry.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: schvarcz@bb.microcomp.sk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * document id for which validation report is loaded
 * @export
 * @interface ValidationReportCriteriaRequest
 */
export interface ValidationReportCriteriaRequest {
    /**
     * 
     * @type {string}
     * @memberof ValidationReportCriteriaRequest
     */
    documentId?: string;
    /**
     * 
     * @type {string}
     * @memberof ValidationReportCriteriaRequest
     */
    controlCriticalityType?: ValidationReportCriteriaRequestControlCriticalityTypeEnum;
}


/**
 * @export
 */
export const ValidationReportCriteriaRequestControlCriticalityTypeEnum = {
    Critical: 'CRITICAL',
    NonCritical: 'NON_CRITICAL',
    Warning: 'WARNING'
} as const;
export type ValidationReportCriteriaRequestControlCriticalityTypeEnum = typeof ValidationReportCriteriaRequestControlCriticalityTypeEnum[keyof typeof ValidationReportCriteriaRequestControlCriticalityTypeEnum];


/**
 * Check if a given object implements the ValidationReportCriteriaRequest interface.
 */
export function instanceOfValidationReportCriteriaRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ValidationReportCriteriaRequestFromJSON(json: any): ValidationReportCriteriaRequest {
    return ValidationReportCriteriaRequestFromJSONTyped(json, false);
}

export function ValidationReportCriteriaRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ValidationReportCriteriaRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'documentId': !exists(json, 'documentId') ? undefined : json['documentId'],
        'controlCriticalityType': !exists(json, 'controlCriticalityType') ? undefined : json['controlCriticalityType'],
    };
}

export function ValidationReportCriteriaRequestToJSON(value?: ValidationReportCriteriaRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'documentId': value.documentId,
        'controlCriticalityType': value.controlCriticalityType,
    };
}

