/* tslint:disable */
/* eslint-disable */
/**
 * Codelist API
 * Nástroj pre správu metadát a katalógov - koncové služby
 *
 * The version of the OpenAPI document: 0.21.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Csv
 */
export interface Csv {
    /**
     * 
     * @type {string}
     * @memberof Csv
     */
    uuid?: string;
    /**
     * 
     * @type {string}
     * @memberof Csv
     */
    status?: CsvStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof Csv
     */
    errorMessage?: string;
    /**
     * 
     * @type {number}
     * @memberof Csv
     */
    percent?: number;
}


/**
 * @export
 */
export const CsvStatusEnum = {
    InProgress: 'IN_PROGRESS',
    Finished: 'FINISHED',
    Error: 'ERROR'
} as const;
export type CsvStatusEnum = typeof CsvStatusEnum[keyof typeof CsvStatusEnum];


/**
 * Check if a given object implements the Csv interface.
 */
export function instanceOfCsv(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CsvFromJSON(json: any): Csv {
    return CsvFromJSONTyped(json, false);
}

export function CsvFromJSONTyped(json: any, ignoreDiscriminator: boolean): Csv {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'uuid': !exists(json, 'uuid') ? undefined : json['uuid'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'errorMessage': !exists(json, 'errorMessage') ? undefined : json['errorMessage'],
        'percent': !exists(json, 'percent') ? undefined : json['percent'],
    };
}

export function CsvToJSON(value?: Csv | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'uuid': value.uuid,
        'status': value.status,
        'errorMessage': value.errorMessage,
        'percent': value.percent,
    };
}

