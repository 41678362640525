/* tslint:disable */
/* eslint-disable */
/**
 * Data import metadata
 * Administration of metadata for import
 *
 * The version of the OpenAPI document: 1.0
 * Contact: schvarcz@bb.microcomp.sk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RowAttributeDefinitionRequest } from './RowAttributeDefinitionRequest';
import {
    RowAttributeDefinitionRequestFromJSON,
    RowAttributeDefinitionRequestFromJSONTyped,
    RowAttributeDefinitionRequestToJSON,
} from './RowAttributeDefinitionRequest';

/**
 * 
 * @export
 * @interface AssignRowAttributeDefinitionsRequest
 */
export interface AssignRowAttributeDefinitionsRequest {
    /**
     * 
     * @type {string}
     * @memberof AssignRowAttributeDefinitionsRequest
     */
    columnDefinitionId?: string;
    /**
     * 
     * @type {Array<RowAttributeDefinitionRequest>}
     * @memberof AssignRowAttributeDefinitionsRequest
     */
    rowAttributeDefinitions?: Array<RowAttributeDefinitionRequest>;
}

/**
 * Check if a given object implements the AssignRowAttributeDefinitionsRequest interface.
 */
export function instanceOfAssignRowAttributeDefinitionsRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AssignRowAttributeDefinitionsRequestFromJSON(json: any): AssignRowAttributeDefinitionsRequest {
    return AssignRowAttributeDefinitionsRequestFromJSONTyped(json, false);
}

export function AssignRowAttributeDefinitionsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AssignRowAttributeDefinitionsRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'columnDefinitionId': !exists(json, 'columnDefinitionId') ? undefined : json['columnDefinitionId'],
        'rowAttributeDefinitions': !exists(json, 'rowAttributeDefinitions') ? undefined : ((json['rowAttributeDefinitions'] as Array<any>).map(RowAttributeDefinitionRequestFromJSON)),
    };
}

export function AssignRowAttributeDefinitionsRequestToJSON(value?: AssignRowAttributeDefinitionsRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'columnDefinitionId': value.columnDefinitionId,
        'rowAttributeDefinitions': value.rowAttributeDefinitions === undefined ? undefined : ((value.rowAttributeDefinitions as Array<any>).map(RowAttributeDefinitionRequestToJSON)),
    };
}

