/* tslint:disable */
/* eslint-disable */
/**
 * Catalog API
 * Nástroj pre správu metadát a katalógov - koncové služby
 *
 * The version of the OpenAPI document: 0.27.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AddressCodelistItem } from './AddressCodelistItem';
import {
    AddressCodelistItemFromJSON,
    AddressCodelistItemFromJSONTyped,
    AddressCodelistItemToJSON,
} from './AddressCodelistItem';
import type { CodelistItem } from './CodelistItem';
import {
    CodelistItemFromJSON,
    CodelistItemFromJSONTyped,
    CodelistItemToJSON,
} from './CodelistItem';
import type { ContactPerson } from './ContactPerson';
import {
    ContactPersonFromJSON,
    ContactPersonFromJSONTyped,
    ContactPersonToJSON,
} from './ContactPerson';
import type { GeographicData } from './GeographicData';
import {
    GeographicDataFromJSON,
    GeographicDataFromJSONTyped,
    GeographicDataToJSON,
} from './GeographicData';
import type { TechnicalAttribute } from './TechnicalAttribute';
import {
    TechnicalAttributeFromJSON,
    TechnicalAttributeFromJSONTyped,
    TechnicalAttributeToJSON,
} from './TechnicalAttribute';

/**
 * 
 * @export
 * @interface InstallationResult
 */
export interface InstallationResult {
    /**
     * 
     * @type {number}
     * @memberof InstallationResult
     */
    technicalCode: number | null;
    /**
     * 
     * @type {number}
     * @memberof InstallationResult
     */
    installationCode: number | null;
    /**
     * 
     * @type {number}
     * @memberof InstallationResult
     */
    ippc: number | null;
    /**
     * 
     * @type {string}
     * @memberof InstallationResult
     */
    name: string | null;
    /**
     * 
     * @type {string}
     * @memberof InstallationResult
     */
    note: string | null;
    /**
     * 
     * @type {AddressCodelistItem}
     * @memberof InstallationResult
     */
    address: AddressCodelistItem | null;
    /**
     * 
     * @type {GeographicData}
     * @memberof InstallationResult
     */
    geographicData: GeographicData | null;
    /**
     * 
     * @type {CodelistItem}
     * @memberof InstallationResult
     */
    naceType: CodelistItem | null;
    /**
     * 
     * @type {CodelistItem}
     * @memberof InstallationResult
     */
    nace2Type: CodelistItem | null;
    /**
     * 
     * @type {number}
     * @memberof InstallationResult
     */
    operatorId: number;
    /**
     * 
     * @type {number}
     * @memberof InstallationResult
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof InstallationResult
     */
    code: string | null;
    /**
     * 
     * @type {Array<ContactPerson>}
     * @memberof InstallationResult
     */
    contactPerson?: Array<ContactPerson>;
    /**
     * 
     * @type {TechnicalAttribute}
     * @memberof InstallationResult
     */
    technicalAttribute: TechnicalAttribute;
    /**
     * 
     * @type {string}
     * @memberof InstallationResult
     */
    status: InstallationResultStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof InstallationResult
     */
    shmuId?: number;
}


/**
 * @export
 */
export const InstallationResultStatusEnum = {
    Delete: 'DELETE',
    Block: 'BLOCK',
    Ok: 'OK',
    Wait: 'WAIT'
} as const;
export type InstallationResultStatusEnum = typeof InstallationResultStatusEnum[keyof typeof InstallationResultStatusEnum];


/**
 * Check if a given object implements the InstallationResult interface.
 */
export function instanceOfInstallationResult(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "technicalCode" in value;
    isInstance = isInstance && "installationCode" in value;
    isInstance = isInstance && "ippc" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "note" in value;
    isInstance = isInstance && "address" in value;
    isInstance = isInstance && "geographicData" in value;
    isInstance = isInstance && "naceType" in value;
    isInstance = isInstance && "nace2Type" in value;
    isInstance = isInstance && "operatorId" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "code" in value;
    isInstance = isInstance && "technicalAttribute" in value;
    isInstance = isInstance && "status" in value;

    return isInstance;
}

export function InstallationResultFromJSON(json: any): InstallationResult {
    return InstallationResultFromJSONTyped(json, false);
}

export function InstallationResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): InstallationResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'technicalCode': json['technicalCode'],
        'installationCode': json['installationCode'],
        'ippc': json['ippc'],
        'name': json['name'],
        'note': json['note'],
        'address': AddressCodelistItemFromJSON(json['address']),
        'geographicData': GeographicDataFromJSON(json['geographicData']),
        'naceType': CodelistItemFromJSON(json['naceType']),
        'nace2Type': CodelistItemFromJSON(json['nace2Type']),
        'operatorId': json['operatorId'],
        'id': json['id'],
        'code': json['code'],
        'contactPerson': !exists(json, 'contactPerson') ? undefined : ((json['contactPerson'] as Array<any>).map(ContactPersonFromJSON)),
        'technicalAttribute': TechnicalAttributeFromJSON(json['technicalAttribute']),
        'status': json['status'],
        'shmuId': !exists(json, 'shmuId') ? undefined : json['shmuId'],
    };
}

export function InstallationResultToJSON(value?: InstallationResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'technicalCode': value.technicalCode,
        'installationCode': value.installationCode,
        'ippc': value.ippc,
        'name': value.name,
        'note': value.note,
        'address': AddressCodelistItemToJSON(value.address),
        'geographicData': GeographicDataToJSON(value.geographicData),
        'naceType': CodelistItemToJSON(value.naceType),
        'nace2Type': CodelistItemToJSON(value.nace2Type),
        'operatorId': value.operatorId,
        'id': value.id,
        'code': value.code,
        'contactPerson': value.contactPerson === undefined ? undefined : ((value.contactPerson as Array<any>).map(ContactPersonToJSON)),
        'technicalAttribute': TechnicalAttributeToJSON(value.technicalAttribute),
        'status': value.status,
        'shmuId': value.shmuId,
    };
}

