/* tslint:disable */
/* eslint-disable */
/**
 * Method import API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FieldError } from './FieldError';
import {
    FieldErrorFromJSON,
    FieldErrorFromJSONTyped,
    FieldErrorToJSON,
} from './FieldError';

/**
 * 
 * @export
 * @interface EndpointExceptionBody
 */
export interface EndpointExceptionBody {
    /**
     * 
     * @type {string}
     * @memberof EndpointExceptionBody
     */
    code?: EndpointExceptionBodyCodeEnum;
    /**
     * 
     * @type {string}
     * @memberof EndpointExceptionBody
     */
    timestamp?: string;
    /**
     * 
     * @type {string}
     * @memberof EndpointExceptionBody
     */
    message?: string;
    /**
     * 
     * @type {string}
     * @memberof EndpointExceptionBody
     */
    stackTrace?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof EndpointExceptionBody
     */
    errors?: Array<string>;
    /**
     * 
     * @type {Array<FieldError>}
     * @memberof EndpointExceptionBody
     */
    fieldErrors?: Array<FieldError>;
}


/**
 * @export
 */
export const EndpointExceptionBodyCodeEnum = {
    InternalServerError: 'INTERNAL_SERVER_ERROR',
    ArgumentNotValid: 'ARGUMENT_NOT_VALID',
    MandatoryArgument: 'MANDATORY_ARGUMENT',
    Exists: 'EXISTS',
    NotFound: 'NOT_FOUND',
    NotEmpty: 'NOT_EMPTY',
    BadRequest: 'BAD_REQUEST',
    Unauthorized: 'UNAUTHORIZED',
    RfoError: 'RFO_ERROR',
    IsdWsError: 'ISD_WS_ERROR'
} as const;
export type EndpointExceptionBodyCodeEnum = typeof EndpointExceptionBodyCodeEnum[keyof typeof EndpointExceptionBodyCodeEnum];


/**
 * Check if a given object implements the EndpointExceptionBody interface.
 */
export function instanceOfEndpointExceptionBody(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function EndpointExceptionBodyFromJSON(json: any): EndpointExceptionBody {
    return EndpointExceptionBodyFromJSONTyped(json, false);
}

export function EndpointExceptionBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): EndpointExceptionBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'code': !exists(json, 'code') ? undefined : json['code'],
        'timestamp': !exists(json, 'timestamp') ? undefined : json['timestamp'],
        'message': !exists(json, 'message') ? undefined : json['message'],
        'stackTrace': !exists(json, 'stackTrace') ? undefined : json['stackTrace'],
        'errors': !exists(json, 'errors') ? undefined : json['errors'],
        'fieldErrors': !exists(json, 'fieldErrors') ? undefined : ((json['fieldErrors'] as Array<any>).map(FieldErrorFromJSON)),
    };
}

export function EndpointExceptionBodyToJSON(value?: EndpointExceptionBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'code': value.code,
        'timestamp': value.timestamp,
        'message': value.message,
        'stackTrace': value.stackTrace,
        'errors': value.errors,
        'fieldErrors': value.fieldErrors === undefined ? undefined : ((value.fieldErrors as Array<any>).map(FieldErrorToJSON)),
    };
}

