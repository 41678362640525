/* tslint:disable */
/* eslint-disable */
/**
 * Data import
 * Modul for importing data to Data Registry.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: schvarcz@bb.microcomp.sk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DataAttributeRowResponse } from './DataAttributeRowResponse';
import {
    DataAttributeRowResponseFromJSON,
    DataAttributeRowResponseFromJSONTyped,
    DataAttributeRowResponseToJSON,
} from './DataAttributeRowResponse';

/**
 * 
 * @export
 * @interface DataAttributeRowResponses
 */
export interface DataAttributeRowResponses {
    /**
     * 
     * @type {Array<DataAttributeRowResponse>}
     * @memberof DataAttributeRowResponses
     */
    dataAttributeRowResponses?: Array<DataAttributeRowResponse>;
}

/**
 * Check if a given object implements the DataAttributeRowResponses interface.
 */
export function instanceOfDataAttributeRowResponses(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DataAttributeRowResponsesFromJSON(json: any): DataAttributeRowResponses {
    return DataAttributeRowResponsesFromJSONTyped(json, false);
}

export function DataAttributeRowResponsesFromJSONTyped(json: any, ignoreDiscriminator: boolean): DataAttributeRowResponses {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dataAttributeRowResponses': !exists(json, 'dataAttributeRowResponses') ? undefined : ((json['dataAttributeRowResponses'] as Array<any>).map(DataAttributeRowResponseFromJSON)),
    };
}

export function DataAttributeRowResponsesToJSON(value?: DataAttributeRowResponses | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dataAttributeRowResponses': value.dataAttributeRowResponses === undefined ? undefined : ((value.dataAttributeRowResponses as Array<any>).map(DataAttributeRowResponseToJSON)),
    };
}

