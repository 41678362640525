/* tslint:disable */
/* eslint-disable */
/**
 * Data import
 * Modul for importing data to Data Registry.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: schvarcz@bb.microcomp.sk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DataAttributeResponse } from './DataAttributeResponse';
import {
    DataAttributeResponseFromJSON,
    DataAttributeResponseFromJSONTyped,
    DataAttributeResponseToJSON,
} from './DataAttributeResponse';

/**
 * 
 * @export
 * @interface DataAttributeColumnResponse
 */
export interface DataAttributeColumnResponse {
    /**
     * 
     * @type {number}
     * @memberof DataAttributeColumnResponse
     */
    column?: number;
    /**
     * 
     * @type {{ [key: string]: DataAttributeResponse; }}
     * @memberof DataAttributeColumnResponse
     */
    attributes?: { [key: string]: DataAttributeResponse; };
}

/**
 * Check if a given object implements the DataAttributeColumnResponse interface.
 */
export function instanceOfDataAttributeColumnResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DataAttributeColumnResponseFromJSON(json: any): DataAttributeColumnResponse {
    return DataAttributeColumnResponseFromJSONTyped(json, false);
}

export function DataAttributeColumnResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): DataAttributeColumnResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'column': !exists(json, 'column') ? undefined : json['column'],
        'attributes': !exists(json, 'attributes') ? undefined : (mapValues(json['attributes'], DataAttributeResponseFromJSON)),
    };
}

export function DataAttributeColumnResponseToJSON(value?: DataAttributeColumnResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'column': value.column,
        'attributes': value.attributes === undefined ? undefined : (mapValues(value.attributes, DataAttributeResponseToJSON)),
    };
}

