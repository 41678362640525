/* tslint:disable */
/* eslint-disable */
/**
 * Overview API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InfoAboutValueSo
 */
export interface InfoAboutValueSo {
    /**
     * 
     * @type {string}
     * @memberof InfoAboutValueSo
     */
    source: InfoAboutValueSoSourceEnum;
    /**
     * 
     * @type {boolean}
     * @memberof InfoAboutValueSo
     */
    valueChanged: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InfoAboutValueSo
     */
    noted: boolean;
}


/**
 * @export
 */
export const InfoAboutValueSoSourceEnum = {
    Import: 'IMPORT',
    ImportForObservation: 'IMPORT_FOR_OBSERVATION',
    Edit: 'EDIT',
    Calculated: 'CALCULATED'
} as const;
export type InfoAboutValueSoSourceEnum = typeof InfoAboutValueSoSourceEnum[keyof typeof InfoAboutValueSoSourceEnum];


/**
 * Check if a given object implements the InfoAboutValueSo interface.
 */
export function instanceOfInfoAboutValueSo(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "source" in value;
    isInstance = isInstance && "valueChanged" in value;
    isInstance = isInstance && "noted" in value;

    return isInstance;
}

export function InfoAboutValueSoFromJSON(json: any): InfoAboutValueSo {
    return InfoAboutValueSoFromJSONTyped(json, false);
}

export function InfoAboutValueSoFromJSONTyped(json: any, ignoreDiscriminator: boolean): InfoAboutValueSo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'source': json['source'],
        'valueChanged': json['valueChanged'],
        'noted': json['noted'],
    };
}

export function InfoAboutValueSoToJSON(value?: InfoAboutValueSo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'source': value.source,
        'valueChanged': value.valueChanged,
        'noted': value.noted,
    };
}

