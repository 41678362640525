/* tslint:disable */
/* eslint-disable */
/**
 * Method import API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { TechnicalAttribute } from './TechnicalAttribute';
import {
    TechnicalAttributeFromJSON,
    TechnicalAttributeFromJSONTyped,
    TechnicalAttributeToJSON,
} from './TechnicalAttribute';

/**
 * 
 * @export
 * @interface ImportFile
 */
export interface ImportFile {
    /**
     * 
     * @type {number}
     * @memberof ImportFile
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ImportFile
     */
    fileName: string;
    /**
     * 
     * @type {string}
     * @memberof ImportFile
     */
    status: ImportFileStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof ImportFile
     */
    numRows: number;
    /**
     * 
     * @type {number}
     * @memberof ImportFile
     */
    rowWithErrors: number;
    /**
     * 
     * @type {string}
     * @memberof ImportFile
     */
    error: string | null;
    /**
     * 
     * @type {TechnicalAttribute}
     * @memberof ImportFile
     */
    technicalAttribute: TechnicalAttribute;
}


/**
 * @export
 */
export const ImportFileStatusEnum = {
    Created: 'created',
    Waiting: 'waiting',
    Importing: 'importing',
    Validating: 'validating',
    Validated: 'validated',
    Imported: 'imported',
    Failed: 'failed',
    Unvalidated: 'unvalidated'
} as const;
export type ImportFileStatusEnum = typeof ImportFileStatusEnum[keyof typeof ImportFileStatusEnum];


/**
 * Check if a given object implements the ImportFile interface.
 */
export function instanceOfImportFile(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "fileName" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "numRows" in value;
    isInstance = isInstance && "rowWithErrors" in value;
    isInstance = isInstance && "error" in value;
    isInstance = isInstance && "technicalAttribute" in value;

    return isInstance;
}

export function ImportFileFromJSON(json: any): ImportFile {
    return ImportFileFromJSONTyped(json, false);
}

export function ImportFileFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImportFile {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'fileName': json['fileName'],
        'status': json['status'],
        'numRows': json['numRows'],
        'rowWithErrors': json['rowWithErrors'],
        'error': json['error'],
        'technicalAttribute': TechnicalAttributeFromJSON(json['technicalAttribute']),
    };
}

export function ImportFileToJSON(value?: ImportFile | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'fileName': value.fileName,
        'status': value.status,
        'numRows': value.numRows,
        'rowWithErrors': value.rowWithErrors,
        'error': value.error,
        'technicalAttribute': TechnicalAttributeToJSON(value.technicalAttribute),
    };
}

