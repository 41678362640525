/* tslint:disable */
/* eslint-disable */
/**
 * Data import metadata
 * Administration of metadata for import
 *
 * The version of the OpenAPI document: 1.0
 * Contact: schvarcz@bb.microcomp.sk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RowTransformationDefinitionResponse
 */
export interface RowTransformationDefinitionResponse {
    /**
     * 
     * @type {string}
     * @memberof RowTransformationDefinitionResponse
     */
    rowTransformationType?: RowTransformationDefinitionResponseRowTransformationTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof RowTransformationDefinitionResponse
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof RowTransformationDefinitionResponse
     */
    name?: string;
}


/**
 * @export
 */
export const RowTransformationDefinitionResponseRowTransformationTypeEnum = {
    ToArray: 'TO_ARRAY'
} as const;
export type RowTransformationDefinitionResponseRowTransformationTypeEnum = typeof RowTransformationDefinitionResponseRowTransformationTypeEnum[keyof typeof RowTransformationDefinitionResponseRowTransformationTypeEnum];


/**
 * Check if a given object implements the RowTransformationDefinitionResponse interface.
 */
export function instanceOfRowTransformationDefinitionResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RowTransformationDefinitionResponseFromJSON(json: any): RowTransformationDefinitionResponse {
    return RowTransformationDefinitionResponseFromJSONTyped(json, false);
}

export function RowTransformationDefinitionResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): RowTransformationDefinitionResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'rowTransformationType': !exists(json, 'rowTransformationType') ? undefined : json['rowTransformationType'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'name': !exists(json, 'name') ? undefined : json['name'],
    };
}

export function RowTransformationDefinitionResponseToJSON(value?: RowTransformationDefinitionResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'rowTransformationType': value.rowTransformationType,
        'code': value.code,
        'name': value.name,
    };
}

