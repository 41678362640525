/* tslint:disable */
/* eslint-disable */
/**
 * Catalog import API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.4.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ImportDataCriteria
 */
export interface ImportDataCriteria {
    /**
     * 
     * @type {string}
     * @memberof ImportDataCriteria
     */
    searchField?: string;
    /**
     * 
     * @type {number}
     * @memberof ImportDataCriteria
     */
    idImportFile?: number;
    /**
     * 
     * @type {string}
     * @memberof ImportDataCriteria
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof ImportDataCriteria
     */
    name?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ImportDataCriteria
     */
    validation?: Array<ImportDataCriteriaValidationEnum>;
}


/**
 * @export
 */
export const ImportDataCriteriaValidationEnum = {
    Ok: 'OK',
    Wrong: 'WRONG',
    Without: 'WITHOUT'
} as const;
export type ImportDataCriteriaValidationEnum = typeof ImportDataCriteriaValidationEnum[keyof typeof ImportDataCriteriaValidationEnum];


/**
 * Check if a given object implements the ImportDataCriteria interface.
 */
export function instanceOfImportDataCriteria(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ImportDataCriteriaFromJSON(json: any): ImportDataCriteria {
    return ImportDataCriteriaFromJSONTyped(json, false);
}

export function ImportDataCriteriaFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImportDataCriteria {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'searchField': !exists(json, 'searchField') ? undefined : json['searchField'],
        'idImportFile': !exists(json, 'idImportFile') ? undefined : json['idImportFile'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'validation': !exists(json, 'validation') ? undefined : json['validation'],
    };
}

export function ImportDataCriteriaToJSON(value?: ImportDataCriteria | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'searchField': value.searchField,
        'idImportFile': value.idImportFile,
        'code': value.code,
        'name': value.name,
        'validation': value.validation,
    };
}

