/* tslint:disable */
/* eslint-disable */
/**
 * Notification API
 * Nástroj pre posielanie notifikácií - koncové služby
 *
 * The version of the OpenAPI document: 0.8.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AccountSo,
} from '../models/index';
import {
    AccountSoFromJSON,
    AccountSoToJSON,
} from '../models/index';

export interface AccountApiSaveRequest {
    accountSo: AccountSo;
}

/**
 * 
 */
export class AccountApi extends runtime.BaseAPI {

    /**
     */
    async getAccountRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AccountSo>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/account/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AccountSoFromJSON(jsonValue));
    }

    /**
     */
    async getAccount(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AccountSo> {
        const response = await this.getAccountRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async saveRaw(requestParameters: AccountApiSaveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AccountSo>> {
        if (requestParameters.accountSo === null || requestParameters.accountSo === undefined) {
            throw new runtime.RequiredError('accountSo','Required parameter requestParameters.accountSo was null or undefined when calling save.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/account/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AccountSoToJSON(requestParameters.accountSo),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AccountSoFromJSON(jsonValue));
    }

    /**
     */
    async save(accountSo: AccountSo, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AccountSo> {
        const response = await this.saveRaw({ accountSo: accountSo }, initOverrides);
        return await response.value();
    }

    /**
     */
    async unreadNotificationsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/account/unread/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async unreadNotifications(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.unreadNotificationsRaw(initOverrides);
        return await response.value();
    }

}
