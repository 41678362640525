import { useEffect, useRef } from 'react';
import { useAuth } from 'react-oidc-context';

interface FetchInstance {
    getJson: (path: string) => Promise<any>;
    postJson: (path: string, body: any) => Promise<any>;
}

export const useFetch = (baseURL: string) => {
    const fetchInstance = useRef<FetchInstance>();
    const { user } = useAuth();

    const kcToken = user?.access_token;
    useEffect(() => {
        fetchInstance.current = {
            getJson: (path: string) =>
                fetch(`${baseURL}${path}`, {
                    method: 'GET',
                    headers: {
                        Authorization: kcToken ? `Bearer ${kcToken}` : ''
                    }
                }).then((response) => response.json()),
            postJson: (path: string, body: any) =>
                fetch(`${baseURL}${path}`, {
                    method: 'POST',
                    headers: {
                        Authorization: kcToken ? `Bearer ${kcToken}` : '',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(body)
                }).then((response) => response.json())
        };
        return () => {
            fetchInstance.current = undefined;
        };
    }, [baseURL, kcToken]);
    return fetchInstance;
};
