/* tslint:disable */
/* eslint-disable */
/**
 * Data import metadata
 * Administration of metadata for import
 *
 * The version of the OpenAPI document: 1.0
 * Contact: schvarcz@bb.microcomp.sk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RowTransformationDefinitionRequest
 */
export interface RowTransformationDefinitionRequest {
    /**
     * 
     * @type {string}
     * @memberof RowTransformationDefinitionRequest
     */
    rowTransformationType?: RowTransformationDefinitionRequestRowTransformationTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof RowTransformationDefinitionRequest
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof RowTransformationDefinitionRequest
     */
    name?: string;
}


/**
 * @export
 */
export const RowTransformationDefinitionRequestRowTransformationTypeEnum = {
    ToArray: 'TO_ARRAY'
} as const;
export type RowTransformationDefinitionRequestRowTransformationTypeEnum = typeof RowTransformationDefinitionRequestRowTransformationTypeEnum[keyof typeof RowTransformationDefinitionRequestRowTransformationTypeEnum];


/**
 * Check if a given object implements the RowTransformationDefinitionRequest interface.
 */
export function instanceOfRowTransformationDefinitionRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RowTransformationDefinitionRequestFromJSON(json: any): RowTransformationDefinitionRequest {
    return RowTransformationDefinitionRequestFromJSONTyped(json, false);
}

export function RowTransformationDefinitionRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): RowTransformationDefinitionRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'rowTransformationType': !exists(json, 'rowTransformationType') ? undefined : json['rowTransformationType'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'name': !exists(json, 'name') ? undefined : json['name'],
    };
}

export function RowTransformationDefinitionRequestToJSON(value?: RowTransformationDefinitionRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'rowTransformationType': value.rowTransformationType,
        'code': value.code,
        'name': value.name,
    };
}

