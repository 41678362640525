/* tslint:disable */
/* eslint-disable */
/**
 * Catalog API
 * Nástroj pre správu metadát a katalógov - koncové služby
 *
 * The version of the OpenAPI document: 0.27.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Place
 */
export interface Place {
    /**
     * 
     * @type {number}
     * @memberof Place
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof Place
     */
    childHistoryGroupId?: number;
    /**
     * 
     * @type {number}
     * @memberof Place
     */
    coefficient?: number;
}

/**
 * Check if a given object implements the Place interface.
 */
export function instanceOfPlace(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PlaceFromJSON(json: any): Place {
    return PlaceFromJSONTyped(json, false);
}

export function PlaceFromJSONTyped(json: any, ignoreDiscriminator: boolean): Place {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'childHistoryGroupId': !exists(json, 'childHistoryGroupId') ? undefined : json['childHistoryGroupId'],
        'coefficient': !exists(json, 'coefficient') ? undefined : json['coefficient'],
    };
}

export function PlaceToJSON(value?: Place | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'childHistoryGroupId': value.childHistoryGroupId,
        'coefficient': value.coefficient,
    };
}

