/* tslint:disable */
/* eslint-disable */
/**
 * Data import metadata
 * Administration of metadata for import
 *
 * The version of the OpenAPI document: 1.0
 * Contact: schvarcz@bb.microcomp.sk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DataRegistryProductDefinitionRequest } from './DataRegistryProductDefinitionRequest';
import {
    DataRegistryProductDefinitionRequestFromJSON,
    DataRegistryProductDefinitionRequestFromJSONTyped,
    DataRegistryProductDefinitionRequestToJSON,
} from './DataRegistryProductDefinitionRequest';

/**
 * 
 * @export
 * @interface AssignDataRegistryProductDefinitionRequest
 */
export interface AssignDataRegistryProductDefinitionRequest {
    /**
     * 
     * @type {string}
     * @memberof AssignDataRegistryProductDefinitionRequest
     */
    importFileDefinitionId?: string;
    /**
     * 
     * @type {DataRegistryProductDefinitionRequest}
     * @memberof AssignDataRegistryProductDefinitionRequest
     */
    dataRegistryProduct?: DataRegistryProductDefinitionRequest;
}

/**
 * Check if a given object implements the AssignDataRegistryProductDefinitionRequest interface.
 */
export function instanceOfAssignDataRegistryProductDefinitionRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AssignDataRegistryProductDefinitionRequestFromJSON(json: any): AssignDataRegistryProductDefinitionRequest {
    return AssignDataRegistryProductDefinitionRequestFromJSONTyped(json, false);
}

export function AssignDataRegistryProductDefinitionRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AssignDataRegistryProductDefinitionRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'importFileDefinitionId': !exists(json, 'importFileDefinitionId') ? undefined : json['importFileDefinitionId'],
        'dataRegistryProduct': !exists(json, 'dataRegistryProduct') ? undefined : DataRegistryProductDefinitionRequestFromJSON(json['dataRegistryProduct']),
    };
}

export function AssignDataRegistryProductDefinitionRequestToJSON(value?: AssignDataRegistryProductDefinitionRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'importFileDefinitionId': value.importFileDefinitionId,
        'dataRegistryProduct': DataRegistryProductDefinitionRequestToJSON(value.dataRegistryProduct),
    };
}

