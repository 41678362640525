import { CODELISTITEM_FIXED_LENGHT } from './commonConst';

// Kod ciselnika je vzdy ako zaciatocna cast kodu polozky, staci odrezat poslednych 6 cisel (znakov)
const getCodelistCodeFromCodelistItemCode = (codelistItemCode: number): number => {
    const codelistCode = +`${codelistItemCode}`.slice(0, -1 * CODELISTITEM_FIXED_LENGHT);
    if (isNaN(codelistCode)) {
        throw new Error('Kód číselníka vypočítaný z kódu číselníkovej položky nie je číslo!');
    }
    return codelistCode;
};

/** Číselníky */
export const codelistsQueryKey = () => ['codelists'] as const;

/** Detail číselníka */
export const codelistQueryKey = (codelistCode: number) => ['codelists', +codelistCode] as const;

/** Zoznam položiek číselníka */
export const codelistItemsQueryKey = (codelistCode: number) => ['codelists', +codelistCode, 'items'] as const;

/** Detail položky číselníka */
export const codelistItemQueryKey = (codelistItemCode: number) => {
    return ['codelists', getCodelistCodeFromCodelistItemCode(codelistItemCode), 'items', +codelistItemCode] as const;
};

/** Vodné útvary */
export const waterShapesQueryKey = () => ['waterShape'] as const;

/** Detail vodného útvaru */
export const waterShapeQueryKey = (waterShapeId: number) => ['waterShape', +waterShapeId] as const;

/** Pozaďové hodnoty */
export const thresholdsQueryKey = () => ['threshold'] as const;

/** Detail pozaďovej hodnoty */
export const thresholdQueryKey = (thresholdId: number) => ['threshold', +thresholdId] as const;

/** Vodné toky */
export const environmentsQueryKey = () => ['environment'] as const;

/** Detail vodného toku */
export const environmentQueryKey = (environmentId: number) => ['environment', +environmentId] as const;

/** Prevádzkovatelia */
export const operatorsQueryKey = () => ['operator'] as const;

/** Detail prevádzkovateľa */
export const operatorQueryKey = (operatorId: number) => ['operator', +operatorId] as const;

/** Prevádzky */
export const installationsQueryKey = () => ['installation'] as const;

/** Detail prevádzky */
export const installationQueryKey = (installationId: number) => ['installation', +installationId] as const;

/** Počet notifikácii */
export const notificationCountQueryKey = () => ['notificationCount'] as const;

/** Definície podľa subdomény */
export const fileDefinitionBySubdomainQueryKey = (domainCode: string) => ['filedefinition', domainCode] as const;

/** Metadáta riadkov */
export const previewMetadataByDocumentIdQueryKey = (documentId: string) => ['data-import', 'preview', 'metadata', documentId] as const;

/** Prehlady previewReferences */
export const previewReferenceByDomainName = (domainName: string) => ['overview', 'preview', 'list', domainName] as const;

/** Zoznam iba domén */
export const allDomainsQueryKey = () => ['data-import', 'domains'] as const;

/** Zoznam všetkých subdomén plochý list */
export const allSubdomainsFlatQueryKey = (leafsOnly: boolean) => ['data-import', 'domains', 'flat', leafsOnly] as const;

/** Zoznam subdomén domény pre importy */
export const importSubdomainsByDomainQueryKey = (subdomainCode: string) => ['data-import', 'domains', subdomainCode] as const;

/** Zoznam všetkých produktov pre danú subdoménu */
export const importProductsByDomainQueryKey = (subdomainCode: string) => ['data-import', 'products', subdomainCode] as const;

/** Preview Metadata description by reference ID */
export const previewMetadataByReference = (reference: string) => ['overview', 'preview', reference] as const;

/** Preview data statistics */
export const previewDataStatisticsByReference = (reference: string) => ['overview', 'preview', reference, 'stats'] as const;

/** has datetime columns by reference ID */
export const previewDateTimeColumnByReference = (reference: string) => ['overview', 'preview', reference, 'datetime'] as const;

/** Dataregistry metadata for Evidence */
export const dataregistryMetadataEvidence = () =>
    [
        'dataregistry',
        'product',
        'sk.shmu',
        'metadata',
        [
            'waste_evidence_1',
            'waste_evidence_2',
            'withdraw_evidence_1',
            'withdraw_evidence_2',
            'takeoff_register',
            'r_withdraw_evidence_1_usage_intent',
            'withdraw_evidence_2_det'
        ]
    ] as const;

export const dataregistryProductEvidence = (referenceName: string, id: number) => ['dataregistry', 'product', referenceName, id] as const;
