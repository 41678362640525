/* tslint:disable */
/* eslint-disable */
/**
 * Data import metadata
 * Administration of metadata for import
 *
 * The version of the OpenAPI document: 1.0
 * Contact: schvarcz@bb.microcomp.sk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProductReferenceResponse } from './ProductReferenceResponse';
import {
    ProductReferenceResponseFromJSON,
    ProductReferenceResponseFromJSONTyped,
    ProductReferenceResponseToJSON,
} from './ProductReferenceResponse';
import type { UnitOfMeasureResponse } from './UnitOfMeasureResponse';
import {
    UnitOfMeasureResponseFromJSON,
    UnitOfMeasureResponseFromJSONTyped,
    UnitOfMeasureResponseToJSON,
} from './UnitOfMeasureResponse';

/**
 * 
 * @export
 * @interface MetadataAttributeResponse
 */
export interface MetadataAttributeResponse {
    /**
     * 
     * @type {string}
     * @memberof MetadataAttributeResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof MetadataAttributeResponse
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof MetadataAttributeResponse
     */
    dataRegistryProductCode?: string;
    /**
     * 
     * @type {string}
     * @memberof MetadataAttributeResponse
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof MetadataAttributeResponse
     */
    type?: MetadataAttributeResponseTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof MetadataAttributeResponse
     */
    length?: number;
    /**
     * 
     * @type {number}
     * @memberof MetadataAttributeResponse
     */
    decimalDigits?: number;
    /**
     * 
     * @type {UnitOfMeasureResponse}
     * @memberof MetadataAttributeResponse
     */
    unitOfMeasure?: UnitOfMeasureResponse;
    /**
     * 
     * @type {boolean}
     * @memberof MetadataAttributeResponse
     */
    allowedZero?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MetadataAttributeResponse
     */
    derivedData?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MetadataAttributeResponse
     */
    classification?: MetadataAttributeResponseClassificationEnum;
    /**
     * 
     * @type {ProductReferenceResponse}
     * @memberof MetadataAttributeResponse
     */
    reference?: ProductReferenceResponse;
    /**
     * 
     * @type {boolean}
     * @memberof MetadataAttributeResponse
     */
    nullable?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MetadataAttributeResponse
     */
    key?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MetadataAttributeResponse
     */
    codeTableName?: string;
}


/**
 * @export
 */
export const MetadataAttributeResponseTypeEnum = {
    Tinyint: 'TINYINT',
    Smallint: 'SMALLINT',
    Integer: 'INTEGER',
    Bigint: 'BIGINT',
    Real: 'REAL',
    Double: 'DOUBLE',
    Numeric: 'NUMERIC',
    Decimal: 'DECIMAL',
    Char: 'CHAR',
    Varchar: 'VARCHAR',
    Longvarchar: 'LONGVARCHAR',
    Longnvarchar: 'LONGNVARCHAR',
    Date: 'DATE',
    Time: 'TIME',
    Null: 'NULL',
    Timestamp: 'TIMESTAMP',
    Boolean: 'BOOLEAN',
    Rowid: 'ROWID',
    Nchar: 'NCHAR',
    Nvarchar: 'NVARCHAR',
    TimeWithTimezone: 'TIME_WITH_TIMEZONE',
    TimestampWithTimezone: 'TIMESTAMP_WITH_TIMEZONE',
    Bfile: 'BFILE',
    IsoDateTime: 'ISO_DATE_TIME',
    Bit: 'BIT',
    Binary: 'BINARY',
    DmsFileUuid: 'DMS_FILE_UUID'
} as const;
export type MetadataAttributeResponseTypeEnum = typeof MetadataAttributeResponseTypeEnum[keyof typeof MetadataAttributeResponseTypeEnum];

/**
 * @export
 */
export const MetadataAttributeResponseClassificationEnum = {
    Standard: 'STANDARD',
    Increment: 'INCREMENT',
    Measured: 'MEASURED',
    CodeTable: 'CODE_TABLE',
    Reference: 'REFERENCE',
    CatalogTable: 'CATALOG_TABLE'
} as const;
export type MetadataAttributeResponseClassificationEnum = typeof MetadataAttributeResponseClassificationEnum[keyof typeof MetadataAttributeResponseClassificationEnum];


/**
 * Check if a given object implements the MetadataAttributeResponse interface.
 */
export function instanceOfMetadataAttributeResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MetadataAttributeResponseFromJSON(json: any): MetadataAttributeResponse {
    return MetadataAttributeResponseFromJSONTyped(json, false);
}

export function MetadataAttributeResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MetadataAttributeResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'dataRegistryProductCode': !exists(json, 'dataRegistryProductCode') ? undefined : json['dataRegistryProductCode'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'length': !exists(json, 'length') ? undefined : json['length'],
        'decimalDigits': !exists(json, 'decimalDigits') ? undefined : json['decimalDigits'],
        'unitOfMeasure': !exists(json, 'unitOfMeasure') ? undefined : UnitOfMeasureResponseFromJSON(json['unitOfMeasure']),
        'allowedZero': !exists(json, 'allowedZero') ? undefined : json['allowedZero'],
        'derivedData': !exists(json, 'derivedData') ? undefined : json['derivedData'],
        'classification': !exists(json, 'classification') ? undefined : json['classification'],
        'reference': !exists(json, 'reference') ? undefined : ProductReferenceResponseFromJSON(json['reference']),
        'nullable': !exists(json, 'nullable') ? undefined : json['nullable'],
        'key': !exists(json, 'key') ? undefined : json['key'],
        'codeTableName': !exists(json, 'codeTableName') ? undefined : json['codeTableName'],
    };
}

export function MetadataAttributeResponseToJSON(value?: MetadataAttributeResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'code': value.code,
        'dataRegistryProductCode': value.dataRegistryProductCode,
        'name': value.name,
        'type': value.type,
        'length': value.length,
        'decimalDigits': value.decimalDigits,
        'unitOfMeasure': UnitOfMeasureResponseToJSON(value.unitOfMeasure),
        'allowedZero': value.allowedZero,
        'derivedData': value.derivedData,
        'classification': value.classification,
        'reference': ProductReferenceResponseToJSON(value.reference),
        'nullable': value.nullable,
        'key': value.key,
        'codeTableName': value.codeTableName,
    };
}

