/* tslint:disable */
/* eslint-disable */
/**
 * Overview API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TimeCondition
 */
export interface TimeCondition {
    /**
     * 
     * @type {string}
     * @memberof TimeCondition
     */
    timeFrom: string;
    /**
     * 
     * @type {string}
     * @memberof TimeCondition
     */
    timeTo: string;
}

/**
 * Check if a given object implements the TimeCondition interface.
 */
export function instanceOfTimeCondition(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "timeFrom" in value;
    isInstance = isInstance && "timeTo" in value;

    return isInstance;
}

export function TimeConditionFromJSON(json: any): TimeCondition {
    return TimeConditionFromJSONTyped(json, false);
}

export function TimeConditionFromJSONTyped(json: any, ignoreDiscriminator: boolean): TimeCondition {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'timeFrom': json['timeFrom'],
        'timeTo': json['timeTo'],
    };
}

export function TimeConditionToJSON(value?: TimeCondition | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'timeFrom': value.timeFrom,
        'timeTo': value.timeTo,
    };
}

