/* tslint:disable */
/* eslint-disable */
/**
 * Data import metadata
 * Administration of metadata for import
 *
 * The version of the OpenAPI document: 1.0
 * Contact: schvarcz@bb.microcomp.sk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ImportFilePartControlResponse } from './ImportFilePartControlResponse';
import {
    ImportFilePartControlResponseFromJSON,
    ImportFilePartControlResponseFromJSONTyped,
    ImportFilePartControlResponseToJSON,
} from './ImportFilePartControlResponse';
import type { RowTypeMetadataResponse } from './RowTypeMetadataResponse';
import {
    RowTypeMetadataResponseFromJSON,
    RowTypeMetadataResponseFromJSONTyped,
    RowTypeMetadataResponseToJSON,
} from './RowTypeMetadataResponse';

/**
 * 
 * @export
 * @interface ImportFilePartMetadataResponse
 */
export interface ImportFilePartMetadataResponse {
    /**
     * 
     * @type {string}
     * @memberof ImportFilePartMetadataResponse
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof ImportFilePartMetadataResponse
     */
    blockPosition?: number;
    /**
     * 
     * @type {string}
     * @memberof ImportFilePartMetadataResponse
     */
    blockSeparator?: string;
    /**
     * 
     * @type {Array<RowTypeMetadataResponse>}
     * @memberof ImportFilePartMetadataResponse
     */
    rowTypes?: Array<RowTypeMetadataResponse>;
    /**
     * 
     * @type {Array<ImportFilePartControlResponse>}
     * @memberof ImportFilePartMetadataResponse
     */
    importFilePartControls?: Array<ImportFilePartControlResponse>;
}

/**
 * Check if a given object implements the ImportFilePartMetadataResponse interface.
 */
export function instanceOfImportFilePartMetadataResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ImportFilePartMetadataResponseFromJSON(json: any): ImportFilePartMetadataResponse {
    return ImportFilePartMetadataResponseFromJSONTyped(json, false);
}

export function ImportFilePartMetadataResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImportFilePartMetadataResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'blockPosition': !exists(json, 'blockPosition') ? undefined : json['blockPosition'],
        'blockSeparator': !exists(json, 'blockSeparator') ? undefined : json['blockSeparator'],
        'rowTypes': !exists(json, 'rowTypes') ? undefined : ((json['rowTypes'] as Array<any>).map(RowTypeMetadataResponseFromJSON)),
        'importFilePartControls': !exists(json, 'importFilePartControls') ? undefined : ((json['importFilePartControls'] as Array<any>).map(ImportFilePartControlResponseFromJSON)),
    };
}

export function ImportFilePartMetadataResponseToJSON(value?: ImportFilePartMetadataResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'blockPosition': value.blockPosition,
        'blockSeparator': value.blockSeparator,
        'rowTypes': value.rowTypes === undefined ? undefined : ((value.rowTypes as Array<any>).map(RowTypeMetadataResponseToJSON)),
        'importFilePartControls': value.importFilePartControls === undefined ? undefined : ((value.importFilePartControls as Array<any>).map(ImportFilePartControlResponseToJSON)),
    };
}

